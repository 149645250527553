import React, { Component } from "react";
import "./npsdashboard.css";
import { t } from "i18next";
import {  BreadCrumb, Button, TitleView,
  CardDetailContainer,SideModel , Toast,
  ProgressDialog
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getUserId} from "../../common";
import { INVESTMENT_CATEGORY } from "../../constants";
import { getFormatAmount ,getFinancialMarketPath} from "../../common";
import * as logger from "../../utils/logger/index";

class NPSDashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditView: false,
      showModal: false,
      editing: null,
      title: "",
      investmentData: []
    };
    this.isRenderingAlready = false;
  }
  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getUserInvestmentByCategory();
    }
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddMoreView() {
    this.props.navigate("/npshome");
  }

  onPressSaveButton() {
    this.props.navigate("/financialdetails");
  }

  onClickEditIcon = (selectedItem) => {
    this.props.navigate('/npsdetailpage', {
      state: { investmentData: selectedItem.item, isEditView: true },
    });
  };

  onPressNo = () => {
    this.setState({ showModal: false });
  };

  onPressYes = (selectedItem) => {
    this.deleteInvestment(selectedItem.item);
  };

  getTotalBalance(list) {
    const totalArray = [];
    list.forEach((item) => {
      totalArray.push({
        money: item.currentvalue,
      });
    });
    const total = (totalArray.reduce((a, v) => a = a + v.money, 0))
    return total;
  }
  prepareCardData(list) {
    const displayData = [];
    list.forEach((item) => {
      displayData.push({
        title: "NPS",
        currencyLabel: "Current value",
        money: item.currentvalue,
        period :item.maturityyear,
        item,
      });
    });
    return displayData;
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }
  getUserInvestmentByCategory() {
    const data = {
      uuid: getUserId(),
      category: INVESTMENT_CATEGORY.NPS,
    };

    this.setState({ showProgress: true }, () => {
      Bl.Investment.getUserInvestmentByCategory(data)
        .then((list) => {
          this.setState({ showProgress: false, investmentData: list });
        })
        .catch((error) => {
          logger.error({error})
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  deleteInvestment(item) {
    const investmentObj = new Bl.Investment(item);
    this.setState({ showProgress: true }, () => {
      investmentObj.deleteInvestment()
        .then(() => {
          const newList = this.state.investmentData && this.state.investmentData.filter(e => e.id !== item.id)
          this.setState({ investmentData: newList, showProgress: false })
        })
        .catch((error) => {
          logger.error({error})
          this.showToast(t("someErrorHasOccured"));
        });
    })

  }
 

  renderNavView() {
    const items = getFinancialMarketPath("NPS");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFM2");
    return <TitleView title={title} information={""} />;
  }

  renderExpectedValueView() {
    const totalBalance = this.getTotalBalance(this.state.investmentData)
    const displayAmount = getFormatAmount(totalBalance);
    return (
      <div className="npsdb_expect_value_view">
        <label className="npsdb_value_text">
          {t("npsDBValue")}: ₹ {displayAmount}
        </label>
      </div>
    );
  }

  renderCardView() {
    const cardData = this.prepareCardData(this.state.investmentData);
    return (
      <div className="npsdb_card_view">
        {cardData.map((card, index) => (
          <CardDetailContainer
            key={index}
            title={card.title}
            currentValue={card.currencyLabel}
            money={card.money}
            extraContent={card.extraContent}
            onClickEdit={() => this.onClickEditIcon(card)}
            onPressNo={this.onPressNo.bind(this)}
            onPressYes={() => this.onPressYes(card)}

          />
        ))}
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="npsdb_content_view">
        {this.renderTitleView()}
        {this.renderExpectedValueView()}
        {this.renderCardView()}
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="npsdb_bottomView">
        <div className="npsdb_buttonView">
          <div className="npsdb_addmore_button">
            <Button
              buttonName={t("npsDBButton1")}
              emptyButton={true}
              onPressButton={this.onPressAddMoreView.bind(this)}
            />
          </div>
          <div className="npsdb_save">
            <Button
              buttonName={t("npsDBButton2")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="npsdb_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default NPSDashBoard;
