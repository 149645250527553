import { useState } from "react";
import { CSVLink } from "react-csv";
import { t } from "i18next";
import { TabSelectionView } from "../../components";
import "./cashladderviewdetails.css";
import CashLadderDetailsTable from "./cashladderdetailstable";

const CashLadderViewDetails = (props) => {
  const cellWidth = 150;
  const [activeTabIndex, setActiveTabIndex] = useState(0);



  const goalColumnData = [
    {
      header: "Year",
      dataKey: "cashflowyear",
      width: cellWidth,
      fixed: true,
      resizable: true,
    },
    {
      header: "Age",
      dataKey: "cashflowage",
      width: cellWidth,
      fixed: true,
      resizable: true,

    },
    {
      header: "Goal Schedule",
      dataKey: 'flowamount',
      align: "center",
      columns: props.goalData?.map((data, index) => {
        return {
          header: props.goalDetails[index],
          dataKey: `data[${data}].flowamount`,
          width: cellWidth,
          resizable: true,
        }
      })
    },
    {
      header: "Total Goals Amount (per year)",
      dataKey: "totalgoal",
      width: cellWidth,
      resizable: true,
    },
  ];

  const incomeColumnData = [
    {
      header: "Year",
      dataKey: "cashflowyear",
      width: cellWidth,
      fixed: true,
      resizable: true,
    },
    {
      header: "Age",
      dataKey: "cashflowage",
      width: cellWidth,
      fixed: true,
      resizable: true,
    },
    {
      header: "Income Schedule",
      dataKey: 'flowamount',
      align: "center",
      columns: props.incomeData?.map((data, index) => {
        return {
          header: props.incomeDetails[index],
          dataKey: `data[${data}].flowamount`,
          width: cellWidth,
          fixed: true,
          resizable: true,
        }
      })
    },
    {
      header: "Total Income(per year)",
      dataKey: "totalincome",
      width: cellWidth,
      resizable: true,
    },
  ];

  const cashLadderColumnData = [

    {
      header: "Year",
      dataKey: "cashflowyear",
      fixed: true,
      resizable: true,
    },
    {
      header: "Age",
      dataKey: "cashflowage",
      fixed: true,
      resizable: true,
    },
    {
      header: "Total Goal",
      dataKey: "goal",
      width: cellWidth,
      resizable: true,
    },
    {
      header: "Total Income",
      dataKey: "income",
      width: cellWidth,
      resizable: true,
    },
    {
      header: "Net Cashflow(I-G)",
      dataKey: "netflow",
      width: cellWidth,
      resizable: true,
    },
    {
      header: "Normalized Net Cashflow",
      dataKey: "nnf",
      width: cellWidth,
      resizable: true,
    },
    {
      header: "Beginning of the year Bucket balance",
      align: "center",
      columns: [
        {
          header: "Secure",
          dataKey: "Begin_sb_start_bal",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Growth",
          dataKey: "Begin_gb_start_bal",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Total",
          dataKey: "total_year_end_balance",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
      ],
    },
    {
      header: "Beginning of the year starting Balance",
      dataKey: "Begin_start_bal",
      width: cellWidth,
      resizable: true,
    },
    {
      header: "Secure Bucket",
      align: "center",
      columns: [
        {
          header: "Required LS",
          dataKey: "sb_required_LS",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Actual LS (C)",
          dataKey: "sb_actual_ls",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Actual LS (A)",
          dataKey: "sb_actual_ls_A",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Required SIP",
          dataKey: "sb_required_sip",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "SIP Amount (Monthly SIP)",
          dataKey: "sb_sip_amount",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
      ],
    },
    {
      header: "Growth Bucket",
      align: "center",
      columns: [
        {
          header: "Required LS",
          dataKey: "gb_required_LS",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Actual LS (C)",
          dataKey: "gb_actual_LS",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Actual LS (A)",
          dataKey: "gb_actual_LS_A",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Required SIP",
          dataKey: "gb_required_sip",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "SIP Amount (Monthly SIP)",
          dataKey: "gb_sip_amount",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
      ],
    },
    {
      header: "Portfolio year end balance",
      align: "center",
      columns: [
        {
          header: "Secure",
          dataKey: "sb_port_year_end_balance",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Growth",
          dataKey: "gb_port_year_end_balance",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
        {
          header: "Total",
          dataKey: "total_port_year_end_balance",
          width: cellWidth,
          fixed: true,
          resizable: true,
        },
      ],
    },
  ];

  const onSelectTabItem = (activeTab, activeTabIndex) => {
    setActiveTabIndex(activeTabIndex)
  };

  const getFileName = () => {
    switch (activeTabIndex) {
      case 0:
        return 'Goal Payout Schedule';
      case 1:
        return 'Income Schedule';
      case 2:
        return 'Cash Ladder Details';
      default:
        break;
    }
  }

  const prepareGoalCsvData = () => {
    if (props.goalValues?.length > 0) {
      const csvData = [
        ["", "", "Goal Payout Schedule", ...props.goalData?.map(() => ""), ""],
        ["Year", "Age", ...props.goalDetails?.map((detail, index) => detail), "Total Goal Amount"],
        ...props.goalValues?.map(({ cashflowyear, cashflowage, data, totalgoal }) =>
          [
            cashflowyear,
            cashflowage,
            ...props.goalData?.map((item) => data[item]?.flowamount ? data[item]?.flowamount : ''),
            totalgoal,
          ]
        ),
      ];

      return csvData;
    } else {
      return []
    }
  }

  const prepareIncomeCsvData = () => {
    if (props.incomeValues?.length > 0) {
      const csvData = [
        ["", "", "Income Schedule", ...props.incomeData?.map(() => ""), ""],
        ["Year", "Age", ...props.incomeDetails?.map((detail, index) => detail), "Total Income"],
        ...props.incomeValues?.map(({ cashflowyear, cashflowage, data, totalincome }) =>
          [
            cashflowyear,
            cashflowage,
            ...props.incomeData?.map((item) => data[item]?.flowamount ? data[item]?.flowamount : ''),
            totalincome,
          ]
        ),
      ];

      return csvData;
    } else {
      return [];
    }
  }

  const prepareCashladderCsvData = () => {
    if (props.cashLadderValues?.length > 0) {
      const csvData = [
        ["", "", "", "", "", "", "Beginning of the year Bucket balance", "", "", "", "Secure Bucket", "", "", "", "", "Growth Bucket", "", "", "", "", "Portfolio year end balance", "", ""],
        ["Year", "Age", "Total Goal", "Total Income", "Net Cashflow(I-G)", "Normalized Net Cashflow", "Secure", "Growth", "Total", "Beginning of the year starting Balance", "Required LS", "Actual LS (C)", "Actual LS (A)", "Required SIP", "SIP Amount (Monthly SIP)", "Required LS", "Actual LS (C)", "Actual LS (A)", "Required SIP", "SIP Amount (Monthly SIP)", "Secure", "Growth", "Total"],
        ...props.cashLadderValues?.map(({ cashflowyear, cashflowage, goal, income, netflow, nnf, Begin_sb_start_bal, Begin_gb_start_bal, total_year_end_balance, Begin_start_bal, sb_required_LS, sb_actual_ls, sb_actual_ls_A, sb_required_sip, sb_sip_amount, gb_required_LS, gb_actual_LS, gb_actual_LS_A, gb_required_sip, gb_sip_amount, sb_port_year_end_balance, gb_port_year_end_balance, total_port_year_end_balance }) => [
          cashflowyear,
          cashflowage,
          goal,
          income,
          netflow,
          nnf,
          Begin_sb_start_bal,
          Begin_gb_start_bal,
          total_year_end_balance,
          Begin_start_bal,
          sb_required_LS,
          sb_actual_ls,
          sb_actual_ls_A,
          sb_required_sip,
          sb_sip_amount,
          gb_required_LS,
          gb_actual_LS,
          gb_actual_LS_A,
          gb_required_sip,
          gb_sip_amount,
          sb_port_year_end_balance,
          gb_port_year_end_balance,
          total_port_year_end_balance
        ]),
      ];

      return csvData;
    } else {
      return []
    }
  }

  const getCsvData = () => {
    switch (activeTabIndex) {
      case 0:
        return prepareGoalCsvData();
      case 1:
        return prepareIncomeCsvData();
      case 2:
        return prepareCashladderCsvData();
      default:
        break;
    }
  }

  const renderFirstTabContent = () => {
    return (
      <CashLadderDetailsTable
        data={props.goalValues}
        columnGroups={goalColumnData}
      />
    );
  };

  const renderSecondTabContent = () => {
    return (
      <CashLadderDetailsTable
        data={props.incomeValues}
        columnGroups={incomeColumnData}
      />
    );
  };

  const renderThirdTabContent = () => {
    return (
      <CashLadderDetailsTable
        data={props.cashLadderValues}
        columnGroups={cashLadderColumnData}
        isNegativeDataDisplay={true}
      />
    );
  };

  const renderTabView = () => {
    const tabs = [
      {
        title: t("Goal Payout Schedule"),
        content: renderFirstTabContent,
      },
      {
        title: t("Income Schedule"),
        content: renderSecondTabContent,
      },
      {
        title: t("Cash Ladder Details"),
        content: renderThirdTabContent,
      },
    ];

    return (
      <div className="cvd_tabSelectionView">
        <TabSelectionView
          tabItems={tabs.map((tab) => tab?.title)}
          tabContents={tabs.map((tab) => tab?.content)}
          onSelectTabItem={onSelectTabItem}
        />
      </div>
    );
  };

  const renderDownloadButtonView = () => {
    const csvData = getCsvData();
    const filename = getFileName();
    return (
      <CSVLink
        className="cvd_downloadView"
        filename={`${filename}.csv`}
        data={csvData}>
        {'Download'}
      </CSVLink>
    );
  }
  return (
    <div className={"cvd_container"}>
      {renderTabView()}
      {renderDownloadButtonView()}
    </div>
  );
};

export default CashLadderViewDetails;
