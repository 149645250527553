import React, { Component } from "react";
import "./jewelleryhome.css";
import { t } from "i18next";
import Jewellery from "../../../assets/svgs/physicalasset/jewellery_home.svg";
import {
  BreadCrumb,
  Button,
  TitleView, SideModel
} from '../../components';
import * as Bl from "../../../businesslogic";
import {
  FIXED_ASSETS_TYPE,
} from "../../constants";
import { getUserId } from "../../common";
import { getInvestmentPhysicalPath } from "../../common";
import * as logger from "../../utils/logger/index"

const newFixedAssets = {
  fixedassettype: FIXED_ASSETS_TYPE.JEWELLERY,
  currentvalue: 0,
  fixedassetdescription: "",
  assetinflation: 0,
  targetliquidationyear: 0,
  userid: "",
  expectedliquidationvalue: 0,
  isrented: false,
  assetdetails: [],
  fixedassetuuid: getUserId(),
  isliquidated: false,
  fvatretirement: 0,
  targetliquidationage: 0,
  isincludefinancialplan: false
};
class JewelleryHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newFixedAssetsData: new Bl.FixedAsset({...newFixedAssets, userid: getUserId()}),
      fixedAssetData: [],
    }
  }
  
  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddValues() {
    logger.debug("test")
    this.props.navigate('/jewellerydetail', {
      state: { fixedAssetsData: this.state.newFixedAssetsData, isEditView: false },
    });
  }

  

  renderNavView() {
    const items = getInvestmentPhysicalPath("Jewellery");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsPA2");
    return <TitleView title={title} />;
  }

  renderProfileView() {
    return (
      <div className="jeh_profile_view">
        <img
          src={Jewellery}
          alt={t("networthAssetsPA2")}
          className="jeh_icon"
        />
        <div className="jeh_add_property">
          <Button
            buttonName={t("jewellButton")}
            onPressButton={this.onPressAddValues.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }

  renderContentView() {
    return (
      <div className="jeh_content_view">
        {this.renderTitleView()}
        {this.renderProfileView()}
      </div>
    );
  }

  render() {
    return (
      <div className="jeh_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default JewelleryHome;
