import React from "react";
import "./businessdetails.css"
import { t } from "i18next";
import {
  Header,
  TitleView,
  MyDivider,
  Button,
  ToggleSwitch,
  FloatingLabelInput,
  BreadCrumb,
  Slider,
  ProgressDialog,
  Toast,
} from "../../components";
import {
  calculate_age,
  getFrequencyType,
  getIncomePathName,
  getUserDOB,
} from "../../common";
import {
  INCOME_FREQUENCY_TYPE,
  INCOME_TYPE,
  DEFAULT_OFFSET
} from "../../constants";
import { getCurrentYear } from "../../utils/datetime";
import * as Bl from "../../../businesslogic";
import * as logger from "../../utils/logger/index";
import { getCurrentAge } from "../../utils/common";

class BusinessDetails extends React.Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const incomeData = params?.incomeData ? params.incomeData : "";
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      incomeData: incomeData ? new Bl.Income(incomeData) : {},
      isEditView,
      currentAge: 0,
      startValue: getCurrentYear() + 1,
      endValue: getCurrentYear() + 2,
    };
    this.isRenderingAlready = false;
    this.dbData = incomeData
      ? JSON.parse(JSON.stringify(new Bl.Income(incomeData)))
      : {};
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getCurrentAge();
    }
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("Pension/AnnuityPage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  onSelectItem(path) {
    const localData = new Bl.Income(this.state.incomeData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeDescription(value) {
    const income = this.state.incomeData;
    income.setIncomeDescription(value);
    this.forceUpdate();
  }

  onChangeFrequency(value) {
    const income = this.state.incomeData;
    const frequency = getFrequencyType(value);
    income.setFrequencyType(frequency);
    this.forceUpdate();
  }

  onChangePension(value) {
    const income = this.state.incomeData;
    income.setIncomeAmount(value ? Number(value) : 0);
    this.forceUpdate();
  }

  onChangeGrowthPension(value) {
    const income = this.state.incomeData;
    income.setIncomeGrowthRate(value);
    this.forceUpdate();
  }

  onPressSaveButton() {
    if (this.state.isEditView) {
      this.updateUserIncome();
    } else {
      this.createUserIncome();
    }
  }

  handleToggleSwitchChange(selectedItem, isSelected) {
    const income = this.state.incomeData;
    income.incometype = isSelected
      ? INCOME_TYPE.RECURRING_PERPETUAL
      : INCOME_TYPE.RECURRING_FINITE;
    if (income.incometype === INCOME_TYPE.RECURRING_PERPETUAL) {
      income.setIncomeEndYear(0);
      income.setIncomeEndAge(0);
    }
    this.forceUpdate();
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const startAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const income = this.state.incomeData;
    income.setIncomeStartYear(newValue ? Number(newValue) : newValue);
    income.setIncomeStartAge(startAge)
    this.forceUpdate();
  }

  handleChangeEnd(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const endAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const income = this.state.incomeData;
    const isValid = this.checkIsEndYearValid(income.incomestartyear, newValue);
    if (isValid) {
      income.setIncomeEndYear(newValue ? Number(newValue) : newValue);
      income.setIncomeEndAge(endAge)
      this.forceUpdate();
    } else {
      this.showToast(t("businessToastYear"));
    }
  }

  createUserIncome() {
    const currentYear = getCurrentYear();
    const incomeObj = new Bl.Income(this.state.incomeData);
    const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
    const endAge = calculate_age(this.state.endValue, currentYear, this.state.currentAge)
    if (incomeObj.incomestartyear === 0) {
      incomeObj.setIncomeStartYear(getCurrentYear() + 1);
      incomeObj.setIncomeStartAge(startAge)
    }
    if (
      incomeObj.incometype === INCOME_TYPE.RECURRING_FINITE &&
      incomeObj.incomeendyear === 0
    ) {
      incomeObj.setIncomeEndYear(getCurrentYear() + 2);
      incomeObj.setIncomeEndAge(endAge)
    }
    const isDataValid = this.checkIsDataValid(incomeObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        incomeObj
          .createIncome()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/businessdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error })
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserIncome() {
    const incomeObj = new Bl.Income(this.state.incomeData);
    const incomeData = this.state.incomeData;
    const currentYear = getCurrentYear();
    const endValue = incomeData?.incomeendyear
      ? incomeData.incomeendyear
      : currentYear + 2;
    const endAge = calculate_age(endValue, currentYear, this.state.currentAge)
    if (incomeObj.incometype === INCOME_TYPE.RECURRING_PERPETUAL) {
      incomeObj.setIncomeEndYear(0);
      incomeObj.setIncomeEndAge(0)
    }
    if (incomeObj.incometype === INCOME_TYPE.RECURRING_FINITE && incomeObj.incomeendyear === 0) {
      incomeObj.setIncomeEndYear(getCurrentYear() + 2);
      incomeObj.setIncomeEndAge(endAge)
    }
    const isDataValid = this.checkIsDataValid(incomeObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        incomeObj
          .updateIncome()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/businessdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error })
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!data.incomedescription) {
      isValid = false;
      message = t("businessToastDescription");
    } else if (!this.isAmountValid(data.incomeamount)) {
      isValid = false;
      message = t("businessToastAmount");
    } else if (
      !this.isStartEndYearValid(
        data.incometype,
        data.incomestartyear,
        data.incomeendyear
      )
    ) {
      isValid = false;
      message = t("businessToastYear");
    } else if (!this.isGrowthRateValid(data.incomegrowthrate)) {
      isValid = false;
      message = t("businessToastPercentage");
    }
    return { isValid, message };
  }

  isAmountValid(amount) {
    return amount && amount > 0;
  }

  isStartEndYearValid(incomeType, startYear, endYear) {
    if (incomeType !== INCOME_TYPE.RECURRING_PERPETUAL) {
      return startYear !== 0 && startYear < endYear;
    } else {
      return startYear !== 0;
    }
  }

  isGrowthRateValid(growthrate) {
    let isValid = true;
    if (growthrate.toString().length <= 0) {
      isValid = false;
    }
    return isValid;
  }

  checkIsEndYearValid(startYear, endYear) {
    return startYear < endYear;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }



  renderNavView() {
    const items = getIncomePathName("Business");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.incomeData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("businessTitle");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = []
  ) {
    return (
      <div className="blt_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
        />
      </div>
    );
  }

  renderDescriptionInput() {
    const incomeData = this.state.incomeData;
    const description = incomeData?.incomedescription
      ? incomeData.incomedescription
      : "";
    return (
      <div id="blt_top_desc_input">
        {this.renderInputView(
          t("businessInputDescription"),
          description,
          this.onChangeDescription.bind(this)
        )}
      </div>
    );
  }

  renderPensionToggle() {
    const income = this.state.incomeData;
    const type =
      income?.incometype === INCOME_TYPE.RECURRING_PERPETUAL
        ? t("businessToggle1")
        : t("businessToggle2");
    return (
      <div id="blt_top_toggle_field">
        <label id="blt_top_toggle_label">{t("businessToggle")}</label>
        <div className="blt_toggle">
          <ToggleSwitch
            checked={type === t("businessToggle1")}
            checkedChildren={t("businessToggle1")}
            unCheckedChildren={t("businessToggle2")}
            onChangeToggle={this.handleToggleSwitchChange.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderFrequencySelection() {
    const incomeData = this.state.incomeData;
    const frequencytype = incomeData?.frequencytype
      ? t(incomeData.frequencytype)
      : t(INCOME_FREQUENCY_TYPE.YEARLY);
    return (
      <div className="blt_bot_top_input">
        {this.renderInputView(
          t("businessInputFrequency"),
          frequencytype,
          this.onChangeFrequency.bind(this),
          false,
          false,
          true,
          [
            t(INCOME_FREQUENCY_TYPE.YEARLY),
            t(INCOME_FREQUENCY_TYPE.HALF_YEARLY),
            t(INCOME_FREQUENCY_TYPE.QUARTERLY),
            t(INCOME_FREQUENCY_TYPE.MONTHLY),
          ]
        )}
      </div>
    );
  }

  renderPensionAmount() {
    const incomeData = this.state.incomeData;
    const incomeamount = incomeData?.incomeamount
      ? incomeData.incomeamount
      : "";
    return (
      <div className="blt_bot_top_input">
        {this.renderInputView(
          t("businessInputIncome"),
          incomeamount,
          this.onChangePension.bind(this),
          true
        )}
      </div>
    );
  }

  renderGrowthPension() {
    const incomeData = this.state.incomeData;
    const incomegrowthrate = incomeData?.incomegrowthrate;
    return (
      <div className="blt_bot_bot_input">
        {this.renderInputView(
          t("businessInputPercentage"),
          incomegrowthrate,
          this.onChangeGrowthPension.bind(this),
          false,
          true
        )}
      </div>
    );
  }

  renderStartYear() {
    const currentYear = getCurrentYear();
    const incomeData = this.state.incomeData;
    const startValue = incomeData?.incomestartyear
      ? incomeData.incomestartyear
      : currentYear + 1;
    const age = calculate_age(startValue, currentYear, this.state.currentAge);
    return (
      <div className="blt_start_year_row">
        <label className="blt_start_text">{t("businessSliderStart")}</label>
        <div className="blt_slider_content">
          <Slider
            value={startValue}
            age={age}
            min={currentYear + 1}
            max={currentYear + 51}
            markStep={10}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderEndYear() {
    const currentYear = getCurrentYear();
    const incomeData = this.state.incomeData;
    const endValue = incomeData?.incomeendyear
      ? incomeData.incomeendyear
      : currentYear + 2;
    const age = calculate_age(endValue, currentYear, this.state.currentAge);
    return (
      <div className="blt_start_year_row">
        <label className="blt_start_text">{t("businessSliderEnd")}</label>
        <div className="blt_slider_content">
          <Slider
            value={endValue}
            age={age}
            min={currentYear + 2}
            max={currentYear + 52}
            markStep={10}
            handleChange={this.handleChangeEnd.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderMiddleContent() {
    const income = this.state.incomeData;
    const isRecurring = income?.incometype === INCOME_TYPE.RECURRING_FINITE;
    return (
      <>
        <div id="blt_middle_content">
          {this.renderStartYear()}
          {isRecurring && this.renderEndYear()}
        </div>
        {!isRecurring && <MyDivider />}
      </>
    );
  }

  renderTopContent() {
    return (
      <div id="blt_top_container">
        {this.renderDescriptionInput()}
        {this.renderPensionToggle()}
      </div>
    );
  }

  renderBottomContent() {
    return (
      <div id="blt_bottom_container">
        <div id="blt_bottom_top">
          {this.renderFrequencySelection()}
          {this.renderPensionAmount()}
        </div>
        {this.renderGrowthPension()}
      </div>
    );
  }

  renderContentView() {
    return (
      <div id="blt_inner_container">
        {this.renderTitleView()}
        {this.renderTopContent()}
        <MyDivider />
        {this.renderMiddleContent()}
        {this.renderBottomContent()}
      </div>
    );
  }

  renderButton() {
    return (
      <div id="blt_bottom_view">
        <div id="blt_button_view">
          <Button
            buttonName={t("businessInputButton1")}
            onPressButton={this.onPressSaveButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div id="blt_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButton()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}
export default BusinessDetails;
