import BackendApi from "../backend/index";

class Income {
  constructor(props) {
    this.incomecategory = props.incomecategory;
    this.userid = props.userid;
    this.frequencytype = props.frequencytype;
    this.incomefrequency = props.incomefrequency;
    this.incomeamount = props.incomeamount;
    this.incomestartyear = props.incomestartyear;
    this.incomeendyear = props.incomeendyear;
    this.insertby = props.insertby;
    this.updateby = props.updateby;
    this.inserttime = props.inserttime;
    this.updatetime = props.updatetime;
    this.incometype = props.incometype;
    this.incomegrowthrate = props.incomegrowthrate;
    this.incomedescription = props.incomedescription;
    this.incomestartage = props.incomestartage;
    this.incomeendage = props.incomeendage;

    this.dbValue = {
      incomecategory: props.incomecategory,
      userid: props.userid,
      frequencytype: props.frequencytype,
      incomefrequency: props.incomefrequency,
      incomeamount: props.incomeamount,
      incomestartyear: props.incomestartyear,
      incomeendyear: props.incomeendyear,
      insertby: props.insertby,
      updateby: props.updateby,
      inserttime: props.inserttime,
      updatetime: props.updatetime,
      incometype: props.incometype,
      incomegrowthrate: props.incomegrowthrate,
      incomedescription: props.incomedescription,
      incomestartage: props.incomestartage,
      incomeendage: props.incomeendage,
    };

    if (props.id) {
      this.id = props.id;
      this.dbValue.id = props.id;
    }
  }

  setIncomeId(id) {
    this.id = id;
    this.dbValue.id = id;
  }

  setIncomeCategory(incomecategory) {
    this.incomecategory = incomecategory;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setFrequencyType(frequencytype) {
    this.frequencytype = frequencytype;
  }

  setIncomeFrequency(incomefrequency) {
    this.incomefrequency = incomefrequency;
  }

  setIncomeAmount(incomeamount) {
    this.incomeamount = incomeamount;
  }

  setIncomeStartYear(incomestartyear) {
    this.incomestartyear = incomestartyear;
  }

  setIncomeEndYear(incomeendyear) {
    this.incomeendyear = incomeendyear;
  }

  setInsertBy(insertby) {
    this.insertby = insertby;
  }

  setUpdateBy(updateby) {
    this.updateby = updateby;
  }

  setInsertTime(inserttime) {
    this.inserttime = inserttime;
  }

  setUpdateTime(updatetime) {
    this.updatetime = updatetime;
  }

  setIncomeType(incometype) {
    this.incometype = incometype;
  }

  setIncomeGrowthRate(incomegrowthrate) {
    this.incomegrowthrate = incomegrowthrate;
  }

  setIncomeDescription(incomedescription) {
    this.incomedescription = incomedescription;
  }

  setIncomeStartAge(incomestartage) {
    this.incomestartage = incomestartage;
  }

  setIncomeEndAge(incomeendage){
    this.incomeendage = incomeendage;
  }

  revertIncomeCategory() {
    this.incomecategory = this.dbValue.incomecategory;
  }

  revertUserId() {
    this.userId = this.dbValue.userId;
  }

  revertFrequencyType() {
    this.frequencytype = this.dbValue.frequencytype;
  }

  revertIncomeFrequency() {
    this.incomefrequency = this.dbValue.incomefrequency;
  }

  revertIncomeAmount() {
    this.incomeamount = this.dbValue.incomeamount;
  }

  revertIncomeStartYear() {
    this.incomestartyear = this.dbValue.incomestartyear;
  }

  revertIncomeEndYear() {
    this.incomeendyear = this.dbValue.incomeendyear;
  }

  revertInsertBy() {
    this.insertby = this.dbValue.insertby;
  }

  revertupdateBy() {
    this.updateby = this.dbValue.updateby;
  }

  revertInsertTime() {
    this.inserttime = this.dbValue.inserttime;
  }

  revertUpdateTime() {
    this.updatetime = this.dbValue.updatetime;
  }

  revertIncomeType() {
    this.incometype = this.dbValue.incometype;
  }

  revertIncomeGrowthRate() {
    this.incomegrowthrate = this.dbValue.incomegrowthrate;
  }

  revertIncomeDescription() {
    this.incomedescription = this.dbValue.incomedescription;
  }

  revertIncomeStartAge() {
    this.incomestartage = this.dbValue.incomestartage;
  }

  revertIncomeEndAge() {
    this.incomeendage = this.dbValue.incomeendage;
  }


  revertAllChanges() {
    this.revertIncomeCategory();
    this.revertUserId();
    this.revertFrequencyType();
    this.revertIncomeFrequency();
    this.revertIncomeAmount();
    this.revertIncomeStartYear();
    this.revertIncomeEndYear();
    this.revertIncomeType();
    this.revertIncomeGrowthRate();
    this.revertIncomeDescription();
    this.revertIncomeStartAge();
    this.revertIncomeEndAge();
  }

  createIncome() {
    this.setInsertBy("Fliber");
    this.setInsertTime(new Date());
    this.setUpdateBy("");
    this.setUpdateTime(0);

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.IncomeApi.createIncome(this)
        .then((response) => {
          const incomeId = response && response.id ? response.id : "";
          this.setIncomeId(incomeId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateIncome() {
    this.setInsertBy("Fliber");
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.IncomeApi.updateIncome(this)
        .then((response) => {
          const incomeId = response && response.id ? response.id : "";
          this.setIncomeId(incomeId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteIncome() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.IncomeApi.deleteIncome(this)
      .then((response) => {
        resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  static getUserIncomesByUserId(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.IncomeApi.getUserIncomesByUserId(data)
        .then((incomeList) => {
          const incomes = [];
          if (incomeList && incomeList.length > 0) {
            incomeList.forEach((item) => {
              const incomeObj = new Income(item);
              incomes.push(incomeObj);
            });
          }
          resolve(incomes);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserIncomesByCategory(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.IncomeApi.getUserIncomesByCategory(data)
        .then((incomeList) => {
          const incomes = [];
          if (incomeList && incomeList.length > 0) {
            incomeList.forEach((item) => {
              const incomeObj = new Income(item);
              incomes.push(incomeObj);
            });
          }
          resolve(incomes);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserIncomeConfig() {
    return new Promise((resolve,reject) => {
      BackendApi.ServerApi.IncomeApi.getUserIncomeConfig()
      .then((configList) => {
        resolve(configList)
      })
      .catch((error) => {
        reject(error)
      })
    }
    )
  }
}

export default Income;
