import React, { useEffect, useRef, useState } from "react";
import "./assetallocationdetailspage.css";
import { t } from "i18next";
import * as Bl from "../../businesslogic";
import {
  TitleView,
  Button,
  BreadCrumb,
  MultiRangeSlider,
  ProgressDialog,
  Toast,
  DisplayAlertView,
} from "../components";
import {
  getAssetAllocationPathName,
  getRiskProfileDescription,
  getUserDetails,
  getUserId,
  setSelectedUserDetail,
} from "../common";
import * as logger from "../utils/logger/index";

const AssetAllocDetails = (props) => {
  const [state, setState] = useState({
    status: "",
    showToast: false,
    toastMessage: "",
    showProgress: false,
    progressMessage: "",
    sb_equityAlloPer: 0,
    sb_debtAlloPer: 100,
    sb_commodityAlloPer: 0,
    gb_equityAlloPer: 0,
    gb_debtAlloPer: 100,
    gb_commodityAlloPer: 0,
    cashLadderValues: {},
    offsetValue: 2,
    showButton: false,
    showEffectiveRate: false,
    userData: {}
  });
  const [effectiveData, setEffectiveData] = useState(null);
  const isRenderedAlready = useRef(false);
  const effectiveRateRef = useRef(null);
  const userId = getUserId();

  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      getUserRiskProfile();
      getUserData();
      getAssetAllocation();
    }
  }, []);

  const onClickBackIcon = (path) => {
    props.navigate(path);
  };

  const onChangeGrowthSliderValue = (min, max) => {
    const equity = Number(min);
    const debt = Number(max - min);
    const others = Number(100 - (equity + debt));
    setState((prevState) => ({
      ...prevState,
      gb_equityAlloPer: equity,
      gb_debtAlloPer: debt,
      gb_commodityAlloPer: others,
      showEffectiveRate: false,
      showButton: false
    }));
  };

  const onChangeSecureSliderValue = (min, max) => {
    const equity = Number(min);
    const debt = Number(max - min);
    const others = Number(100 - (equity + debt));
    setState((prevState) => ({
      ...prevState,
      sb_equityAlloPer: equity,
      sb_debtAlloPer: debt,
      sb_commodityAlloPer: others,
      showEffectiveRate: false,
      showButton: false
    }));
  };

  const createAssetAllocation = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const data =
    {
      secure_bucket: [
        {
          assetclass: "EQUTY",
          allocationpercentage: state.sb_equityAlloPer,
          startyear: currentYear,
          endyear: currentYear,
        },
        {
          assetclass: "DEBT",
          allocationpercentage: state.sb_debtAlloPer,
          startyear: currentYear,
          endyear: currentYear,
        },
        {
          assetclass: "CMODT",
          allocationpercentage: state.sb_commodityAlloPer,
          startyear: currentYear,
          endyear: currentYear,
        },
      ],
      growth_bucket: [
        {
          assetclass: "CMODT",
          allocationpercentage: state.gb_commodityAlloPer,
          startyear: currentYear,
          endyear: currentYear,
        },
        {
          assetclass: "EQUTY",
          allocationpercentage: state.gb_equityAlloPer,
          startyear: currentYear,
          endyear: currentYear,
        },
        {
          assetclass: "DEBT",
          allocationpercentage: state.gb_debtAlloPer,
          startyear: currentYear,
          endyear: currentYear,
        },
      ],
    }
    console.log({ data })
    Bl.CashLadder.createAssetAllocation(userId, data)
      .then(async (response) => {
        console.log("response for create assetallocation", response)

        const userId = getUserId();
        const userDetail = await Bl.User.getUserDetailsById(userId)
        setSelectedUserDetail(userDetail.User)
        
        props.navigate("/cashladder", {
          state: {
            isFromAssetAllocation: true,
            effectiveData: {
              sbeffectiverate: Number((effectiveData.secure_bucket / 100).toFixed(4)),
              gbeffectiverate: Number((effectiveData.growth_bucket / 100).toFixed(4)),
            }
          },
        });
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occured"));
      });
  };

  const onPressUpdateCashladder = () => {
    getUserData()
  }

  const onPressCEButton = () => {
    const data = {
      "sbequity": state.sb_equityAlloPer,
      "sbdebt": state.sb_debtAlloPer,
      "sbpthers": state.sb_commodityAlloPer,
      "gbequity": state.gb_equityAlloPer,
      "gbdebt": state.gb_debtAlloPer,
      "gbpthers": state.gb_commodityAlloPer
    }
    Bl.CashLadder.createCalculateEffectiveRate(data)
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          setEffectiveData(response.data[0])
        }
        setState((prevState) => ({
          ...prevState,
          showButton: true,
          showEffectiveRate: true
        }));
        effectiveRateRef.current.scrollIntoView({ behavior: "smooth" }); // Update the state with the calculated effective rates
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occurred while calculating the effective rate"));
      });
  };

  const onPressNextButton = () => {
    const { showButton } = state;
    if (!showButton) {
      return;
    }
    createAssetAllocation();
  };

  const getUserRiskProfile = () => {
    const data = {
      uuid: userId,
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    Bl.RiskProfile.getUserRiskProfile(data)
      .then((response) => {
        const categoryType = response?.finalriskcategory;
        const status = getRiskProfileDescription(categoryType);
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          status: status,
        }));
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("User did not answer the question"));
      });
  };

  const getUserData = () => {
    const userData = getUserDetails();
    setState((prevState) => ({
      ...prevState,
      userData
    }));
  }

  const getAssetAllocation = () => {
    const data = {
      uuid: userId,
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    Bl.CashLadder.getAssetAllocationDetails(data)
      .then((response) => {
        if (response) {
          let gb_equityAlloPer = 0;
          let gb_debtAlloPer = 100;
          let gb_commodityAlloPer = 0;
          let sb_equityAlloPer = 0;
          let sb_debtAlloPer = 100;
          let sb_commodityAlloPer = 0;

          if (response.growth_bucket) {
            response.growth_bucket.forEach((growth) => {
              if (growth.assetclass === "EQUTY") {
                gb_equityAlloPer = growth.allocationpercentage;
              } else if (growth.assetclass === "CMODT") {
                gb_commodityAlloPer = growth.allocationpercentage;
              } else if (growth.assetclass === "DEBT") {
                gb_debtAlloPer = growth.allocationpercentage;
              }
            });
          }

          if (response.secure_bucket) {
            response.secure_bucket.forEach((secure) => {
              if (secure.assetclass === "EQUTY") {
                sb_equityAlloPer = secure.allocationpercentage;
              } else if (secure.assetclass === "CMODT") {
                sb_commodityAlloPer = secure.allocationpercentage;
              } else if (secure.assetclass === "DEBT") {
                sb_debtAlloPer = secure.allocationpercentage;
              }
            });
          }

          setState((prevState) => ({
            ...prevState,
            showProgress: false,
            gb_equityAlloPer,
            gb_debtAlloPer,
            gb_commodityAlloPer,
            sb_equityAlloPer,
            sb_debtAlloPer,
            sb_commodityAlloPer,
          }));
        } else {
          // Handle the case when the response is not as expected
          showToast(t("Invalid response received"));
        }
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occurred"));
      });
  };

  const hideToast = () => {
    setState((prevState) => ({
      ...prevState,
      toastMessage: "",
      showToast: false,
    }));
  };

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      toastMessage,
      showToast: true,
      showProgress: false,
    }));
    setTimeout(() => {
      hideToast();
    }, 3000);
  };

  const renderNavView = () => {
    const items = getAssetAllocationPathName();
    const location = props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={onClickBackIcon}
      />
    );
  };

  const renderTitleView = () => {
    const title = t("AssetAllocation: Portfolio Asset Allocation");
    return <TitleView title={title} information={""} />;
  };

  const renderRiskProfileBalance = () => {
    const status = state.status;
    return (
      <div className="adp_risk_profile">
        <label className="adp_risk_label">
          {t("AssetAllocation: Risk profile")}
        </label>
        <label>{status}</label>
      </div>
    );
  };

  const renderCashladderUpdateView = () => {
    return (
      <div className="adp_alert_View">
        <DisplayAlertView
          onPressYes={onPressUpdateCashladder}
        />
      </div>
    );
  }

  const renderRiskNCashladderUpdateView = () => {
    return (
      <div className="adp_main_content">
        {renderRiskProfileBalance()}
        {state.userData?.iscashladderupdated && renderCashladderUpdateView()}
      </div>
    );
  }

  const renderSecureBucketView = () => {
    const data = {
      equity: state.sb_equityAlloPer,
      debt: state.sb_debtAlloPer,
      others: state.sb_commodityAlloPer,
    };
    return (
      <div className={"adp_slider_container"}>
        <label className="adp_assetTitleStyle">
          {t("AssetAllocation: Secure Bucket")}
        </label>
        <div className="adp_sliderView" ref={effectiveRateRef}>
          <MultiRangeSlider
            data={data}
            min={data.equity}
            max={data.equity + data.debt}
            labels={[
              t("AssetAllocation: Equity"),
              t("AssetAllocation: Debt"),
              t("AssetAllocation: Others"),
            ]}
            onChangeValue={onChangeSecureSliderValue}
          />
        </div>
      </div>
    );
  };

  const renderGrowthBucketView = () => {
    const data = {
      equity: state.gb_equityAlloPer,
      debt: state.gb_debtAlloPer,
      others: state.gb_commodityAlloPer,
    };
    return (
      <div className={"adp_slider_container"}>
        <label className="adp_assetTitleStyle">
          {t("AssetAllocation: Growth Bucket")}
        </label>
        <div className="adp_sliderView" ref={effectiveRateRef}>
          <MultiRangeSlider
            data={data}
            min={data.equity}
            max={data.equity + data.debt}
            labels={[
              t("AssetAllocation: Equity"),
              t("AssetAllocation: Debt"),
              t("AssetAllocation: Others"),
            ]}
            onChangeValue={onChangeGrowthSliderValue}
          />
        </div>
      </div>
    );
  };

  const renderCalculateEffectiveRate = () => {
    const secureData = effectiveData?.secure_bucket ? (effectiveData.secure_bucket).toFixed(2) : 0;
    const growthData = effectiveData?.growth_bucket ? (effectiveData.growth_bucket).toFixed(2) : 0;
    return (
      <div className="adp_cal_eff_container">
        <div className="adp_cal_eff_content">
          <label className="adp_cal_eff_secure">{t("Secure Bucket")}</label>
          <label className="adp_cal_eff_secure">{secureData}</label>
        </div>
        <div className="adp_cal_eff_content">
          <label className="adp_cal_eff_secure">{t("Growth Bucket")}</label>
          <label className="adp_cal_eff_secure">{growthData}</label>
        </div>
      </div>
    )
  }

  const renderChartContent = () => {
    return (
      <div className="adp_chart_contant">
        <div className="adp_chart_bucket">
          {renderSecureBucketView()}
          {renderGrowthBucketView()}
        </div>
        {state.showEffectiveRate &&
          <div className="adp_chart_view">
            <div className="adp_cal_title">{t("Effective Rate")}</div>
            {renderCalculateEffectiveRate()}
          </div>}
      </div>
    );
  };

  const renderButton = () => {
    const { showButton } = state;
    return (
      <div className="adp_bottom_view">
        <div className="adp_button_view">
          <Button
            buttonName={t("AssetAllocationCalculateEffectiveRate")}
            onPressButton={onPressCEButton}
          />
        </div>
        <div className="adp_button_view">
          <Button
            buttonName={t("AssetAllocation: Generate Cashladder")}
            onPressButton={onPressNextButton}
            disabled={!showButton}
            style={{ opacity: showButton ? 1 : 0.5 }}
          />
        </div>
      </div>
    );
  };

  const renderBodyContent = () => {
    return (
      <div className="adp_inner_container">
        {renderRiskNCashladderUpdateView()}
        {renderTitleView()}
        {renderChartContent()}
      </div>
    );
  };

  const renderToastView = () => {
    return (
      <Toast showToast={state.showToast} toastMessage={state.toastMessage} />
    );
  };

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  };

  return (
    <div className="adp_container">
      {renderNavView()}
      {renderBodyContent()}
      {renderButton()}
      {renderToastView()}
      {renderProgressDialog()}
    </div>
  );
};
export default AssetAllocDetails;
