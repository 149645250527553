import React, { Component } from "react";
// import { t } from "i18next";
import "./titleview.css";

class TitleView extends Component {
  render() {
    const { information, title } = this.props;
    return (
      <div className="tv_titleView">
        <label className="tv_titleStyle">{title}</label>
        <label className="tv_infoStyle">{information}</label>
      </div>
    );
  }
}

export default TitleView;
