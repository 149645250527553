import React from "react";
import { t } from "i18next";
import BaseScrn from '../basescrn';
import { ResetForm } from "../components";
import * as Bl from "../../businesslogic";
import * as logger from "../utils/logger/index";

class ResetPin extends BaseScrn {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            showToast: false,
            toastMessage: "",
            showProgress: false,
            progressMessage: "",
            showVerifyOtpView: false,
            showSetNewPin: false,
            userId: ''
        }
    }

    onPressGetOTP(emailId, dob) {
        const data = {
            "email": emailId,
            "dob": dob
        }

        this.setState({ showProgress: true }, () => {
            Bl.User.userForgetPin(data).then((response) => {
                this.setState({ showProgress: false, showVerifyOtpView: true, userId: response.id })
            }).catch((error) => {
                this.showToast(t('Please enter valid email id & date of birth'));
                logger.error({ error })
            })
        })
    }

    onPressVerifyOTP(otpArray) {
        const otp = otpArray?.join('');
        const data = {
            "id": this.state.userId,
            "email_otp": otp
        }

        this.setState({ showProgress: true }, () => {
            Bl.User.verifyEmail(data).then((response) => {
                this.setState({ showProgress: false, showVerifyOtpView: false, showSetNewPin: true })
            }).catch((error) => {
                this.showToast(t('Please enter valid otp'));
                logger.error({ error })
            })
        })
    }

    onPressContinue(newPwd, reEnterNewPwd) {
        const isDataValid = this.checkIsPasswordValid(newPwd, reEnterNewPwd);
        if (isDataValid.isValid) {
            const data = {
                "id": this.state.userId,
                "password": newPwd
            }

            this.setState({ showProgress: true }, () => {
                Bl.User.changePassword(data).then((response) => {
                    this.setState({ showProgress: false }, () => {
                        this.props.navigate('/loginscrn')
                    })
                }).catch((error) => {
                    this.showToast(t('Please enter valid password'));
                    logger.error({ error })
                })
            })
        } else {
            this.showToast(isDataValid.message);
        }
    }

    checkIsPasswordValid(newPwd, reEnterNewPwd) {
        let isValid = true;
        let message = "";
        if (newPwd?.length === 0 || reEnterNewPwd?.length === 0) {
            isValid = false;
            message = t("Please enter the password");
        } else if (newPwd !== reEnterNewPwd) {
            isValid = false;
            message = t("Password not matched.");
        } else {
            const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s)/;
            if (!regex.test(newPwd)) {
                isValid = false;
                message = t("Password should contain atleast one special character,one digit,one capital, one small letter and should not include space.");
            }
        }
        return { isValid, message };
    }

    renderFormContentView() {
        return (
            <div className="bs_formContentView">
                <ResetForm
                    navigate={this.props.navigate}
                    showVerifyOtpView={this.state.showVerifyOtpView}
                    showSetNewPin={this.state.showSetNewPin}
                    onPressGetOTP={this.onPressGetOTP.bind(this)}
                    onPressVerifyOTP={this.onPressVerifyOTP.bind(this)}
                    onPressContinue={this.onPressContinue.bind(this)}
                />
            </div>
        );
    }
}

export default ResetPin;