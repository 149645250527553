import BackendApi from "../backend/index";

class FixedAsset {
  constructor(props) {
    this.fixedassettype = props.fixedassettype;
    this.fixedassetdescription = props.fixedassetdescription;
    this.currentvalue = props.currentvalue;
    this.assetinflation = props.assetinflation;
    this.userid = props.userid;
    this.insertby = props.insertby;
    this.updatetime = props.updatetime;
    this.inserttime = props.inserttime;
    this.updateby = props.updateby;
    this.targetliquidationyear = props.targetliquidationyear;
    this.expectedliquidationvalue = props.expectedliquidationvalue;
    this.isrented = props.isrented;
    this.assetdetails = props.assetdetails;
    this.fixedassetuuid = props.fixedassetuuid;
    this.isliquidated = props.isliquidated;
    this.fvatretirement = props.fvatretirement;
    this.targetliquidationage = props.targetliquidationage;
    this.isincludefinancialplan = props.isincludefinancialplan;

    this.dbValue = {
      fixedassettype: props.fixedassettype,
      fixedassetdescription: props.fixedassetdescription,
      currentvalue: props.currentvalue,
      assetinflation: props.assetinflation,
      userid: props.userid,
      insertby: props.insertby,
      updatetime: props.updatetime,
      inserttime: props.inserttime,
      updateby: props.updateby,
      targetliquidationyear: props.targetliquidationyear,
      expectedliquidationvalue: props.expectedliquidationvalue,
      isrented: props.isrented,
      assetdetails: props.assetdetails,
      fixedassetuuid: props.fixedassetuuid,
      isliquidated: props.isliquidated,
      fvatretirement: props.fvatretirement,
      targetliquidationage: props.targetliquidationage,
      isincludefinancialplan:props.isincludefinancialplan
    };

    if (props.id) {
      this.id = props.id;
      this.dbValue.id = props.id;
    }
  }

  setFixedAssetstId(id) {
    this.id = id;
    this.dbValue.id = id;
  }
  setUserId(userId) {
    this.userId = userId;
  }
  setFixedAssetType(fixedassettype) {
    this.fixedassettype = fixedassettype;
  }

  setCurrentValue(currentvalue) {
    this.currentvalue = currentvalue;
  }

  setAssetInflation(assetinflation) {
    this.assetinflation = assetinflation;
  }

  setInsertBy(insertby) {
    this.insertby = insertby;
  }

  setUpdateBy(updateby) {
    this.updateby = updateby;
  }

  setInsertTime(inserttime) {
    this.inserttime = inserttime;
  }

  setUpdateTime(updatetime) {
    this.updatetime = updatetime;
  }

  setTargetLiquidationYear(targetliquidationyear) {
    this.targetliquidationyear = targetliquidationyear;
  }

  setExpectedLiquidationValue(expectedliquidationvalue) {
    this.expectedliquidationvalue = expectedliquidationvalue;
  }

  setIsRented(isrented) {
    this.isrented = isrented;
  }

  setFixedAssetDescription(fixedassetdescription) {
    this.fixedassetdescription = fixedassetdescription;
  }

  setFixedAssetUuid(fixedassetuuid) {
    this.fixedassetuuid = fixedassetuuid;
  }

  setAssetDetails(assetdetails) {
    this.assetdetails = assetdetails;
  }
  setIsLiquidated(isliquidated) {
    this.isliquidated = isliquidated;
  }

  setFvatRetirement(fvatretirement) {
    this.fvatretirement = fvatretirement;
  }

  setTargetLiquidationAge(targetliquidationage) {
    this.targetliquidationage = targetliquidationage;
  }

  setIsIncludeFinancialPlan(isincludefinancialplan){
    this.isincludefinancialplan = isincludefinancialplan;
  }

  revertFixedAssetType() {
    this.fixedassettype = this.dbValue.fixedassettype;
  }

  revertCurrentValue() {
    this.currentvalue = this.dbValue.currentvalue;
  }

  revertAssetInflation() {
    this.assetinflation = this.dbValue.assetinflation;
  }

  revertInsertBy() {
    this.insertby = this.dbValue.insertby;
  }

  revertupdateBy() {
    this.updateby = this.dbValue.updateby;
  }

  revertInsertTime() {
    this.inserttime = this.dbValue.inserttime;
  }

  revertUpdateTime() {
    this.updatetime = this.dbValue.updatetime;
  }

  revertUserId() {
    this.userid = this.dbValue.userid;
  }

  revertTargetLiquidationYear() {
    this.targetliquidationyear = this.dbValue.targetliquidationyear;
  }

  revertExpectedLiquidationValue() {
    this.expectedliquidationvalue = this.dbValue.expectedliquidationvalue;
  }

  revertIsRented() {
    this.isrented = this.dbValue.isrented;
  }

  revertFixedAssetDescription() {
    this.fixedassetdescription = this.dbValue.fixedassetdescription;
  }

  revertFixedAssetUuid() {
    this.fixedassetuuid = this.dbValue.fixedassetuuid;
  }

  revertAssetDetails() {
    this.assetdetails = this.dbValue.assetdetails;
  }

  revertIsLiquidated() {
    this.isliquidated = this.dbValue.isliquidated;
  }

  revertFvatRetirement() {
    this.fvatretirement = this.dbValue.fvatretirement;
  }

  revertTargetLiquidationAge() {
    this.targetliquidationage = this.dbValue.targetliquidationage;
  }

  revertIsIncludeFinancialPlan(){
    this.isincludefinancialplan = this.dbValue.isincludefinancialplan;
  }
  revertAllChanges() {
    this.revertFixedAssetType();
    this.revertCurrentValue();
    this.revertAssetInflation();
    this.revertExpectedLiquidationValue();
    this.revertFixedAssetDescription();
    this.revertIsRented();
    this.revertTargetLiquidationYear();
    this.revertUserId();
    this.revertFixedAssetUuid();
    this.revertAssetDetails();
    this.revertIsLiquidated();
    this.revertFvatRetirement();
    this.revertTargetLiquidationAge();
    this.revertIsIncludeFinancialPlan();
  }

  createFixedAssets() {
    this.setInsertBy("Fliber");
    this.setInsertTime(new Date());
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.FixedAssetsApi.createFixedAssets(this)
        .then((response) => {
          const fixedAssetsId = response && response.id ? response.id : "";
          this.setFixedAssetstId(fixedAssetsId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateFixedAssets() {
    this.setInsertBy("Fliber");
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.FixedAssetsApi.updateFixedAssets(this)
        .then((response) => {
          const fixedAssetsId = response && response.id ? response.id : "";
          this.setFixedAssetstId(fixedAssetsId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteFixedAssets() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.FixedAssetsApi.deleteFixedAssets(this)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  static getUserFixedAssetsByUserId(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.FixedAssetsApi.getUserFixedAssetsByUserId(data)
        .then((fixedAssetsList) => {
          const fixedAssets = [];
          if (fixedAssetsList && fixedAssetsList.length > 0) {
            fixedAssetsList.forEach((item) => {
              const fixedAssetsObj = new FixedAsset(item);
              fixedAssets.push(fixedAssetsObj);
            });
          }
          resolve(fixedAssets);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserFixedAssetsByType(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.FixedAssetsApi.getUserFixedAssetsByType(data)
        .then((fixedAssetsList) => {
          const fixedAssets = [];
          if (fixedAssetsList && fixedAssetsList.length > 0) {
            fixedAssetsList.forEach((item) => {
              const fixedAssetsObj = new FixedAsset(item);
              fixedAssets.push(fixedAssetsObj);
            });
          }
          resolve(fixedAssets);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}


export default FixedAsset;
