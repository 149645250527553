import BackendApi from "../backend/index";

class RiskProfile {
  constructor(props) {
    this.userid = props.userid;
    this.questionid = props.questionid;
    this.choiceid = props.choiceid;
    this.questiontext = props.questiontext;
    this.choicetext = props.choicetext;
    this.questionseqno = props.questionseqno;
    this.isriskprofilelocked = props.isriskprofilelocked;
    this.date = props.date;
    this.insertby = props.insertby;
    this.inserttime = props.inserttime;
    this.updatetime = props.updatetime;
    this.updateby = props.updateby;

    this.dbValue = {
      userid: props.userid,
      questionid: props.questionid,
      choiceid: props.choiceid,
      questiontext: props.questiontext,
      choicetext: props.choicetext,
      questionseqno: props.questionseqno,
      isriskprofilelocked: props.isriskprofilelocked,
      date: props.date,
      insertby: props.insertby,
      inserttime: props.inserttime,
      updatetime: props.updatetime,
      updateby: props.updateby,
    };

    if (props.id) {
      this.id = props.id;
      this.dbValue.id = props.id;
    }
  }

  setUserId(userid) {
    this.userid = userid;
  }

  setQuestionId(questionid) {
    this.questionid = questionid;
  }

  setChoiceId(choiceid) {
    this.choiceid = choiceid;
  }

  setQuestionText(questiontext) {
    this.questiontext = questiontext;
  }

  setChoiceText(choicetext) {
    this.choicetext = choicetext;
  }

  setQuesSqeNo(questionseqno) {
    this.questionseqno = questionseqno;
  }

  setInsertBy(insertby) {
    this.insertby = insertby;
  }

  setInsertTime(inserttime) {
    this.inserttime = inserttime;
  }

  setUpdateBy(updateby) {
    this.updateby = updateby;
  }

  setUpdateTime(updatetime) {
    this.updatetime = updatetime;
  }

  setAnswerId(ansId) {
    this.id = ansId;
    this.dbValue.id = ansId;
  }

  setIsriskProfileLocked(isriskprofilelocked) {
    this.isriskprofilelocked = isriskprofilelocked;
  }

  setDate(date) {
    this.date = date;
  }

  revertUserId() {
    this.userid = this.dbValue.userid;
  }

  revertQuestionId() {
    this.questionid = this.dbValue.questionid;
  }

  revertChoiceId() {
    this.choiceid = this.dbValue.choiceid;
  }

  revertQuestionText() {
    this.questiontext = this.dbValue.questiontext;
  }

  revertChoiceText() {
    this.choicetext = this.dbValue.choicetext;
  }

  revertIsriskProfileLocked() {
    this.isriskprofilelocked = this.dbValue.isriskprofilelocked;
  }

  revertDate() {
    this.date = this.dbValue.date;
  }
  revertQuesSeqNo() {
    this.questionseqno = this.dbValue.questionseqno;
  }

  revertAllChanges() {
    this.revertUserId();
    this.revertQuestionId();
    this.revertChoiceId();
    this.revertQuestionText();
    this.revertChoiceText();
    this.revertIsriskProfileLocked();
    this.revertDate();
  }

  createUserAnswers() {
    this.setInsertBy("Fliber");
    this.setInsertTime(new Date());
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.createUserAnswers(this)
        .then((response) => {
          const ansId = response && response.id ? response.id : "";
          this.setAnswerId(ansId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateUserAnswers() {
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.updateUserAnswers(this)
        .then((response) => {
          const ansId = response && response.id ? response.id : "";
          this.setAnswerId(ansId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteUserAnswers() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.deleteUserAnswers(this)
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static createRiskProfileStatus(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.createRiskProfileStatus(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateRiskProfileStatus(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.updateRiskProfileStatus(data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getRiskQuestions() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.getRiskQuestions()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserAnswers(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.getUserAnswers(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserRiskProfile(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.getUserRiskProfile(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static userInitiateRisk(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.userInitiateRisk(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  static validateRiskProfileUrl(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.validateRiskProfileUrl(data)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
    })
  }

  static createRiskProfileStatusInfo(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.RiskProfileApi.createRiskProfileStatusInfo(data)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error)
        })
    })
  }
}

export default RiskProfile;
