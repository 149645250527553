import React from "react";
import "./breadcrumb.css";
import { t } from "i18next";
import BreadScrumb from "../../assets/svgs/BreadCrumb_left_arrow.svg";
import RecordModal from "./recordmodal";

class BreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalMessage: "",
      modalAction: null,
    };
  }

  onClickItem = (path) => {
    const hasChanged = this.checkIfScreenChanged();
    if (hasChanged) {
      this.showModal(
        t(
          "BreadCrumb: Are you sure you want to navigate away from this screen?"
        ),
        () => {
          if (this.props.onClickItem) {
            this.props.onClickItem(path);
          }
        }
      );
    } else {
      if (this.props.onClickItem) {
        this.props.onClickItem(path);
      }
    }
  };

  onPressBack = () => {
    const { items, isLastPath, currentPath, onClickBackIcon } = this.props;
    const currentIndex = items.findIndex((item) => item.path === currentPath);
    let previousItem = null;
    if (currentIndex > 0) {
      previousItem = items[currentIndex - 1];
    } else if (isLastPath) {
      previousItem = items[items.length - 2];
    }
    const hasChanged = this.checkIfScreenChanged();
    if (previousItem && hasChanged) {
      this.showModal(
        "Are you sure you want to navigate away from this screen?",
        () => onClickBackIcon(previousItem.path)
      );
    } else {
      onClickBackIcon(previousItem?.path);
    }
  };

  checkIfScreenChanged = () => {
    let isScreenChanged = this.props.isStateValueChanged ? true : false;
    const dbData = this.props.dbData;
    const localData = this.props.localData;
    if (dbData && localData) {
      const lData = JSON.parse(JSON.stringify(localData));
      if (JSON.stringify(dbData) !== JSON.stringify(lData)) {
        isScreenChanged = true;
      }
    }
    return isScreenChanged;
  };

  showModal = (message, action) => {
    this.setState({
      showModal: true,
      modalMessage: message,
      modalAction: action,
    });
  };

  onHideModal = () => {
    this.setState({ showModal: false });
  };

  handlePressNo = () => {
    this.onHideModal();
  };

  handlePressYes = () => {
    this.onHideModal();
    if (this.state.modalAction) {
      this.state.modalAction();
    }
  };

  renderModalView = () => {
    const buttonNames = { no: "No", yes: "Yes" };
    const title = this.state.modalMessage;
    return (
      <RecordModal
        open={this.state.showModal}
        onClose={this.onHideModal}
        onPressNo={this.handlePressNo}
        onPressYes={this.handlePressYes}
        title={title}
        buttonNames={buttonNames}
      />
    );
  };

  render() {
    const { items } = this.props;
    const { showModal } = this.state;

    return (
      <div className="breadcrumb">
        <img
          src={BreadScrumb}
          alt="Arrow"
          className="arrowStyle"
          onClick={this.onPressBack.bind(this)}
        />
        <div className="breadcrumb_style">
          {items.map((item, index) => {
            if (index === items.length - 1) {
              return (
                <span key={index} style={{ color: "#069" }}>
                  {item.name}
                </span>
              );
            } else {
              const path = item.path;
              return (
                <span
                  key={index}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div
                    onClick={() => {
                      this.onClickItem(path);
                    }}
                    style={{
                      textDecoration: "none",
                      color: "#1675e0",
                      cursor: "pointer",
                    }}
                  >
                    {item.name}
                  </div>
                  <span style={{ color: "#222222", paddingRight: "5px" }}>
                    &gt;
                  </span>{" "}
                </span>
              );
            }
          })}
        </div>
        {showModal && this.renderModalView()}
      </div>
    );
  }
}

export default BreadCrumb;
