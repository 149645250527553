import React, { Component } from "react";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  ProgressDialog,
  Slider,
  TitleView,
  Toast,
  ToggleSwitch,
} from "../components";
import "./emiexpensepage.css";
import { t } from "i18next";
import { GOAL_CATEGORY, GOAL_FREQUENCY_TYPE, DEFAULT_OFFSET, GOAL_EXPENSE_TYPE } from "../constants";
import { getCurrentYear } from "../utils/datetime";
import * as Bl from "../../businesslogic";
import { calculate_age, getAdvisorId, getGoalsPathName, getUserDOB, getUserId } from "../common";
import { getCurrentAge } from "../utils/common";

const newGoal = {
  userid: '',
  goalcategory: GOAL_CATEGORY.EMI_PREMIUM,
  goalstartyear: 0,
  goalendyear: 0,
  goalamountpv: 0,
  goalfrequency: 1,
  advisorid: getAdvisorId(),
  goalfrequencytype: GOAL_FREQUENCY_TYPE.YEARLY,
  goalbucketcode: "",
  retirewanttype: "",
  monthlyexpenselist: [],
  goaldescription: "",
  goalinflationrate: 0,
  goalamountfv: 0,
  homeloanstatus: false,
  downpayment: 0,
  goaldetails: [],
  goalstartage: 0,
  goalendage: 0,
  userage: 0,
  expensetype:GOAL_EXPENSE_TYPE.RECURRING
};

class EmiExpensePage extends Component {
  constructor(props) {
    super(props); const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    const goalData = params?.goalData;
    this.state = {
      startValue: isEditView ? goalData.goalstartyear : getCurrentYear() + 1,
      startYear: getCurrentYear() + 1,
      endYear: getCurrentYear() + 2,
      endValue: isEditView ? goalData.goalendyear : getCurrentYear() + 2,
      selectedSliderIndex: 0,
      type: isEditView ? goalData.expensetype : GOAL_EXPENSE_TYPE.RECURRING,
      frequencyValue: isEditView ? goalData.goalfrequency : 1,
      sliderContent: [],
      description: isEditView ? goalData.goaldescription : "",
      amount: isEditView ? goalData.goalamountpv : "",
      currentAge: 0,
      isEditView: isEditView ? isEditView : false,
      newGoalData: isEditView ? new Bl.Goals(goalData) : new Bl.Goals({ ...newGoal, userid: getUserId() }),
    };
    this.isRenderingAlready = false;
    this.dbValue = this.state.newGoalData;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getCurrentAge();
    }
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true, fetchingCurrentAge: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const goalsObj = this.state.newGoalData;
          goalsObj.setUserAge(currentAge);
          this.userage = currentAge;
          this.dbValue.userage = currentAge;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
            fetchingCurrentAge: false
          }, () => {
            this.getDBValue();
          });
        })
        .catch((error) => {
          console.log("EMIExpensePage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getDBValue() {
    if(this.state.fetchingCurrentAge === false){
      const dbValue = this.state.newGoalData
      ? JSON.parse(JSON.stringify(this.state.newGoalData))
      : {};
      this.setState({dbValue})
    }
  }
  
  onSelectItem(path) {
    const localData = new Bl.Goals(this.state.newGoalData)
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeDescription(value) {
    const goals = this.state.newGoalData;
    goals.setDescription(value);
    this.setState({ description: value });
  }

  onChangePremium(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalAmountPv(value ? Number(value) : 0);
    this.setState({ amount: value });
  }

  isValueValid(value) {
    return value > 0;
  }

  isStartAndEndYearValid(goalType, startYear, endYear) {
    if (goalType === GOAL_EXPENSE_TYPE.RECURRING) {
      return startYear !== 0 && startYear < endYear;
    } else {
      return startYear !== 0;
    }
  }

  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    const goalType = this.state.type;
    const startYear = data.goalstartyear ? data.goalstartyear : 0;
    const endYear = data.goalendyear ? data.goalendyear : 0;

    if (!data.goaldescription) {
      isValid = false;
      message = t("emiToastDescription")
    } else if (!this.isStartAndEndYearValid(goalType, startYear, endYear)) {
      isValid = false;
      message = t("emiToastYear");
    } else if (!this.isValueValid(data.goalamountpv)) {
      isValid = false;
      message = t("emiToastAmount");
    }
    return { isValid, message };
  }

  checkIsEndYearValid(startYear, endYear) {
    return startYear < endYear;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  refreshData() {
    newGoal.userage = this.userage;
    this.setState({
      description: "",
      amount: "",
      startValue: getCurrentYear() + 1,
      endValue: getCurrentYear() + 2,
      type: GOAL_EXPENSE_TYPE.RECURRING,
      frequencyValue: 1,
      newGoalData: new Bl.Goals({ ...newGoal, userid: getUserId() }),
    });
  }

  navigateToNextScreen() {
    this.props.navigate("/goalscategorize", { state: { path: "Emi" } });
  }

  createEMIGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
    }
    if (
      goalObj.expensetype === GOAL_EXPENSE_TYPE.RECURRING &&
      goalObj.goalendyear === 0
    ) {
      const endYear = getCurrentYear() + 1;
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .createGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("EmiExpensePage/Create Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  updateEMIGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (
      goalObj.expensetype === GOAL_EXPENSE_TYPE.RECURRING &&
      goalObj.goalendyear === 0
    ) {
      const endYear = getCurrentYear();
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }

    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .updateGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("EmiExpensePage/updateEduGoal Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  onPressSaveButton() {
    if (!this.state.isEditView) {
      this.createEMIGoal(this.navigateToNextScreen.bind(this));
    }
    else {
      this.updateEMIGoal(this.navigateToNextScreen.bind(this));
    }
  }

  onPressSaveAndAdd() {
    this.createEMIGoal(this.refreshData.bind(this));
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const startAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    goals.setStartYear(newValue);
    goals.setGoalStartAge(startAge)
    this.setState({ startValue: newValue });
  }

  handleChangeEnd(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const endAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    const isValid = this.checkIsEndYearValid(goals.goalstartyear, newValue);
    if (isValid) {
      goals.setEndYear(newValue);
      goals.setGoalEndAge(endAge);
      this.setState({ endValue: newValue });
    } else {
      this.showToast(t("emiToastYear"));
    }
  }

  handleToggleSwitchChange(selectedItem) {
    const goals = this.state.newGoalData;
    const frequencyValue =
      selectedItem === t("emiToggle2")
        ? GOAL_EXPENSE_TYPE.RECURRING
        : GOAL_EXPENSE_TYPE.ONE_TIME;
    goals.setExpenseType(frequencyValue);
    const endValue = selectedItem === t("emiToggle2") ? this.state.endValue : getCurrentYear() + 2;
    goals.setEndYear(0);
    this.setState({ type: frequencyValue, endValue });
  }

  handleSelectSlider(index) {
    this.setState({ selectedSliderIndex: index });
  }



  renderNavView() {
    const items = getGoalsPathName("Emi");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        dbData={this.dbValue}
        localData={this.state.newGoalData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitle() {
    const title = t("emiTitle");
    return <TitleView title={title} information={""} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="emi_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderStartYearSlider() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.startValue, currentYear, this.state.currentAge);
    return (
      <div className="emi_start_year_row">
        <label className="emi_start_text">{t("emiSliderStart")}</label>
        <div className="emi_slider_content">
          <Slider
            value={this.state.startValue}
            age={age}
            min={currentYear + 1}
            max={currentYear + 21}
            markStep={5}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderEndYearSlider() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.endValue, currentYear, this.state.currentAge);
    return (
      <div className="emi_start_year_row">
        <label className="emi_start_text">{t("emiSliderEnd")}</label>
        <div className="emi_slider_content">
          <Slider
            value={this.state.endValue}
            age={age}
            min={currentYear + 2}
            max={currentYear + 22}
            markStep={5}
            handleChange={this.handleChangeEnd.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderYearView() {
    return (
      <div className="emi_slider_view">
        {this.renderStartYearSlider()}
        {this.state.type === GOAL_EXPENSE_TYPE.RECURRING &&
          this.renderEndYearSlider()}
      </div>
    );
  }

  renderExpenseType() {
    return (
      <div className="emi_description_view">
        {this.renderInputView(
          t("emiInputDescription"),
          this.state.description,
          this.onChangeDescription.bind(this)
        )}
        <div className="emi_expense">
          <label className="emi_expense_text">{t("emiToggle")}</label>
          <div className="emi_toggle">
            <ToggleSwitch
              checked={this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME}
              checkedChildren={t("emiToggle1")}
              unCheckedChildren={t("emiToggle2")}
              onChangeToggle={this.handleToggleSwitchChange.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="emi_bottomView">
        {!this.state.isEditView && <div className="emi_buttonView">
          <div>
            <label
              onClick={this.onPressSaveAndAdd.bind(this)}
              className="emi_link_text"
            >
              {t("emiButtonLink")}
            </label>
          </div>
        </div>}
        <div className="emi_save">
          <Button
            buttonName={t("emiButton")}
            onPressButton={this.onPressSaveButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderEmiPremiumAmount() {
    return (
      <div className="emi_premium_view">
        {this.renderInputView(
          t("emiInputAmount"),
          this.state.amount,
          this.onChangePremium.bind(this),
          true
        )}
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="emi_content_view">
        {this.renderTitle()}
        {this.renderExpenseType()}
        <div className="emi_seperatorStyle" />
        {this.renderYearView()}
        <div className="emi_seperatorStyle" />
        {this.renderEmiPremiumAmount()}
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  // renderDrawerContent() {
  //   const headerTitle = t("financialPlan");
  //   const step = 2;
  //   return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  // }
  render() {
    return (
      <div className="emi_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default EmiExpensePage;
