import { getCurrentUrl } from "../common";

export const GOAL_CATEGORY = {
  EDUCATION: "KGEDU",
  RETIREMENT: "HOUSE",
  VEHICLE: "VEHCL",
  VACATION: "VACCN",
  REAL_ESTATE: "REALE",
  WEDDING: "KGWED",
  EMI_PREMIUM: "EMIPR",
  GEN_WEALTH: "GENWL",
  CUSTOM_GOAL: "CUSTG",
  HEALTH_CARE: "HLTHC",
};

export const RETIRE_WANT_TYPE = {
  LIVE_LAVISHLY: "Luxury",
  LIVE_MODESTLY: "Modestly",
  MAINTAIN_EXISTING: "Existing"
}

export const GOAL_FREQUENCY_TYPE = {
  YEARLY: "YEAR ",
  HALF_YEARLY: "HLFY ",
  QUARTERLY: "QTRY ",
  MONTHLY: "MONT ",
};

export const GOAL_EXPENSE_TYPE = {
  ONE_TIME: 'ONETM',
  RECURRING: 'RECCF',
  LIFE_LONG: "RECCP",
  SPECIFIC_PERIOD: "RECCF",
}

export const GOAL_AMOUNT_TYPE = {
  PRESENT: 'Present',
  FUTURE: 'Future'
}

export const GOAL_BUCKET_CODE = {
  NEEDS: "NEEDS",
  WANTS: "WANTS",
  DESIRES: "DESIR",
};

export const INCOME_CATEGORY = {
  PENSION_ANNUITY: "PENIN",
  BUSINESS: "BUSIN",
  RENTAL: "RNTIN",
  INSURANCE: "INSIN",
  OTHER: "OTHIN",
};

export const INCOME_TYPE = {
  ONE_TIME: "ONETM",
  RECURRING_PERPETUAL: "RECCP",
  RECURRING_FINITE: "RECCF",
};

export const INCOME_FREQUENCY_TYPE = {
  //code limit is 5 digit in db, so add one space extra in code
  YEARLY: "YEAR ",
  HALF_YEARLY: "HLFY ",
  QUARTERLY: "QTRY ",
  MONTHLY: "MONT ",
};

export const INVESTMENT_CATEGORY = {
  STOCKS: "STOCK",
  OTHER_NONMARKET: "OTINV",
  OTHER_MARKET: "OTINV",
  EPF: "EPFIN",
  PPF: "PPFIN",
  NPS: "NPSIN",
  MUTUAL_FUND: "MUTFN",
  BONDS: "BONDS",
  FIXED_DEPOSIT: "FXDEP",
  GSS: "GOVSC",
  BANK_SAVINGS: "BASVG",
};

export const LIABILITIES_CATEGORY = {
  PERSONAL_LOAN: "PLOAN",
  HOUSE_LOAN: "HLOAN",
  OTHER_LIABILITIES: "OLOAN",
  CREDITCARD_BALANCE: "CLOAN",
  VEHICLE_LOAN: "VLOAN"
}
export const FIXED_ASSETS_TYPE = {
  ART: "ARWRK",
  REAL_ESTATE: "REALE",
  JEWELLERY: "JWVLB",
  OTHER_PHY_ASSETS: "CUSTM"
};

export const RISK_PROFILE = {
  MODERATIELY_CONSERVATIVE: "MOCVT",
  CONSERVATIVE: "CONVT",
  BALANCED: "BALND",
  MODERATIELY_AGGRESSIVE: "MOAGV",
  AGGRESSIVE: "AGRSV"
}

export const USER_GENDER = {
  MALE: "M",
  FEMALE: "F",
  OTHERS: "O"
};

export const USER_MARITALSTATUS = {
  MARRIED: "MARR",
  SINGLE: "SING",
  DIVORCED: "DVRC",
  OTHER: "Others"
};

export const USER_RESIDENTSTATUS = {
  RESIDENT_INDIAN: "RES  ",
  NON_RESIDENT_INDIAN: "NRES "
};

export const USER_CUSTOMERTYPE = {
  RIA: "RIA",
  MFD: "MFD"
};

export const USER_STATUSTYPE = {
  PROSTATE: "Prostate",
  ACTIVATE: "Activate",
  DEACTIVATE: "Deactivate"
};

export const DEFAULT_OFFSET = 2;


export const USER_ROLE = {
  USER: "user",
  ADMIN: "admin",
  ADVISOR: "advisor",
  OPERATION: "operation",
  CUSTOMER: 'customer'
}

export const SECURITY_LEVEL = {
  GENERAL: "general",
  ADMIN: "admin",
  RESTRICTED: "restricted"
}

export const SUPPORT_TYPE = {
  GENERAL: 'genenq',
  CUSTOMER: 'cusenq',
  CLIENT: 'clienq'
}

export const BASE_ORIGIN_URL = getCurrentUrl();
export const RISK_PROFILE_URL = `${BASE_ORIGIN_URL}/riskprofile`;
export const RESET_PIN_URL = `${BASE_ORIGIN_URL}/resetpin`;

console.log('origin urls check:', RISK_PROFILE_URL, RESET_PIN_URL)