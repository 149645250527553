import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class InvestmentApi {
  static createInvestment(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserInvestment/`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static createNPSCalculator(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserInvestment/nps/calculator`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateInvestment(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserInvestment/`, Methods.PATCH, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteInvestment(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserInvestment/by/investment/id?id=${data.id}`, Methods.DELETE, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  

  static getUserInvestmentById(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserInvestment/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserInvestmentByCategory(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserInvestment/by/category?uuid=${data.uuid}&investmentcategory=${data.category}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export default InvestmentApi;
