import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class GoalsApi {
  static createGoal(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `ChooseGoals/?userid=${data.userid}&goalcategory=${data.goalcategory}&operation=${data.operation}`,
        Methods.POST,
        data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static viewExpectedAmt(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `ChooseGoals/?userid=${data.userid}&goalcategory=${data.goalcategory}&operation=${data.operation}`,
        Methods.POST,
        data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateGoal(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/?userid=${data.userid}&goalid=${data.id}&goalcategory=${data.goalcategory}`, Methods.PATCH, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateCategoryStatus(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/update_category_status?id=${data.id}&goalbucketcode=${data.goalbucketcode}&goalpriority=${data.goalpriority}`, Methods.PATCH)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteGoal(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/${data.id}`, Methods.DELETE, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getGoalsByUser(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getGoalsByCategory(data) {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/${data.category}/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getGoalsConfigInfo() {
    return new Promise((resolve, reject) => {
      apiCall(`ChooseGoals/get/goals/config`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default GoalsApi;
