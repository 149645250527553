import BackendApi from "../backend/index";

class Investment {
  constructor(props) {
    this.investmentcategory = props.investmentcategory;
    this.currentvalue = props.currentvalue;
    this.annualcontribution = props.annualcontribution;
    this.maturityyear = props.maturityyear;
    this.maturityvalue = props.maturityvalue;
    this.expectedror = props.expectedror;
    this.userid = props.userid;
    this.insertby = props.insertby;
    this.updatetime = props.updatetime;
    this.inserttime = props.inserttime;
    this.updateby = props.updateby;
    this.investementtype = props.investementtype;
    this.contributiongrowthrate = props.contributiongrowthrate;
    this.investmentdetails = props.investmentdetails;
    this.investmentdescription = props.investmentdescription;
    this.fvatretirement = props.fvatretirement;
    this.maturityage = props.maturityage;
    this.userage = props.userage;
    this.isincludefinancialplan = props.isincludefinancialplan;

    this.dbValue = {
      investmentcategory: props.investmentcategory,
      currentvalue: props.currentvalue,
      annualcontribution: props.annualcontribution,
      maturityyear: props.maturityyear,
      maturityvalue: props.maturityvalue,
      expectedror: props.expectedror,
      userid: props.userid,
      insertby: props.insertby,
      updatetime: props.updatetime,
      inserttime: props.inserttime,
      updateby: props.updateby,
      investementtype: props.investementtype,
      contributiongrowthrate: props.contributiongrowthrate,
      investmentdetails: props.investmentdetails,
      investmentdescription: props.investmentdescription,
      fvatretirement : props.fvatretirement,
      maturityage: props.maturityage,
      userage : props.userage,
      isincludefinancialplan: props.isincludefinancialplan
    };

    if (props.id) {
      this.id = props.id;
      this.dbValue.id = props.id;
    }
  }

  setInvestmentId(id) {
    this.id = id;
    this.dbValue.id = id;
  }

  setInvestmentCategory(investmentcategory) {
    this.investmentcategory = investmentcategory;
  }

  setCurrentValue(currentvalue) {
    this.currentvalue = currentvalue;
  }

  setAnnualContribution(annualcontribution) {
    this.annualcontribution = annualcontribution;
  }

  setMaturityYear(maturityyear) {
    this.maturityyear = maturityyear;
  }

  setMaturityValue(maturityvalue) {
    this.maturityvalue = maturityvalue;
  }

  setExpectedror(expectedror) {
    this.expectedror = expectedror;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setInsertBy(insertby) {
    this.insertby = insertby;
  }

  setUpdateBy(updateby) {
    this.updateby = updateby;
  }

  setInsertTime(inserttime) {
    this.inserttime = inserttime;
  }

  setUpdateTime(updatetime) {
    this.updatetime = updatetime;
  }

  setInvestmentType(investementtype) {
    this.investementtype = investementtype;
  }

  setContributionGrowthRate(contributiongrowthrate) {
    this.contributiongrowthrate = contributiongrowthrate;
  }

  setInvestmentDetails(investmentdetails) {
    this.investmentdetails = investmentdetails;
  }

  setInvestmentDescription(investmentdescription) {
    this.investmentdescription = investmentdescription;
  }
  
  setFvatRetirement (fvatretirement){
    this.fvatretirement = fvatretirement;
  }

  setMaturityAge(maturityage) {
    this.maturityage = maturityage;
  }

  setUserAge(userage) {
    this.userage = userage;
  }

  setIsIncludeFinancialPlan(isincludefinancialplan){
    this.isincludefinancialplan = isincludefinancialplan;
  }

  revertInvestmentCategory() {
    this.investmentcategory = this.dbValue.investmentcategory;
  }

  revertCurrentValue() {
    this.currentvalue = this.dbValue.currentvalue;
  }

  revertAnnualContribution() {
    this.annualcontribution = this.dbValue.annualcontribution;
  }

  revertMaturityYear() {
    this.maturityyear = this.dbValue.maturityyear;
  }

  revertMaturityValue() {
    this.maturityvalue = this.dbValue.maturityvalue;
  }

  revertExpectedror() {
    this.expectedror = this.dbValue.expectedror;
  }

  revertInsertBy() {
    this.insertby = this.dbValue.insertby;
  }

  revertupdateBy() {
    this.updateby = this.dbValue.updateby;
  }

  revertInsertTime() {
    this.inserttime = this.dbValue.inserttime;
  }

  revertUpdateTime() {
    this.updatetime = this.dbValue.updatetime;
  }

  revertUserId() {
    this.userid = this.dbValue.userid;
  }

  revertInvestmentType() {
    this.investementtype = this.dbValue.investementtype;
  }

  revertContributionGrowthRate() {
    this.contributiongrowthrate = this.dbValue.contributiongrowthrate;
  }

  revertInvestmentDetails() {
    this.investmentdetails = this.dbValue.investmentdetails;
  }

  revertInvestmentDescription() {
    this.investmentdescription = this.dbValue.investmentdescription;
  }

  revertFvatRetirement(){
    this.fvatretirement = this.dbValue.fvatretirement;
  }

  revertMaturityAge() {
    this.maturityage = this.dbValue.maturityage;
  }

  revertUserAge() {
    this.userage = this.dbValue.userage;
  }

  revertIsIncludeFinancialPlan() {
    this.isincludefinancialplan = this.dbValue.isincludefinancialplan;
  }

  revertAllChanges() {
     this.revertInvestmentCategory();
     this.revertCurrentValue();
     this.revertAnnualContribution();
     this.revertContributionGrowthRate();
     this.revertExpectedror();
     this.revertInvestmentDescription();
     this.revertInvestmentDetails();
     this.revertInvestmentType();
     this.revertMaturityValue();
     this.revertMaturityYear();
     this.revertUserId();
     this.revertFvatRetirement();
     this.revertMaturityAge();
     this.revertUserAge();
     this.revertIsIncludeFinancialPlan();
  }

  createInvestment() {
    this.setInsertBy("Fliber");
    this.setInsertTime(new Date());
    this.setUpdateBy("Fliber");
    this.setUpdateTime(0);

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.InvestmentApi.createInvestment(this)
        .then((response) => {
          const investmentId = response && response.id ? response.id : "";
          this.setInvestmentId(investmentId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createNPSCalculator() {
    this.setInsertBy("Fliber");
    this.setInsertTime(new Date());
    this.setUpdateBy("Fliber");
    this.setUpdateTime(0);

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.InvestmentApi.createNPSCalculator(this)
      
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateInvestment() {
    this.setInsertBy("Fliber");
    this.setUpdateBy("Fliber");
    this.setUpdateTime(new Date());

    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.InvestmentApi.updateInvestment(this)
        .then((response) => {
          const investmentId = response && response.id ? response.id : "";
          this.setInvestmentId(investmentId);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteInvestment(){
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.InvestmentApi.deleteInvestment(this)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  static getUserInvestmentByUserId(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.InvestmentApi.getUserInvestmentByUserId(data)
        .then((investmentList) => {
          const investments = [];
          if (investmentList && investmentList.length > 0) {
            investmentList.forEach((item) => {
              const investmentObj = new Investment(item);
              investments.push(investmentObj);
            });
          }
          resolve(investments);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserInvestmentByCategory(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.InvestmentApi.getUserInvestmentByCategory(data)
        .then((investmentList) => {
          const investments = [];
          if (investmentList && investmentList.length > 0) {
            investmentList.forEach((item) => {
              const investmentObj = new Investment(item);
              investments.push(investmentObj);
            });
          }
          resolve(investments);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default Investment;
