import React from "react"
import "./button.css"

class Button extends React.Component {


  onClickButton() {
    if (this.props.onPressButton) {
      this.props.onPressButton()
    }
  }

  render() {
    return (
      <div
        className={this.props.emptyButton ? "b_empty_button" : "b_container"}
        onClick={this.onClickButton.bind(this)}
        disabled={this.props.disabled}
        style={{opacity: this.props.disabled ? 0.5 : 1, pointerEvents: this.props.disabled ? "none" : "auto"}}
      >
        <label
          className={
            this.props.emptyButton ? "b_empty_text" : "b_buttonTextStyle"
          }
        >
          {this.props.buttonName}
        </label>
      </div>
    )
  }
}

export default Button
