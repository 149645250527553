import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class RiskProfileApi {
  static createUserAnswers(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserRiskResponse/`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateUserAnswers(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserRiskResponse/`, Methods.PATCH, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteUserAnswers(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserRiskResponse/userid/${data.userid}`, Methods.DELETE, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getRiskQuestions() {
    return new Promise((resolve, reject) => {
      apiCall(`UserRiskResponse/riskQuestions`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserAnswers(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `UserRiskResponse/getAnswers/by/{user_id}?uuid=${data.uuid}`,
        Methods.GET
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserRiskProfile(data) {
    return new Promise((resolve, reject) => {
      apiCall(`Calculations/RiskProfile?user_id=${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static createRiskProfileStatus(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserProfileStatus/`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateRiskProfileStatus(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserProfileStatus/`, Methods.PATCH, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static userInitiateRisk(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserProfileStatus/intiateRisk?emailid=${data.emailid}&weburl=${data.url}`, Methods.POST)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static validateRiskProfileUrl(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserProfileStatus/validate?link=${data.link}`, Methods.POST)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        })
    })
  }

  static createRiskProfileStatusInfo(data) {
    return new Promise((resolve, reject) => {
      apiCall('User/risk_profile_status_info/', Methods.POST, data)
        .then((response) => {
          resolve(response.data);
        }).catch((error) => {
          reject(error)
        })
    })
  }
}

export default RiskProfileApi;
