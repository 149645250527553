import React from "react";
import { t } from "i18next";
import Button from "./button";
import FloatingLabelInput from "./floatinglabelinput";
import './loginform.css';
import OtpView from "./otpview";



class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailId: '',
            pwd: '',
            otpArray: ["", "", "", "", "", ""]
        }
    }

    onClickRegister() {
        this.props.navigate('/registerscrn')
    }

    onClickForgotPassword() {
        if (this.props.onPressForgotPwd) {
            this.props.onPressForgotPwd()
        }
    }

    onChangeValue(value) {
        const mailId = value.toLowerCase();
        this.setState({ emailId: mailId })
    }

    onChangePwd(value) {
        this.setState({ pwd: value })
    }

    onPressLogin() {
        const { emailId, pwd } = this.state;
        if (this.props.onPressLogin) {
            this.props.onPressLogin(emailId, pwd)
        }
    }

    renderTitleView() {
        return (
            <div className="lf_titleView">
                <label className="lf_titleStyle">{t('login')}</label>
            </div>
        );
    }

    renderEmailPhoneNoView() {
        return (
            <div className="lf_inputView">
                <FloatingLabelInput
                    label={t('emailIDMobileNumber')}
                    value={this.state.emailId}
                    onChangeValue={this.onChangeValue.bind(this)}
                    errorMessage={this.state.errorMessage}
                    showErrorMessage={this.state.showErrorMessage}
                />
            </div>
        );
    }

    renderOtpView() {
        return (
            <div className="lf_otpView">
                <label className="lf_otpLabelStyle">{t('pleaseEnterYourPIN')}</label>
                <OtpView
                    otp={this.state.otpArray}
                    noOfInputs={6}
                    showActions={true}
                    showForgetPin={true}
                    onChangeOTP={() => { }}
                />
            </div>
        );
    }

    renderForgotPinView() {
        return (
            <div className={"lf_forgotpinView"} onClick={this.onClickForgotPassword.bind(this)}>
                <label className={"lf_forgotStyle"}>{t('forgotPassword')}</label>
            </div>
        );
    }

    renderPwdView() {
        return (
            <div className="lf_otpView">
                <FloatingLabelInput
                    label={t('password')}
                    onChangeValue={this.onChangePwd.bind(this)}
                    type="password"
                    onPressEnterKey={this.onPressLogin.bind(this)}
                />
                {this.renderForgotPinView()}
            </div>
        );
    }

    renderRegisterLinkView() {
        return (
            <div className="lf_linkView">
                <label className="lf_linkLabelStyle">{t('dontHaveAnAccount')}</label>
                <div onClick={this.onClickRegister.bind(this)}>
                    <label className="lf_linkStyle">{t('register')}</label>
                </div>
            </div>
        )
    }

    renderButtonView() {
        return (
            <div className="lf_buttonView">
                <Button
                    buttonName={t('login')}
                    onPressButton={this.onPressLogin.bind(this)}
                />
            </div>
        );
    }

    renderContentView() {
        return (
            <div className="lf_content">
                {this.renderEmailPhoneNoView()}
                {/* {this.renderOtpView()} */}
                {this.renderPwdView()}
                {this.renderButtonView()}
                {/* {this.renderRegisterLinkView()} */}
            </div>
        )
    }

    render() {
        return (
            <div className="lf_container">
                {this.renderTitleView()}
                {this.renderContentView()}
            </div>
        );
    }
}

export default LoginForm;