import React from "react";
import "./sidemodel.css";
import CollapseableModal from "./collapseablemodal";
import { FLIBER_STEPS } from "../common";

class SideModel extends React.Component {
  onClickSliderButton() {
    if (this.props.onClickClose()) {
      this.props.onClickClose();
    }
  }

  renderSteps() {
    const step = this.props.step;
    const DisplayItem = FLIBER_STEPS[step];
    return (
      <div className="sm_itemView">
        <DisplayItem />
      </div>
    );
  }

  renderSideContent() {
    return (
      <div className="sm_main_div">
        <label className="sm_header">{this.props.headerTitle}</label>
        {this.renderSteps()}
      </div>
    );
  }

  render() {
    return (
      <CollapseableModal renderContent={this.renderSideContent.bind(this)} />
    );
  }
}

export default SideModel;
