import React from "react";
import "chart.js/auto";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import "./chartview.css";

class ChartView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getChartOptions(title, xAxisName,yAxisName, isStacked = false, isDoughnut = false) {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scaleShowLabels: true,
      plugins: {
        title: {
          display: !isDoughnut,
          text: title,
          color: "#0B2653",
          position: "top",
          align: "start",
          padding: {
            bottom: 24,
          },
          colors: {
            enabled: true,
          },
          font: {
            size: 18,
            family: "RubikRegular",
            weight: 400,
          },
        },
        legend: {
          display: true,
          position: isDoughnut || isStacked ? "right" : "bottom",
          labels: {
            usePointStyle: true,
          },
        },
      },
      scales: {
        x: {
          stacked: isStacked,
          display: !isDoughnut,
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: xAxisName,
            color: "#51668A",
            padding: {
              bottom: 12,
            },
            font: {
              size: 14,
              family: "RubikRegular",
              weight: 400,
            },
          },
          ticks: {
            family: "RubikRegular",
            weight: 400,
            size: 12,
            color: "#51668A",
          },
        },
        y: {
          stacked: isStacked,
          display: !isDoughnut,
          grid: {
            display: true,
            color: "#E0E0E0",
            drawTicks: false,
          },
          border: {
            dash: [5, 5],
          },
          title: {
            display: true,
            text: yAxisName ? yAxisName : "",
            color: "#51668A",
            padding: {
              bottom: 12,
            },
            font: {
              size: 14,
              family: "RubikRegular",
              weight: 400,
            },
          },
          ticks: {
            family: "RubikRegular",
            weight: 400,
            size: 12,
            color: "#51668A",
          },
          stack: isStacked,
        },
      },
    };
  }

  getChartType() {
    const type = this.props.type;
    switch (type) {
      case "line":
        return this.renderLineChartView.bind(this);
      case "bar":
        return this.renderBarChartView.bind(this);
      case "stack":
        return this.renderStackChartView.bind(this);
      case "doughnut":
        return this.renderDonghnutChartView.bind(this);
      default:
        return this.renderLineChartView.bind(this);
    }
  }

  renderDonghnutChartView() {
    const { chartData, title, xAxisName,yAxisName } = this.props;
    const options = this.getChartOptions(title, xAxisName,yAxisName, false, true);
    return (
      <div className={"cv_chart_doughnut_view"}>
        <Doughnut type={"doughnut"} data={chartData} options={options} />
      </div>
    );
  }

  renderStackChartView() {
    const { chartData, title, xAxisName ,yAxisName} = this.props;
    const options = this.getChartOptions(title, xAxisName,yAxisName, true);
    return (
      <div className={"cv_chartView"}>
        <Bar type={"stack"} data={chartData} options={options} />
      </div>
    );
  }

  renderBarChartView() {
    const { chartData, title, xAxisName ,yAxisName, isHorizontal} = this.props;
    const options = this.getChartOptions(title, xAxisName,yAxisName);
    if (isHorizontal) {
      options.indexAxis = "y";
    }
    return (
      <div className={"cv_chartView"}>
        <Bar type={"bar"} data={chartData} options={options} />
      </div>
    );
  }

  renderLineChartView() {
    const { chartData, title, xAxisName,yAxisName } = this.props;
    const options = this.getChartOptions(title, xAxisName,yAxisName);
    return (
      <div className={"cv_chartView"}>
        <Line type={"line"} data={chartData} options={options} />
      </div>
    );
  }

  render() {
    const chartType = this.getChartType();
    return <div className={"cv_container"}>{chartType()}</div>;
  }
}

export default ChartView;
