import React, { Component } from "react";
import "./customerprofile.css";
import { t } from "i18next";
import {
  TitleView,
  FloatingLabelInput,
  ToggleSingleSelection,
  Slider,
  Button,
  ToggleSwitch,
  Toast,
  ProgressDialog,
  RecordModal,
} from "../components";
import {
  getRoleLabel,
  getSecurityLevelLabel,
  getSelectedUserData,
  getUserDetails,
  getUserId,
  getUserRole,
  isAdminRAdvisor,
  isRestrictedCustomer,
  resetSelectedUserDetail,
  resetUserCashLadderDetail,
} from "../common";
import MaleGender from "../../assets/svgs/male.svg";
import FemaleGender from "../../assets/svgs/female.svg";
import OtherGender from "../../assets/svgs/other.svg";
import SelectedMaleIcon from "../../assets/svgs/male_selected.svg";
import SelectedFemaleIcon from "../../assets/svgs/female_selected.svg";
import SelectedOtherIcon from "../../assets/svgs/other_selected.svg";
import MaritalSelectedToggle from "../../assets/svgs/selected_toggle.svg";
import MaritalUnSelectedToggle from "../../assets/svgs/unselected_toggle.svg";
import MarriedIcon from "../../assets/svgs/marrieddselected.svg";
import MarriedSelected from "../../assets/svgs/marriedselected.svg";
import SingleIcon from "../../assets/svgs/singledselected.svg";
import SingleSelected from "../../assets/svgs/singleselected.svg";
import DivorcedIcon from "../../assets/svgs/divorceddselected.svg";
import DivorcedSelected from "../../assets/svgs/divorcedselected.svg";
import OtherIcon from "../../assets/svgs/othermaritaldeselcted.svg";
import OtherSelected from "../../assets/svgs/othermaritalselected.svg";
import * as Bl from "../../businesslogic";
import * as logger from "../utils/logger/index";
import VerifyPAN from "../../assets/svgs/verifypan.svg";
import {
  SECURITY_LEVEL,
  USER_CUSTOMERTYPE,
  USER_GENDER,
  USER_MARITALSTATUS,
  USER_RESIDENTSTATUS,
  USER_ROLE,
  USER_STATUSTYPE,
} from "../constants";
import { Modal } from "rsuite";
import SuccessModalIcon from "../../assets/svgs/successmodal.svg";
import kycNotVerIcon from "../../assets/svgs/redcross.svg";
import { DEFAULT_OFFSET } from "../constants";
import { getCommonConfigInfo } from "../utils/common";
import { getCurrentYear } from "../utils/datetime";

const today = new Date();

const newUserObj = {
  firstname: "",
  lastname: "",
  middlename: "",
  mobilenumber: "",
  emailid: "",
  ismobileverified: true,
  isemailverified: true,
  addressline1: "",
  addressline2: "",
  city: "",
  country: "IN",
  pincode: "",
  dateofbirth: "",
  gender: USER_GENDER.MALE,
  retirementage: 0,
  retirementyear: 0,
  residentstatus: USER_RESIDENTSTATUS.RESIDENT_INDIAN,
  active: true,
  deviceregistrationid: 0,
  insertby: "Fliber",
  updateby: "Fliber",
  state: "",
  maritalstatuscode: USER_MARITALSTATUS.MARRIED,
  uanno: "",
  uanpassword: "",
  pannumber: "",
  lifeexpectancy: 0,
  spousefirstname: "",
  spouselastname: "",
  spousepannumber: "",
  spousedateofbirth: 0,
  customer_type: USER_CUSTOMERTYPE.RIA,
  status: USER_STATUSTYPE.PROSTATE,
  password: "",
  risk_profile: "",
  nrd: 0,
  kycstatus: "",
  role: USER_ROLE.CUSTOMER,
  offset: DEFAULT_OFFSET,
  is_risk_profile_completed: false,
  risk_profile_updated_date: `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`,
  lastreviewdate: 0,
  nextriviewdate: 0,
  securitylevel: '',
  advisorname: '',
  advisorid: '',
  iscashladderupdated: false,
  isportfolioalloated: false,
  planportfolioratesb: 0,
  isassetallocated: false,
  planportfoliorategb: 0,
  intiateportfolioallocation: false,
  portfolioallocationlock: false,
  lastportfolioallocateddate: '1970-01-01'
};

class CustomerProfile extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const isEditView = params?.isEditable ? params.isEditable : false;
    const userRole = params?.role ? params.role : getUserRole();
    const securityLevel = params?.securitylevel ? params.securitylevel : '';
    const userId = params?.userId ? params.userId : "";
    const loggedInUser = params?.loggedInUser ? params.loggedInUser : getUserDetails();

    newUserObj.securitylevel = securityLevel;
    newUserObj.role = userRole;

    this.state = {
      selectedGenderIndex: 0,
      selectedMaritalIndexs: 0,
      retirementAge: 0,
      selectedResidentIndex: 0,
      isEditView: isEditView ? isEditView : false,
      showVerifyPanModal: isEditView ? true : false,
      showVerifySpousePanModal: false,
      userId: userId ? userId : "",
      panVerificationMsg: "",
      spousePanVerificationMsg: "",
      newUserData: new Bl.User(newUserObj),
      userRole: userRole,
      showModalCreate: false,
      showModal: false,
      showConfirmationModal: false,
      modalMessage: "",
      modalAction: null,
      states: [],
      sliderMinValue: 0,
      sliderMaxValue: 0,
      currentAge: 0,
      loggedInUser
    };
    this.isRenderingAlready = false;
    this.dbValue = this.state.newUserData
      ? JSON.parse(JSON.stringify(this.state.newUserData))
      : {};
    // UserId from authdata
    this.userId = getUserId();
    this.isCustomerOnly = isRestrictedCustomer();
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      //When admin came out from the user screens,remove selectedUserDetail from localstorage
      if (!this.isCustomerOnly && getSelectedUserData()?.id) {
        resetSelectedUserDetail();
        resetUserCashLadderDetail();
      }

      if (this.isCustomerOnly) {
        if (this.userId) {
          this.getUserDetailsById(this.userId);
        }
      } else {
        if (this.state.isEditView) {
          this.getUserDetailsById(this.state.userId);
        }
      }

      //get lifeexpectancy value from config API
      this.getConfigValues();

      Bl.Common.getStates()
        .then((stateObj) => {
          const states = stateObj.data ? stateObj.data : [];
          this.setState({ states });
        })
        .catch((error) => {
          console.error("Error fetching states:", error);
        });
    }
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  getSelectedGenderIndex(gender) {
    switch (gender) {
      case USER_GENDER.MALE:
        return 0;
      case USER_GENDER.FEMALE:
        return 1;
      case USER_GENDER.OTHERS:
        return 2;
      default:
        break;
    }
  }

  getSelectedMaritalIndex(maritalCode) {
    switch (maritalCode) {
      case USER_MARITALSTATUS.MARRIED:
        return 0;
      case USER_MARITALSTATUS.SINGLE:
        return 1;
      case USER_MARITALSTATUS.DIVORCED:
        return 2;
      case USER_MARITALSTATUS.OTHER:
        return 3;
      default:
        break;
    }
  }

  getUserDetailsById(userid) {
    const id = userid;
    this.setState({ showProgress: true }, () => {
      Bl.User.getUserDetailsById(id)
        .then((UserList) => {
          const user = new Bl.User(UserList.User);
          const selectedGenderIndex = this.getSelectedGenderIndex(user.gender);
          const selectedMaritalIndexs = this.getSelectedMaritalIndex(
            user.maritalstatuscode
          );
          const kycStatus = user.kycstatus;
          this.setState({
            showProgress: false,
            newUserData: user,
            selectedGenderIndex,
            selectedMaritalIndexs,
            panVerificationMsg: kycStatus,
          }, () => {
            this.getUserCurrentAge()
          });
          this.dbValue = user ? JSON.parse(JSON.stringify(user)) : {};
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getConfigValues() {
    getCommonConfigInfo()
      .then((configList) => {
        const configData =
          configList && configList.length > 0 ? configList[0] : {};
        const newUserData = this.state.newUserData;
        newUserData.lifeexpectancy = configData.lifeexpectancy;
        this.setState({ newUserData })
      })
      .catch((error) => {
        console.error("Error fetching config data:", error);
      });
  }

  onChangeFirstName(value) {
    const user = this.state.newUserData;
    user.setFirstName(value);
    this.forceUpdate();
  }

  onChangeLastName(value) {
    const user = this.state.newUserData;
    user.setLastName(value);
    this.forceUpdate();
  }
  onChangeDOB(value) {
    const user = this.state.newUserData;
    const year = new Date(value).getFullYear();
    user.setDOB(value);
    if (Number(year) > 1000) {
      this.getUserCurrentAge();
    } else {
      this.forceUpdate();
    }
  }

  onChangeLastReviewDate(value) {
    const user = this.state.newUserData;
    user.setLastReviewDate(value);
    this.forceUpdate();
  }

  onChangeNextReviewDate(value) {
    const user = this.state.newUserData;
    user.setNextReviewDate(value);
    this.forceUpdate();
  }

  onChangeOffset(value) {
    const user = this.state.newUserData;
    user.setOffset(value);
    this.forceUpdate();
  }

  onChangePANNumber(value) {
    const user = this.state.newUserData;
    user.setPanNumber(value);
    //There is any changes happened in PAN number , no need keep the old kyc status
    //so, changed the kyc status as ''
    user.setKycStatus("");
    this.setState({ showVerifyPanModal: false, panVerificationMsg: "" });
  }

  onChangeSpouseFirstName(value) {
    const user = this.state.newUserData;
    user.setSpouseFirstName(value);
    this.forceUpdate();
  }

  onChangeSpouseLastName(value) {
    const user = this.state.newUserData;
    user.setSpouseLastName(value);
    this.forceUpdate();
  }

  onChangeLifeExpectancy(value) {
    const user = this.state.newUserData;
    user.setLifeExpectancy(value);
    this.forceUpdate();
  }

  onChangeSpousePANNumber(value) {
    const user = this.state.newUserData;
    user.setSpousePanNumber(value);
    this.setState({
      showVerifySpousePanModal: false,
      spousePanVerificationMsg: "",
    });
  }

  onChangeSpouseDOB(value) {
    const user = this.state.newUserData;
    user.setSpouseDateOfBirth(value);
    this.forceUpdate();
  }

  onChangeEmailId(value) {
    const user = this.state.newUserData;
    const mailId = value.toLowerCase();
    user.setEmailId(mailId);
    this.forceUpdate();
  }

  onChangeMobileNumber(value) {
    const user = this.state.newUserData;
    user.setMobileNumber(value);
    this.forceUpdate();
  }

  onChangeAddress(value) {
    const user = this.state.newUserData;
    user.setAddressLine1(value);
    this.forceUpdate();
  }

  onChangePinCode(value) {
    const user = this.state.newUserData;
    user.setPinCode(value);
    this.forceUpdate();
  }

  onChangeState(value) {
    const user = this.state.newUserData;
    user.setState(value);
    this.forceUpdate();
  }

  onChangeSecurityLevel(value) {
    const securitylevel = getSecurityLevelLabel(value);
    const user = this.state.newUserData;
    user.setSecurityLevel(securitylevel);
    this.forceUpdate();
  }

  onChangeRole(value) {
    const role = getRoleLabel(value);
    const user = this.state.newUserData;
    user.setRole(role);
    this.forceUpdate();
  }

  onSelectGenderButton(index) {
    const user = this.state.newUserData;
    const selectedItem = this.getTypes(index);
    user.setGender(selectedItem);
    this.setState({ selectedGenderIndex: index });
  }

  onSelectResidentButton(index) {
    const user = this.state.newUserData;
    const selectedItem = this.getResidentTypes(index);
    user.setResidentStatus(selectedItem);
    this.setState({ selectedResidentIndex: index });
  }

  onSelectMaritalButton(index) {
    const user = this.state.newUserData;
    const selectedItem = this.getMaritalTypes(index);
    user.setMaritalStatusCode(selectedItem);
    this.setState({ selectedMaritalIndexs: index });
  }

  onModalClose() {
    this.setState({ showModal: false });
  }

  onPressOk() {
    this.setState({ showModal: false });
    if (!this.isCustomerOnly) {
      this.props.navigate("/customermgmt");
    }
  }
  openModal() {
    this.setState({ showModal: true });
  }

  isValidEmail(email) {
    // Simple email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  isValidPhoneNumber(phoneNumber) {
    // Simple phone number validation using a regular expression
    const phoneNumberPattern = /^[0-9]{10}$/; // Assumes the phone number is 10 digits
    return phoneNumberPattern.test(phoneNumber);
  }

  async createUser(callbackfn) {
    const userObj = new Bl.User(this.state.newUserData);
    if (userObj?.lifeexpectancy === 0 || userObj?.lifeExpectancy === "") {
      userObj.setLifeExpectancy(this.state.lifeExpectancy);
    }

    userObj.setAdvisorName(this.state?.loggedInUser?.firstname);
    userObj.setAdvisorId(this.state?.loggedInUser?.id)

    const isDataValid = await this.checkIsDataValid(userObj);
    console.log({ userObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        userObj
          .createUser()
          .then((response) => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("customer profile page/create user Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUser(callbackfn) {
    const userObj = new Bl.User(this.state.newUserData);
    if (userObj?.lifeexpectancy === 0 || userObj.lifeExpectancy === "") {
      userObj.setLifeExpectancy(this.state.lifeExpectancy);
    }
    console.log({ userObj })
    this.setState({ showProgress: true, viewExpense: false }, () => {
      userObj
        .UpdateUser()
        .then((response) => {
          this.setState({ showProgress: false }, () => {
            callbackfn();
          });
        })
        .catch((error) => {
          console.log("customer profile page/update user Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  deleteUser() {
    const userObj = new Bl.User(this.state.newUserData);
    console.log({ userObj });
    userObj
      .deleteUser()
      .then((response) => {
        this.setState({ showProgress: false }, () => {
          this.props.navigate("/customermgmt");
        });
      }).catch((error) => {
        console.log('deleteUser Error:', error);
      });
  }

  validatePan(pannumber, firstname) {
    const user = this.state.newUserData;
    const data = { pannumber: pannumber, firstname: firstname };
    this.setState({ showProgress: true }, () => {
      Bl.User.validateVerifyPAN(data)
        .then((result) => {
          const panVerifiedMsg = result?.message;
          this.setState({
            showProgress: false,
            panVerificationMsg: panVerifiedMsg,
            showVerifyPanModal: !this.state.showVerifyPanModal,
          });
          user.setKycStatus(panVerifiedMsg ? panVerifiedMsg : "");
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast("Please enter valid PAN number");
        });
    });
  }

  validateSpousePan(spousepannumber, spousefirstname) {
    // const user = this.state.newUserData;
    const data = { pannumber: spousepannumber, firstname: spousefirstname };
    this.setState({ showProgress: true }, () => {
      Bl.User.validateVerifyPAN(data)
        .then((result) => {
          const panVerifiedMsg = result?.message;
          this.setState({
            showProgress: false,
            spousePanVerificationMsg: panVerifiedMsg,
            showVerifySpousePanModal: !this.state.showVerifySpousePanModal,
          });
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast("Please enter valid PAN number");
        });
    });
  }
  checkIsDataValid = async (data) => {
    let isValid = true;
    let message = "";
    const userList = await Bl.User.getUserDetails();

    if (data?.firstname === "") {
      isValid = false;
      message = t("Please enter the name");
    } else if (data?.dateofbirth === "") {
      isValid = false;
      message = t("Please select the DOB");
    } else if (data?.pannumber === "") {
      isValid = false;
      message = t("Please enter the PAN number");
    } else if (
      data?.maritalstatuscode === USER_MARITALSTATUS.MARRIED &&
      data?.spousefirstname === ""
    ) {
      isValid = false;
      message = t("Please enter the spouse's name");
    } else if (
      data?.maritalstatuscode === USER_MARITALSTATUS.MARRIED &&
      data?.spousedateofbirth === ""
    ) {
      isValid = false;
      message = t("Please select the spouse's DOB");
    }
    // else if (
    //   data?.maritalstatuscode === USER_MARITALSTATUS.MARRIED &&
    //   data?.spousepannumber === ""
    // ) {
    //   isValid = false;
    //   message = t("Please enter the spouse's PAN number");
    // }
    //TODO: remove the comment and add the following code to check kyc validation,
    // when the KYC api issue resolved
    // else if (!isCustomerOnly && data.kycstatus === "") {
    //   isValid = false;
    //   message = t("PAN verification is not done");
    // } 
    else if (data?.role === USER_ROLE.CUSTOMER && Number(data.retirementage) <= 0) {
      isValid = false;
      message = t("Please select the Retirement age");
    }
    else if (data?.role === USER_ROLE.CUSTOMER && Number(data.lifeexpectancy) < Number(data.retirementage)) {
      isValid = false;
      message = t("Life expectancy should be greater than Retirement age");
    } else if (data.emailid === '') { // Check if the email is valid
      isValid = false;
      message = t("Please enter a email address");
    } else if (!this.isValidEmail(data.emailid)) { // Check if the email is valid
      isValid = false;
      message = t("Please enter a valid email address");
    } else if (data.mobilenumber === '') { // Check if the phone number is valid
      isValid = false;
      message = t("Please enter a phone number");
    } else if (!this.isValidPhoneNumber(data.mobilenumber)) { // Check if the phone number is valid
      isValid = false;
      message = t("Please enter a valid phone number");
    } else if (await this.checkIsEmailAlreadyAvail(userList, data)) {
      isValid = false;
      message = t("The given Email id already available")
    } else if (await this.checkIsPanAlreadyAvail(userList, data)) {
      isValid = false;
      message = t("The given Pan number already available")
    }
    return { isValid, message };
  }

  checkIsEmailAlreadyAvail = async (userList, data) => {
    const isEmailAvail = userList?.filter(user => user.emailid === data?.emailid)?.length > 0;
    return isEmailAvail;
  }

  checkIsPanAlreadyAvail = async (userList, data) => {
    const isPanAvail = userList?.filter(user => user.pannumber === data?.pannumber)?.length > 0;
    return isPanAvail;
  }

  checkIfScreenChanged = () => {
    let isScreenChanged = false;
    const dbData = this.dbValue;
    const localData = new Bl.User(this.state.newUserData);
    if (dbData && localData) {
      const lData = JSON.parse(JSON.stringify(localData));
      if (JSON.stringify(dbData) !== JSON.stringify(lData)) {
        isScreenChanged = true;
      }
    }
    return isScreenChanged;
  };
  onPressCancelBtn() {
    const hasChanged = this.checkIfScreenChanged();
    if (hasChanged) {
      this.showConfirmationModal(
        t(
          "BreadCrumb: Are you sure you want to navigate away from this screen?"
        ),
        () => {
          this.props.navigate("/customermgmt");
        }
      );
    } else {
      this.props.navigate("/customermgmt");
    }
  }

  onPressDeleteBtn() {
    this.showConfirmationModal(
      t("Are you sure you want to delete the user details?"),
      this.deleteUser.bind(this)
    );
  }

  onPressButton() {
    if (this.isCustomerOnly || this.state.isEditView) {
      this.updateUser(this.openModal.bind(this));
    } else {
      this.createUser(this.openModal.bind(this));
    }
  }
  handleToggleSwitchChange(selectedItem) {
    if (!this.isCustomerOnly) {
      const user = this.state.newUserData;
      const customerType =
        selectedItem === t("MFD")
          ? USER_CUSTOMERTYPE.MFD
          : USER_CUSTOMERTYPE.RIA;
      user.setCustomerType(customerType);
      this.forceUpdate();
    }
  }

  handleToggleResidentChange(selectedItem) {
    if (!this.isCustomerOnly) {
      const user = this.state.newUserData;
      const residentStatus =
        selectedItem === t("Resident Indian")
          ? USER_RESIDENTSTATUS.RESIDENT_INDIAN
          : USER_RESIDENTSTATUS.NON_RESIDENT_INDIAN;
      user.setResidentStatus(residentStatus);
      this.forceUpdate();
    }
  }

  handleVerifyPanClick(newUserData) {
    this.validatePan(newUserData.pannumber, newUserData.firstname);
  }

  handleVerifySpousePanClick(newUserData) {
    this.validateSpousePan(
      newUserData.spousepannumber,
      newUserData.spousefirstname
    );
  }

  getTypes(index) {
    const types = [USER_GENDER.MALE, USER_GENDER.FEMALE, USER_GENDER.OTHERS];
    return types[index];
  }
  getResidentTypes(index) {
    const types = ["Resident Indian", "Non Resident Indian"];
    return types[index];
  }

  getMaritalTypes(index) {
    const types = [
      USER_MARITALSTATUS.MARRIED,
      USER_MARITALSTATUS.SINGLE,
      USER_MARITALSTATUS.DIVORCED,
      USER_MARITALSTATUS.OTHER,
    ];
    return types[index];
  }

  getGenederIcon(index) {
    const genderIcons = [MaleGender, FemaleGender, OtherGender];
    return genderIcons[index];
  }

  getSelectedGenederIcon(index) {
    const genderIcons = [
      SelectedMaleIcon,
      SelectedFemaleIcon,
      SelectedOtherIcon,
    ];
    return genderIcons[index];
  }

  getMaritalIcon(index) {
    const maritalIcons = [MarriedIcon, SingleIcon, DivorcedIcon, OtherIcon];
    return maritalIcons[index];
  }

  getSelectedMaritalIcon(index) {
    const maritalIcons = [
      MarriedSelected,
      SingleSelected,
      DivorcedSelected,
      OtherSelected,
    ];
    return maritalIcons[index];
  }

  getKYCstatusDetails(panMsg) {
    let label = "";
    let icon = "";
    if (panMsg?.includes("NOT")) {
      label = t("KYCnotverified");
      icon = kycNotVerIcon;
    } else {
      label = t("KYC Verified");
      icon = VerifyPAN;
    }
    return { label, icon };
  }

  handleChangeStart(event) {
    if (!this.isCustomerOnly) {
      const newValue = event.target.value;
      const user = this.state.newUserData;
      const currentYear = getCurrentYear();
      const currentAge = this.state.currentAge;
      let displayYear = currentYear;
      if (newValue !== 0) {
        const diffAge = newValue - currentAge;
        displayYear = currentYear + diffAge;
      }
      user.setRetirementAge(newValue);
      user.setRetirementYear(displayYear)
      this.setState({ retirementAge: newValue });
    }
  }

  showConfirmationModal = (message, action) => {
    this.setState({
      showConfirmationModal: true,
      modalMessage: message,
      modalAction: action,
    });
  };

  onHideModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  handlePressNo = () => {
    this.onHideModal();
  };

  handlePressYes = () => {
    this.onHideModal();
    if (this.state.modalAction) {
      this.state.modalAction();
    }
  };

  renderInputView(
    label,
    value,
    onChange,
    selectionView = false,
    selectedOptions = [],
    className = "",
    type = "text",
    maxLength,
    disabled,
    showPercentage = false
  ) {
    return (
      <div className="cp_retInput">
        <FloatingLabelInput
          label={label}
          value={value}
          onChangeValue={onChange}
          type={type}
          maxLength={maxLength}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
          className={className}
          disabled={disabled}
          showPercentage={showPercentage}
        />
      </div>
    );
  }
  renderGenderContent(index) {
    const GenderIcon = this.getGenederIcon(index);
    return (
      <div className="cp_toggle_gender">
        <img src={GenderIcon} alt="Male" className="cp_genderIcon" />
      </div>
    );
  }

  renderSelectedGenderContent(index) {
    const GenderIcon = this.getSelectedGenederIcon(index);
    return (
      <div className="cp_toggle_selectedgender">
        <img src={GenderIcon} alt="SelectedToggle" className="cp_genderIcon" />
      </div>
    );
  }

  renderMaritalContent(index) {
    const MaritalIcon = this.getMaritalIcon(index);
    return (
      <div className="cp_toggle_input">
        <img src={MaritalIcon} alt="UnSelectToggle" className="cp_iconStyle" />
      </div>
    );
  }

  renderSelectedMaritalContent(index) {
    const MaritalIcon = this.getSelectedMaritalIcon(index);
    return (
      <div className="cp_toggle_input">
        <img src={MaritalIcon} alt="SelectedToggle" className="cp_iconStyle" />
      </div>
    );
  }
  renderResidentContent(index) {
    const type = this.getResidentTypes(index);
    return (
      <div className="cp_toggle_input">
        <img
          src={MaritalUnSelectedToggle}
          alt="UnSelectToggle"
          className="cp_iconStyle"
        />
        <label className="cp_marital_text">{type}</label>
      </div>
    );
  }

  renderSelectedResidentContent(index) {
    const type = this.getResidentTypes(index);
    return (
      <div className="cp_toggle_input">
        <img
          src={MaritalSelectedToggle}
          alt="SelectedToggle"
          className="cp_iconStyle"
        />
        <label className="cp_marital_text">{type}</label>
      </div>
    );
  }

  renderCustomLabel(value) {
    //Display current year & current age as a sliders starting value
    //And calculate the year based on the current age & current year
    const currentYear = getCurrentYear();
    const currentAge = this.state.currentAge;
    let displayYear = currentYear;
    if (value !== 0) {
      const diffAge = value - currentAge;
      displayYear = currentYear + diffAge;
    }
    return (
      <div>
        <label className="custprofile_year_value"> {displayYear} </label>
      </div>
    );
  }

  renderNavView() {
    // const items = getIncomePathName("FinancialDetails");
    // const location = this.props.location;
    // return (
    //   <BreadCrumb
    //     items={items}
    //     currentPath={location.pathname}
    //     onClickBackIcon={this.onSelectItem.bind(this)}
    //     onClickItem={this.onSelectItem.bind(this)}
    //   />
    // );
  }
  renderTitleView() {
    const title = t("cusprofilePersonalInfo");
    return <TitleView title={title} />;
  }
  renderNameView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    return (
      <div className="cp_name_view">
        <div className="cusprofileFirstName">
          {this.renderInputView(
            t("cusprofileFirstName"),
            newUserData.firstname,
            this.onChangeFirstName.bind(this),
            false,
            [],
            "",
            "",
            "",
            this.isCustomerOnly ? true : false
          )}
        </div>
        <div className="cp_secondname_view">
          {this.renderInputView(
            t("cusprofileLastName"),
            newUserData.lastname,
            this.onChangeLastName.bind(this),
            false,
            [],
            "",
            "",
            "",
            this.isCustomerOnly ? true : false
          )}
        </div>
      </div>
    );
  }

  getUserCurrentAge() {
    const user = this.state.newUserData;
    const data = { dob: user.dateofbirth, offset: DEFAULT_OFFSET }

    this.setState({ showProgress: true }, () => {
      Bl.Common.getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          this.setState({
            currentAge,
            sliderMinValue: currentAge,
            sliderMaxValue: currentAge + 30,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("Customerprofile.js/getUserCurrentAge Error:", error);
          this.setState({ showProgress: false });
        });
    });
  }

  renderDOBView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    var today = new Date().toISOString().split("T")[0];
    return (
      <div className="cp_dob_section">
        <div className="cp_retInput">
          <FloatingLabelInput
            value={newUserData.dateofbirth}
            label={t("cusprofileDateOfBirth")}
            type={"date"}
            onChangeValue={this.onChangeDOB.bind(this)}
            max={today}
            disabled={this.isCustomerOnly ? true : false}
          />
        </div>
        <div className="cp_verificationPAN"></div>
      </div>
    );
  }

  renderPanVerifyResultView(newUserData) {
    const kycDetail = this.getKYCstatusDetails(newUserData?.kycstatus);
    const kycStatusLabel = kycDetail.label;
    const statusIcon = kycDetail.icon;
    return (
      <div className="cp_verificationPAN">
        <img src={statusIcon} alt={"VerifyPan"} className="cp_verifyPAN"></img>
        <label className="cp_verifiedPanLabel">{kycStatusLabel}</label>
      </div>
    );
  }

  renderPanLinkView(newUserData) {
    return newUserData?.kycstatus !== "" && newUserData?.kycstatus !== null ? (
      this.renderPanVerifyResultView(newUserData)
    ) : isAdminRAdvisor() ? (
      <div
        onClick={() => {
          this.handleVerifyPanClick(newUserData);
        }}
        className="cp_verificationPAN"
      >
        <label className="cp_verifyPanLabel">{t("Verify KYC")}</label>
      </div>
    ) : (
      <div className="cp_verificationPAN"></div>
    );
  }

  renderDOBNPANView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    return (
      <div className="cp_dob_gender">
        {this.renderDOBView()}
        <div className="cp_PANView">
          {this.renderInputView(
            t("cusprofilePanNumber"),
            newUserData.pannumber,
            this.onChangePANNumber.bind(this),
            false,
            [],
            "",
            "",
            "",
            this.isCustomerOnly ? true : false
          )}
          {this.renderPanLinkView(newUserData)}
        </div>
      </div>
    );
  }
  renderGenderToggles() {
    return (
      <div className="cp_toggle_gender_container">
        <label className="cp_genderLabel">{t("cusprofileGender")}</label>
        <ToggleSingleSelection
          noOfRows={1}
          noOfCols={3}
          onSelectItem={
            this.isCustomerOnly
              ? () => { }
              : this.onSelectGenderButton.bind(this)
          }
          contentCb={this.renderGenderContent.bind(this)}
          selectedContentCb={this.renderSelectedGenderContent.bind(this)}
          selectedIndex={this.state.selectedGenderIndex}
        />
      </div>
    );
  }

  renderResidentMarital() {
    return (
      <div className="cp_toggle_maritalcontainer">
        <ToggleSingleSelection
          noOfRows={1}
          noOfCols={2}
          onSelectItem={this.onSelectResidentButton.bind(this)}
          contentCb={this.renderResidentContent.bind(this)}
          selectedContentCb={this.renderSelectedResidentContent.bind(this)}
          selectedIndex={this.state.selectedResidentIndex}
        />
      </div>
    );
  }

  renderMaritalSelectionView() {
    return (
      <div className="cp_toggle_maritalcontainer">
        <ToggleSingleSelection
          noOfRows={1}
          noOfCols={4}
          onSelectItem={
            this.isCustomerOnly
              ? () => { }
              : this.onSelectMaritalButton.bind(this)
          }
          contentCb={this.renderMaritalContent.bind(this)}
          selectedContentCb={this.renderSelectedMaritalContent.bind(this)}
          selectedIndex={this.state.selectedMaritalIndexs}
        />
      </div>
    );
  }

  renderMaritalStatusView() {
    return (
      <div className="cp_maritalstatusView">
        <label className="cp_maritalLabel">
          {t("cusprofileMaritalStatus")}
        </label>
        {this.renderMaritalSelectionView()}
      </div>
    );
  }

  renderResidentStatusView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    return (
      <div className="cp_description_view">
        <div className="cp_expense">
          <label className="cp_residentLabel">
            {t("cusprofileResidentStatus")}
          </label>
          <div className="cp_toggle">
            <ToggleSwitch
              checked={
                newUserData?.residentstatus ===
                USER_RESIDENTSTATUS.RESIDENT_INDIAN
              }
              checkedChildren={t("Resident Indian")}
              unCheckedChildren={t("NRI / OCI")}
              onChangeToggle={this.handleToggleResidentChange.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderStartYearSlider() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    return (
      <div className="cp_start_year_row">
        <label className="cp_start_text">{t("cusprofileRetirementAge")}</label>
        <div className="cp_slider_content">
          <Slider
            value={newUserData?.retirementage ? newUserData.retirementage : this.state.sliderMinValue}
            age={newUserData?.retirementage ? newUserData.retirementage : this.state.sliderMinValue}
            min={this.state.sliderMinValue}
            max={this.state.sliderMaxValue}
            markStep={5}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
            renderCustomLabel={this.renderCustomLabel.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderGender_Marital() {
    return (
      <div className="cp_gender_view">
        <div className="cp_gender_toggles">{this.renderGenderToggles()}</div>
        {this.renderMaritalStatusView()}
      </div>
    );
  }

  renderResidentStatus() {
    return (
      <div className="cp_resident_view">
        <div className="cp_expenseType">{this.renderCustomerType()}</div>
        {this.renderResidentStatusView()}
      </div>
    );
  }
  renderCustomerType() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    return (
      <div className="cp_description_view">
        <div className="cp_expense">
          <label className="cp_expense_text">{t("cusprofileType")}</label>
          <div className="cp_toggle">
            <ToggleSwitch
              checked={newUserData?.customer_type === USER_CUSTOMERTYPE.MFD}
              checkedChildren={t("MFD")}
              unCheckedChildren={t("RIA")}
              onChangeToggle={this.handleToggleSwitchChange.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderSpouseNameView(newUserData) {
    return (
      <div className="cp_spouseName">
        <div className="cp_spouseFirstName">
          {this.renderInputView(
            t("cusprofileSpouseFirstName"),
            newUserData?.spousefirstname ? newUserData.spousefirstname : "",
            this.onChangeSpouseFirstName.bind(this),
            false,
            [],
            "",
            "",
            "",
            this.isCustomerOnly ? true : false
          )}
        </div>
        {this.renderInputView(
          t("cusprofileSpouseLastName"),
          newUserData?.spouselastname ? newUserData.spouselastname : "",
          this.onChangeSpouseLastName.bind(this),
          false,
          [],
          "",
          "",
          "",
          this.isCustomerOnly ? true : false
        )}
      </div>
    );
  }

  renderSpouseDOBView(newUserData) {
    var today = new Date().toISOString().split("T")[0];
    return (
      <div className="cp_dob_section">
        <div className="cp_retInput">
          <FloatingLabelInput
            value={newUserData?.spousedateofbirth}
            label={t("cusprofileSpouseDateOfBirth")}
            type={"date"}
            onChangeValue={this.onChangeSpouseDOB.bind(this)}
            max={today}
            disabled={this.isCustomerOnly ? true : false}
          />
        </div>
      </div>
    );
  }

  renderSpousePanVerifyResultView(spousePanStatus) {
    const kycDetail = this.getKYCstatusDetails(spousePanStatus);
    const kycStatusLabel = kycDetail.label;
    const statusIcon = kycDetail.icon;
    return (
      <div className="cp_verificationPAN">
        <img src={statusIcon} alt={"VerifyPan"} className="cp_verifyPAN"></img>
        <label className="cp_verifiedPanLabel">{kycStatusLabel}</label>
      </div>
    );
  }

  renderSpousePanLinkView(newUserData) {
    const spousePanStatus = this.state.spousePanVerificationMsg;
    return spousePanStatus !== "" && spousePanStatus !== null ? (
      this.renderPanVerifyResultView(spousePanStatus)
    ) : isAdminRAdvisor() ? (
      <div
        onClick={() => {
          this.handleVerifySpousePanClick(newUserData);
        }}
        className="cp_verificationPAN"
      >
        <label className="cp_verifyPanLabel">{t("Verify KYC")}</label>
      </div>
    ) : (
      <div className="cp_verificationPAN"></div>
    );
  }

  renderSpouseDetails() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    const spouseLabel = t("cusprofileSpouseDetails");
    return (
      <div>
        <label className="cp_spouseLabel">{spouseLabel}</label>
        <div className="cp_spouse_view">
          {this.renderSpouseNameView(newUserData)}

          <div className="cp_dob_gender">
            {this.renderSpouseDOBView(newUserData)}
            <div className="cp_spousePanNumber">
              {this.renderInputView(
                t("cusprofileSpousePANNumber"),
                newUserData?.spousepannumber ? newUserData.spousepannumber : "",
                this.onChangeSpousePANNumber.bind(this),
                false,
                [],
                "",
                "",
                "",
                this.isCustomerOnly ? true : false
              )}
              {/* {this.renderSpousePanLinkView(newUserData)} */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getAllStates() {
    const stateNames = [];
    const states = this.state.states;
    states.forEach((state) => {
      stateNames.push(state.name);
    });
    return stateNames;
  }

  renderContactInfo() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    const contactLabel = t("cusprofileContactInfo");
    return (
      <div>
        <label className="cp_contactLabel">{contactLabel}</label>
        <div className="cp_contact_view">
          <div className="cp_emailId_phone">
            <div className="cp_emailId">
              {this.renderInputView(
                t("cusprofileEmailID"),
                newUserData?.emailid ? newUserData.emailid : "",
                this.onChangeEmailId.bind(this),
                false,
                [],
                "",
                "",
                "",
                this.isCustomerOnly || this.state.isEditView ? true : false
              )}
            </div>
            {this.renderInputView(
              t("cusprofileMobileNumber"),
              newUserData?.mobilenumber ? newUserData.mobilenumber : "",
              this.onChangeMobileNumber.bind(this),
              false,
              [],
              "",
              "number",
              10
            )}
          </div>
          <div className="cp_emailId_phone">
            <div className="cp_address">
              {this.renderInputView(
                t("cusprofileAddress"),
                newUserData?.addressline1 ? newUserData.addressline1 : "",
                this.onChangeAddress.bind(this)
              )}
            </div>
            <div className="cp_state">
              {this.renderInputView(
                t("cusprofileSelectState"),
                newUserData?.state ? newUserData.state : "Tamil Nadu",
                this.onChangeState.bind(this),
                true,
                this.getAllStates(),
                "ActiveInputBox"
              )}
            </div>
          </div>
          <div className="cp_address">
            {this.renderInputView(
              t("cusprofilePinCode"),
              newUserData?.pincode ? newUserData.pincode : "",
              this.onChangePinCode.bind(this)
            )}
          </div>
        </div>
      </div>
    );
  }
  renderYearNAgeView() {
    return (
      <div className="cp_slider_view">
        {this.renderStartYearSlider()}
        {this.renderRetirementPlan()}
      </div>
    );
  }

  renderYearNoffsetView() {
    return (
      <div>
        <div className="cp_yearsView">{this.renderYearNAgeView()}</div>
        <div className="cp_yearsView">{this.renderOffsetView()}</div>
      </div>
    );
  }

  renderSecurityLevelNRoleView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    return (
      <div>
        <label className="cp_contactLabel">{t('Access Information')}</label>
        <div className="cp_securityNroleView">
          {this.renderInputView(
            t('Security Level'),
            t(newUserData?.securitylevel),
            this.onChangeSecurityLevel.bind(this),
            true,
            [t(SECURITY_LEVEL.GENERAL), t(SECURITY_LEVEL.ADMIN), t(SECURITY_LEVEL.RESTRICTED)])
          }
          {this.renderInputView(
            t('role'),
            t(newUserData?.role),
            this.onChangeRole.bind(this),
            true,
            [t(USER_ROLE.ADVISOR), t(USER_ROLE.CUSTOMER)])
          }
        </div>
      </div>
    );
  }

  renderLastReviewDateView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    var today = new Date().toISOString().split("T")[0];
    return (
      <div className="cp_retInput">
        <FloatingLabelInput
          value={newUserData.lastreviewdate}
          label={t("cusprofileLRD")}
          type={"date"}
          onChangeValue={this.onChangeLastReviewDate.bind(this)}
          max={today}
          disabled={this.isCustomerOnly ? true : false}
        />
      </div>
    );
  }

  renderNextReviewDateView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    var today = new Date().toISOString().split("T")[0];
    return (
      <div className="cp_retInput">
        <FloatingLabelInput
          value={newUserData.nextriviewdate}
          label={t("cusprofileNRD")}
          type={"date"}
          onChangeValue={this.onChangeNextReviewDate.bind(this)}
          // max={today}
          disabled={this.isCustomerOnly ? true : false}
        />
      </div>
    );
  }

  renderReviewDatesView() {
    return (
      <div>
        <label className="cp_contactLabel">{t('cusprofileReviewInfo')}</label>
        <div className="cp_DateSelectionView">
          {this.renderLastReviewDateView()}
          {this.renderNextReviewDateView()}
        </div>
      </div>
    );
  }

  renderPortfolioView() {
    const newUserData = this.state.newUserData;
    const lastUpdatedDate = newUserData?.lastportfolioallocateddate === '1900-01-01' ? '' : newUserData.lastportfolioallocateddate;
    return (
      <div>
        <label className="cp_contactLabel">{t('cusprofilePortfolioInfo')}</label>
        <div className="cp_port_view">
          <div className="cp_port_row">
            {this.renderInputView(
              t('Secure Bucket Rate'),
              Number((newUserData?.planportfolioratesb) * 100).toFixed(2),
              () => { },
              false,
              [],
              "",
              "",
              "",
              true,
              true
            )}
            {this.renderInputView(
              t('Growth Bucket Rate'),
              Number((newUserData?.planportfoliorategb) * 100).toFixed(2),
              () => { },
              false,
              [],
              "",
              "",
              "",
              true,
              true
            )}
          </div>
          <div className="cp_port_row">
            {this.renderInputView(
              t('Last Updated Date'),
              lastUpdatedDate,
              () => { },
              false,
              [],
              "",
              "",
              "",
              true
            )}
          </div>
        </div>
      </div>
    );
  }

  renderOffsetView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    return (
      <div className="cp_offsetInputView">
        <FloatingLabelInput
          value={newUserData.offset}
          label={t("cusprofileOffset")}
          type={"number"}
          onChangeValue={this.onChangeOffset.bind(this)}
          max={today}
          disabled={this.isCustomerOnly ? true : false}
        />
      </div>
    );
  }

  renderCustomInputView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    const disableInput = isRestrictedCustomer() || this.state.isEditView;
    return (
      <div className="cp_retInputView">
        <FloatingLabelInput
          label={t("Till you are")}
          value={
            newUserData?.lifeexpectancy
              ? newUserData.lifeexpectancy
              : ''
          }
          onChangeValue={this.onChangeLifeExpectancy.bind(this)}
          type={"number"}
          maxLength={3}
          disabled={disableInput}
        >
          <label className="cp_retirementLabel"> Years</label>
        </FloatingLabelInput>
      </div>
    );
  }
  renderRetirementPlan() {
    const retirementLabel = t("cusprofileRetirementLabel");
    return (
      <div className="cp_retirementPlan">
        <label className="cp_retLabel">{retirementLabel}</label>
        {this.renderCustomInputView()}
      </div>
    );
  }

  renderContentView() {
    const newUserData = this.state.newUserData ? this.state.newUserData : {};
    return (
      <div className="cp_content_view">
        {this.renderTitleView()}
        <div className="cp_view">
          {this.renderNameView()}
          {this.renderDOBNPANView()}
          {this.renderGender_Marital()}
          {!this.isCustomerOnly &&
            this.renderResidentStatus()}
        </div>
        <div className="cp_seperatorStyle" />
        {newUserData?.maritalstatuscode === USER_MARITALSTATUS.MARRIED &&
          this.renderSpouseDetails()}
        {newUserData?.maritalstatuscode === USER_MARITALSTATUS.MARRIED && (
          <div className="cp_seperatorStyle" />
        )}
        {newUserData?.role === USER_ROLE.CUSTOMER && this.renderYearNoffsetView()}
        {newUserData?.role !== USER_ROLE.CUSTOMER && this.renderSecurityLevelNRoleView()}
        <div className="cp_seperatorStyle" />
        {this.renderContactInfo()}
        <div className="cp_seperatorStyle" />
        {!this.isCustomerOnly &&
          this.state.isEditView &&
          <div className="cp_yearsView">{this.renderReviewDatesView()}</div>}
        {!this.isCustomerOnly &&
          this.state.isEditView && <div className="cp_seperatorStyle" />}
        {!this.isCustomerOnly && this.renderPortfolioView()}
      </div>
    );
  }
  renderButtonView() {
    return (
      <div className={"cp_bottom_view"}>
        {!this.isCustomerOnly && (
          <div className={"cp_button_view"}>
            <Button
              buttonName={t("Cancel")}
              onPressButton={this.onPressCancelBtn.bind(this)}
            ></Button>
          </div>
        )}
        {!this.isCustomerOnly && (
          <div className={"cp_button_view"}>
            <Button
              buttonName={t("Delete")}
              onPressButton={this.onPressDeleteBtn.bind(this)}
            ></Button>
          </div>
        )}
        <div className={"cp_button_view"}>
          <Button
            buttonName={t("Submit")}
            onPressButton={this.onPressButton.bind(this)}
          ></Button>
        </div>
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  renderMsg() {
    const successLabel =
      !this.isCustomerOnly && !this.state.isEditView
        ? "Profile created successfully!"
        : "Profile Updated successfully!";
    return (
      <div className="cp_successModal_dispaly">
        <div className="cp_successModal_close">
          <span onClick={this.onModalClose.bind(this)}>&times;</span>
        </div>
        <div className={"cp_successModal_msg"}>
          <img
            src={SuccessModalIcon}
            alt="SelectedItem"
            className={"cp_successlIcon"}
          />
          <label className="cp_successModal_heading">{successLabel}</label>
        </div>
        <div className="cp_ok">
          {" "}
          <Button
            buttonName={t("OK")}
            onPressButton={this.onPressOk.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderModal() {
    return (
      <Modal
        open={this.state.showModal}
        onClose={this.onModalClose.bind(this)}
        size={"md"}
        className={"cp_successModal"}
      >
        <div className="cp_successModal-content">{this.renderMsg()}</div>
      </Modal>
    );
  }

  renderConfirmationModalView = () => {
    const buttonNames = { no: "No", yes: "Yes" };
    const title = this.state.modalMessage;
    return (
      <RecordModal
        open={this.state.showConfirmationModal}
        onClose={this.onHideModal}
        onPressNo={this.handlePressNo}
        onPressYes={this.handlePressYes}
        title={title}
        buttonNames={buttonNames}
      />
    );
  };

  render() {
    return (
      <div className="cp_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonView()}
        {this.state.showModal && this.renderModal()}
        {!this.isCustomerOnly &&
          this.state.showConfirmationModal &&
          this.renderConfirmationModalView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}
export default CustomerProfile;