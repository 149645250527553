import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class NetworthApi {
   
  static getNetworthByUserId(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserNetWorth/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserFinancialDetails(data) {
    return new Promise((resolve, reject) => {
        apiCall(`UserNetWorth/FinancialDetails/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default NetworthApi;
