import React from "react";
import './basescrn.css';
import Bse from '../assets/svgs/bse.svg';
import Amfi from '../assets/svgs/amfi.svg';
import Sbi from '../assets/svgs/sbi.svg';
import { Footer, CarouselView, Toast, ProgressDialog } from "./components";



class BaseScrn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showToast: false,
            toastMessage: "",
            showProgress: false,
            progressMessage: ""
        }
    }

    hideToast() {
        this.setState({ toastMessage: "", showToast: false });
    }

    showToast(toastMessage) {
        this.setState(
            { toastMessage, showToast: true, showProgress: false },
            () => {
                setTimeout(() => {
                    this.hideToast();
                }, 3000);
            }
        );
    }

    renderBottomView() {
        return (
            <div className="bs_bottomView">
                <Footer />
            </div>
        );
    }

    renderFormContentView() {
        return (
            <div className="bs_formContentView">

            </div>
        );
    }

    renderAdviserView() {
        return (
            <div className="bs_adviserView">
                <label className="bs_adviserLabelStyle">
                    {'We are a SEBI registered investment advisor'}
                </label>
                <div className="bs_adviserImagesView">
                    <div className="bs_itemsView">
                        <img src={Sbi} alt={Sbi} />
                        <label className="bs_itemTextStyle">{'INA100012190'}</label>
                    </div>
                    <div className="bs_itemsView">
                        <img src={Bse} alt={Bse} />
                        <label className="bs_itemTextStyle">{'BSE-24801'}</label>
                    </div>
                    <div className="bs_itemsView">
                        <img src={Amfi} alt={Amfi} />
                        <label className="bs_itemTextStyle">{'ARN-151913'}</label>
                    </div>
                </div>
            </div>
        );
    }

    renderFormView() {
        return (
            <div className="bs_formView">
                {this.renderFormContentView()}
                {/* {this.renderAdviserView()} */}
            </div>
        );
    }

    renderCarouselView() {
        return (
            <div className="bs_carouselView">
                <CarouselView />
            </div>
        );
    }

    renderContentView() {
        return (
            <div className="bs_contentContainer">
                {this.renderFormView()}
                {this.renderCarouselView()}
            </div>
        );
    }

    renderToastView() {
        return (
            <Toast
                showToast={this.state.showToast}
                toastMessage={this.state.toastMessage}
            />
        );
    }

    renderProgressDialog() {
        return (
            <ProgressDialog
                showProgress={this.state.showProgress}
                progressMessage={this.state.progressMessage}
            />
        );
    }

    render() {
        return (
            <div className="bs_container">
                {this.renderContentView()}
                {this.renderBottomView()}
                {this.renderToastView()}
                {this.renderProgressDialog()}
            </div>
        );
    }
}

export default BaseScrn;