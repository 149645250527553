import React from 'react';

class AppListener extends React.Component {
  
  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload.bind(this));
  }

  onUnload(event) {
    if (window.addEventListener) {
      if (event && event.preventDefault) {
        event.returnValue = '';
        event.preventDefault();
      }
    }  
  }
  
  render() {
    return this.props.children ? this.props.children : null;
  }
}

export default AppListener