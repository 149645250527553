import * as React from "react";
import "./mutualfundpage.css";
import {
  BreadCrumb,
  Button,
  ChartView,
  FundDetailsCardView,
  Header,
  TabSelectionView,
  TitleView,
  SideModel,
} from "../../components";
import { t } from "i18next";
import AdityaBirla from "../../../assets/svgs/adityaBirla.svg";
import RatingUrl from "../../../assets/svgs/Ic_rating.svg";
import Boi from "../../../assets/svgs/boi.svg";
import { getFinancialMarketPath } from "../../common";

const adityaBirla = {
  url: AdityaBirla,
  name: "Aditya Birla Sun Life Corporate Bond Fund Direct Plan Growth",
  total: "20,000",
  category: "Tax saving",
  units: "20,000",
  currentValue: "Tax saving",
  fundNav: "200",
  sharpeRatio: "2.99",
  ratingUrl: RatingUrl,
  rating: 5,
  oneYrReturn: "13.5%",
  threeYrReturn: "13.5%",
  fiveYrReturn: "13.5%",
};

const boi = {
  url: Boi,
  name: "BOI AXA Credit Risk Growth Direct Plan",
  total: "20,000",
  category: "Tax saving",
  units: "20,000",
  currentValue: "Tax saving",
  fundNav: "200",
  sharpeRatio: "2.99",
  ratingUrl: RatingUrl,
  rating: 5,
  oneYrReturn: "13.5%",
  threeYrReturn: "13.5%",
  fiveYrReturn: "13.5%",
};

class MutualFund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      equityList: [adityaBirla, boi],
      debtList: [adityaBirla],
      commodityList: [adityaBirla, boi],
      nonEquityList: [adityaBirla],
      nonDebtList: [boi],
      nonCommodityList: [adityaBirla, boi],
    };
  }

  onClickBackIcon(path) {
    this.props.navigate(path);
  }

  onPressUploadButton() {
    //TODO : open 3rd party library
  }



  renderNavView() {
    const items = getFinancialMarketPath("MutualFund");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={this.onClickBackIcon.bind(this)}
      />
    );
  }

  renderTitleView() {
    const information = "";
    const title = t("yourMutualFundPorfolio");
    return <TitleView title={title} information={information} />;
  }

  renderChartView() {
    const chartData = {
      datasets: [
        {
          data: [55, 5, 45],
          backgroundColor: ["#324155", "#6C8FF7", "#FFC700"],
        },
      ],
      labels: [t("equity"), t("gold"), t("debt")],
    };
    return (
      <div className="mf_chartOuterView">
        <label className="mf_assetTitleStyle">{t("assetAllocation")}</label>
        <div className={"mf_chartView"}>
          <ChartView type={"doughnut"} chartData={chartData} />
        </div>
      </div>
    );
  }

  renderUploadButtonView() {
    return (
      <div className="mf_buttonView">
        <Button
          buttonName={t("uploadCas")}
          onPressButton={this.onPressUploadButton.bind(this)}
        />
      </div>
    );
  }

  renderAssetAllocationView() {
    return (
      <div className="mf_assetAllocationView">
        {this.renderChartView()}
        {this.renderUploadButtonView()}
      </div>
    );
  }

  renderTitleNAmountView(title, amount) {
    return (
      <div className="mf_titleNamtView">
        <label className="mf_titleStyle">{title}</label>
        <label className="mf_amtStyle">{`₹ ${amount}`}</label>
      </div>
    );
  }

  renderDetailContentView(cardDetails) {
    return (
      <div className="mf_detailsContentView">
        {cardDetails.map((card, index) => {
          return (
            <div key={index} className="mf_detailsCardView">
              <FundDetailsCardView cardDetail={card} />
            </div>
          );
        })}
      </div>
    );
  }

  renderEquityView(equityList) {
    return (
      <div className="mf_detailsView">
        {this.renderTitleNAmountView(t("equity"), "6,00,000")}
        {this.renderDetailContentView(equityList)}
      </div>
    );
  }

  renderDebtView(debtList) {
    return (
      <div className="mf_detailsView">
        {this.renderTitleNAmountView(t("debt"), "8,00,000")}
        {this.renderDetailContentView(debtList)}
      </div>
    );
  }

  renderCommoditiesView(commodityList) {
    return (
      <div className="mf_detailsView">
        {this.renderTitleNAmountView(t("commodities"), "2,00,000")}
        {this.renderDetailContentView(commodityList)}
      </div>
    );
  }

  renderTabContent(equityList, debtList, commodityList) {
    return (
      <div>
        {this.renderEquityView(equityList)}
        {this.renderDebtView(debtList)}
        {this.renderCommoditiesView(commodityList)}
      </div>
    );
  }

  renderFirstTabContent() {
    return (
      <div className="fdp_tabContentView">
        {this.renderTabContent(
          this.state.equityList,
          this.state.debtList,
          this.state.commodityList
        )}
      </div>
    );
  }

  renderSecondTabContent() {
    return (
      <div className="fdp_tabContentView">
        {this.renderTabContent(
          this.state.nonEquityList,
          this.state.nonDebtList,
          this.state.nonCommodityList
        )}
      </div>
    );
  }

  renderTabSelectionView() {
    const tabs = [
      {
        title: t("fliberPortfolio"),
        content: this.renderFirstTabContent.bind(this),
      },
      {
        title: t("nonFliberPortfolio"),
        content: this.renderSecondTabContent.bind(this),
      },
    ];

    return (
      <div className="fdp_tabSelectionView">
        <TabSelectionView
          tabItems={tabs.map((tab) => tab.title)}
          tabContents={tabs.map((tab) => tab.content)}
        />
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="mf_contentView">
        {this.renderTitleView()}
        {this.renderAssetAllocationView()}
        {this.renderTabSelectionView()}
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  render() {
    return (
      <div className="mf_container">
        {this.renderNavView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderContentView()}
      </div>
    );
  }
}

export default MutualFund;
