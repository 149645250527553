import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class AssetAllocationApi {

  static getAssetAllocationValues() {
    return new Promise((resolve, reject) => {
      apiCall(`AssetAllocation/`, Methods.GET).then((response) => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    })
  }

  static updateAssetAllocationValues(data) {
    return new Promise((resolve, reject) => {
      apiCall(`AssetAllocation/`, Methods.PATCH, data).then((response) => {
        console.log('backend:',response)
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default AssetAllocationApi;