import React from "react";
import "./financialdetailspage.css";
import { t } from "i18next";
import {
  BreadCrumb,
  TitleView,
  ChartView,
  TabSelectionView,
  Button,
  Toast,
  ProgressDialog,
  AlertNotify,
} from "../components";
import RealEstate from "../../assets/svgs/financial/realestate.svg";
import Jewellary from "../../assets/svgs/financial/jewel.svg";
import Art from "../../assets/svgs/financial/art.svg";
import PhysicalAssets from "../../assets/svgs/financial/physicalOthers.svg";
import Stock from "../../assets/svgs/financial/stock.svg";
import MutualFund from "../../assets/svgs/financial/mutualFund.svg";
import Bond from "../../assets/svgs/financial/bond.svg";
import FinancialOthers from "../../assets/svgs/financial/financialOthers.svg";
import BankSaving from "../../assets/svgs/financial/backSaving.svg";
import FixedDeposit from "../../assets/svgs/financial/fixedDeposit.svg";
import GSS from "../../assets/svgs/financial/gss.svg";
import HomeLoan from "../../assets/svgs/financial/homeLoan.svg";
import VehicleLoan from "../../assets/svgs/financial/vehicleLoan.svg";
import CreditCard from "../../assets/svgs/financial/creditCard.svg";
import PersonalLoan from "../../assets/svgs/financial/personalLoan.svg";
import OtherLiabilities from "../../assets/svgs/financial/otherLiability.svg";
import expand from "../../assets/svgs/ic_expand.svg";
import { getIncomePathName, getFormatAmount, getUserId, isRestrictedCustomer } from "../common";
import * as Bl from "../../businesslogic";
import * as logger from "../utils/logger/index";
import {
  FIXED_ASSETS_TYPE,
  INCOME_CATEGORY,
  INVESTMENT_CATEGORY,
  LIABILITIES_CATEGORY,
} from "../constants";

function formatDate(dateStr) {
  const options = { month: "short", day: "numeric", year: "numeric" };
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US", options);
}

class FinancialDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandableDiv: null,
      networthValue: 0,
      liabilityValue: 0,
      investmentValue: 0,
      InvestmentTotals: [],
    };
    this.isRenderingAlready = false;
    this.userId = getUserId();
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getNetworthByUserId();
      this.getUserFinancialDetails();
    }
  }

  onSelectItem(path) {
    if (isRestrictedCustomer()) {
      this.props.navigate("/dashboard");
    } else {
      this.props.navigate(path);
    }
  }

  onPressAddScheme(homeurl) {
    this.props.navigate(homeurl);
  }

  onPressFinish() {
    if (isRestrictedCustomer()) {
      this.props.navigate("/dashboard");
    } else {
      this.props.navigate("/financial");
    }
  }

  onSelectSchemeItem = async (type, homeurl, dashboardurl, category) => {
    let available = false;
    if (homeurl === '' && dashboardurl === '') {
      return
    } else if (isRestrictedCustomer()) {
      AlertNotify.info('Please contact the Fliber team to change your plan')
    } else {
      switch (category) {
        case "FixedAsset":
          available = await this.fixedAssetAlreadyAvailable(type);
          break;
        case "InvestmentCategory":
          available = await this.investmentAlreadyAvailable(type);
          break;
        case "LiabilitiesCategory":
          available = await this.liabilitiesAlreadyAvailable(type);
          break;
        case "IncomeCategory":
          available = await this.incomeAlreadyAvailable(type);
          break;
        default:
          break;
      }
      if (available) {
        this.props.navigate(dashboardurl);
      } else {
        this.props.navigate(homeurl);
      }
    }
  };

  async fixedAssetAlreadyAvailable(type) {
    const data = {
      uuid: this.userId,
      type: type,
    };
    const list = await Bl.FixedAsset.getUserFixedAssetsByType(data);
    return list.length > 0;
  }

  async investmentAlreadyAvailable(type) {
    const data = {
      uuid: this.userId,
      category: type,
    };
    const list = await Bl.Investment.getUserInvestmentByCategory(data);
    return list.length > 0;
  }

  async liabilitiesAlreadyAvailable(type) {
    const data = {
      uuid: this.userId,
      category: type,
    };
    const list = await Bl.Liability.getUserLiabilityByCategory(data);
    return list.length > 0;
  }

  async incomeAlreadyAvailable(type) {
    const data = {
      uuid: this.userId,
      category: type,
    };
    const list = await Bl.Income.getUserIncomesByCategory(data);
    return list.length > 0;
  }

  handleExpand(index) {
    if (this.state.expandableDiv === index) {
      this.setState({ expandableDiv: null });
    } else {
      this.setState({ expandableDiv: index });
    }
  }
  getNetworthByUserId() {
    const data = {
      uuid: this.userId,
    };
    this.setState({ showProgress: true }, () => {
      Bl.Networth.getNetworthByUserId(data)
        .then((list) => {
          this.setState({
            showProgress: false,
            networthValue: list.Networth,
            liabilityValue: list.liabilities,
            investmentValue: list.investment,
            lastUpdatedDate: list.last_updated,
          });
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getUserFinancialDetails() {
    const data = {
      uuid: this.userId,
    };
    Bl.Networth.getUserFinancialDetails(data)
      .then((list) => {
        this.setState({ showProgress: false, InvestmentTotals: list });
      })
      .catch((error) => {
        logger.error({ error });
      });
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderNavView() {
    const items = getIncomePathName("FinancialDetails");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const information = "";
    const title = t("networthTitle");
    return <TitleView title={title} information={information} />;
  }

  calculatePercentage(value, total) {
    return Math.round((value / total) * 100);
  }

  renderMyNetworthView() {
    const networthValue = getFormatAmount(this.state.networthValue);
    const formattedDate = formatDate(this.state?.lastUpdatedDate);
    const displayNetworth = networthValue ? networthValue : 0;
    return (
      <div className="fdp_myNetworthView">
        <label className="fdp_titleStyle">{t("networthConTitle")}</label>
        <label className="fdp_amountStyle">{`₹ ${displayNetworth}`}</label>
        {this.state?.lastUpdatedDate &&
          <label className="fdp_dateStyle">{
            `${t(
              "networthLastUpdate"
            )} ${formattedDate}`}
          </label>}
      </div>
    );
  }

  renderChartView() {
    const totalValue = this.state.investmentValue + this.state.liabilityValue;
    const investmentPercentage = this.calculatePercentage(
      this.state.investmentValue,
      totalValue
    );
    const liabilityPercentage = this.calculatePercentage(
      this.state.liabilityValue,
      totalValue
    );
    const chartData = {
      datasets: [
        {
          data: [investmentPercentage, liabilityPercentage],
          backgroundColor: ["#145079", "#F9CF81"],
        },
      ],
      labels: [t("networthItem1"), t("networthItem2")],
    };
    return (
      <div className="fdp_chartOuterView">
        <div className={"fdp_chartView"}>
          <ChartView type={"doughnut"} chartData={chartData} />
        </div>
      </div>
    );
  }

  renderNetWorthValuesView(label, percentage, value) {
    const percent = percentage ? `${percentage} %` : `0 %`;
    const amount = value ? `₹ ${value}` : `₹ 0`;
    return (
      <div className="fdp_valuesView">
        <label className="fdp_percentageStyle">{percent}</label>
        <label className="fdp_labelStyle">{label}</label>
        <label className="fdp_valueStyle">{amount}</label>
      </div>
    );
  }

  renderBreakUpView() {
    const investments = getFormatAmount(this.state.investmentValue);
    const liabilities = getFormatAmount(this.state.liabilityValue);
    const totalValue = this.state.investmentValue + this.state.liabilityValue;
    const investmentPercentage = this.calculatePercentage(
      this.state.investmentValue,
      totalValue
    );
    const liabilityPercentage = this.calculatePercentage(
      this.state.liabilityValue,
      totalValue
    );
    return (
      <div className="fdp_breakupView">
        <label className="fdp_breakupStyle">{t("networthRightConTitle")}</label>
        {this.renderNetWorthValuesView(
          t("networthItem1"),
          investmentPercentage,
          investments
        )}
        {this.renderNetWorthValuesView(
          t("networthItem2"),
          liabilityPercentage,
          liabilities
        )}
      </div>
    );
  }

  renderNetWorthView() {
    return (
      <div className="fdp_networthView">
        {this.renderMyNetworthView()}
        {this.renderChartView()}
        {this.renderBreakUpView()}
      </div>
    );
  }

  renderTabSelectionView() {
    const tabs = [
      {
        title: t("networthItem1"),
        content: this.renderFirstTabContent.bind(this),
      },
      {
        title: t("networthItem2"),
        content: this.renderSecondTabContent.bind(this),
      },
      {
        title: t("networthItem3"),
        content: this.renderThirdTabContent.bind(this),
      },
    ];

    return (
      <div className="fdp_tabSelectionView">
        <TabSelectionView
          tabItems={tabs.map((tab) => tab.title)}
          tabContents={tabs.map((tab) => tab.content)}
        />
      </div>
    );
  }

  /*This is Dropdown Content Section */

  renderContentTitle(props) {
    const title = props.title;
    const description = props.description;
    const canExpand = props.canExpand;
    const canAdd = props.canAdd;
    const expanded = props.expanded;
    return (
      <div className={canAdd ? "fdp_addable_card" : "fdp_drpdwn_cnt_title"}>
        {canExpand && (
          <img
            className={expanded ? "fdp_drpdwn_icon" : "fdp_drpdwn_icon_rotate"}
            src={expand}
            alt={t("expand")}
          />
        )}
        <div className="fdp_dd_cnt_titleNdesc">
          <label className="fdp_dd_title_style">{title}</label>
          <label className="fdp_dd_desc_style">{description}</label>
        </div>
        {canAdd && (
          <label className="fdp_add_scheme">{t("networthAddIcon")}</label>
        )}
      </div>
    );
  }

  renderScheme(props) {
    const displayValue =
      props.value && props.value !== "0.00" ? `₹ ${props.value}` : "";
    return (
      <div className={displayValue ? "fdp_scheme_value" : "fdp_scheme_outer"}>
        <div className="fdp_scheme_inner">
          <div
            className="fdp_add_style"
            onClick={
              () => {
                this.onSelectSchemeItem(
                  props.type,
                  props.homeurl,
                  props.dashboardurl,
                  props.category
                );
              }
            }
          >
            <img
              className="fdp_drpdwn_icon"
              src={props.icon}
              alt={props.title}
            />
            <div style={{ marginLeft: 12 }}>
              <label className="fdp_svngs_sch_title">{props.title}</label>
              {displayValue && (
                <div className="fdp_descNValueView">
                  <label className="fdp_dd_desc_style">
                    {`${t("totalCurrentValue")} : `}
                  </label>
                  <label className="fdp_dd_value_style">{displayValue}</label>
                </div>
              )}
            </div>
          </div>
          {!isRestrictedCustomer() ?
            props.homeurl !== '' ?
              <label
                className="fdp_add_scheme"
                onClick={() => {
                  this.onPressAddScheme(props.homeurl);
                }}
              >
                {t("networthAddIcon")}
              </label> :
              <label className="fdp_add_scheme fdp_extraLabelStyle">
                {t("System Update")}
              </label>
            : <label></label>
          }
        </div>
      </div >
    );
  }

  renderPhysicalAssetsExpanded() {
    const investmentTotals = this.state.InvestmentTotals;
    const categoryField = investmentTotals.fixedassets;
    const realEstateTotal = getFormatAmount(categoryField?.REALE);
    const jewelleryTotal = getFormatAmount(categoryField?.JWVLB);
    const artTotal = getFormatAmount(categoryField?.ARWRK);
    const otherPhysicalAssetsTot = getFormatAmount(categoryField?.CUSTM);
    return (
      <div className="fdp_expandable_content">
        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: realEstateTotal,
          icon: RealEstate,
          title: t("networthAssetsPA1"),
          homeurl: "/realestatehome",
          dashboardurl: "/realestatedashboard",
          type: FIXED_ASSETS_TYPE.REAL_ESTATE,
          category: "FixedAsset",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: jewelleryTotal,
          icon: Jewellary,
          title: t("networthAssetsPA2"),
          homeurl: "/jewelleryhome",
          dashboardurl: "/jewellerydashboard",
          type: FIXED_ASSETS_TYPE.JEWELLERY,
          category: "FixedAsset",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: artTotal,
          icon: Art,
          title: t("networthAssetsPA3"),
          homeurl: "/artantiqueshome",
          dashboardurl: "/artantiquesdashboard",
          type: FIXED_ASSETS_TYPE.ART,
          category: "FixedAsset",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: otherPhysicalAssetsTot,
          icon: PhysicalAssets,
          title: t("networthAssetsPA4"),
          homeurl: "/physicalassethome",
          dashboardurl: "/physicalassetdashboard",
          type: FIXED_ASSETS_TYPE.OTHER_PHY_ASSETS,
          category: "FixedAsset",
        })}
      </div>
    );
  }

  renderFinancialMarketExpanded() {
    const investmentTotals = this.state.InvestmentTotals;
    const categoryField = investmentTotals.investment;
    const npsTotal = getFormatAmount(categoryField?.NPSIN);
    const bondTotal = getFormatAmount(categoryField?.BONDS);
    const mutualfundTotal = getFormatAmount(categoryField?.MUTFN);
    const stockTotal = getFormatAmount(categoryField?.STOCK);
    return (
      <div className="fdp_expandable_content">
        <div className="fdp_separatorStyle" />


        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: npsTotal,
          icon: Bond,
          title: t("networthAssetsFM2"),
          homeurl: "/npshome",
          dashboardurl: "/npsdashboard",
          type: INVESTMENT_CATEGORY.NPS,
          category: "InvestmentCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: bondTotal,
          icon: Bond,
          title: t("networthAssetsFM3"),
          homeurl: "/bondhome",
          dashboardurl: "/bonddashboard",
          type: INVESTMENT_CATEGORY.BONDS,
          category: "InvestmentCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: mutualfundTotal,
          icon: MutualFund,
          title: t("networthAssetsFM1"),
          homeurl: "",
          dashboardurl: "",
          type: INVESTMENT_CATEGORY.MUTUAL_FUND,
          category: "InvestmentCategory",
        })}
        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: stockTotal,
          icon: Stock,
          title: t("stock"),
          homeurl: "/stockshome",
          dashboardurl: "/stocksdashboard",
          type: INVESTMENT_CATEGORY.STOCKS,
          category: "InvestmentCategory"
        })}
      </div>
    );
  }

  renderFinancialNonMarketExpanded() {
    const investmentTotals = this.state.InvestmentTotals;
    const categoryField = investmentTotals.investment;
    const bankSavingsTotal = getFormatAmount(categoryField?.BASVG);
    const govSavingsTotal = getFormatAmount(categoryField?.GOVSC);
    const ppfTotal = getFormatAmount(categoryField?.PPFIN);
    const epfTotal = getFormatAmount(categoryField?.EPFIN);
    const fixedDepositTotal = getFormatAmount(categoryField?.FXDEP);
    const otherNonInvTotal = getFormatAmount(categoryField?.OTINV);
    return (
      <div className="fdp_expandable_content">
        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: bankSavingsTotal,
          icon: BankSaving,
          title: t("networthAssetsFNM1"),
          homeurl: "/banksavingshome",
          dashboardurl: "/banksavingsdashboard",
          type: INVESTMENT_CATEGORY.BANK_SAVINGS,
          category: "InvestmentCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: fixedDepositTotal,
          icon: FixedDeposit,
          title: t("networthAssetsFNM2"),
          homeurl: "/fdhome",
          dashboardurl: "/fddashboard",
          type: INVESTMENT_CATEGORY.FIXED_DEPOSIT,
          category: "InvestmentCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: govSavingsTotal,
          icon: GSS,
          title: t("networthAssetsFNM3"),
          homeurl: "/gsshome",
          dashboardurl: "/gssdashboard",
          type: INVESTMENT_CATEGORY.GSS,
          category: "InvestmentCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: epfTotal,
          icon: GSS,
          title: t("networthAssetsFNM4"),
          homeurl: "/epfhome",
          dashboardurl: "/epfdashboard",
          type: INVESTMENT_CATEGORY.EPF,
          category: "InvestmentCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: ppfTotal,
          icon: GSS,
          title: t("networthAssetsFNM5"),
          homeurl: "/ppfhome",
          dashboardurl: "/ppfdashboard",
          type: INVESTMENT_CATEGORY.PPF,
          category: "InvestmentCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: otherNonInvTotal,
          icon: FinancialOthers,
          title: t("networthAssetsFNM6"),
          homeurl: "/othernonmarkethome",
          dashboardurl: "/othernonmarketdashboard",
          type: INVESTMENT_CATEGORY.OTHER_NONMARKET,
          category: "InvestmentCategory",
        })}
      </div>
    );
  }

  renderLiabilitiesExpanded() {
    const investmentTotals = this.state.InvestmentTotals;
    const categoryField = investmentTotals.liabilities;
    const homeLoanTotal = getFormatAmount(categoryField?.HLOAN);
    const otherLoanTotal = getFormatAmount(categoryField?.OLOAN);
    const creditCardLoanTotal = getFormatAmount(categoryField?.CLOAN);
    const personalLoanTotal = getFormatAmount(categoryField?.PLOAN);
    const vehicleLoanTotal = getFormatAmount(categoryField?.VLOAN);
    return (
      <div className="fdp_expandable_content">
        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: homeLoanTotal,
          icon: HomeLoan,
          title: t("networthLib1"),
          homeurl: "/housingloanhome",
          dashboardurl: "/housingloandashboard",
          type: LIABILITIES_CATEGORY.HOUSE_LOAN,
          category: "LiabilitiesCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: vehicleLoanTotal,
          icon: VehicleLoan,
          title: t("networthLib2"),
          homeurl: "/vehicleloanhome",
          dashboardurl: "/vehicleloandashboard",
          type: LIABILITIES_CATEGORY.VEHICLE_LOAN,
          category: "LiabilitiesCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: creditCardLoanTotal,
          icon: CreditCard,
          title: t("networthLib3"),
          homeurl: "/creditcardhome",
          dashboardurl: "/creditcarddashboard",
          type: LIABILITIES_CATEGORY.CREDITCARD_BALANCE,
          category: "LiabilitiesCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: personalLoanTotal,
          icon: PersonalLoan,
          title: t("networthLib4"),
          homeurl: "/personalloanhome",
          dashboardurl: "/personalloandashboard",
          type: LIABILITIES_CATEGORY.PERSONAL_LOAN,
          category: "LiabilitiesCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: otherLoanTotal,
          icon: OtherLiabilities,
          title: t("networthLib5"),
          homeurl: "/otherloanhome",
          dashboardurl: "/otherloandashboard",
          type: LIABILITIES_CATEGORY.OTHER_LIABILITIES,
          category: "LiabilitiesCategory",
        })}
      </div>
    );
  }

  renderIncomeExpanded() {
    const investmentTotals = this.state.InvestmentTotals;
    const categoryField = investmentTotals.income;
    const pensionTotal = getFormatAmount(categoryField?.PENIN);
    const businessTotal = getFormatAmount(categoryField?.BUSIN);
    const rentalTotal = getFormatAmount(categoryField?.RNTIN);
    const insuranceTotal = getFormatAmount(categoryField?.INSIN);
    const otherTotal = getFormatAmount(categoryField?.OTHIN);
    return (
      <div className="fdp_expandable_content">
        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: pensionTotal,
          icon: HomeLoan,
          title: t("networthIncome1"),
          homeurl: "/annuityhomepage",
          dashboardurl: "/annuitydashboard",
          type: INCOME_CATEGORY.PENSION_ANNUITY,
          category: "IncomeCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: businessTotal,
          icon: HomeLoan,
          title: t("networthIncome2"),
          homeurl: "/businesshomepage",
          dashboardurl: "/businessdashboard",
          type: INCOME_CATEGORY.BUSINESS,
          category: "IncomeCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: rentalTotal,
          icon: HomeLoan,
          title: t("networthIncome3"),
          homeurl: "/rentalhomepage",
          dashboardurl: "/rentaldashboard",
          type: INCOME_CATEGORY.RENTAL,
          category: "IncomeCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: insuranceTotal,
          icon: HomeLoan,
          title: t("networthIncome4"),
          homeurl: "/insurancehomepage",
          dashboardurl: "/insurancedashboard",
          type: INCOME_CATEGORY.INSURANCE,
          category: "IncomeCategory",
        })}

        <div className="fdp_separatorStyle" />
        {this.renderScheme({
          value: otherTotal,
          icon: HomeLoan,
          title: t("networthIncome5"),
          homeurl: "/otherhomepage",
          dashboardurl: "/otherincomedashboard",
          type: INCOME_CATEGORY.OTHER,
          category: "IncomeCategory",
        })}
      </div>
    );
  }

  renderContent(content) {
    const expanded = this.state.expandableDiv === content.index ? true : false;
    return (
      <div
        className="fdp_scheme_card"
        onClick={() => {
          this.handleExpand(content.index);
        }}
      >
        {this.renderContentTitle({
          title: content.title,
          description: content.description,
          canExpand: true,
          expanded: expanded,
        })}
        {expanded && content.renderExpanded()}
      </div>
    );
  }

  renderFirstTabContent() {
    return (
      <div className="fdp_tabContentView">
        <div className="fdp_colView">
          {this.renderContent({
            index: 0,
            title: t("assets1"),
            description: "",
            renderExpanded: this.renderPhysicalAssetsExpanded.bind(this),
          })}
          {this.renderContent({
            index: 2,
            title: t("assets2"),
            description: "",
            renderExpanded: this.renderFinancialNonMarketExpanded.bind(this),
          })}
        </div>
        <div className="fdp_colView">
          {this.renderContent({
            index: 1,
            title: t("assets3"),
            description: "",
            renderExpanded: this.renderFinancialMarketExpanded.bind(this),
          })}
        </div>
      </div>
    );
  }

  renderSecondTabContent() {
    return (
      <div className="fdp_tabContentView">
        <div className="fdp_colView">
          {this.renderContent({
            index: 1,
            title: t("liabilities1"),
            description: "",
            renderExpanded: this.renderLiabilitiesExpanded.bind(this),
          })}
        </div>
      </div>
    );
  }

  renderThirdTabContent() {
    return (
      <div className="fdp_tabContentView">
        <div className="fdp_colView">
          {this.renderContent({
            index: 2,
            title: t("income1"),
            description: "",
            renderExpanded: this.renderIncomeExpanded.bind(this),
          })}
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="fdp_content">
        {this.renderTitleView()}
        {this.renderNetWorthView()}
        {this.renderTabSelectionView()}
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="fdp_bottomView">
        <div className="fdp_buttonView">
          <Button
            buttonName={t("finish")}
            onPressButton={this.onPressFinish.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="fdp_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default FinancialDetailsPage;
