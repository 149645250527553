import "./cashladderfinancialview.css";
import { t } from "i18next";
import { FiFilter as Filter } from "react-icons/fi";
import { FiZoomIn as ZoomIn } from "react-icons/fi";
import ToggleSwitch from "./toggleswitch";
import { useState } from "react";
import ChartView from "./chartview";
import { Modal } from "rsuite";



const CashLadderFinancialView = (props) => {
  const Data = props.cashLadderValues;
  const [state, setState] = useState({
    showCashLadder: true,
    showModal: false,
  });

  const handleToggleSwitchChange = (selectedItem, checked) => {
    setState((prevState) => ({ ...prevState, showCashLadder: checked }));
  };
  const onClose = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false
    }))
  }

  const onPressZoomIn = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true
    }))
  }

  const renderZoomInView = () => {
    return <ZoomIn className={"cf_iconStyle"} onClick={onPressZoomIn} />;
  };

  const renderToggleView = () => {
    return (
      <div className="cf_toggleView">
        <ToggleSwitch
          checked={state.showCashLadder === true}
          checkedChildren={t("CashLadder: Income / Expense")}
          unCheckedChildren={t("CashLadder")}
          onChangeToggle={handleToggleSwitchChange}
        />
      </div>
    );
  };

  const renderTitleView = () => {
    const title = state.showCashLadder ? t("CashLadder") : t("CashLadder: Income / Expense");
    return (
      <div className={"cf_titleView"}>
        <div className={"cf_itemsView"}>
          <label className={"cf_titleStyle"}>{title}</label>
          {renderZoomInView()}
        </div>
        <div className={"cf_itemsView"}>{renderToggleView()}</div>
      </div>
    );
  };

  const renderCashLadderChartView = () => {
    const chartData = {
      labels: Data.map((data) => data.cashflowage),

      datasets: [
        {
          type: "line",
          label: "Starting Balance",
          data: Data.map((data) => data.Begin_start_bal),
          backgroundColor: "#e6b400",
          borderColor: '#e6b400'
        },
        {
          type: "line",
          label: "Total",
          data: Data.map((data) => data.total_port_year_end_balance),
          backgroundColor: "#212124",
          borderColor: '#212124'
        },
        {
          type: "bar",
          label: "Secure",
          data: Data.map((data) => data.sb_port_year_end_balance),
          backgroundColor: "#326da8",
        },
        {
          type: "bar",
          label: "Growth ",
          data: Data.map((data) => data.gb_port_year_end_balance),
          backgroundColor: "#fa3e05",
        },
      ],
    };
    return (
      <div className={state.showModal ? "cf_chartViewModal" : "cf_chartView"}>
        <ChartView
          type={"bar"}
          xAxisName={t("CashLadder: Age In Years")}
          chartData={chartData}
        />
      </div>
    );
  };

  const renderExpenseIncomeChartView = () => {
    const chartData = {
      labels: Data.map((data) => data.cashflowage),
      datasets: [
        {
          label: "Income",
          data: Data.map((data) => data.income),
          backgroundColor: "#F3C970",
        },
        {
          label: "Expense ",
          data: Data.map((data) => data.goal),
          backgroundColor: "#013F60",
        },
      ],
    };
    return (
      <div className={state.showModal ? "cf_chartViewModal" : "cf_chartView"}>
        <ChartView
          type={"bar"}
          xAxisName={t("CashLadder: Age In Years")}
          chartData={chartData}
        />
      </div>
    );
  };

  const renderChartView = () => {
    return (
      <div className={"cf_chartContainer"}>
        {state.showCashLadder
          ? renderCashLadderChartView()
          : renderExpenseIncomeChartView()}
      </div>
    );
  };

  const renderTabelTitle = () => {
    const title = state.showCashLadder ? t("CashLadder") : t("CashLadder: Income / Expense");
    return (
      <div className="gf_modal_title">
        <div></div>
        <label className="gf_modal_heading">{title}</label>
        <span className="gf_modal_close" onClick={onClose}>&times;</span>
      </div>
    )
  }

  const renderDisclaimerView = () => {
    return (
      <div className="clp_effectiveView" style={{ marginTop: '12px', justifyContent: 'center' }}>
        <label className="clp_effectRateStyle">{`GBR : ${Number((props?.effectiveData?.gbeffectiverate) * 100)?.toFixed(2)} %`}</label>
        <label className="clp_effectRateStyle">{`SBR : ${Number((props?.effectiveData?.sbeffectiverate) * 100)?.toFixed(2)} %`}</label>
        <label className="clp_negativeStyle">{`Your Portfolio should last till your age of ${props.negativeYr} years`}</label>
      </div >
    );

  }
  const renderTabelModal = () => {
    return (
      <Modal
        size="xl"
        open={state.showModal}
        onClose={onClose}
        className="gf_modal"
      >
        {renderTabelTitle()}
        {renderChartView()}
        {props.showDisclaimer && renderDisclaimerView()}
      </Modal>
    )
  };

  return (
    <div className="cf_container">
      {renderTitleView()}
      {renderChartView()}
      {state.showModal && renderTabelModal()}
    </div>
  );
};

export default CashLadderFinancialView;
