import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class IncomeApi {
  static createIncome(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserIncomes/`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateIncome(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserIncomes/`, Methods.PATCH, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteIncome(data) {
    return new Promise((resolve,reject) => {
      apiCall(`UserIncomes/by/income/id/?id=${data.id}`,Methods.DELETE, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
    })
  }

  static getUserIncomeConfig() {
    return new Promise((resolve, reject) => {
      apiCall(`UserIncomes/get/income/config`, Methods.GET)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      })
    })
  }

  static getUserIncomesByUserId(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserIncomes/by/user/id/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserIncomesByCategory(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `UserIncomes/by/income/category/${data.uuid}/${data.category}`,
        Methods.GET
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default IncomeApi;
