import React, { Component } from "react";
import { t } from "i18next";
import "./epfhome.css";
import EPFProfile from "../../../assets/svgs/epf_home.svg";
import {
  BreadCrumb,
  Button,
  TitleView,
  SideModel,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { DEFAULT_OFFSET, INVESTMENT_CATEGORY } from "../../constants";
import { getFinancialNonMarketPath, getUserDOB, getUserId } from "../../common";
import { getCurrentAge } from "../../utils/common";
import { getCurrentYear } from "../../utils/datetime";
import { getUserDetails } from "../../common";

const newInvestment = {
  investmentcategory: INVESTMENT_CATEGORY.EPF,
  currentvalue: 0,
  annualcontribution: 0,
  maturityyear: 0,
  maturityvalue: 0,
  expectedror: 0,
  userid: '',
  contributiongrowthrate: 0,
  investementtype: "YEAR",
  investmentdetails: [
    {
      isEPFO: false,
    },
  ],
  investmentdescription: "",
  fvatretirement: 0,
  maturityage: 0,
  userage: 0,
  isincludefinancialplan: false
};
class EpfHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInvestmentData: new Bl.Investment({ ...newInvestment, userid: getUserId() }),
      investmentData: [],
    };
    this.isRenderingAlready = false;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getCurrentAge();
    }
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressSaveButton() {
    this.props.navigate("/epfdetails", {
      state: {
        investmentData: this.state.newInvestmentData,
        isEditView: false,
      },
    });
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET };
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const investmentObj = this.state.newInvestmentData;
          const userData = getUserDetails();
          investmentObj.setUserAge(currentAge);
          investmentObj.setMaturityAge(userData.retirementage);
          investmentObj.setMaturityYear(userData.retirementyear);
          this.setState({
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("EPF.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  renderNavView() {
    const items = getFinancialNonMarketPath("EPF");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFNM4");
    return <TitleView title={title} information={""} />;
  }

  renderProfileView() {
    return (
      <div className="epf_profile_view">
        <img
          src={EPFProfile}
          alt={t("networthAssetsFNM4")}
          className="epf_icon"
        />
        <div className="epf_save">
          <Button
            buttonName={t("epfButton")}
            onPressButton={this.onPressSaveButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="epf_content_view">
        {this.renderTitleView()}
        {this.renderProfileView()}
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  render() {
    return (
      <div className="epf_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default EpfHome;
