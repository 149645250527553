import React, { Component } from "react";
import "./numbersliderview.css";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

class NumberSliderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  onSelectItem(index) {
    if (this.props.onSelectSlider) {
      this.props.onSelectSlider(index);
    }
  }

  onPressLeftArrow() {
    if (this.sliderRef) {
      this.sliderRef.scrollLeft -= 250;
    }
  }

  onPressRightArrow() {
    if (this.sliderRef) {
      this.sliderRef.scrollLeft += 250;
    }
  }

  renderValue() {
    const numbers = this.props.sliderContent;
    const enableNumbers = this.props.enableNumbers;
    return numbers.map((number, index) => {
      const isDisabled = !enableNumbers?.includes(index);
      return (
        <div
          key={index}
          className="sl_array_view"
          onClick={
            isDisabled
              ? () => {}
              : () => {
                  this.onSelectItem(index);
                }
          }
        >
          <label
            className={`sl_value ${
              this.props.sliderIndex === index ? "sl_selectedValue" : ""
            }`}
            style={{ color: isDisabled ? "" : "#013F60" }}
          >
            {index + 1}
          </label>
          {this.props.sliderIndex === index ? (
            <div className="sl_indicator_style" />
          ) : (
            <div className={"sl_emptyStyle"} />
          )}
        </div>
      );
    });
  }

  render() {
    return (
      <div className="sl_container">
        <BiChevronLeft
          className="left_arrow"
          onClick={this.onPressLeftArrow.bind(this)}
        />
        <div
          className="sl_content"
          ref={(ref) => {
            this.sliderRef = ref;
          }}
        >
          {this.renderValue()}
        </div>
        <BiChevronRight
          className="right_arrow"
          onClick={this.onPressRightArrow.bind(this)}
        />
      </div>
    );
  }
}

export default NumberSliderView;
