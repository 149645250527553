import React, { useState } from 'react';
import './stockshome.css'
import { t } from "i18next";
import StockProfile from '../../../assets/svgs/stock_home.svg'
import { BreadCrumb, Button, TitleView } from '../../components';
import { getFinancialMarketPath, getUserId } from '../../common';
import { INVESTMENT_CATEGORY } from '../../constants';
import * as Bl from '../../../businesslogic';

const newInvestment = {
  investmentcategory: INVESTMENT_CATEGORY.STOCKS,
  currentvalue: 0,
  annualcontribution: 0,
  maturityyear: 0,
  maturityvalue: 0,
  expectedror: 0,
  userid: '',
  contributiongrowthrate: 0,
  investementtype: "YEAR",
  investmentdetails: [],
  investmentdescription: "",
  fvatretirement: 0,
  maturityage: 0,
  userage: 0,
  isincludefinancialplan: false
};


const StocksHome = (props) => {
  const [state] = useState({
    newInvestmentData: new Bl.Investment({ ...newInvestment, userid: getUserId() }),
    investmentData: [],
  })

  const onSelectItem = (path) => {
    props.navigate(path);
  }

  const onPressAddButton = () => {
    props.navigate('/stocksdetailpage', {
      state: {
        investmentData: state.newInvestmentData,
        isEditView: false
      }
    })
  }


  const renderNavView = () => {
    const items = getFinancialMarketPath("STOCK");
    const location = props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={onSelectItem}
        onClickItem={onSelectItem}
      />
    );
  }

  const renderTitleView = () => {
    const title = t('stocks');
    return (
      <TitleView title={title} />
    );
  }

  const renderProfileView = () => {
    return (
      <div className='st_profile_view'>
        <img
          src={StockProfile}
          alt={"Stock Profile"}
          className='st_icon'
        />
        <div className='st_info_view'>{t("Include stocks that you have earmarked for retirement")}</div>
        <div className='st_save'>
          <Button
            buttonName={t('addStocks')}
            onPressButton={onPressAddButton}
          />
        </div>
      </div>
    )
  }

  const renderContentView = () => {
    return (
      <div className='st_content_view'>
        {renderTitleView()}
        {renderProfileView()}
      </div>
    )
  }


  return (
    <div className='st_container'>
      {renderNavView()}
      {renderContentView()}
    </div>
  )
}

export default StocksHome;
