import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class FixedAssetsApi {
  static createFixedAssets(data) {

    return new Promise((resolve, reject) => {
      apiCall(`UserFixedAssets/`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateFixedAssets(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserFixedAssets/?Type=${data.fixedassettype}`, Methods.PATCH, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteFixedAssets(data) {
    return new Promise((resolve, reject) => {
    
       apiCall(`UserFixedAssets/by/${data.id}`, Methods.DELETE, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  

  static getUserFixedAssetsByUserId(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserFixedAssets/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserFixedAssetsByType(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserFixedAssets/by/type/${data.uuid}/${data.type}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
export default FixedAssetsApi;
