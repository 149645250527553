import React, { Component } from "react";
import "./customgoalexpensepage.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  CostPerYearView,
  FloatingLabelInput,
  ProgressDialog,
  Slider,
  TitleView,
  Toast,
  ToggleSwitch,
} from "../components";
import { GOAL_CATEGORY, GOAL_FREQUENCY_TYPE, DEFAULT_OFFSET, GOAL_EXPENSE_TYPE, GOAL_AMOUNT_TYPE } from "../constants";
import { getCurrentYear } from "../utils/datetime";
import * as Bl from "../../businesslogic";
import {
  calculate_age,
  getAdvisorId,
  getGoalsPathName,
  getUserDOB,
  getUserId,
} from "../common";
import { getGoalConfigInfo, getCurrentAge } from "../utils/common";

const newGoal = {
  userid: '',
  goalcategory: GOAL_CATEGORY.CUSTOM_GOAL,
  goalstartyear: 0,
  goalendyear: 0,
  goalamountpv: 0,
  goalfrequency: 1,
  advisorid: getAdvisorId(),
  goalfrequencytype: GOAL_FREQUENCY_TYPE.YEARLY,
  goalbucketcode: "",
  retirewanttype: "",
  monthlyexpenselist: [],
  goaldescription: "",
  goalinflationrate: 0,
  goalamountfv: 0,
  homeloanstatus: false,
  downpayment: 0,
  goaldetails: [],
  goalstartage: 0,
  goalendage: 0,
  userage: 0,
  expensetype: GOAL_EXPENSE_TYPE.LIFE_LONG
};

class CustomGoalExpensePage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    const goalData = params?.goalData;
    this.state = {
      startValue: isEditView ? goalData.goalstartyear : getCurrentYear() + 1,
      endValue: isEditView ? goalData.goalendyear : getCurrentYear() + 2,
      startYear: getCurrentYear() + 1,
      endYear: getCurrentYear() + 2,
      selectedSliderIndex: 0,
      currentAge: 0,
      type: isEditView && goalData.expensetype === GOAL_EXPENSE_TYPE.ONE_TIME ? goalData.expensetype : GOAL_EXPENSE_TYPE.RECURRING,
      frequencyValue: isEditView ? goalData.goalfrequency : 1,
      sliderContent: [],
      description: isEditView ? goalData.goaldescription : "",
      cost: isEditView ? (goalData.goalamountpv !== 0 ? goalData.goalamountpv : goalData.goalamountfv) : "",
      inflationRate: isEditView ? goalData.goalinflationrate : 0,
      viewExpense: false,
      showButtons: false,
      isEditView: isEditView ? isEditView : false,
      recurringType: isEditView ? goalData.expensetype : GOAL_EXPENSE_TYPE.LIFE_LONG,
      newGoalData: isEditView ? new Bl.Goals(goalData) : new Bl.Goals({ ...newGoal, userid: getUserId() }),
      amountType: isEditView ? (goalData.goalamountpv !== 0 ? GOAL_AMOUNT_TYPE.PRESENT : GOAL_AMOUNT_TYPE.FUTURE) : GOAL_AMOUNT_TYPE.PRESENT
    };
    this.costPerYearViewRef = React.createRef();
    this.isRenderingAlready = false;
    this.inflationRate = 0;
    this.dbValue = this.state.newGoalData;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      !this.state.isEditView && this.getInflationRate();
      this.getCurrentAge();
    }
  }

  onSelectItem(path) {
    const localData = new Bl.Goals(this.state.newGoalData)
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeDescription(value) {
    const goals = this.state.newGoalData;
    goals.setDescription(value);
    this.setState({ description: value });
  }

  onChangeGoalAmount(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    if (this.state.amountType === GOAL_AMOUNT_TYPE.PRESENT) {
      goals.setGoalAmountPv(value ? Number(value) : 0);
    } else if (this.state.amountType === GOAL_AMOUNT_TYPE.FUTURE) {
      goals.setGoalAmountFv(value ? Number(value) : 0);
    }
    this.setState({ cost: value, viewExpense: false });
  }

  onChangeInflationRate(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalInflationRate(value ? Number(value) : 0);
    this.setState({ inflationRate: value, viewExpense: false });
  }

  isValueValid(data) {
    const value = this.state.amountType === GOAL_AMOUNT_TYPE.PRESENT ? data.goalamountpv : data.goalamountfv;
    return value > 0;
  }
  isInflationValid(data) {
    let isValid = true;

    const isInflationRequired = this.state.amountType === GOAL_AMOUNT_TYPE.FUTURE &&
      this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME;

    if (!isInflationRequired) {
      if (!data?.goalinflationrate || data?.goalinflationrate.toString().length <= 0) {
        isValid = false;
      }
    }
    return isValid;
  }

  getInflationRate() {
    //TODO get inflation rate from API and use that
    this.setState({ showProgress: true, fetchingInflationRate: true }, () => {
      getGoalConfigInfo(GOAL_CATEGORY.CUSTOM_GOAL)
        .then((configList) => {
          const configInfo = configList;
          const goalsObj = this.state.newGoalData;
          goalsObj.setGoalInflationRate(configInfo.inflationrate);
          this.dbValue.goalinflationrate = configInfo.inflationrate;
          this.inflationRate = configInfo.inflationrate;
          this.setState({
            inflationRate: configInfo.inflationrate,
            showProgress: false,
            fetchingInflationRate: false
          }, () => {
            this.getDBValue()
          });
        })
        .catch((error) => {
          console.log("CustomGoalExpensePage.js/getInflationRate Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true, fetchingCurrentAge: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const goalsObj = this.state.newGoalData;
          goalsObj.setUserAge(currentAge);
          this.userage = currentAge;
          this.dbValue.userage = currentAge;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
            fetchingCurrentAge: false
          }, () => {
            this.getDBValue();
          });
        })
        .catch((error) => {
          console.log("CustomGoalExpensePage.js/getInflationRate Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getDBValue() {
    if (this.state.fetchingInflationRate === false && this.state.fetchingCurrentAge === false) {
      const dbValue = this.state.newGoalData
        ? JSON.parse(JSON.stringify(this.state.newGoalData))
        : {};
      this.setState({ dbValue })
    }
  }

  isStartAndEndYearValid(goalType, startYear, endYear) {
    if (goalType === GOAL_EXPENSE_TYPE.RECURRING &&
      this.state.recurringType === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD) {
      return startYear !== 0 && startYear < endYear;
    } else {
      return startYear !== 0;
    }
  }

  checkIsDataValid(data, skipDescriptionValidation = false) {
    let isValid = true;
    let message = "";
    const goalType = this.state.type;
    const startYear = data.goalstartyear ? data.goalstartyear : 0;
    const endYear = data.goalendyear ? data.goalendyear : 0;

    if (!this.isStartAndEndYearValid(goalType, startYear, endYear)) {
      isValid = false;
      message = t("cgToastYear");
    } else if (!this.isValueValid(data)) {
      isValid = false;
      message = t("cgToastAmount");
    } else if (!this.isInflationValid(data)) {
      isValid = false;
      message = t("cgToastInflationRate");
    }
    return { isValid, message };
  }

  checkIsEndYearValid(startYear, endYear) {
    return startYear < endYear;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  refreshData() {
    newGoal.goalinflationrate = this.inflationRate;
    newGoal.userage = this.userage;
    this.setState({
      description: "",
      cost: "",
      inflationRate: this.inflationRate,
      startValue: getCurrentYear() + 1,
      endValue: getCurrentYear() + 2,
      type: GOAL_EXPENSE_TYPE.RECURRING,
      frequencyValue: 1,
      viewExpense: false,
      showButtons: false,
      newGoalData: new Bl.Goals({ ...newGoal, userid: getUserId() }),
    });
  }

  navigateToNextScreen() {
    this.props.navigate("/goalscategorize", { state: { path: "CustomGoal" } });
  }

  createCustomGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
    }
    if (
      goalObj.expensetype === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD &&
      goalObj.goalendyear === 0
    ) {
      const endYear = getCurrentYear();
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }

    //If amount type===future, goal type=onetm  then goalinflation=0
    if (this.state.amountType === GOAL_AMOUNT_TYPE.FUTURE &&
      this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME) {
      goalObj.setGoalInflationRate(0);
    }

    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .createGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log(
              "CustomGoalExpensePage/create Error:",
              error
            );
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  updateCustomGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (
      goalObj.expensetype === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD &&
      goalObj.goalendyear === 0
    ) {
      const endYear = getCurrentYear();
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }

    //If amount type===future, goal type=onetm  then goalinflation=0
    if (this.state.amountType === GOAL_AMOUNT_TYPE.FUTURE &&
      this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME) {
      goalObj.setGoalInflationRate(0);
    }

    console.log({ goalObj })

    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .updateGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("CustomgoalExpensePage/updateEduGoal Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  onPressSaveButton() {
    const { showButtons } = this.state;
    if (!showButtons) {
      return;
    }
    if (!this.state.isEditView) {
      this.createCustomGoal(this.navigateToNextScreen.bind(this));
    }
    else {
      this.updateCustomGoal(this.navigateToNextScreen.bind(this));
    }
  }

  onPressSaveAndAdd() {
    const { showButtons } = this.state;
    if (!showButtons) {
      return;
    }
    this.createCustomGoal(this.refreshData.bind(this));
  }

  prepareData(data) {
    const sliderContent = [];
    data.map((item) =>
      sliderContent.push({
        year: item.cashflowyear,
        money: item.flowamount,
      })
    );
    return sliderContent;
  }

  onPressViewExpense() {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
    }
    if (
      goalObj.expensetype === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD &&
      goalObj.goalendyear === 0
    ) {
      const endYear = getCurrentYear();
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }

    //If amount type===future, goal type=onetm  then goalinflation=0
    if (this.state.amountType === GOAL_AMOUNT_TYPE.FUTURE &&
      this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME) {
      goalObj.setGoalInflationRate(0);
    }

    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj, true);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .viewExpectedAmt()
          .then((data) => {
            const sliderContent = this.prepareData(data.result);
            this.setState({
              sliderContent,
              showProgress: false,
              viewExpense: true,
              showButtons: true
            }, () => {
              this.costPerYearViewRef.current.scrollIntoView({ behavior: "smooth" });
            });
          })
          .catch((error) => {
            console.log("CustomGoalExpense/onPressViewExpense Error:", error);
            this.showToast(t('Some error has occurred'));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const startAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    goals.setStartYear(newValue);
    goals.setGoalStartAge(startAge)
    this.setState({ startValue: newValue, viewExpense: false });
  }

  handleChangeEnd(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const endAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    const isValid = this.checkIsEndYearValid(goals.goalstartyear, newValue);
    if (isValid) {
      goals.setEndYear(newValue);
      goals.setGoalEndAge(endAge);
      this.setState({ endValue: newValue, viewExpense: false });
    } else {
      this.showToast(t("cgToastYear"));
    }
  }

  handleToggleSwitchChange(selectedItem) {
    const goals = this.state.newGoalData;
    const frequencyValue =
      selectedItem === t("cgToggle2")
        ? GOAL_EXPENSE_TYPE.RECURRING
        : GOAL_EXPENSE_TYPE.ONE_TIME;
    const recurringType = selectedItem === t("cgToggle2")
      ? GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD
      : GOAL_EXPENSE_TYPE.LIFE_LONG;
    goals.setExpenseType(frequencyValue);
    const endValue = selectedItem === t("cgToggle2") ? this.state.endValue : getCurrentYear() + 2;
    goals.setEndYear(0);
    if (frequencyValue === GOAL_EXPENSE_TYPE.ONE_TIME) {
      goals.setGoalAmountPv(Number(this.state.cost));
      goals.setGoalAmountFv(0);
    } else if (frequencyValue === GOAL_EXPENSE_TYPE.RECURRING) {
      if (this.state.amountType === GOAL_AMOUNT_TYPE.PRESENT) {
        goals.setGoalAmountPv(Number(this.state.cost));
        goals.setGoalAmountFv(0);
      } else {
        goals.setGoalAmountPv(0);
        goals.setGoalAmountFv(Number(this.state.cost));
      }
    }
    this.setState({ type: frequencyValue, endValue, recurringType, viewExpense: false });
  }

  handleAmountToggleChange(selectedItem) {
    const goals = this.state.newGoalData;
    if (selectedItem === GOAL_AMOUNT_TYPE.PRESENT) {
      goals.setGoalAmountPv(Number(this.state.cost));
      goals.setGoalAmountFv(0);
    } else if (selectedItem === GOAL_AMOUNT_TYPE.FUTURE) {
      if (this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME) {
        goals.setGoalAmountPv(Number(this.state.cost));
        goals.setGoalAmountFv(0);
      } else {
        goals.setGoalAmountPv(0);
        goals.setGoalAmountFv(Number(this.state.cost));
      }
    }
    this.setState({ amountType: selectedItem })
  }

  handleSelectSlider(index) {
    this.setState({ selectedSliderIndex: index });
  }

  onSelectRecurringType(selectedType) {
    const goals = this.state.newGoalData;
    goals.setExpenseType(selectedType);
    this.setState({ recurringType: selectedType });
  }

  renderNavView() {
    const items = getGoalsPathName("CustomGoal");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        dbData={this.dbValue}
        localData={this.state.newGoalData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("cgTitle");
    return <TitleView title={title} information={""} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="cge_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderStartYearSlider() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.startValue, currentYear, this.state.currentAge);
    return (
      <div className="cge_start_year_row">
        <label className="cge_start_text">{t("cgSliderStart")}</label>
        <div className="cge_slider_content">
          <Slider
            value={this.state.startValue}
            age={age}
            min={currentYear + 1}
            max={currentYear + 21}
            markStep={5}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderEndYearSlider() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.endValue, currentYear, this.state.currentAge);
    return (
      <div className="cge_start_year_row">
        <label className="cge_start_text">{t("cgSliderEnd")}</label>
        <div className="cge_slider_content">
          <Slider
            value={this.state.endValue}
            age={age}
            min={currentYear + 2}
            max={currentYear + 22}
            markStep={5}
            handleChange={this.handleChangeEnd.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderYearView() {
    return (
      <div className="cge_slider_view">
        {this.renderStartYearSlider()}
        {this.state.type === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD &&
          this.state.recurringType === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD &&
          this.renderEndYearSlider()}
      </div>
    );
  }

  renderSelectionView() {
    return (
      <div className="cge_checkboxOuterView">
        <div className="cge_checkboxView">
          <input
            checked={this.state.recurringType === GOAL_EXPENSE_TYPE.LIFE_LONG}
            type={"checkbox"}
            className={"cge_checkboxStyle"}
            onChange={() => {
              this.onSelectRecurringType(GOAL_EXPENSE_TYPE.LIFE_LONG)
            }}
          />
          <label className="cge_checkboxLabelStyle">{t('cgToggle3')}</label>
        </div>
        <div className="cge_checkboxView">
          <input
            checked={this.state.recurringType === GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD}
            type={"checkbox"}
            className={"cge_checkboxStyle"}
            onChange={() => {
              this.onSelectRecurringType(GOAL_EXPENSE_TYPE.SPECIFIC_PERIOD)
            }}
          />
          <label className="cge_checkboxLabelStyle">{t('cgToggle4')}</label>
        </div>
      </div>
    );
  }

  renderExpenseTypeView() {
    return (
      <div className="cge_description_view">
        {this.renderInputView(
          t("cgInputDescription"),
          this.state.description,
          this.onChangeDescription.bind(this)
        )}
      </div>
    );
  }

  renderToggleSelectionsView() {
    return (
      <div className="cge_description_view">
        <div className="cge_expense">
          <label className="cge_expense_text">{t("cgToggle5")}</label>
          <div className="cge_toggle">
            <ToggleSwitch
              checked={this.state.amountType === GOAL_AMOUNT_TYPE.PRESENT}
              checkedChildren={t("cgToggle6")}
              unCheckedChildren={t("cgToggle7")}
              onChangeToggle={this.handleAmountToggleChange.bind(this)}
            />
          </div>
        </div>
        <div className="cge_expense">
          <label className="cge_expense_text">{t("cgToggle")}</label>
          <div className="cge_toggle">
            <ToggleSwitch
              checked={this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME}
              checkedChildren={t("cgToggle1")}
              unCheckedChildren={t("cgToggle2")}
              onChangeToggle={this.handleToggleSwitchChange.bind(this)}
            />
          </div>
          {this.state.type !== GOAL_EXPENSE_TYPE.ONE_TIME && this.renderSelectionView()}
        </div>
      </div>
    );
  }

  renderButtonsView() {
    const { showButtons } = this.state;
    return (
      <div className="cge_bottomView">
        {!this.state.isEditView && <div className="cge_buttonView">
          <div>
            <label
              onClick={this.onPressSaveAndAdd.bind(this)}
              className="cge_link_text"
              disabled={!showButtons}
              style={{ opacity: showButtons ? 1 : 0.5 }}
            >
              {t("cgButtonLink")}
            </label>
          </div>
        </div>}
        <div className="cge_expense_button">
          <Button
            buttonName={t("cgButton1")}
            emptyButton={true}
            onPressButton={this.onPressViewExpense.bind(this)}
          />
        </div>
        <div className="cge_save">
          <Button
            buttonName={t("cgButton2")}
            onPressButton={this.onPressSaveButton.bind(this)}
            disabled={!showButtons}
            style={{ opacity: showButtons ? 1 : 0.5 }}
          />
        </div>
      </div>
    );
  }

  renderMoneyInflationView() {
    const hideInflationView = this.state.amountType === GOAL_AMOUNT_TYPE.FUTURE && this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME;
    return (
      <div className="cge_money_inflation">
        {this.renderInputView(
          t("cgInputAmount"),
          this.state.cost,
          this.onChangeGoalAmount.bind(this),
          true
        )}
        {!hideInflationView && this.renderInputView(
          t("cgInputInflationRate"),
          this.state.inflationRate,
          this.onChangeInflationRate.bind(this),
          false,
          true
        )}
      </div>
    );
  }

  renderCostOfCustomGoal() {
    return (
      <div className="cge_cost_age_slider" ref={this.costPerYearViewRef}>
        <CostPerYearView
          sliderContent={this.state.sliderContent}
          sliderIndex={this.state.selectedSliderIndex}
          onSelectSlider={(index) => this.handleSelectSlider(index)}
          labelText={t("cgCostYear")}
        />
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="cge_content_view">
        {this.renderTitleView()}
        {this.renderExpenseTypeView()}
        {this.renderToggleSelectionsView()}
        <div className="cge_seperatorStyle" />
        {this.renderYearView()}
        <div className="cge_seperatorStyle" />
        {this.renderMoneyInflationView()}
        {this.state.viewExpense && this.renderCostOfCustomGoal()}
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  // renderDrawerContent() {
  //   const headerTitle = t("financialPlan");
  //   const step = 2;
  //   return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  // }
  render() {
    return (
      <div className="cge_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default CustomGoalExpensePage;
