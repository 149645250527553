import { t } from "i18next";
import Home from "../assets/svgs/home.svg";
import Chair from "../assets/svgs/chair.svg";
import Settings from "../assets/svgs/settings.svg";
import Logout from "../assets/svgs/logout.svg";
import { isAdminOnly, isAdminRAdvisor, isRestrictedCustomer } from "../pages/common";

function getMenuList() {
  const menuList = [
    {
      name: t("Menu: Customer Management"),
      icon: Home,
      show: isAdminAdvisorLevel(),
      url: "/customermgmt",
      hasSubMenu: false,
    },
    {
      name: t("Menu: System Management"),
      icon: Chair,
      show: isAdminLevel(),
      hasSubMenu: true,
      subMenus: [
        {
          name: t("SubMenu: Manage Configuration"),
          url: "/configuration",
        },
        {
          name: t("SubMenu: Manage Asset Allocation"),
          url: "/assestallocationruletable",
        },
        {
          name: t("SubMenu: Manage Category"),
          url: "/categorymgmt",
        },
        {
          name: t("SubMenu: Manage Portfolio Rule"),
          url: "/portfoliorule",
        }
      ],
    },
    {
      name: t("Menu: Dashboard"),
      icon: Home,
      show: isUserLevel(),
      url: "/dashboard",
      hasSubMenu: false,
    },
    {
      name: t("Menu: Your Financial Plan"),
      icon: Chair,
      show: isUserLevel(),
      hasSubMenu: true,
      subMenus: [
        {
          name: t("SubMenu: Goals and Expenses"),
          url: "/goalscategorize",
          showToast: true,
          toastMsg: 'Please contact the Fliber team to change your plan'
        },
        {
          name: t("SubMenu: Financial Details"),
          url: "/financialdetails",
          showToast: true,
          toastMsg: 'Please contact the Fliber team to change your plan'
        },
      ],
    },
    {
      name: t("Menu: Settings"),
      icon: Settings,
      show: isUserLevel(),
      hasSubMenu: true,
      subMenus: [
        {
          name: t("SubMenu: Profile"),
          url: "/customerprofile",
        },
        {
          name: t("SubMenu: Change password"),
          url: "/resetpin",
        },
      ],
    },
    {
      name: t("Menu: Logout"),
      icon: Logout,
      show: true,
      url: "/"
    },
  ];
  return menuList;
}

function isAdminLevel() {
  return isAdminOnly()
}

function isAdminAdvisorLevel() {
  return isAdminRAdvisor();
}

function isUserLevel() {
  return isRestrictedCustomer();
}

export { getMenuList };