
function getCurrentDateTime() {
  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  let hours = now.getHours();
  let minutes = now.getMinutes();
  let seconds = now.getSeconds();
  let milliseconds = now.getMilliseconds();

  // Add leading zeroes to single digit values
  month = month.toString().padStart(2, '0');
  day = day.toString().padStart(2, '0');
  hours = hours.toString().padStart(2, '0');
  minutes = minutes.toString().padStart(2, '0');
  seconds = seconds.toString().padStart(2, '0');
  milliseconds = milliseconds.toString().padStart(3, '0');

  let dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  return dateTimeString;
}

const debug = (...args) => {
  var caller =
    new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';
  console.debug(`[${getCurrentDateTime()}]`, caller, ...args);
};
const error = (...args) => {
  var caller =
    new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';
  console.error(`[${getCurrentDateTime()}]`, caller, ...args);
};
const info = (...args) => {
  var caller =
    new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';
  if (
    caller.match('Object.fnStart()') ||
    caller.match('Object.fnEnd()') ||
    caller.match('apply()') ||
    caller.match('Object.fnEndRender()')
  ) {
    // Workaround to avoid printing the caller when it is called from
    // fnStart and fnEnd
    console.info(`[${getCurrentDateTime()}]`, ...args);
  } else {
    console.info(`[${getCurrentDateTime()}]`, caller, ...args);
  }
};
const log = (...args) => {
  var caller =
    new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';
  console.log(`[${getCurrentDateTime()}]`, caller, ...args);
};
const warn = (...args) => {
  var caller =
    new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';
  console.warn(`[${getCurrentDateTime()}]`, caller, ...args);
};
const assert = (condition, message) => {
  if (!condition) {
    var caller =
      new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';
    throw new Error(
      typeof message === 'string'
        ? `Assertion failed at ${caller}: ${message}`
        : `Assertion failed at ${caller}`,
    );
  }
};

const fnStart = (...args) => {
  var caller =
    new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';
  var logger = console;
  logger.info(caller, 'START', ...args);
};
const fnEnd = (...args) => {
  var caller =
    new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';

  var logger = console;

  logger.info(caller, 'END', ...args);
};
const fnEndRender = (...args) => {
  var caller =
    new Error().stack.toString().split('at ')[2].split('(')[0].trim() + '()';

  var logger = console;

  logger.info(caller, 'END:RENDER', ...args);
};
export {debug, error, info, log, warn, assert, fnStart, fnEnd, fnEndRender};
