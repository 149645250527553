import BackendApi from "../backend/index";

class AssetAllocation {

  static getAssetAllocationValues() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.AssetAllocationApi.getAssetAllocationValues().then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static updateAssetAllocationValues(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.AssetAllocationApi.updateAssetAllocationValues(data).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  }
}

export default AssetAllocation;