import React, { Component } from "react";
import { t } from "i18next";
import {
  Button,
  TitleView,
  BreadCrumb,
  FloatingLabelInput,
  Toast,
  ProgressDialog,
} from "../components";
import "./retirementexpensepage.css";
import ArrowIcon from "../../assets/svgs/greaterthanarrow.svg";
import { DEFAULT_OFFSET, GOAL_CATEGORY, GOAL_EXPENSE_TYPE, GOAL_FREQUENCY_TYPE } from "../constants";
import * as Bl from "../../businesslogic";
import { getAdvisorId, getGoalsPathName, getUserDOB, getUserId } from "../common";
import { getCurrentAge } from "../utils/common";

const newGoal = {
  userid: '',
  goalcategory: GOAL_CATEGORY.RETIREMENT,
  goalstartyear: 0,
  goalendyear: 0,
  goalamountpv: 0,
  goalfrequency: 1,
  advisorid: getAdvisorId(),
  goalfrequencytype: GOAL_FREQUENCY_TYPE.MONTHLY,
  goalbucketcode: "",
  retirewanttype: "",
  monthlyexpenselist: [],
  goaldescription: "",
  goalinflationrate: 0,
  goalamountfv: 0,
  homeloanstatus: false,
  downpayment: 0,
  goaldetails: [],
  goalstartage: 0,
  goalendage: 0,
  userage: 0,
  expensetype: GOAL_EXPENSE_TYPE.LIFE_LONG
};

class RetirementExpensePage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    const goalData = params?.goalData;
    const selectedItem = isEditView ? goalData.retirewanttype : params.selectedItem;
    const monthlyExpenseList = isEditView && goalData.monthlyexpenselist?.length > 0 ? goalData.monthlyexpenselist[0] : {};
    
    this.state = {
      showMenu: isEditView && Object.keys(monthlyExpenseList)?.length > 0 ? false : true,
      monthlyExpense: isEditView ? goalData.goalamountpv : '',
      rent: isEditView && monthlyExpenseList?.rent ? monthlyExpenseList.rent : "",
      foodGrocery: isEditView && monthlyExpenseList?.foodGrocery ? monthlyExpenseList.foodGrocery : "",
      utilityBills: isEditView && monthlyExpenseList?.utilityBills ? monthlyExpenseList.utilityBills : "",
      miscellaneous: isEditView && monthlyExpenseList?.miscellaneous ? monthlyExpenseList.miscellaneous : "",
      conveyance: isEditView && monthlyExpenseList?.conveyance ? monthlyExpenseList.conveyance : "",
      buildingMaintanence: isEditView && monthlyExpenseList?.buildingMaintanence ? monthlyExpenseList.buildingMaintanence : "",
      others: isEditView && monthlyExpenseList?.others ? monthlyExpenseList.others : "",
      showMonthlyExpense: isEditView && Object.keys(monthlyExpenseList)?.length > 0 ? false : true,
      retirewanttype: selectedItem,
      isEditView: isEditView ? isEditView : false,
      newGoalData: isEditView ? new Bl.Goals(goalData) : new Bl.Goals({ ...newGoal, userid: getUserId() }),
    };
    this.isRenderingAlready = false;
    this.dbValue = this.state.newGoalData;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getCurrentAge();
    }
  }
  isAmountValueChanged() {
    const { rent, foodGrocery, utilityBills, miscellaneous, conveyance, buildingMaintanence, others } = this.state;
    return rent || foodGrocery || utilityBills || miscellaneous || conveyance || buildingMaintanence || others;
  }

  isMonthlyExpenseValid(data) {
    let isValid = true;
    const total = this.getTotal();
    if (total === 0) {
      isValid = false;
    }
    return isValid;
  }

  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!this.isValueValid(data.goalamountpv)) {
      isValid = false;
      message = t("retirementToastAmount");
    }
    return { isValid, message };
  }
  checkIsDataValidForExpense(data) {
    let isValid = true;
    let message = "";
    if (!this.isMonthlyExpenseValid(data.monthlyexpenselist)) {
      isValid = false;
      message = t("retirementToastExpenseList");
    }
    return { isValid, message };
  }

  onChangeAmount(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalAmountPv(value ? Number(value) : 0);
    this.setState({ amount: value });
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true, fetchingCurrentAge: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const goalsObj = this.state.newGoalData;
          goalsObj.setUserAge(currentAge);
          // goalsObj.setGoalStartAge(currentAge + 1);
          // goalsObj.setStartYear(getCurrentYear() + 1);
          this.setState({
            currentAge: currentAge,
            showProgress: false,
            fetchingCurrentAge: false
          }, () => {
            this.getDBValue();
          });
        })
        .catch((error) => {
          console.log("RetirementExpensePage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getDBValue() {
    if (this.state.fetchingCurrentAge === false) {
      const dbValue = this.state.newGoalData
        ? JSON.parse(JSON.stringify(this.state.newGoalData))
        : {};
      this.setState({ dbValue })
    }
  }

  getTotal() {
    const total =
      Number(this.state.rent) +
      Number(this.state.foodGrocery) +
      Number(this.state.conveyance) +
      Number(this.state.miscellaneous) +
      Number(this.state.others) +
      Number(this.state.utilityBills) +
      Number(this.state.buildingMaintanence);
    return total;
  }

  prepareExpenseData() {
    const monthlyExpenseList = [
      {
        rent: this.state.rent,
        foodGrocery: this.state.foodGrocery,
        utilityBills: this.state.utilityBills,
        miscellaneous: this.state.miscellaneous,
        conveyance: this.state.conveyance,
        buildingMaintanence: this.state.buildingMaintanence,
        others: this.state.others,
      },
    ];
    const total = this.getTotal();
    return { monthlyExpenseList, total };
  }
  isValueValid(value) {
    return value > 0;
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }
  onSelectMenu() {
    const goals = this.state.newGoalData;
    goals.setGoalAmountPv(0);
    goals.setMonthlyExpenseList([]);
    this.setState({
      showMenu: !this.state.showMenu,
      showMonthlyExpense: !this.state.showMonthlyExpense,
      monthlyExpense: "",
      rent: "",
      foodGrocery: "",
      utilityBills: "",
      miscellaneous: "",
      conveyance: "",
      buildingMaintanence: "",
      others: "",
    });

  }

  onSelectItem(path) {
    const localData = new Bl.Goals(this.state.newGoalData)
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeMonthlyExpense(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalAmountPv(value ? Number(value) : 0);
    this.setState({ monthlyExpense: value });
  }

  onChangeRentValue(value) {
    value = value.trim();
    this.setState({ rent: value });
  }

  onChangeFoodGroceryValue(value) {
    value = value.trim();
    this.setState({ foodGrocery: value });
  }

  onChangeUtilityBillValues(value) {
    value = value.trim();
    this.setState({ utilityBills: value });
  }

  onChangeMiscellaneousValue(value) {
    value = value.trim();
    this.setState({ miscellaneous: value });
  }

  onChangeConveyanceValue(value) {
    value = value.trim();
    this.setState({ conveyance: value });
  }

  onChangeBuildingMaintanenceValue(value) {
    value = value.trim();
    this.setState({ buildingMaintanence: value });
  }

  onChangeOthersValue(value) {
    value = value.trim();
    this.setState({ others: value });
  }
  createGoal(goalObj) {
    goalObj.setRetireWantType(this.state.retirewanttype);
    console.log({ goalObj })
    this.setState({ showProgress: true }, () => {
      goalObj
        .createGoal()
        .then(() => {
          this.setState({ showProgress: false }, () => {
            this.props.navigate("/goalscategorize", { state: { path: "Retirement" } });
          });
        })
        .catch((error) => {
          console.log("RetirementExpensePage/createRetirementGoal Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  updateGoal(goalObj) {
    goalObj.setRetireWantType(this.state.retirewanttype);
    console.log({ goalObj })
    this.setState({ showProgress: true }, () => {
      goalObj
        .updateGoal()
        .then(() => {
          this.setState({ showProgress: false }, () => {
            this.props.navigate("/goalscategorize", { state: { path: "Retirement" } });
          });
        })
        .catch((error) => {
          console.log("RetirementExpensePage/updateRetirementGoal Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  onPressSubmitButton() {
    if (!this.state.showMonthlyExpense) {
      const data = this.prepareExpenseData();
      const goalObj = new Bl.Goals(this.state.newGoalData);
      goalObj.setMonthlyExpenseList(data.monthlyExpenseList);
      goalObj.setGoalAmountPv(data.total);
      const isDataValid = this.checkIsDataValidForExpense(goalObj);
      if (isDataValid.isValid) {
        if (!this.state.isEditView) {
          this.createGoal(goalObj);
        } else {
          this.updateGoal(goalObj)
        }
      } else {
        const toastMessage = isDataValid.message;
        this.showToast(toastMessage);
      }
    } else {
      if (!this.state.monthlyExpense) {
        const message = t("retirementToastAmount");
        this.showToast(message);
        return;
      }
      const goalObj = new Bl.Goals(this.state.newGoalData);
      const isDataValid = this.checkIsDataValid(goalObj);
      if (isDataValid.isValid) {
        if (!this.state.isEditView) {
          this.createGoal(goalObj);
        } else {
          this.updateGoal(goalObj)
        }
      } else {
        const toastMessage = isDataValid.message;
        this.showToast(toastMessage);
      }
    }
  }


  renderTitleView() {
    const title = t("retirementTitle");
    return <TitleView title={title} information={""} />;
  }

  renderNavView() {
    const items = getGoalsPathName("Retirement");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        dbData={this.dbValue}
        localData={this.state.newGoalData}
        isStateValueChanged={this.isAmountValueChanged()}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderExpensesMenuView() {
    const isSelected = this.state.showMenu;
    return (
      <div className="re_menu_view">
        <div
          className={isSelected ? "re_selected_submenu" : "re_sub_menu"}
          style={{ borderTopLeftRadius: 8 }}
          onClick={this.onSelectMenu.bind(this)}
        >
          <div className="re_menu_items">{t("retirementMenu1")}</div>
          <img src={ArrowIcon} alt={"Arrow"} className="re_arrow" />
        </div>

        <div
          className={!isSelected ? "re_selected_submenu" : "re_sub_menu"}
          onClick={this.onSelectMenu.bind(this)}
        >
          <div className="re_menu_items">{t("retirementMenu2")}</div>
          <img src={ArrowIcon} alt={"Arrow"} className="re_arrow" />
        </div>
        <div className="re_or_style">
          <label>OR</label>
        </div>
      </div>
    );
  }

  renderMonthlyExpenseView() {
    return (
      <div className="re_expenselist">
        <div className="re_title">{t("retirementMenu1Title1")}</div>
        {this.renderInputView(
          t("retirementMenu1Input1"),
          this.state.monthlyExpense,
          this.onChangeMonthlyExpense.bind(this),
          true
        )}
      </div>
    );
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="re_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderDetailMonthlyExpense() {
    const total = this.getTotal();
    const displayTotal = total ? `₹ ${total}` : `₹ ${0}`;
    return (
      <div className="re_expenselist">
        <div className="re_title_row">
          <div className="re_title">{t("retirementMenu2Title")}</div>
          <div className="re_total">
            {`${t("total")}${" : "}`}
            <span className="re_highlighted-text">{displayTotal}</span>
          </div>
        </div>
        <div className="re_input_fields">
          {this.renderInputView(
            t("retirementMenu2Input1"),
            this.state.rent,
            this.onChangeRentValue.bind(this),
            true
          )}
          {this.renderInputView(
            t("retirementMenu2Input2"),
            this.state.foodGrocery,
            this.onChangeFoodGroceryValue.bind(this),
            true
          )}
          {this.renderInputView(
            t("retirementMenu2Input3"),
            this.state.utilityBills,
            this.onChangeUtilityBillValues.bind(this),
            true
          )}
          {this.renderInputView(
            t("retirementMenu2Input4"),
            this.state.miscellaneous,
            this.onChangeMiscellaneousValue.bind(this),
            true
          )}
          {this.renderInputView(
            t("retirementMenu2Input5"),
            this.state.conveyance,
            this.onChangeConveyanceValue.bind(this),
            true
          )}
          {this.renderInputView(
            t("retirementMenu2Input6"),
            this.state.buildingMaintanence,
            this.onChangeBuildingMaintanenceValue.bind(this),
            true
          )}
          {this.renderInputView(
            t("retirementMenu2Input7"),
            this.state.others,
            this.onChangeOthersValue.bind(this),
            true
          )}
        </div>
      </div>
    );
  }

  renderExpensesMenu() {
    return (
      <div className="re_content">
        {this.renderExpensesMenuView()}
        {this.state.showMonthlyExpense
          ? this.renderMonthlyExpenseView()
          : this.renderDetailMonthlyExpense()}
      </div>
    );
  }

  renderButton() {
    return (
      <div className="re_bottomView">
        <div className="re_buttonView">
          <Button
            buttonName={t("retirementButton")}
            onPressButton={this.onPressSubmitButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="re_content_view">
        {this.renderTitleView()}
        {this.renderExpensesMenu()}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  // renderDrawerContent() {
  //   const headerTitle = t("retirementDrawerContentTitle");
  //   const step = 2;
  //   return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  // }

  render() {
    return (
      <div className="re_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButton()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default RetirementExpensePage;
