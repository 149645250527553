import BackendApi from "../backend/index";

class AccountPortfolioRule {


  static getAllSebiCategoryValues() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.AccountPortfolioRuleApi.getAllSebiCategoryValues().then(result => {
        resolve(result);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static getGrowthBucketValues(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.AccountPortfolioRuleApi.getGrowthBucketValues(data).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error);
      })
    })
  }

  static getSecureBucketValues(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.AccountPortfolioRuleApi.getSecureBucketValues(data).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error);
      })
    })
  }

  static createAccountPortfoliRule(userId, bucketType, data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.AccountPortfolioRuleApi.createAccountPortfoliRule(userId, bucketType, data).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error);
      })
    })
  }

  static calculateEffectiveRate(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.AccountPortfolioRuleApi.calculateEffectiveRate(data).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error);
      })
    })
  }

  static updateSebicategoryValues(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.AccountPortfolioRuleApi.updateSebicategoryValues(data).then((response) => {
        resolve(response.data);
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default AccountPortfolioRule;