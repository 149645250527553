import { t } from "i18next";
import Retirement from "../assets/svgs/hh_expense.svg";
import HealthCare from "../assets/svgs/hcare_icon.svg";
import GWTransfer from "../assets/svgs/gwtransfer_icon.svg";
import Education from "../assets/svgs/gdeducation_icon.svg";
import Vacation from "../assets/svgs/vacation_icon.svg";
import PVehicle from "../assets/svgs/pvechicle_icon.svg";
import PREstate from "../assets/svgs/prstate_icon.svg";
import Wedding from "../assets/svgs/kidswed_icon.svg";
import EmiPremium from "../assets/svgs/emipre_icon.svg";
import CustomGoal from "../assets/svgs/cg_icon.svg";
import { ReactComponent as Fliber_Step_0 } from "../assets/svgs/fliber_steps/step_0.svg";
import { ReactComponent as Fliber_Step_1 } from "../assets/svgs/fliber_steps/step_1.svg";
import { ReactComponent as Fliber_Step_2 } from "../assets/svgs/fliber_steps/step_2.svg";
import { ReactComponent as Fliber_Step_3 } from "../assets/svgs/fliber_steps/step_3.svg";
import { ReactComponent as Fliber_Step_4 } from "../assets/svgs/fliber_steps/step_4.svg";
import { ReactComponent as Fliber_Step_5 } from "../assets/svgs/fliber_steps/step_5.svg";
import { ReactComponent as Fliber_Step_6 } from "../assets/svgs/fliber_steps/step_6.svg";
import { ReactComponent as Fliber_Step_7 } from "../assets/svgs/fliber_steps/step_7.svg";
import { INCOME_FREQUENCY_TYPE, RISK_PROFILE, SECURITY_LEVEL, USER_ROLE } from "./constants";

export const getAdvisorId = () => {
  const advisorid = '3fa85f64-5717-4562-b3fc-2c963f66afa6';
  return advisorid;
}

export function getCardDetailsByCategory(category) {
  switch (category) {
    case "HOUSE": //TODO: need to change retirment
      return {
        title: "Retirement",
        icon: Retirement,
        bgcolor: "#BDD9CD",
      };
    case "HLTHC":
      return {
        title: "Health Care",
        icon: HealthCare,
        bgcolor: "#D6E6D7",
      };
    case "GENWL":
      return {
        title: "Generational Wealth Transfer",
        icon: GWTransfer,
        bgcolor: "#E4EEE5",
      };
    case "KGEDU":
      return {
        title: "Education",
        icon: Education,
        bgcolor: "#AFC9E2",
      };
    case "VACCN":
      return {
        title: "Vacation",
        icon: Vacation,
        bgcolor: "#D1DBE6",
      };
    case "VEHCL":
      return {
        title: "Purchase Vehicle",
        icon: PVehicle,
        bgcolor: "#CCDBEB",
      };
    case "REALE":
      return {
        title: "Purchase Real Estate",
        icon: PREstate,
        bgcolor: "#EDE7B4",
      };
    case "KGWED":
      return {
        title: "Wedding",
        icon: Wedding,
        bgcolor: "#F3E7A5",
      };
    case "EMIPR":
      return {
        title: "EMIs / Premiums",
        icon: EmiPremium,
        bgcolor: "#EADEC7",
      };
    case "CUSTG":
      return {
        title: "Custom Goal",
        icon: CustomGoal,
        bgcolor: "#E8D8E1",
      };
    default:
      break;
  }
}

export function getInvestmentPhysicalPath(pageName) {
  const commonPaths = [
    { name: "Financial Plan", path: "/financial" },
    { name: "Net Worth / Assets / Physical Assets", path: "/financialdetails" },
  ];
  switch (pageName) {
    case "FinancialPlan":
      return [{ name: "Financial Plan", path: "/financial" }];
    case "FinancialDetails":
      return [
        { name: "Financial Plan", path: "/financial" },
        {
          name: "Net Worth / Assets / Physical Assets",
          path: "/financialdetails",
        },
      ];
    case "RealEstate":
      return [...commonPaths, { name: "Real Estate", path: "/realestatehome" }];
    case "Jewellery":
      return [
        ...commonPaths,
        { name: "Jewellery / Valuables", path: "/jewelleryhome" },
      ];
    case "ArtAntiques":
      return [
        ...commonPaths,
        { name: "Art/ Antiques", path: "/artantiqueshome" },
      ];
    case "OtherPhysicalAssets":
      return [
        ...commonPaths,
        { name: "Other Physical Assets", path: "/physicalassethome" },
      ];
    default:
      return commonPaths;
  }
}

export function getFinancialNonMarketPath(pageName) {
  const commonPaths = [
    { name: "Financial Plan", path: "/financial" },
    {
      name: "Net Worth / Assets / Financial Assets - NON Market Linked",
      path: "/financialdetails",
    },
  ];
  switch (pageName) {
    case "FinancialPlan":
      return [{ name: "Financial Plan", path: "/financial" }];
    case "FinancialDetails":
      return [
        { name: "Financial Plan", path: "/financial" },
        {
          name: "Net Worth / Assets / Financial Assets - NON Market Linked",
          path: "/financialdetails",
        },
      ];
    case "BankSaving":
      return [
        ...commonPaths,
        { name: "Bank Saving", path: "/banksavingshome" },
      ];
    case "FD":
      return [...commonPaths, { name: "Fixed Deposits", path: "/fdhome" }];
    case "GSS":
      return [
        ...commonPaths,
        { name: "Government Saving Schemes", path: "/gsshome" },
      ];
    case "EPF":
      return [
        ...commonPaths,
        { name: "Employee Provident Fund (EPF)", path: "/epfhome" },
      ];
    case "PPF":
      return [
        ...commonPaths,
        { name: "Public Provident Fund (PPF)", path: "/ppfhome" },
      ];
    case "Others":
      return [...commonPaths, { name: "Others", path: "/othernonmarkethome" }];
    default:
      return commonPaths;
  }
}

export function getFinancialMarketPath(pageName) {
  const commonPaths = [
    { name: "Financial Plan", path: "/financial" },
    {
      name: "Net Worth / Assets / Financial Assets - Market Linked",
      path: "/financialdetails",
    },
  ];
  switch (pageName) {
    case "FinancialPlan":
      return [{ name: "Financial Plan", path: "/financial" }];
    case "FinancialDetails":
      return [
        { name: "Financial Plan", path: "/financial" },
        {
          name: "Net Worth / Assets / Financial Assets - Market Linked",
          path: "/financialdetails",
        },
      ];
    case "MutualFund":
      return [...commonPaths, { name: "Mutual Fund", path: "/mutualfund" }];
    case "NPS":
      return [
        ...commonPaths,
        { name: "National Pension Scheme", path: "/npshome" },
      ];
    case "Bonds":
      return [...commonPaths, { name: "Bonds", path: "/bondhome" }];
    case "STOCK":
      return [...commonPaths, { name: "Stocks", path: "/stockshome" }];
    case "Others":
      return [...commonPaths, { name: "Others", path: "/otherfinancialhome" }];
    default:
      return commonPaths;
  }
}

export function getIncomePathName(pageName) {
  const commonPaths = [
    { name: "Financial Plan", path: "/financial" },
    { name: "NetWorth / Financial Details", path: "/financialdetails" },
  ];
  switch (pageName) {
    case "FinancialPlan":
      return [{ name: "Financial Plan", path: "/financial" }];
    case "FinancialDetails":
      return [
        { name: "Financial Plan", path: "/financial" },
        { name: "NetWorth / Financial Details", path: "/financialdetails" },
      ];
    case "Business":
      return [
        ...commonPaths,
        { name: "Business Income", path: "/businesshomepage" },
      ];
    case "Rental":
      return [
        ...commonPaths,
        { name: "Rental Income", path: "/rentalhomepage" },
      ];
    case "Annuity":
      return [
        ...commonPaths,
        { name: "Pension / Annuity", path: "/annuityhomepage" },
      ];
    case "Other_Income":
      return [...commonPaths, { name: "Other Income", path: "/otherhomepage" }];
    case "Insurance":
      return [
        ...commonPaths,
        { name: "Insurance", path: "/insurancehomepage" },
      ];
    default:
      return commonPaths;
  }
}

export function getLiabilityPathName(pageName) {
  const commonPaths = [
    { name: "Financial Plan", path: "/financial" },
    { name: "NetWorth / Liabilities", path: "/financialdetails" },
  ];
  switch (pageName) {
    case "FinancialPlan":
      return [{ name: "Financial Plan", path: "/financial" }];
    case "FinancialDetails":
      return [
        { name: "Financial Plan", path: "/financial" },
        { name: "NetWorth / Liabilities", path: "/financialdetails" },
      ];
    case "HousingLoan":
      return [
        ...commonPaths,
        { name: "Housing Loan", path: "/housingloanhome" },
      ];
    case "VehicleLoan":
      return [
        ...commonPaths,
        { name: "Vehicle Loan", path: "/vehicleloanhome" },
      ];
    case "PersonalLoan":
      return [
        ...commonPaths,
        { name: "Personal Loan", path: "/personalloanhome" },
      ];
    case "OtherLiabilities":
      return [
        ...commonPaths,
        { name: "Other Liabilities", path: "/otherloanhome" },
      ];
    case "CreditCard":
      return [
        ...commonPaths,
        { name: "Outstanding Credit Card Balance", path: "/creditcardhome" },
      ];
    default:
      return commonPaths;
  }
}

export function getGoalsPathName(pageName) {
  const commonPaths = [
    { name: "Financial Plan", path: "/financial" },
    { name: "Goals & Expenses", path: "/goals" },
  ];

  switch (pageName) {
    case "FinancialPlan":
      return [{ name: "Financial Plan", path: "/financial" }];
    case "GoalsExpenses":
      return [
        { name: "Financial Plan", path: "/financial" },
        { name: "Goals & Expenses", path: "/goals" },
      ];
    case "Retirement":
      return [...commonPaths, { name: "Retirement", path: "/retirement" }];
    case "Healthcare":
      return [
        ...commonPaths,
        { name: "Healthcare Expenses", path: "/healthcare" },
      ];
    case "GWT":
      return [
        ...commonPaths,
        { name: "Generational Wealth Transfer", path: "/wealthtransfer" },
      ];
    case "Education":
      return [...commonPaths, { name: "Education", path: "/education" }];
    case "Vacation":
      return [...commonPaths, { name: "Vacation", path: "/vacation" }];
    case "Vehicle":
      return [...commonPaths, { name: "Purchase Vehicle", path: "/vehicle" }];
    case "RealEstate":
      return [
        ...commonPaths,
        { name: "Purchase Real Estate", path: "/realestate" },
      ];
    case "Wedding":
      return [...commonPaths, { name: "Wedding", path: "/wedding" }];
    case "Emi":
      return [...commonPaths, { name: "EMIs / Premiums", path: "/emi" }];
    case "CustomGoal":
      return [...commonPaths, { name: "Custom Goal", path: "/customgoal" }];
    default:
      return commonPaths;
  }
}

export function getRiskProfilePathName() {
  return [
    { name: "Financial Plan", path: "/financial" },
    { name: "Risk Assesment", path: "/riskprofilehome" },
  ];
}

export function getAssetAllocationPathName() {
  return [
    { name: "Financial Plan", path: "/financial" },
    { name: "Asset Allocation", path: "/assetallocdetails" }
  ];
}

export function getCashLadderPathName() {
  return [
    { name: "Financial Plan", path: "/financial" },
    { name: "Cashladder", path: "/cashladder" }
  ];
}

export function getRiskProfileDescription(categoryType) {
  let status = "";
  switch (categoryType) {
    case RISK_PROFILE.MODERATIELY_CONSERVATIVE:
      status = "Moderately Conservative";
      break;
    case RISK_PROFILE.CONSERVATIVE:
      status = "Conservative";
      break;
    case RISK_PROFILE.BALANCED:
      status = "Balanced";
      break;
    case RISK_PROFILE.MODERATIELY_AGGRESSIVE:
      status = "Moderately Aggressive";
      break;
    case RISK_PROFILE.AGGRESSIVE:
      status = "Aggressive";
      break;
    default:
      break;
  }
  return status;
}

export function getFrequencyType(frequencytype) {
  switch (frequencytype) {
    case t(INCOME_FREQUENCY_TYPE.YEARLY):
      return INCOME_FREQUENCY_TYPE.YEARLY;
    case t(INCOME_FREQUENCY_TYPE.HALF_YEARLY):
      return INCOME_FREQUENCY_TYPE.HALF_YEARLY;
    case t(INCOME_FREQUENCY_TYPE.QUARTERLY):
      return INCOME_FREQUENCY_TYPE.QUARTERLY;
    case t(INCOME_FREQUENCY_TYPE.MONTHLY):
      return INCOME_FREQUENCY_TYPE.MONTHLY;
    default:
      return INCOME_FREQUENCY_TYPE;
  }
}

export function getRoleLabel(role) {
  switch (role) {
    case t(USER_ROLE.USER):
      return USER_ROLE.USER;
    case t(USER_ROLE.ADMIN):
      return USER_ROLE.ADMIN;
    case t(USER_ROLE.CUSTOMER):
      return USER_ROLE.CUSTOMER;
    case t(USER_ROLE.ADVISOR):
      return USER_ROLE.ADVISOR;
    case t(USER_ROLE.OPERATION):
      return USER_ROLE.OPERATION;
    default:
      return USER_ROLE.CUSTOMER
  }
}

export function getSecurityLevelLabel(securitylevel) {
  switch (securitylevel) {
    case t(SECURITY_LEVEL.GENERAL):
      return SECURITY_LEVEL.GENERAL;
    case t(SECURITY_LEVEL.ADMIN):
      return SECURITY_LEVEL.ADMIN;
    case t(SECURITY_LEVEL.RESTRICTED):
      return SECURITY_LEVEL.RESTRICTED;
    default:
      return SECURITY_LEVEL.GENERAL;
  }
}

//Test user object
// export const userDetail = {
//   userId: "b17301c0-e68b-42f1-b9de-5f3254ba65bf",
//   dob: "1978-10-11",
//   advisorid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
// };
let userDetailObj = {};

export function userDetail() {

  if (!userDetailObj || Object.keys(userDetailObj).length === 0) {
    const result = localStorage.getItem("fliberAuthUserInfo");
    userDetailObj = result && JSON.parse(result);
  }

  if (!userDetailObj || Object.keys(userDetailObj).length === 0) {
    userDetailObj = {
      id: "",
      dateofbirth: 0,
      role: ''
    };
  }

  return userDetailObj;
}

export function setUserDetail(userInfo) {
  localStorage.setItem("fliberAuthUserInfo", JSON.stringify(userInfo))
  userDetailObj = userInfo
}

export function resetUserDetail() {
  localStorage.removeItem("fliberAuthUserInfo");
  userDetailObj = {}
}

// If admin uses the userscrns for create, update user details then admin needs the user data,  
// so store the selected user data in local storage with another variable name
let selectedUserDetailObj = {};

export function getSelectedUserData() {
  if (!selectedUserDetailObj || Object.keys(selectedUserDetailObj).length === 0) {
    const result = localStorage.getItem("fliberSelectedUserInfo");
    selectedUserDetailObj = result && JSON.parse(result);
  }

  return selectedUserDetailObj;
}

export function setSelectedUserDetail(userInfo) {
  localStorage.setItem("fliberSelectedUserInfo", JSON.stringify(userInfo))
  selectedUserDetailObj = userInfo
}

export function resetSelectedUserDetail() {
  localStorage.removeItem("fliberSelectedUserInfo");
  selectedUserDetailObj = {}
}


export function calculate_age(selectedYear, currentYear, userstartage) {
  //TODO to get dob for user object
  // var birthDate = getUserDOB();
  var age_now = selectedYear - currentYear + userstartage;
  return age_now;
}


export function calculate_year(age, dob) {
  //TODO to get dob for user object
  var birthDate = dob ? new Date(dob) : new Date(getUserDOB());
  var year = birthDate.getFullYear() + age;
  return year;
}

export function getFormatAmount(num) {
  if (num >= 100000 && num < 9999999) {
    return (num = (num / 100000).toFixed(2).replace(/\.0$/, "") + " L");
  } else if (num >= 10000000) {
    return (num = (num / 10000000).toFixed(2).replace(/\.0$/, "") + " C");
  } else if (num < 100000) {
    return num;
  }
  return num;
}

// Not Allowes - ,  (fraction values , no negative values also)
export function validateNumber(number) {
  //number validation check with two decimals
  const regEx = /^\d{0,10}(\.\d{0,2})?$/;
  return number === "" || number.match(regEx) !== null;
}

export function currencyFormat(amount) {
  return amount?.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "INR",
  })
}

export function prepareCashLadderValues(values) {
  const myObj = values;
  const outputArr = [];
  if (Object.keys(myObj)?.length > 0 && Object.keys(myObj["cashflowage"])?.length > 0) {
    for (let i = 0; i < Object.keys(myObj["cashflowage"]).length; i++) {
      const newObj = {};
      newObj["cashflowage"] = myObj["cashflowage"] ? myObj["cashflowage"][i] : '';
      newObj["goal"] = myObj["goal"] ? myObj["goal"][i].toFixed(2) : '';
      newObj["index"] = myObj["index"] ? myObj["index"][i] : '';
      newObj["flowamount"] = myObj["flowamount"] ? myObj["flowamount"][i].toFixed(2) : '';
      newObj["flowamount_x"] = myObj["flowamount_x"] ? myObj["flowamount_x"][i].toFixed(2) : '';
      newObj["flowamount_y"] = myObj["flowamount_y"] ? myObj["flowamount_y"][i].toFixed(2) : '';
      newObj["income"] = myObj["income"] ? myObj["income"][i].toFixed(2) : '';
      newObj["netflow"] = myObj["netflow"] ? myObj["netflow"][i].toFixed(2) : '';
      newObj["nnf"] = myObj["nnf"] ? myObj["nnf"][i].toFixed(2) : '';
      newObj["sb_required_LS"] = myObj["sb_required_LS"] ? myObj["sb_required_LS"][i].toFixed(2) : '';
      newObj["npv"] = myObj["npv"] ? myObj["npv"][i].toFixed(2) : '';
      newObj["gb_required_LS"] = myObj["gb_required_LS"] ? myObj["gb_required_LS"][i].toFixed(2) : '';
      newObj["Begin_start_bal"] = myObj["Begin_start_bal"] ? myObj["Begin_start_bal"][i].toFixed(2) : '';
      newObj["sb_actual_ls"] = myObj["sb_actual_ls"] ? myObj["sb_actual_ls"][i].toFixed(2) : '';
      newObj["sb_required_sip"] = myObj["sb_required_sip"] ? myObj["sb_required_sip"][i].toFixed(2) : '';
      newObj["gb_required_sip"] = myObj["gb_required_sip"] ? myObj["gb_required_sip"][i].toFixed(2) : '';
      newObj["gb_actual_LS"] = myObj["gb_actual_LS"] ? myObj["gb_actual_LS"][i].toFixed(2) : '';
      newObj["sb_sip_amount"] = myObj["sb_sip_amount"] ? myObj["sb_sip_amount"][i].toFixed(2) : '';
      newObj["gb_sip_amount"] = myObj["gb_sip_amount"] ? myObj["gb_sip_amount"][i].toFixed(2) : '';
      newObj["sb_port_year_end_balance"] = myObj["sb_port_year_end_balance"] ? myObj["sb_port_year_end_balance"][i].toFixed(2) : '';
      newObj["gb_port_year_end_balance"] = myObj["gb_port_year_end_balance"] ? myObj["gb_port_year_end_balance"][i].toFixed(2) : '';
      newObj["total_port_year_end_balance"] = myObj["total_port_year_end_balance"] ? myObj["total_port_year_end_balance"][i].toFixed(2) : '';
      newObj["sb_actual_ls_A"] = myObj["sb_actual_ls_A"] ? myObj["sb_actual_ls_A"][i].toFixed(2) : '';
      newObj["gb_actual_LS_A"] = myObj["gb_actual_LS_A"] ? myObj["gb_actual_LS_A"][i].toFixed(2) : '';
      newObj["Begin_sb_start_bal"] = myObj["Begin_sb_start_bal"] ? myObj["Begin_sb_start_bal"][i].toFixed(2) : '';
      newObj["Begin_gb_start_bal"] = myObj["Begin_gb_start_bal"] ? myObj["Begin_gb_start_bal"][i].toFixed(2) : '';
      newObj["total_year_end_balance"] = myObj["total_year_end_balance"] ? myObj["total_year_end_balance"][i].toFixed(2) : '';
      newObj["cashflowyear"] = myObj["cashflowyear"] ? myObj["cashflowyear"][i] : '';
      outputArr.push(newObj);
    }
  }
  return outputArr;
}

export function prepareGoalFullfilmentValues(values) {
  const myObj = values;
  let modifiedData = [];
  if (Object.keys(myObj).length > 0) {
    const cashflowage = Object.keys(myObj.flowamount).map(Number);
    const goalId = Object.values(myObj.id);
    const amount = Object.values(myObj.flowamount);
    const cashflowyear = Object.values(myObj.cashflowyear);
    const goalCategory = Object.values(myObj.goalcategory);
    const goalbucketcode = Object.values(myObj.goalbucketcode);
    const goalPriority = Object.values(myObj.goalpriority);
    const newData = {
      goalId,
      cashflowage,
      amount,
      cashflowyear,
      goalCategory,
      goalbucketcode,
      goalPriority
    };
    modifiedData = newData.cashflowage?.map((age, index) => ({
      id: newData?.goalId[index],
      cashflowage: age,
      cashflowyear: newData?.cashflowyear[index],
      flowamount: newData?.amount[index]?.map(value => value.toFixed(2)),
      goalCategory: newData?.goalCategory[index],
      goalBucketCode: newData?.goalbucketcode[index],
      goalPriority: newData?.goalPriority[index],
      totalgoal: (newData?.amount[index]?.reduce((a, b) => a + b, 0) || 0).toFixed(2),
    }));
  }

  return modifiedData
}

const getGoalData = (index, goalId, goalCategories, cashflowyear, amount, age, goalbucketcode, goalPriority, goalData, goalDesc, goalDetails) => {
  const data = {};
  goalId[index].forEach((id, itemIndex) => {
    const goalCategory = goalCategories[index][itemIndex];
    const goalDescription = goalDesc[index][itemIndex];
    if (!goalData.includes(id)) {
      goalData.push(id)
      goalDetails.push(goalDescription ? `${t(goalCategory)} : ${goalDescription}` : t(goalCategory))
    }
    data[id] = {
      id: id,
      cashflowage: age,
      cashflowyear: cashflowyear[index][itemIndex],
      flowamount: currencyFormat(amount[index][itemIndex]),
      goalCategory: goalCategory,
      goalBucketCode: goalbucketcode[index][itemIndex],
      goalPriority: goalPriority[index][itemIndex],
    }
  });
  return data;
};

const getIncomeData = (index, incomeId, incomeCategories, cashflowyear, amount, age, incomeData, incomeDesc, incomeDetails) => {
  const data = {};
  incomeId[index].forEach((id, itemIndex) => {
    const incomeCategory = incomeCategories[index][itemIndex];
    const incomeDescription = incomeDesc[index][itemIndex];
    if (!incomeData.includes(id)) {
      incomeData.push(id)
      incomeDetails.push(incomeDescription ? `${t(incomeCategory)} : ${incomeDescription}` : t(incomeCategory))
    }

    data[id] = {
      id: id,
      cashflowage: age,
      cashflowyear: cashflowyear[index][itemIndex],
      flowamount: currencyFormat(amount[index][itemIndex]),
      incomeDescription: incomeDescription,
    }
  })

  return data;
}

export function prepareGoalPayoutValues(values) {
  const myObj = values;
  let modifiedData = [];
  const goalData = [];
  const goalDetails = [];

  if (Object.keys(myObj).length > 0) {
    const cashflowage = Object.keys(myObj.flowamount).map(Number);
    const goalId = Object.values(myObj.id);
    const amount = Object.values(myObj.flowamount);
    const cashflowyear = Object.values(myObj.cashflowyear);
    const goalCategory = Object.values(myObj.goalcategory);
    const goalbucketcode = Object.values(myObj.goalbucketcode);
    const goalPriority = Object.values(myObj.goalpriority);
    const goalDesc = Object.values(myObj.goaldescription);


    modifiedData = cashflowage?.map((age, index) => ({
      cashflowage: age,
      cashflowyear: cashflowyear[index][0],
      data: getGoalData(index, goalId, goalCategory, cashflowyear, amount, age, goalbucketcode, goalPriority, goalData, goalDesc, goalDetails),
      totalgoal: currencyFormat(amount[index]?.reduce((a, b) => a + b, 0) || 0),
    }));

  }

  return { modifiedData, goalData, goalDetails }
}

export function prepareIncomePayoutValues(values) {
  const myObj = values;
  let modifiedData = [];
  let incomeData = [];
  let incomeDetails = [];

  if (Object.keys(myObj).length > 0) {
    const cashflowage = Object.keys(myObj.flowamount).map(Number);
    const incomeId = Object.values(myObj.id);
    const amount = Object.values(myObj.flowamount);
    const cashflowyear = Object.values(myObj.cashflowyear);
    const incomeCategory = Object.values(myObj.incomecategory);
    const incomeDesc = Object.values(myObj.incomedescription);

    modifiedData = cashflowage?.map((age, index) => ({
      cashflowage: age,
      cashflowyear: cashflowyear[index][0],
      data: getIncomeData(index, incomeId, incomeCategory, cashflowyear, amount, age, incomeData, incomeDesc, incomeDetails),
      totalincome: currencyFormat(amount[index]?.reduce((a, b) => a + b, 0) || 0),
    }));
  }


  return { modifiedData, incomeData, incomeDetails }
}

export const FLIBER_STEPS = [
  Fliber_Step_0,
  Fliber_Step_1,
  Fliber_Step_2,
  Fliber_Step_3,
  Fliber_Step_4,
  Fliber_Step_5,
  Fliber_Step_6,
  Fliber_Step_7,
];

export function getUserRole() {
  return userDetail()?.role;
}

export function getSecurityLevel() {
  return userDetail()?.securitylevel;
}

export function isRestrictedCustomer() {
  const user = userDetail();
  return user?.securitylevel === SECURITY_LEVEL.RESTRICTED && user?.role === USER_ROLE.CUSTOMER;
}

export function isAdminRAdvisor() {
  const user = userDetail();
  return user?.securitylevel === SECURITY_LEVEL.ADMIN || user?.role === USER_ROLE.ADVISOR;
}

export function isAdminOnly() {
  const user = userDetail();
  return user?.securitylevel === SECURITY_LEVEL.ADMIN;
}

export function getUserId() {
  const userData = userDetail();
  const selectedUserData = getSelectedUserData();

  if (isAdminRAdvisor() && selectedUserData?.id) {
    return selectedUserData.id;
  } else {
    return userData.id;
  }
}

export function getUserDOB() {
  const userData = userDetail();
  const selectedUserData = getSelectedUserData();

  if (isAdminRAdvisor() && selectedUserData?.dateofbirth) {
    return selectedUserData.dateofbirth;
  } else {
    return userData.dateofbirth;
  }
}

export function getUserDetails() {
  const userData = userDetail();
  const selectedUserData = getSelectedUserData();

  if (isAdminRAdvisor() && selectedUserData && Object.keys(selectedUserData)?.length > 0) {
    return selectedUserData;
  } else {
    return userData;
  }
}

let cashLadderObj = {};

export function getUserCashLadderDetail() {
  if (!cashLadderObj || Object.keys(cashLadderObj).length === 0) {
    const result = localStorage.getItem("cashLadderInfo");
    cashLadderObj = result && JSON.parse(result);
  }

  return cashLadderObj;
}

export function setUserCashLadderDetail(cashladderInfo) {
  localStorage.setItem("cashLadderInfo", JSON.stringify(cashladderInfo))
  cashLadderObj = cashladderInfo;
}

export function resetUserCashLadderDetail() {
  localStorage.removeItem("cashLadderInfo");
  cashLadderObj = {}
}


export function getCurrentUrl() {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const baseUrl = url.origin;

  return baseUrl;
}

export function getFormattedDate(date) {
  const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDate = date?.toLocaleDateString('en-US', dateOptions);

  const [month, day, year] = formattedDate?.split('/');
  const rearrangedDate = `${year}-${month}-${day}`;

  return rearrangedDate;
}