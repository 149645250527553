import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class CommonApi {
  static getCommonConfigInfo() {
    return new Promise((resolve, reject) => {
      apiCall(`configuration/get/config`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getFutureValue(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `Calculations/futureValue?intial_investment=${data.intial_investment}&interest_rate=${data.interest_rate}&investment_period=${data.investment_period}&growth_rate=${data.growth_rate}`,
        Methods.GET
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getFutureValueMarketLinked(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `Calculations/futureValueMarketLinked?intial_investment=${data.intial_investment}&equity_percentage=${data.equity_percentage}&investment_period=${data.investment_period}`,
        Methods.GET
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCurrentAge(data) {
    return new Promise((resolve, reject) => {
      apiCall(`User/user_current_age`, Methods.POST, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getStates() {
    return new Promise((resolve, reject) => {
      apiCall(`State/states`, Methods.GET)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

  static updateConfigInfo(data) {
    return new Promise((resolve, reject) => {
      apiCall(`configuration/update/config`, Methods.PATCH, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static initiateCustomerCredentials(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserProfileStatus/intiateUserCredintial?emailid=${data.emailId}&weburl=${data.webUrl}`, Methods.POST, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default CommonApi;
