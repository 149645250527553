import BackendApi from "../backend/index";

class PortfolioAllocation {

  static getRuleDetails() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.PortfolioAllocationApi.getRuleDetails().then(response => {
        resolve(response);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static getPortfolioAllocationValues(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.PortfolioAllocationApi.getPortfolioAllocationValues(data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static createPortfolioAllocationValues(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.PortfolioAllocationApi.createPortfolioAllocationValues(data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error)
      })
    })
  }

  static updatePortfolioAllocationValues(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.PortfolioAllocationApi.updatePortfolioAllocationValues(data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default PortfolioAllocation;