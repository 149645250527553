import "./goalfulfillmentview.css";
import { t } from "i18next";
import { FiZoomIn as ZoomIn } from "react-icons/fi";
import { useState } from "react";
import { Button, FloatingLabelInput } from "../components";
import { getCardDetailsByCategory, getFormatAmount } from "../common";
import { Modal, Popover, Whisper } from "rsuite";
import FilterIcon from "../../assets/svgs/filtericon.svg"
import { AiOutlineClose as Close } from "react-icons/ai";

const GoalFulFillmentView = (props) => {
  let editViewRef;
  const data = props.goalAllocationValues ? props.goalAllocationValues : [];
  const [state, setState] = useState({
    showModal: false,
    selectedOption: "Age",
    searchValueGoal: "",
    searchMinValue: "",
    searchMaxValue: ""
  });
  const getGoalBucketCode = (bucketcode) => {
    switch (bucketcode) {
      case "NEEDS":
        return "N";
      case "WANTS":
        return "W";
      case "DESIR":
        return "D";
      default:
        return "N";
    }
  };

  const getSelectedOption = () => {
    switch (state.selectedOption) {
      case t("age"):
        return "age";
      case t("goalCategory"):
        return "goalCategory";
      case t("amount"):
        return "amount";
      default:
        return "age";
    }
  }

  const onPressApply = () => {
    const selectedOption = getSelectedOption();
    if (state.selectedOption === "Goals") {
      if (props.onPressApply) {
        props.onPressApply(selectedOption, state.searchValueGoal)
      }
    }
    else {
      if (props.onPressApply) {
        props.onPressApply(selectedOption, state.searchMinValue, state.searchMaxValue)
      }
    }
    hidePopOver();
  }

  const onPressClose = () => {
    hidePopOver();
  }

  const hidePopOver = () => {
    if (editViewRef) {
      editViewRef.close();
    }
  }

  const onClose = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false
    }))
  }

  const onPressZoomIn = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true
    }))
  }

  const onChangeOption = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchMinValue: '',
      searchMaxValue: '',
      searchValueGoal: '',
      selectedOption: value
    }));
  }

  const onChangeSearchValue = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchValueGoal: value
    }));
  }

  const onChangeSearchMinValue = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchMinValue: value
    }));
  }

  const onChangeSearchMaxValue = (value) => {
    setState((prevState) => ({
      ...prevState,
      searchMaxValue: value
    }));
  }

  const renderInputView = (
    label,
    value,
    onChange,
    selectionView = false,
    selectedOptions = [],
    className = "",
    type = "text",
    maxLength
  ) => {
    return (
      <div className="gf_retInput">
        <FloatingLabelInput
          label={label}
          value={value}
          onChangeValue={onChange}
          type={type}
          maxLength={maxLength}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
          className={className}
        />
      </div>
    );
  }

  const renderPopOverView = (index, onClickClose) => {
    return (
      <Popover className="gf_filter_view" onClose={hidePopOver}>
        <div className="gf_inputView">
          <div className={"gf_close"}>
            <label className={"gf_filterHeading"}>{t("Filter")}</label>
            <Close className={"gf_closeIconStyle"} onClick={onClickClose} />
          </div>
          <div className="gf_actionLink">
            <div className='gf_selectionInput' >
              {renderInputView(
                t("selectField"),
                state.selectedOption,
                onChangeOption,
                true,
                [t("age"), t("goalCategory"), t("amount")],
                "ActiveInputBox"
              )}
            </div>
            {state.selectedOption === "Goals" ? <div className="gf_searchInput">
              {renderInputView(t("Search Value"), state.searchValueGoal, onChangeSearchValue, false)}
            </div> :
              <div className="gf_searchInput">
                {renderInputView(t("Min Value"), state.searchMinValue, onChangeSearchMinValue, false)}
                {renderInputView(t("Max Value"), state.searchMaxValue, onChangeSearchMaxValue, false)}
              </div>}
            <div className="gf_apply"> <Button
              buttonName={t("Apply")}
              onPressButton={onPressApply}
            /></div>
          </div>
        </div>
      </Popover>
    );
  }

  const renderFilterView = (index) => {
    return (
      <div className="gf_filterStyle">
        <Whisper
          placement="right"
          speaker={renderPopOverView(index, onPressClose)}
          trigger="click"
          ref={(ref) => (editViewRef = ref)}
        >
          <img
            title="Filter"
            src={FilterIcon}
            alt={"Filter Icon"}
            className="cdc_more"
          />
        </Whisper>
      </div>
    )
  };

  const renderZoomInView = () => {
    const isEmptyView = props?.goalAllocationValues?.length === 0;
    return (
      <ZoomIn
        className={"gf_iconStyle"}
        onClick={isEmptyView ? () => { } : onPressZoomIn}
      />
    );
  };

  const renderTitleView = () => {
    return (
      <div className={"gf_titleView"}>
        <label className={"gf_titleStyle"}>
          {t("GoalFulFillment: Goal Fulfillment")}
        </label>
        {renderFilterView()}
        {renderZoomInView()}
      </div>
    );
  };
  const renderGoalItem = (item, goalItem, index) => {
    const goal = getCardDetailsByCategory(goalItem).title;
    const bucketcode = getGoalBucketCode(item?.goalBucketCode[0]);
    const displayBucketCode = `(${bucketcode})`;
    const isFulFilled = item.totalgoal > 0;
    return (
      <div
        className={"gf_goalItemView"}
        style={{
          borderColor: isFulFilled ? "#007D0D" : "#D02037",
        }}
        title={goal}
      >
        <label className={"gf_goalLabelStyle"}>{goal}</label>
        <label className={"gf_goalItemStyle"}>{displayBucketCode}</label>
      </div>
    );
  };

  const renderTableInfoView = () => {
    return (
      <div className="gf_tableInfoView">
        *  ( N ) - Needs, ( W ) - Wants, ( D ) - Desires
      </div>
    );
  };
  const renderFirstRowView = (item, index) => {
    return (
      <div className={"gf_ageStyle"}>
        {item.cashflowage}
      </div>
    );
  };

  const renderSecondRowView = (item, index, viewStyle) => {
    return (
      <div className={viewStyle}>
        {item.goalCategory.map((goalItem, itemIndex) => (
          <div key={itemIndex}>{renderGoalItem(item, goalItem, index)}</div>
        ))}
      </div>
    );
  };

  const renderThirdRowView = (item, index) => {
    return (
      <div className={"gf_balanceStyle"}>
        {item.totalgoal ? `₹ ${getFormatAmount(item.totalgoal)}` : item.totalgoal}
      </div>
    );
  };

  const renderForthRowView = (item, index) => {
    return (
      <div className={"gf_higelightView"}>
        {item.totalgoal <= 0 ? <div className="gf_highlightsStyle"></div> : ""}
      </div>
    );
  };

  const renderTableHeaderView = (goalHeaderStyle) => {
    return (
      <div className="gf_headerView">
        <div className={"gf_ageStyle"}>{t("CashLadder: Age")}</div>
        <div className={goalHeaderStyle}>
          <label className={"gf_goalTextStyle"}>{t("CashLadder: Goals")}</label>
        </div>
        <div className={"gf_balanceStyle"}>
          {t("CashLadder: Total Goal Amount")}
        </div>
        {/* <div className={"gf_highlightStyle"}>
          {t("CashLadder: Highlights")}
        </div> */}
      </div>
    );
  }

  const renderBackgroundView = (goalHeaderStyle = "gf_bg_goalsView") => {
    return (
      <div className={"gf_bg_outerView"}>
        <div className={"gf_bg_ageView"}></div>
        <div className={goalHeaderStyle}></div>
        <div className={"gf_bg_balanceView"}></div>
        {/* <div className={"gf_bg_highlightsView"}></div> */}
      </div>
    );
  }

  const renderTableContentView = (contentStyle, goalHeaderStyle, goalViewStyle) => {
    return (
      <div className={"gf_tableView"}>
        {renderBackgroundView(goalHeaderStyle)}
        <div className={"gf_tableContentView"}>
          {renderTableHeaderView(goalHeaderStyle)}
          {data.map((item, index) => (
            <div key={index} className={contentStyle}>
              {renderFirstRowView(item, index)}
              {renderSecondRowView(item, index, goalViewStyle)}
              {renderThirdRowView(item, index)}
              {/* {renderForthRowView(item, index)} */}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderTabelContent = (outerViewStyle = {}, contentStyle, goalHeaderStyle, goalViewStyle) => {
    const isEmptyView = props?.goalAllocationValues?.length === 0
    return (
      isEmptyView ?
        <div className={"gf_content"}>
          <div className={"gf_emtyView"}>
            <label className={"gf_emtyTextStyle"}>{t('No Goals Found')}</label>
          </div>
        </div> :
        <div className={outerViewStyle}>
          {renderTableInfoView()}
          {renderTableContentView(contentStyle, goalHeaderStyle, goalViewStyle)}
        </div>
    )
  }

  const renderTabelTitle = () => {
    return (
      <div className="gf_modal_title">
        <div></div>
        <label className="gf_modal_heading">{t('Goal Fullfilment View')}</label>
        <span className="gf_modal_close" onClick={onClose}>&times;</span>
      </div>
    )
  }
  const renderTabelModal = () => {
    return (
      <Modal
        size="xl"
        open={state.showModal}
        onClose={onClose}
        className="gf_modal"
      >
        <div>
          {renderTabelTitle()}
          {renderTabelContent({}, "gf_tableContentsModal", "gf_goalStyleModal", "gf_goalItemsViewShowModal")}
        </div>
      </Modal>
    )
  };

  return (
    <div className={"gf_container"}>
      {renderTitleView()}
      {renderTabelContent("gf_content", "gf_tableContents", "gf_goalStyle", "gf_goalItemsView")}
      {state.showModal && renderTabelModal()}
    </div>
  );
};

export default GoalFulFillmentView;
