import React from "react";

function MyDivider(props) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ width: "inherit", border: "1px solid #E2E8F0" }}></div>
    </div>
  )
}

export default MyDivider
