import React, { Component } from "react";
import CardContainer from "./cardcontainer";
import "./goalscategorycard.css";
import { Popover, Whisper } from "rsuite";
import EditIcon from "../../assets/svgs/ic_edit.svg";
import DeleteIcon from "../../assets/svgs/ic_delete.svg";
import MoreIcon from "../../assets/svgs/More_Icon.svg";
import RecordModal from "./recordmodal";
import { t } from "i18next";

class GoalsCategoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  onDragStart(event) {
    if (this.props.onDragStart) {
      this.props.onDragStart(event);
    }
  }

  onSelectCard() {
    if (this.props.onSelectCard) {
      this.props.onSelectCard();
    }
  }

  onClickEditIcon() {
    this.hidePopOver();
    if (this.props.onPressEdit) {
      this.props.onPressEdit();
    }
  }

  onClickDeleteIcon() {
    this.hidePopOver();
    this.setState({ showModal: true });
  }

  onHideModal = () => {
    this.hidePopOver();
    this.setState({ showModal: false });
  };

  handlePressNo = () => {
    this.onHideModal();
    if (this.props.onPressNo) {
      this.props.onPressNo();
    }
  };

  handlePressYes = () => {
    this.onHideModal();
    if (this.props.onPressYes) {
      this.props.onPressYes();
    }
  };

  hidePopOver() {
    if (this.editViewRef) {
      this.editViewRef.close();
    }
  }

  renderTitleNPriorityView(category) {
    const title = category?.title ? category.title : "";
    const priority = category?.priority ? category.priority : "";
    const disableClick = this.props.disable;
    return (
      <div className="gc_rowStyle">
        {this.props.showPriority && (
          <div className="gc_priority_view">
            <label className="gc_priority_style">{priority}</label>
          </div>
        )}
        <label className={disableClick ? "gc_title_disable": "gc_title"}>{title}</label>
        {this.props.showDelete && this.renderMoreIconView()}
      </div>
    );
  }

  renderPopOverView(index) {
    return (
      <Popover className="gc_edit_view" onClose={this.hidePopOver.bind(this)}>
        <div className="gc_edit_row" onClick={this.onClickEditIcon.bind(this)}>
          <img src={EditIcon} alt={"Edit Icon"} className="gc_more" />
          <label className="gc_edit_text">{t('edit')}</label>
        </div>
        <div
          className="gc_edit_row"
          onClick={this.onClickDeleteIcon.bind(this)}
        >
          <img src={DeleteIcon} alt={"Delete Icon"} className="gc_more" />
          <label className="gc_edit_text">{t('delete')}</label>
        </div>
      </Popover>
    );
  }

  renderMoreIconView(index) {
    return (
      <Whisper
        placement="right"
        speaker={this.renderPopOverView()}
        trigger="click"
        ref={(ref) => (this.editViewRef = ref)}
      >
        <img
          src={MoreIcon}
          alt={"More Icon"}
          className="gc_delete_view"
        />
      </Whisper>
    );
  }

  renderAmountNIconView(category) {
    const amount = category?.amount ? category.amount : "";
    const displayAmount = amount ? `${"₹ "} ${amount}` : "";
    return (
      <div className="gc_rowStyle">
        {this.props.showAmount && (
          <div className="gc_amount_style">{displayAmount}</div>
        )}
        <img
          src={category.icon}
          alt={category.title}
          className={"gc_icon_style"}
        ></img>
      </div>
    );
  }

  renderDescView(desc) {
    return (
      <div className="gc_descView">
        <label className="gc_descStyle" title={desc}>{desc}</label>
      </div>
    );
  }

  renderContentView() {
    const category = this.props.category;
    const desc =
      category?.description?.length > 0 ? category.description : null;
      const disableClick = this.props.disable;
    return (
      <div
        className={disableClick? "gc_content_disableview" : "gc_content_view"}
        style={{ backgroundColor: category.bgcolor }}
        onClick={disableClick  ? () => {} : this.onSelectCard.bind(this)}
      >
        {this.renderTitleNPriorityView(category)}
        {desc && this.renderDescView(desc)}
        {this.renderAmountNIconView(category)}
      </div>
    );
  }

  renderModalView() {
    const buttonNames = { no: t("no"), yes: t("yes") };
    const title = t("areYouSureYouWantToDeleteTheSelectedGoals");
    return (
      <RecordModal
        open={this.state.showModal}
        onClose={this.onHideModal.bind(this)}
        onPressNo={this.handlePressNo.bind(this)}
        onPressYes={this.handlePressYes.bind(this)}
        title={title}
        buttonNames={buttonNames}
      />
    );
  }

  render() {
    return (
      <CardContainer
        draggable={this.props.draggable}
        style={this.props.boxStyle}
        onDragStart={this.onDragStart.bind(this)}
      >
        {this.renderContentView()}
        {this.state.showModal && this.renderModalView()}
      </CardContainer>
    );
  }
}

export default GoalsCategoryCard;
