import { useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './index.css';
import {
  Button,
  FloatingLabelInput,
  ProgressDialog,
  Toast
} from '../../../components';
import Close from '../../../../assets/svgs/websvgs/close.svg';
import Success from "../../../../assets/svgs/successmodal.svg";
import { SUPPORT_TYPE } from '../../../constants';
import * as Bl from '../../../../businesslogic';

const ContactModal = (props) => {
  const [state, setState] = useState({
    name: '',
    panNumber: '',
    mobileNumber: '',
    emailId: '',
    interactionType:'enquiry',
    query: '',
    showToast: false,
    toastMessage: '',
    interactionNumber: null,
    showProgress: false,
    progressMessage: '',
    showResponseView: false
  });

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }


  const onChangeName = (value) => {
  setState((prevState) => ({
    ...prevState,
    name: value
  }))
}

  const onChangeMobileNumber = (value) => {
 
  const numericValue = value.replace(/\D/g, '');
  setState((prevState) => ({
    ...prevState,
    mobileNumber: numericValue
  }));
};

  const onChangeEmailId = (value) => {
    const mailId = value.toLowerCase();
    setState((prevState) => ({
      ...prevState,
      emailId: mailId
    }))
  }

  const onChangeQuery = (event) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      query: value
    }))
  }

  const onChangeInteractionType = (type) => {
  setState((prevState) => ({
    ...prevState,
    interactionType: type, 
  }));
};

 const checkIsDataValid = () => {
  let isValid = true;
  let toastMessage = '';
  if (!state.emailId || state.emailId.length === 0) {
    isValid = false;
    toastMessage = 'Please enter your Email Id';
  } else {
    
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(state.emailId)) {
      isValid = false;
      toastMessage = 'Please enter a valid Email Id';
    }
  }

  return { isValid, toastMessage };
}

  const onPressSubmit = () => {
  
  const isDataValid = checkIsDataValid();

  if (isDataValid.isValid) {
   
    const data = {
      "name": state.name,
      "mobile": state.mobileNumber,
      "email": state.emailId,
      "description": state.query,
      "pan": state.panNumber,
      "supporttype": SUPPORT_TYPE.GENERAL,
      "status": "Progress",
      "interactiontype": state.interactionType,
      "isexistingcustomer": false,              
      "interactionopendate": new Date().toISOString().split('T')[0],
      "interactionstatus": "open",              
      "interactionsource": "DIRECT" 
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }));
    Bl.Support.createSupportQuery(data)
      .then(response => {
        
         const interactionNumber = response.interactionnumber;
        
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          mobileNumber: '',
          emailId: '',
          panNumber: '',
          query: '',
          interactionType: '', 
           interactionNumber: interactionNumber,
          showResponseView: true
        }));
         
      })
      .catch(error => {
        
        console.error('EnquiryModal/ onPressSend', error);
        showToast(error.message);
      });
  } else {
    
    showToast(isDataValid.toastMessage);
  }
};

  const showToast = (toastMessage) => {
    
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const renderInputView = (
    label,
    value,
    onChange,    
    showRupeeSymbol = false,
    showPercentage = false,
    type,
    required = false 
  ) => {
    return (
      <div className="com_input">
        <FloatingLabelInput
          label={label}
          value={value}
          onChangeValue={onChange}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          
          type={type}
           required={required} 
        />
      </div>
    );
  };

  const renderTextAreaView = () => {
    return (
      <div className='com_textAreaView'>
        <label className='com_textLabelStyle'>{t('Write us your query')}</label>
        <textarea
          value={state.query}
          onChange={onChangeQuery}
          className='com_textAreaStyle'
        />
      </div>
    );
  }

  const renderHeaderView = (label) => {
    return (
      <div className='com_headerView'>
        <label className='com_headingStyle'>{label}</label>
        <img className='com_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderInputsView = () => {
    return (
      <div className='com-inputs-content-view'>
  <div className='com-row-view'>
    {renderInputView(t('Name'), state.name, onChangeName, false, false, 'text')}
    {renderInputView(t('Email ID'), state.emailId, onChangeEmailId, false, false, 'email', true)}
    {renderInputView(t('Mobile Number'), state.mobileNumber, onChangeMobileNumber, false, false, 'text')}
  
  </div>
  
  <div className='com-checkbox-container'>
    <div className='com-checkbox-item'>
      <input
        type='checkbox'
        id='enquiryCheckbox'
        checked={state.interactionType === 'enquiry'}
        onChange={() => onChangeInteractionType('enquiry')}
      />
      <label htmlFor='enquiryCheckbox'>{t('Enquiry')}</label>
    </div>
    <div className='com-checkbox-item'>
      <input
        type='checkbox'
        id='complaintsCheckbox'
        checked={state.interactionType === 'complaints'}
        onChange={() => onChangeInteractionType('complaints')}
      />
      <label htmlFor='complaintsCheckbox'>{t('Complaints')}</label>
    </div>
  </div>

  {renderTextAreaView()}

  <label className='com-sub-label-style'>{t('contact us info')}</label>
</div>
    );
  }

  const renderButtonView = () => {
    return (
      <div className='com_buttonView'>
        <Button buttonName={t('Submit')} onPressButton={onPressSubmit} />
      </div>
    );
  }

  const renderResponseView = () => {
    return (
      <div className={"com_responseContentView"}>
        {renderHeaderView(t('Get in Touch'))}
        <div className={"com_responseView"}>
          <img src={Success} alt={"success"} className={"com_successStyle"} />
           <label className={"com_responseStyle"}>
          {t(`Thanks for submitting your details. Your interaction number for this interaction is `)}
          <span className={"highlight_number"}>
            {state.interactionNumber}
          </span>
          {t(`. Please quote this number for any future reference to this interaction. Fliber will get back to you soon.`)}
        </label>
        </div>
      </div>
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'com_formContent'}>
        {renderHeaderView(t('Get in Touch'))}
        {renderInputsView()}
        {renderButtonView()}
      </div>
    )
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }


  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      dialogClassName={'com_container'}
      size={"md"}
    >
      <div className='com_content'>
        {state.showResponseView ? renderResponseView() : renderFormContent()}
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default ContactModal;