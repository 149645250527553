import React, { Component } from "react";
import {
  Header,
  BreadCrumb,
  GoalsCategoryCard,
  RetirementModal,
  Toast,
  ProgressDialog,
} from "../components";
import { t } from "i18next";
import "./goalslandingpage.css";
import {
  getCardDetailsByCategory,
  getGoalsPathName,
  getUserId,
} from "../common";
import { GOAL_CATEGORY, RETIRE_WANT_TYPE } from "../constants";
import * as Bl from "../../businesslogic";

class GoalsLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      showModal: false,
      showRetirementModal: false,
      selectedItem: "",
      isRetirementExists: false,
      isHealthCareExists: false,
    };
    this.isRenderingAlready = false;
    this.userId = getUserId();
  }
  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.isRetirementHealthCareExists();
    }
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }
  onSelectItem(path) {
    this.props.navigate(path);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  onSelectRetirementCard() {
    this.setState({ showRetirementModal: true });
  }

  onSelectHealthCare() {
    this.props.navigate("/healthcare");
  }

  onSelectWealthTransfer() {
    this.props.navigate("/wealthtransfer");
  }

  onSelectEducation() {
    this.props.navigate("/education");
  }

  onSelectVacation() {
    this.props.navigate("/vacation");
  }

  onSelectPurchaseVehicle() {
    this.props.navigate("/vehicle");
  }

  onSelectRealEstate() {
    this.props.navigate("/realestate");
  }

  onSelectWedding() {
    this.props.navigate("/wedding");
  }

  onSelectEMIPremium() {
    this.props.navigate("/emi");
  }

  onSelectCustomGoal() {
    this.props.navigate("/customgoal");
  }

  onClickCancelModal() {
    this.setState({ showRetirementModal: false });
  }

  isRetirementHealthCareExists() {
    const data1 = {
      uuid: this.userId,
      category: GOAL_CATEGORY.RETIREMENT,
    };
    const data2 = {
      uuid: this.userId,
      category: GOAL_CATEGORY.HEALTH_CARE,
    };
    this.setState({ showProgress: true }, async () => {
      try {
        const retirementList = await Bl.Goals.getGoalsByCategory(data1);
        const healthCareList = await Bl.Goals.getGoalsByCategory(data2);
        const isRetirementExists = retirementList?.length > 0;
        const isHealthCareExists = healthCareList?.length > 0;
        this.setState({ isRetirementExists, isHealthCareExists, showProgress: false });
      } catch {
        this.setState({ showProgress: false });
      }

    })
  }

  onPressNextButtonInModal(selectedItem) {
    let retirewanttype = '';
    switch (selectedItem) {
      case t('retirementModal1'):
        retirewanttype = RETIRE_WANT_TYPE.LIVE_LAVISHLY
        break;
      case t('retirementModal2'):
        retirewanttype = RETIRE_WANT_TYPE.MAINTAIN_EXISTING
        break;
      case t('retirementModal3'):
        retirewanttype = RETIRE_WANT_TYPE.LIVE_MODESTLY
        break;
      default:
        break;
    }
    this.setState({ showRetirementModal: false }, () => {
      this.props.navigate("/retirement", {
        state: { selectedItem: retirewanttype },
      });
    });
  }

  renderTitleView() {
    return (
      <div className="gl_titleView">
        <label className="gl_titleStyle">{t("goalsLandingTitle")}</label>
      </div>
    );
  }

  renderCardsView() {
    return (
      <div className="gl_informationView">
        <div className="gl_cardsView">{this.renderCards()}</div>
      </div>
    );
  }

  renderNavView() {
    const items = getGoalsPathName("GoalsExpenses");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderRetirementView() {
    const category = getCardDetailsByCategory("HOUSE");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        disable={this.state.isRetirementExists}
        onSelectCard={this.onSelectRetirementCard.bind(this)}
      />
    );
  }

  renderHealthCareView() {
    const category = getCardDetailsByCategory("HLTHC");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        disable={this.state.isHealthCareExists}
        onSelectCard={this.onSelectHealthCare.bind(this)}
      />
    );
  }

  renderGenerationalWealthTransferView() {
    const category = getCardDetailsByCategory("GENWL");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        onSelectCard={this.onSelectWealthTransfer.bind(this)}
      />
    );
  }

  renderKidsEducation() {
    const category = getCardDetailsByCategory("KGEDU");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        onSelectCard={this.onSelectEducation.bind(this)}
      />
    );
  }

  renderVacationView() {
    const category = getCardDetailsByCategory("VACCN");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        onSelectCard={this.onSelectVacation.bind(this)}
      />
    );
  }

  renderPurchaseVehicleView() {
    const category = getCardDetailsByCategory("VEHCL");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        onSelectCard={this.onSelectPurchaseVehicle.bind(this)}
      />
    );
  }

  renderRealEstateView() {
    const category = getCardDetailsByCategory("REALE");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        onSelectCard={this.onSelectRealEstate.bind(this)}
      />
    );
  }

  renderWeddingView() {
    const category = getCardDetailsByCategory("KGWED");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        onSelectCard={this.onSelectWedding.bind(this)}
      />
    );
  }

  renderEmisPremiumView() {
    const category = getCardDetailsByCategory("EMIPR");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        onSelectCard={this.onSelectEMIPremium.bind(this)}
      />
    );
  }

  renderCustomGoalView() {
    const category = getCardDetailsByCategory("CUSTG");
    return (
      <GoalsCategoryCard
        category={category}
        showAmount={false}
        showPriority={false}
        onSelectCard={this.onSelectCustomGoal.bind(this)}
      />
    );
  }

  renderCards() {
    return (
      <div className="gl_cards">
        {this.renderRetirementView()}
        {this.renderHealthCareView()}
        {this.renderGenerationalWealthTransferView()}
        {this.renderKidsEducation()}
        {this.renderVacationView()}
        {this.renderPurchaseVehicleView()}
        {this.renderRealEstateView()}
        {this.renderWeddingView()}
        {this.renderEmisPremiumView()}
        {this.renderCustomGoalView()}
      </div>
    );
  }

  renderExpensesModal() {
    return (
      <RetirementModal
        showModal={this.state.showRetirementModal}
        onClickCancel={this.onClickCancelModal.bind(this)}
        onPressNextButton={this.onPressNextButtonInModal.bind(this)}
      />
    );
  }

  renderContentView() {
    return (
      <div className="gl_content_view">
        {this.renderTitleView()}
        {/* <div className="gl_separatorStyle" /> */}
        {this.renderCardsView()}
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  render() {
    return (
      <div className="gl_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.state.showRetirementModal && this.renderExpensesModal()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default GoalsLandingPage;
