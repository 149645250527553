import { apiCall } from "./apiservice";
import Methods from "./methods.json";


class SupportApi {

  static createSupportQuery(data) {
    return new Promise((resolve, reject) => {
      apiCall(`Support/`, Methods.POST, data).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
  }

   static getMonthEndingComplaints() {
    return new Promise((resolve, reject) => {      
      apiCall(`Support/monthEndingComplaints/`, Methods.GET)
        .then(response => {          
          resolve(response);        
        })
        .catch(error => {
          reject(error); 
        });
    });
  }

  static getMonthlyDisposalTrend(){
    return new Promise((resolve, reject) => {     
      apiCall(`Support/monthlyDisposalTrend/`, Methods.GET)
        .then(response => {          
          resolve(response);           
        })
        .catch(error => {
          reject(error); 
        });
    });
  }

  static getYearlyDisposalTrend(){
    return new Promise((resolve, reject) => {     
      apiCall(`Support/yearlyDisposalTrend/`, Methods.GET)
        .then(response => {          
          resolve(response);      
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export default SupportApi;