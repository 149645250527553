import React, { Component } from "react";
import './artantiqueshome.css'
import { t } from "i18next";
import ArtAntHome from "../../../assets/svgs/physicalasset/art_ant_home.svg";
import {
  BreadCrumb,
  Button,
  TitleView, SideModel
} from '../../components';
import * as Bl from "../../../businesslogic";
import {
  FIXED_ASSETS_TYPE,
} from "../../constants";
import { getUserId } from "../../common";
import { getInvestmentPhysicalPath } from "../../common";

const newFixedAssets = {
  fixedassettype: FIXED_ASSETS_TYPE.ART,
  currentvalue: 0,
  fixedassetdescription: "",
  assetinflation: 0,
  targetliquidationyear: 0,
  userid: "",
  expectedliquidationvalue: 0,
  isrented: false,
  assetdetails: [],
  fixedassetuuid: getUserId(),
  isliquidated: false,
  fvatretirement: 0,
  targetliquidationage: 0,
  isincludefinancialplan: false
};
class ArtAntiquesHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newFixedAssetsData: new Bl.FixedAsset({...newFixedAssets, userid: getUserId()}),
      fixedAssetData: [],
    }
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddValues() {
    this.props.navigate('/artantiquesdetail', {
      state: { fixedAssetsData: this.state.newFixedAssetsData, isEditView: false },
    });
  }



  renderNavView() {
    const items = getInvestmentPhysicalPath("ArtAntiques");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsPA3");
    return <TitleView title={title} />;
  }

  renderProfileView() {
    return (
      <div className="aah_profile_view">

        <img src={ArtAntHome} alt={t("networthAssetsPA3")} className="aah_icon" />
        <div className="aah_add_property">
          <Button
            buttonName={t("artAntButton")}
            onPressButton={this.onPressAddValues.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    return (
      <div className='aah_content_view'>
        {this.renderTitleView()}
        {this.renderProfileView()}
      </div>
    )
  }

  render() {
    return (
      <div className="aah_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default ArtAntiquesHome;
