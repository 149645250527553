import React from "react";
import "./insurancedetails.css";
import { t } from "i18next";
import {
  Header,
  TitleView,
  MyDivider,
  Button,
  FloatingLabelInput,
  BreadCrumb,
  Slider,
  ProgressDialog,
  Toast,
} from "../../components";
import { calculate_age, getIncomePathName, getUserDOB } from "../../common";
import { getCurrentYear } from "../../utils/datetime";
import * as Bl from "../../../businesslogic";
import * as logger from "../../utils/logger/index";
import { DEFAULT_OFFSET } from "../../constants";
import { getCurrentAge } from "../../utils/common";


class InsuranceDetails extends React.Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const incomeData = params?.incomeData ? params.incomeData : "";
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      incomeData: incomeData ? new Bl.Income(incomeData) : {},
      isEditView,
      currentAge: 0,
    };
    this.isRenderingAlready = false;
    this.dbData = incomeData
      ? JSON.parse(JSON.stringify(new Bl.Income(incomeData)))
      : {};
  }
  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getCurrentAge();
    }
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("Pension/AnnuityPage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  onSelectItem(path) {
    const localData = new Bl.Income(this.state.incomeData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }
  onChangeDescription(value) {
    const income = this.state.incomeData;
    income.setIncomeDescription(value);
    this.forceUpdate();
  }

  onChangeMaturityAmount(value) {
    const income = this.state.incomeData;
    income.setIncomeAmount(value || value === 0 ? Number(value) : 0);
    this.forceUpdate();
  }

  onPressSaveButton() {
    if (this.state.isEditView) {
      this.updateUserIncome();
    } else {
      this.createUserIncome();
    }
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const startAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const income = this.state.incomeData;
    income.setIncomeStartYear(newValue ? Number(newValue) : newValue);
    income.setIncomeStartAge(startAge)
    this.forceUpdate();
  }

  createUserIncome() {
    const incomeObj = new Bl.Income(this.state.incomeData);
    const currentYear = getCurrentYear();
    const incomeData = this.state.incomeData;
    const startValue = incomeData?.incomestartyear
      ? incomeData.incomestartyear
      : currentYear + 1;
    const startAge = calculate_age(startValue, currentYear, this.state.currentAge)
    if (incomeObj.incomestartyear === 0) {
      incomeObj.setIncomeStartYear(getCurrentYear() + 1);
      incomeObj.setIncomeStartAge(startAge)
    }
    const isDataValid = this.checkIsDataValid(incomeObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        incomeObj
          .createIncome()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/insurancedashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserIncome() {
    const incomeObj = new Bl.Income(this.state.incomeData);
    const isDataValid = this.checkIsDataValid(incomeObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        incomeObj
          .updateIncome()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/insurancedashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!data.incomedescription) {
      isValid = false;
      message = t("insToastDescription");
    } else if (!this.isAmountValid(data.incomeamount)) {
      isValid = false;
      message = t("insToastAmount");
    }
    return { isValid, message };
  }
  isAmountValid(amount) {
    return amount && amount > 0;
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }
  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }


  renderNavView() {
    const items = getIncomePathName("Insurance");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.incomeData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("insTitle");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = []
  ) {
    return (
      <div className="ins_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
        />
      </div>
    );
  }

  renderDescriptionInput() {
    const incomeData = this.state.incomeData;
    const description = incomeData?.incomedescription
      ? incomeData.incomedescription
      : "";
    return (
      <div id="ins_top_desc_input">
        {this.renderInputView(
          t("insInputdescription"),
          description,
          this.onChangeDescription.bind(this)
        )}
      </div>
    );
  }

  renderExpectedMaturity() {
    const incomeData = this.state.incomeData;
    const incomeamount = incomeData?.incomeamount || incomeData.incomeamount === 0
      ? incomeData.incomeamount
      : "";
    return (
      <div id="ins_top_desc_input">
        {this.renderInputView(
          t("insInputIncome"),
          incomeamount,
          this.onChangeMaturityAmount.bind(this),
          true
        )}
      </div>
    );
  }

  renderStartYear() {
    const currentYear = getCurrentYear();
    const incomeData = this.state.incomeData;
    const startValue = incomeData?.incomestartyear
      ? incomeData.incomestartyear
      : currentYear + 1;
    const age = calculate_age(startValue, currentYear, this.state.currentAge);
    return (
      <div className="ins_start_year_row">
        <label className="ins_start_text">{t("insSliderStart")}</label>
        <div className="ins_slider_content">
          <Slider
            value={startValue}
            age={age}
            min={currentYear + 1}
            max={currentYear + 51}
            markStep={10}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderMiddleContent() {
    const income = this.state.incomeData;
    return (
      <>
        <div id="ins_middle_content">{income && this.renderStartYear()}</div>
      </>
    );
  }

  renderTopContent() {
    return (
      <div id="ins_top_container">
        {this.renderDescriptionInput()}
        {this.renderExpectedMaturity()}
      </div>
    );
  }

  renderButton() {
    return (
      <div id="ins_bottom_view">
        <div id="ins_button_view">
          <Button
            buttonName={t("insInputButton1")}
            onPressButton={this.onPressSaveButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div id="ins_container">
        {this.renderNavView()}
        <div id="ins_inner_container">
          {this.renderTitleView()}
          {this.renderTopContent()}
          <MyDivider />
          {this.renderMiddleContent()}
        </div>
        {this.renderButton()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}
export default InsuranceDetails;
