import React from "react";
import RRScoreNeedle from "./rrscoreneedle";
import { ReactComponent as Gauge } from "../../../../../assets/svgs/websvgs/scoreValue.svg";
import "./rrscoredialler.css";

class RRScoreDialler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={"rrs_viewStyle"}>
        <svg
          width="211"
          height="166"
          viewBox="0 0 211 166"
          fill="none"
          className={"rrs_diallerViewStyle"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <RRScoreNeedle
            size={200}
            currentValue={this.props.value}
            needleSharp={true}
            needleColor={this.props.needleColor}
            progressFontSize={12}
            needleBaseSize={9.5}
            needleBaseColor={this.props.needleBaseColor}
            needleWidth={8}
          />
        </svg>
        <div className="rrs_valueViewStyle">
          <Gauge />
        </div>
      </div>
    );
  }
}

export default RRScoreDialler;
