import React, { Component } from "react";
import "./realestatedetail.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Slider,
  TitleView,
  ToggleSwitch,
  CostItemPerYear,
  RecordModal,
  SideModel,
  Toast,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getInvestmentPhysicalPath, getUserDOB, getUserId } from "../../common";
import { calculate_age } from "../../common";
import { getCurrentYear } from "../../utils/datetime";
import * as logger from "../../utils/logger/index";
import { getCommonConfigInfo, getCurrentAge } from "../../utils/common";
import { DEFAULT_OFFSET } from "../../constants";
class RealEstateDetail extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const fixedAssetsData = params?.fixedAssetsData
      ? params.fixedAssetsData
      : {};
    const isEditView = params?.isEditView ? params.isEditView : false;

    this.state = {
      showModal: false,
      inputValue: "",
      editingItem: null,
      viewExpense: false,
      selectedOptions: [],
      isViewSaleValueClicked: false,
      currentAge: 0,
      fixedAssetsData: fixedAssetsData ? new Bl.FixedAsset(fixedAssetsData) : 0,
      initialPropertyType: fixedAssetsData?.assetdetails[0]?.propertytype
        ? fixedAssetsData.assetdetails[0].propertytype
        : "Home",
      propertyType: fixedAssetsData?.assetdetails[0]?.propertytype
        ? fixedAssetsData.assetdetails[0].propertytype
        : "Home",
      isEditView,
    };
    this.isRenderingAlready = false;
    this.costPerYearViewRef = React.createRef();
    this.dbData = fixedAssetsData
      ? JSON.parse(JSON.stringify(new Bl.FixedAsset(fixedAssetsData)))
      : {};
  }

  onSelectItem(path) {
    const localData = new Bl.FixedAsset(this.state.fixedAssetsData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      !this.state.isEditView && this.getConfigInfo();
      this.getCurrentAge();
    }
  }
  getConfigInfo() {
    this.setState({ showProgress: true }, () => {
      getCommonConfigInfo()
        .then((configList) => {
          const configInfo = configList[0];
          const fixedAssetData = this.state.fixedAssetsData;
          fixedAssetData.setAssetInflation((configInfo.realestateror * 100).toFixed(2));
          this.setState({
            fixedAssetsData: fixedAssetData,
            showProgress: false,
          });
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("RealestateDetailsPage.js/getCurrentAge Error:", error);
        });
    });
  }
  checkIsDataValid(data, isView = false) {
    let isValid = true;
    const fixedAssetsDetails = data?.assetdetails[0]
      ? data.assetdetails[0]
      : "";

    let message = "";
    if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("realestateToastCurrentBalance");
    } else if (!this.isPropertyType(fixedAssetsDetails.propertytype)) {
      isValid = true;
      message = t("realestateToastPropertyType");
    }
    return { isValid, message };
  }

  isStateValueChanged() {
    const {
      initialPropertyType,
      propertyType,
    } = this.state;
    return initialPropertyType !== propertyType;
  }

  isAmountValid(amount) {
    return amount && amount > 0;
  }
  isPropertyType(type) {
    return type;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  prepareAssetData() {
    const assetDetails = [
      {
        propertytype: this.state.propertyType,
      },
    ];
    return { assetDetails };
  }
  async createUserFixedAssets(data) {
    const currentYear = getCurrentYear();
    const fixedAssetData = this.state.fixedAssetsData;
    const startValue = fixedAssetData?.targetliquidationyear
      ? fixedAssetData.targetliquidationyear
      : currentYear + 1;
    const targetLiquidationAge = calculate_age(startValue, currentYear, this.state.currentAge)
    const fixedAssetsObj = new Bl.FixedAsset(this.state.fixedAssetsData);

    if (fixedAssetsObj.targetliquidationyear === 0) {
      fixedAssetsObj.setTargetLiquidationYear(getCurrentYear() + 1);
      fixedAssetsObj.setTargetLiquidationAge(targetLiquidationAge)
    }
    fixedAssetsObj.setAssetDetails(data.assetDetails);
    //initially asset inflation multiplied by 100 , so at time of store divide by 100
    fixedAssetsObj.assetinflation /= 100;
    const userId = await getUserId();
    fixedAssetsObj.setFixedAssetUuid(userId);
    const isDataValid = this.checkIsDataValid(fixedAssetsObj);
    console.log({ fixedAssetsObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        fixedAssetsObj
          .createFixedAssets()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/realestatedashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserFixedAssets(data) {
    const fixedAssetsObj = new Bl.FixedAsset(this.state.fixedAssetsData);
    fixedAssetsObj.setAssetDetails(data.assetDetails);
    //initially asset inflation multiplied by 100 , so at time of store divide by 100
    fixedAssetsObj.assetinflation /= 100;
    const isDataValid = this.checkIsDataValid(fixedAssetsObj);
    console.log({ fixedAssetsObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        fixedAssetsObj
          .updateFixedAssets()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/realestatedashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressSaveButton() {
    const data = this.prepareAssetData();
    if (this.state.isEditView) {
      this.updateUserFixedAssets(data);
    } else {
      this.createUserFixedAssets(data);
    }
  }

  onChangeDescription(value) {
    const fixedAssets = this.state.fixedAssetsData;
    fixedAssets.setFixedAssetDescription(value);
    this.forceUpdate();
  }

  onChangePropertyType(value) {
    this.setState({ propertyType: value, viewExpense: false });
  }

  onChangepropertyValue(value) {
    const fixedAssets = this.state.fixedAssetsData;
    fixedAssets.setCurrentValue(value ? Number(value) : 0);
    this.setState({ viewExpense: false });
  }

  onChangeInflationValue(value) {
    const fixedAssets = this.state.fixedAssetsData;
    fixedAssets.setAssetInflation(value);
    this.setState({ viewExpense: false });
  }

  onPressViewExpense() {
    const fixedObj = new Bl.FixedAsset(this.state.fixedAssetsData);
    const fixedAsset = this.state.fixedAssetsData;
    const period = fixedAsset?.targetliquidationyear
      ? fixedAsset?.targetliquidationyear - getCurrentYear()
      : 1;
    const isDataValid = this.checkIsDataValid(fixedObj, true);
    const data = {
      intial_investment: fixedAsset?.currentvalue,
      interest_rate: fixedAsset?.assetinflation / 100,
      investment_period: period,
      growth_rate: 0,
    };
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        Bl.Common.getFutureValue(data)
          .then((result) => {
            fixedAsset.setExpectedLiquidationValue(result.futureValue);
            this.setState({
              viewExpense: true,
              isViewSaleValueClicked: true,
              showProgress: false,
            }, () => {
              this.costPerYearViewRef.current.scrollIntoView({ behavior: "smooth" });
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentAge = getCurrentYear();
    const targetLiquidationAge = calculate_age(newValue, currentAge, this.state.currentAge);
    const fixedAsset = this.state.fixedAssetsData;
    fixedAsset.setTargetLiquidationYear(newValue);
    fixedAsset.setTargetLiquidationAge(targetLiquidationAge);
    this.setState({ viewExpense: false });
    this.forceUpdate();
  }

  handleEdit = (item) => {
    this.setState({
      showModal: true,
      inputValue: item.cost,
      editingItem: item,
    });
  };

  handleInputChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      inputValue: "",
      editingItem: null,
    });
  };

  handleSaveModal = () => {
    const { inputValue, editingItem } = this.state;
    // Update the cost value of the editingItem with the new input value
    editingItem.cost = inputValue;
    // Close the modal and update the state with the new cost value
    const fixedAssetsData = this.state.fixedAssetsData;
    fixedAssetsData.setExpectedLiquidationValue(Number(inputValue));
    this.setState({ showModal: false });
  };

  toggleSellValue = (selectedItem, checked) => {
    const fixedAsset = this.state.fixedAssetsData;
    fixedAsset.setIsLiquidated(checked);
    this.setState({ viewExpense: false });
  };

  toggleRented = (selectedItem, checked) => {
    const fixedAsset = this.state.fixedAssetsData;
    fixedAsset.setIsRented(checked);
    this.setState({ viewExpense: false });
  };

  toggleSellValueFuture = (selectedItem, checked) => {
    const fixedAsset = this.state.fixedAssetsData;
    fixedAsset.setIsIncludeFinancialPlan(checked);
    this.setState({ viewExpense: false });
  };

  renderNavView() {
    const items = getInvestmentPhysicalPath("RealEstate");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        isStateValueChanged={this.isStateValueChanged()}
        dbData={this.dbData}
        localData={this.state.fixedAssetsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsPA1");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = [],
    className = ""
  ) {
    return (
      <div className="resd_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
          className={className}
        />
      </div>
    );
  }

  renderToggleView(label, checked, onChange) {
    return (
      <div className="resd_toggle_view">
        <label className="resd_toggle_text">{label}</label>
        <ToggleSwitch
          checkedChildren={t("realEstateToggle1")}
          unCheckedChildren={t("realEstateToggle2")}
          onChangeToggle={onChange}
          defaultChecked={checked}
        />
      </div>
    );
  }

  renderRealEstateDetails() {
    const fixedAssetsData = this.state.fixedAssetsData;
    const balance = fixedAssetsData?.currentvalue
      ? fixedAssetsData?.currentvalue
      : "";
    const inflation = fixedAssetsData?.assetinflation;
    const description = fixedAssetsData?.fixedassetdescription;
    const isRented = fixedAssetsData?.isrented;
    const isSellValue = fixedAssetsData?.isliquidated;
    return (
      <div className="resd_description_view">
        <div className="resd_input_row">
          {this.renderInputView(
            t("realEstateInputDescription"),
            description,
            this.onChangeDescription.bind(this)
          )}
          {this.renderInputView(
            t("realEstateInputPropertyType"),
            this.state.propertyType,
            this.onChangePropertyType.bind(this),
            true,
            false,
            true,
            ["Home", "Land", "House", "Appartment", "Commercial Property"],
            "ActiveInputBox"
          )}
        </div>
        <div className="resd_sec_row">
          {/* {this.renderToggleView(
            t("realEstateTg1Text"),
            isRented,
            this.toggleRented.bind(this)
          )} */}
          {this.renderInputView(
            t("realEstateInputPropertyValue"),
            balance,
            this.onChangepropertyValue.bind(this),
            true
          )}
          {this.renderInputView(
            t("realEstateInputInflationValue"),
            inflation,
            this.onChangeInflationValue.bind(this),
            false,
            true
          )}
        </div>
        <div className="res_third_row">
          {this.renderToggleView(
            t("realEstateTg2Text"),
            isSellValue,
            this.toggleSellValue.bind(this)
          )}
        </div>
      </div>
    );
  }

  renderYearView() {
    const fixedAssetsData = this.state.fixedAssetsData;
    const currentYear = getCurrentYear();
    const targetliquidationyear =
      fixedAssetsData && fixedAssetsData.targetliquidationyear
        ? fixedAssetsData.targetliquidationyear
        : getCurrentYear() + 1;
    const age = calculate_age(targetliquidationyear, currentYear, this.state.currentAge);
    return (
      <div className="resd_slider_view">
        <div className="resd_start_year_row">
          <label className="resd_start_text">{t("realEstateSlider")}</label>
          <div className="resd_slider_content">
            <Slider
              value={targetliquidationyear}
              age={age}
              min={getCurrentYear() + 1}
              max={getCurrentYear() + 51}
              markStep={10}
              handleChange={this.handleChangeStart.bind(this)}
              showAge={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCostItemPerYear() {
    const futureValue = this.state.fixedAssetsData?.expectedliquidationvalue;
    return (
      <div className="resd_cost_item_view" ref={this.costPerYearViewRef}>
        <CostItemPerYear
          item={{
            label: t("realEstateCostYear"),
            cost: futureValue,
          }}
          showEditIcon={true}
          handleEdit={this.handleEdit.bind(this)}
        />
      </div>
    );
  }

  renderEditModal() {
    const { showModal, inputValue } = this.state;
    const title = t("realEstateCostYear");
    return (
      <RecordModal
        open={showModal}
        onClose={this.handleCloseModal.bind(this)}
        title={title}
        buttonNames={{ one: t("realEstateModalButton") }}
        onPressYes={this.handleSaveModal.bind(this)}
        showInput={true}
        value={inputValue}
        label={t("realEstateValue")}
        showRupeeSymbol={true}
        onInputChange={this.handleInputChange.bind(this)}
      />
    );
  }

  renderButtonsView() {
    const fixedAssetData = this.state.fixedAssetsData;
    const includeSellValue = fixedAssetData?.isliquidated;
    return (
      <div className="resd_bottomView">
        <div className="resd_buttonView">
          <div className="resd_sale_button">
            <Button
              buttonName={t("realEstateButton1")}
              emptyButton={true}
              disabled={!includeSellValue}
              onPressButton={this.onPressViewExpense.bind(this)}
            />
          </div>
          <div className="resd_save">
            <Button
              buttonName={t("realEstateButton2")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }

  renderContentView() {
    const fixedAssetData = this.state.fixedAssetsData;
    const includeSellValue = fixedAssetData?.isliquidated;
    return (
      <div className="resd_content_view">
        {this.renderTitleView()}
        {this.renderRealEstateDetails()}
        {includeSellValue && (
          <>
            <div className="resd_seperatorStyle" />
            {this.renderYearView()}
            {this.state.viewExpense && this.renderCostItemPerYear()}
            <div className="resd_seperatorStyle" />
            <div className="resd_retirement_toggle">
              {this.renderToggleView(
                t("realEstateTg3Text"),
                fixedAssetData?.isincludefinancialplan,
                this.toggleSellValueFuture.bind(this)
              )}
            </div>
            {this.renderEditModal()}
          </>
        )}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="resd_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default RealEstateDetail;
