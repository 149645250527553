import React from "react";
import './footer.css';

class Footer extends React.Component {

    
    render() {
        return (
            <div className="f_container">
                <label className="f_labelStyle">{'Copyright @ 2023 - Fliber. All Rights Reserved.'}</label>
            </div>
        );
    }
}

export default Footer;