import React, { Component } from "react";
import "./banksavingshome.css";
import { t } from "i18next";
import BankSavings from "../../../assets/svgs/fasset/banksavings_home.svg"
import { BreadCrumb, Button, TitleView, SideModel } from "../../components";
import * as Bl from "../../../businesslogic";
import {
  INVESTMENT_CATEGORY,
} from "../../constants";
import { getFinancialNonMarketPath, getUserId } from "../../common";

const newInvestment = {
  investmentcategory: INVESTMENT_CATEGORY.BANK_SAVINGS,
  currentvalue:0,
  annualcontribution:0,
  maturityyear:0,
  maturityvalue:0,
  expectedror:0,
  userid: "",
  contributiongrowthrate: 0,
  investementtype:"YEAR",
  investmentdetails:[],
  investmentdescription: "",
  fvatretirement : 0,
  maturityage: 0,
  userage:0,
  isincludefinancialplan: false
};

class BankSavingsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newInvestmentData: new Bl.Investment({...newInvestment, userid: getUserId()}),
      investmentData: []
    };
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddSavings() {
    this.props.navigate('/banksavingsdetail',{
      state: { investmentData: this.state.newInvestmentData, isEditView: false },
    });
  }


  onPressAddOtherLoan() {
      this.props.navigate('/otherloandetail');
    }

  

  renderNavView() {
    const items = getFinancialNonMarketPath("BankSaving");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFNM1");
    return <TitleView title={title} />;
  }

  renderProfileView() {
    return (
      <div className="bsh_profile_view">
        <img src={BankSavings} alt={t("networthAssetsFNM1")} className="bsh_icon" />
        <div className="bsh_add_property">
          <Button
            buttonName={t("bsButton")}
            onPressButton={this.onPressAddSavings.bind(this)}
          />
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    return (
      <div className="bsh_content_view">
        {this.renderTitleView()}
        {this.renderProfileView()}
      </div>
    );
  }

  render() {
    return (
      <div className="bsh_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default BankSavingsHome;
