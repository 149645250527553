import React from "react";
import './carouselView.css';
import { Carousel } from 'rsuite';
import Ellipse from "../../assets/svgs/ellipse.svg"
import Intro_1 from "../../assets/svgs/intro_1.svg";
import Intro_2 from "../../assets/svgs/intro_2.svg";
import Intro_3 from "../../assets/svgs/intro_3.svg";

class CarouselView extends React.Component {


    renderFirstItem() {
        return (
            <div className="c_contentView">
                <img src={Intro_1} alt={Ellipse} className={"c_itemImgStyle"} />
                <div className="c_labelView">
                    <label className="c_labelStyle">{'Plan Now and Enjoy Later '}</label>
                </div>
            </div>
        );
    }

    renderSecondItem() {
        return (
            <div className="c_contentView">
                <img src={Intro_2} alt={Ellipse} className={"c_itemImgStyle"} />
                <div className="c_labelView">
                    <label className="c_labelStyle">{'Peace in Retirement Life'}</label>
                </div>
            </div>
        );
    }

    renderThirdItem() {
        return (
            <div className="c_contentView">
                <img src={Intro_3} alt={Ellipse} className={"c_itemImgStyle"} />
                <div className="c_labelView">
                    <label className="c_labelStyle">{'Roadtrip at 60! Possible!'}</label>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="c_container">
                <Carousel
                    shape={"bar"}
                    autoplay={true}
                    className="c_carouselView"
                >
                    {this.renderFirstItem()}
                    {this.renderSecondItem()}
                    {this.renderThirdItem()}
                </Carousel>
            </div>
        );
    }
}

export default CarouselView;