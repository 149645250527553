import { useState } from 'react';
import { t } from 'i18next';
import { Modal } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import './index.css';
import {
  Button,
  FloatingLabelInput,
  ProgressDialog,
  Toast
} from '../../../components';
import Close from '../../../../assets/svgs/websvgs/close.svg';
import Success from "../../../../assets/svgs/successmodal.svg";
import { SUPPORT_TYPE } from '../../../constants';
import * as Bl from '../../../../businesslogic';

const EnquiryModal = (props) => {
  const [state, setState] = useState({
    mobileNumber: '',
    emailId: '',
    query: '',
    showToast: false,
    toastMessage: '',
    showProgress: false,
    progressMessage: '',
    showResponseView: false
  });

  const onClickClose = () => {
    if (props.hideModal) {
      props.hideModal()
    }
  }

  const onChangeMobileNumber = (value) => {
    setState((prevState) => ({
      ...prevState,
      mobileNumber: value
    }))
  }

  const onChangeEmailId = (value) => {
    const mailId = value.toLowerCase();
    setState((prevState) => ({
      ...prevState,
      emailId: mailId
    }))
  }

  const onChangeQuery = (event) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      query: value
    }))
  }

  const checkIsDataValid = () => {
    let isValid = true;
    let toastMessage = '';

    if (state.mobileNumber?.length === 0 && state.emailId?.length === 0) {
      isValid = false;
      toastMessage = 'Please enter either Mobile Number or Email Id';
    } else if (state.query?.length === 0) {
      isValid = false;
      toastMessage = 'Please enter your Query';
    }

    return { isValid, toastMessage };
  }

  const onPressSend = () => {
    const isDataValid = checkIsDataValid();

    if (isDataValid.isValid) {
      const data = {
        "mobile": state.mobileNumber,
        "email": state.emailId,
        "query": state.query,
        "pan": "",
        "supporttype": SUPPORT_TYPE.CUSTOMER,
        "status": "Progress"
      }

      setState((prevState) => ({
        ...prevState,
        showProgress: true
      }))

      Bl.Support.createSupportQuery(data).then(response => {
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          mobileNumber: '',
          emailId: '',
          query: '',
          showResponseView: true
        }))
      }).catch(error => {
        console.log('EnquiryModal/ onPressSend', error)
        showToast(error.message)
      })
    } else {
      showToast(isDataValid.toastMessage)
    }
  }

  const showToast = (toastMessage) => {
    console.log('showToast called')
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const renderInputView = (
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    type
  ) => {
    return (
      <div className="em_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          type={type}
        />
      </div>
    );
  };

  const renderTextAreaView = () => {
    return (
      <div className='em_textAreaView'>
        <label className='em_textLabelStyle'>{t('Write us your query')}</label>
        <textarea
          value={state.query}
          onChange={onChangeQuery}
          className='em_textAreaStyle'
        />
      </div>
    );
  }

  const renderHeaderView = (label) => {
    return (
      <div className='em_headerView'>
        <label className='em_headingStyle'>{label}</label>
        <img className='em_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderInputsView = () => {
    return (
      <div className='em_inputsContentView'>
        <label className='em_labelStyle'>{t('Our friendly team would love to hear from you!')}</label>
        <div className='em_rowStyle'>
          {renderInputView(t('Mobile Number'), state.mobileNumber, onChangeMobileNumber, false, false, 'number')}
          {renderInputView(t('Email ID'), state.emailId, onChangeEmailId, false, false)}
        </div>
        {renderTextAreaView()}
        <label className='em_subLabelStyle'>{t('contact us info')}</label>
      </div>
    );
  }

  const renderButtonView = () => {
    return (
      <div className='em_buttonView'>
        <Button buttonName={t('Send')} onPressButton={onPressSend} />
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  const renderFormContent = () => {
    return (
      <div className={'em_formContent'}>
        {renderHeaderView(t('Customer Enquiries'))}
        {renderInputsView()}
        {renderButtonView()}
      </div>
    )
  }

  const renderResponseView = () => {
    return (
      <div className={"em_responseContentView"}>
        {renderHeaderView(t('Customer Enquiries'))}
        <div className={"em_responseView"}>
          <img src={Success} alt={"success"} className={"em_successStyle"} />
          <label className={"em_responseStyle"}>{t("Thank you for submitting your quiries, An our advisor from Fliber will get back to you soon.")}</label>
        </div>
      </div>
    );
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.hideModal}
      className={'em_container'}
    >
      <div className='em_content'>
        {state.showResponseView ? renderResponseView() : renderFormContent()}
        {state.showToast && renderToastView()}
        {state.showProgress && renderProgressDialog()}
      </div>
    </Modal>
  );
}

export default EnquiryModal;