import React from "react";
import './index.css';
import Quote from '../../../assets/svgs/websvgs/quote.svg';
import { ScrollMenu} from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

const feedbacks = [
  {
    name: "Vishal Gaur",
    company: "IT Director in a MNC",
    feedback: "I am writing here to pay my gratitude to Vikram and Maninder for the splendid work they have been doing in managing our assets.Both me and my wife are working and it becomes very difficult to manage our assets portfolio.It was a boon for both of us when Vikram connected with us and talked about his company Lookinglaz Technologies LLP, we were relieved that someone can continuously work towards growing our money"
  },
  {
    name: "Roby John",
    company: "Senior Leader in a Global financial firm",
    feedback: "Exemplary Customer Service,  Patient to answer queries ranging from novice to expert investors, Highly Transparent.  Pleasure to be a part of their journey even as they help chalk out my financial one"
  },
  
  {
    name: "Sreekanth Kodumur",
    company: "Senior Leader in Altimetrik",
    feedback: "Thanks to Fliber's guidance and expertise, I now have a clear financial path to follow, and I can confidently say that I am much more relieved about my financial future. I wholeheartedly recommend Fliber to anyone seeking a financial planner who goes above and beyond to ensure their clients' financial success. Working with Fliber has been a transformative and beautiful experience, and I couldn't be happier with the results"
  }
]

const Testimonials = () => {

  const renderItemDisplayView = (item, index) => {
    const names = item?.name?.split(" ");
    const nameText = names[0].charAt(0) + names[1].charAt(0);
    return (
      <div key={index} className="te_itemDisplayView">
        <div className="te_feedbackView">
          <img src={Quote} alt={"quotes"} className="te_quoteStyle"></img>
          <label className="te_itemStyle">{item.feedback}</label>
        </div>
        <div className="te_nameView">
          {/* <label className="te_avatarStyle">{nameText}</label> */}
          <label className="te_nameStyle">{item.name}</label>
          <label className="te_companyStyle">{item.company}</label>
        </div>
      </div>
    );
  }


  return (
    <div className="te_container">
      <ScrollMenu>
        {feedbacks.map((feedback, index) => (
          renderItemDisplayView(feedback, index)
        ))}
      </ScrollMenu>
    </div>
  );
}


export default Testimonials;