import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import EditIcon from "../../assets/svgs/ic_edit.svg";
import Review from "../../assets/svgs/review.svg";
import { Popover, Whisper } from "rsuite";
import ActionIcon from "../../assets/svgs/actionicon.svg"
import './customermgmt.css';
import { t } from 'i18next';
import { Input } from 'rsuite';
import { SearchOutlined } from '@material-ui/icons';
import UnlockRiskProfile from "../../assets/svgs/unlockrp.svg";
import InitiateRisk from "../../assets/svgs/initiaterisk.svg";
import ResetPassword from "../../assets/svgs/resetpassword.svg";
import CustomerCredentials from "../../assets/svgs/customerCredentials.svg";
import { AiOutlineClose as Close } from "react-icons/ai";
import { AlertNotify, ProgressDialog, RecordModal, Toast, ToggleSwitch } from '../components';
import * as Bl from "../../businesslogic";
import * as logger from "../utils/logger/index";
import { RESET_PIN_URL, RISK_PROFILE_URL, SECURITY_LEVEL, USER_ROLE, USER_STATUSTYPE } from '../constants';
import {
    getRiskProfileDescription,
    getRoleLabel,
    getSelectedUserData,
    getUserDetails,
    isAdminOnly,
    isAdminRAdvisor,
    resetSelectedUserDetail,
    resetUserCashLadderDetail,
    setSelectedUserDetail
} from '../common';
import RoleSelectorModal from './roleselectormodal';

const CustomerMgmt = (props) => {
    const [state, setState] = useState({
        showToast: false,
        toastMessage: "",
        showProgress: false,
        progressMessage: "",
        showModal: false,
        showConfirmationModal: false,
        modalMessage: "",
        selectedItem: "",
        selectedRows: [],
        rowData: [],
        userData: [],
        showRoleSelectorModal: false,
        selectedUserType: USER_ROLE.CUSTOMER
    });
    const [searchQuery, setSearchQuery] = useState('');
    let editViewRef;
    const isRenderedAlready = useRef(false);
    const gridRef = useRef(null);

    useEffect(() => {
        if (!isRenderedAlready.current) {
            isRenderedAlready.current = true;
            //When admin came out from the user screens,remove selectedUserDetail from localstorage
            if (getSelectedUserData()?.id) {
                resetSelectedUserDetail();
                resetUserCashLadderDetail();
            }

            getUserList();
        }
    }, []);

    const getUserList = () => {
        setState((prevState) => ({
            ...prevState,
            showProgress: true,
        }));
        Bl.User.getUserDetails()
            .then((userlist) => {
                const filteredUserData = filteredUserDataByRole(userlist, state.selectedUserType);
                const sortedData = filteredUserData?.sort((a, b) => a.firstname.localeCompare(b.firstname));
                setState((prevState) => ({
                    ...prevState,
                    showProgress: false,
                    userData: userlist,
                    rowData: sortedData
                }));
            })
            .catch((error) => {
                logger.error({ error });
                showToast(t("Some error has occured"));
            });
    };

    const filteredUserDataByRole = (userList, userType) => {
        let filteredUserData = [];
        const loggedInUser = getUserDetails();
        if (loggedInUser?.securitylevel === SECURITY_LEVEL.ADMIN) {
            if (userType === USER_ROLE.CUSTOMER) {
                filteredUserData = userList.filter(user => user?.role === USER_ROLE.CUSTOMER);
            } else if (userType === USER_ROLE.USER) {
                filteredUserData = userList.filter(user => user?.role !== USER_ROLE.CUSTOMER);
            }
        } else if (loggedInUser?.role === USER_ROLE.ADVISOR) {
            if (userType === USER_ROLE.CUSTOMER) {
                filteredUserData = userList.filter(user => user?.role === USER_ROLE.CUSTOMER && user?.advisorid === loggedInUser?.id);
            } else if (userType === USER_ROLE.USER) {
                filteredUserData = userList.filter(user => user?.role !== USER_ROLE.CUSTOMER && user?.advisorid === loggedInUser?.id);
            }
        } else {
            AlertNotify.info('Admins only have the permission to access this page')
        }

        return filteredUserData;
    }

    const handleSearchQueryChange = (value) => {
        setSearchQuery(value);
    };

    const onPressAddIcon = () => {
        setState((prevState) => ({
            ...prevState,
            showRoleSelectorModal: true
        }))
    }

    const onPressEditIcon = (params) => {
        if (params.colDef.headerName === 'Edit') {
            const id = params.data.id;
            props.navigate('/customerprofile', { state: { userId: id, isEditable: true } })
        }
    }

    const onClickUserId = (params) => {
        const selectedUserData = params.data;
        setSelectedUserDetail(selectedUserData);
        props.navigate('/financial')
    }

    const showConfirmationModal = (message) => {
        setState((prevState) => ({
            ...prevState,
            showProgress: true,
            showConfirmationModal: true,
            modalMessage: message,
        }));
    };

    const onHideModal = () => {
        setState((prevState) => ({
            ...prevState,
            showProgress: false,
            showConfirmationModal: false
        }));
    };

    const hideRoleSelectorModal = () => {
        setState((prevState) => ({
            ...prevState,
            showRoleSelectorModal: false
        }));
    }

    const handlePressNo = () => {
        onHideModal()
    };

    const handlePressYes = (userId) => {
        onHideModal();
        const data = { userId: state.selectedItem }
        setState((prevState) => ({
            ...prevState,
            showProgress: true,
        }));
        Bl.User.updateUserStatus(data)
            .then((userStatus) => {
                const statusInfo = userStatus[0]
                const filteredData = state.rowData.filter((e) => e.id === statusInfo.UserID)
                filteredData[0].status = statusInfo.Status;
                setState((prevState) => ({
                    ...prevState,
                    showProgress: false,
                }));
                showToast(t("Updated the status of user"));
            })
            .catch((error) => {
                logger.error({ error });
                showToast(t("Some error has occured"));
            });
    };

    const handleToggleRoleChange = (selectedItem) => {
        const role = getRoleLabel(selectedItem)
        const filteredUserData = filteredUserDataByRole(state?.userData, role);
        const sortedData = filteredUserData?.sort((a, b) => a.firstname.localeCompare(b.firstname));
        setState((prevState) => ({
            ...prevState,
            selectedUserType: role,
            rowData: sortedData
        }));
    }

    const onSelectRole = (role) => {
        hideRoleSelectorModal();
        const loggedInUser = getUserDetails();
        if (role === USER_ROLE.CUSTOMER) {
            props.navigate('/customerprofile', {
                state: {
                    role: role,
                    securitylevel: SECURITY_LEVEL.RESTRICTED,
                    loggedInUser: loggedInUser
                }
            })
        } else if (role === USER_ROLE.USER) {
            if (loggedInUser?.securitylevel === SECURITY_LEVEL.ADMIN && loggedInUser?.role !== USER_ROLE.ADVISOR) {
                props.navigate('/customerprofile', {
                    state: {
                        role: USER_ROLE.ADVISOR,
                        securitylevel: SECURITY_LEVEL.GENERAL,
                        loggedInUser: loggedInUser
                    }
                })
            } else {
                AlertNotify.info('Admin only have the permission to create the users')
            }
        }
    }

    const onClickUserStatusLink = (userId) => {
        setState((prevState) => ({
            ...prevState,
            selectedItem: userId,
        }));
        showConfirmationModal(
            t(
                "BreadCrumb: Are you sure you want to change the status?"
            ))
    }

    const filterData = (rowData, query) => {
        return rowData.filter((row) => {
            return Object.values(row).some((value) => {
                return String(value).toLowerCase().includes(query.toLowerCase());
            });
        });
    };
    const filterRowData = () => {
        if (searchQuery) {
            return filterData(state.rowData, searchQuery);
        }
        else {
            return state.rowData.filter((row) => {
                return (
                    row.firstname.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    row.emailid.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    row.customer_type.toString().includes(searchQuery) ||
                    row.pannumber.toString().includes(searchQuery) ||
                    row.risk_profile.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    row.portfolio_balance.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
                    row.nrd.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
                    row.status.toString().includes(searchQuery)
                );
            });
        };
    }
    const columnDefs = [
        {
            headerName: '',
            field: 'checkbox',
            checkboxSelection: true,
            width: 50,
        },
        {
            headerName: 'Name', field: 'firstname', minWidth: 180, cellRenderer: (params) => (
                <div onClick={() => onClickUserId(params)}>
                    <label className={"cm_statusLink"}>{params.value}</label>
                </div>
            ), filter: 'agSetColumnFilter', resizable: true, headerTooltip: 'Customer Name'
        },
        {
            headerName: 'Email Id',
            field: 'emailid',
            minWidth: 230,
            cellRenderer: (params) => (
                <div>
                    <label>{params.value}</label>
                </div>
            ),
            filter: 'agSetColumnFilter',
            resizable: true,
            headerTooltip: 'Customer Email Id'
        },
        { headerName: 'Type', field: 'customer_type', minWidth: 150, filter: 'agSetColumnFilter', resizable: true, headerTooltip: 'Customer Type' },
        { headerName: 'PAN Number', field: 'pannumber', minWidth: 150, filter: 'agNumberColumnFilter', width: 150, resizable: true, headerTooltip: 'PAN Number' },
        // {
        //     headerName: 'Risk Profile', field: "risk_profile", minWidth: 150, filter: 'agTextColumnFilter', resizable: true, headerTooltip: 'Risk Profile',
        //     cellRenderer: (params) => {
        //         const riskProfile = getRiskProfileDescription(params.data.risk_profile)
        //         return (
        //             <div>
        //                 <label > {riskProfile}</label>
        //             </div>
        //         );
        //     }
        // },
        // { headerName: 'Portfolio Balance', field: 'portfolio_balance', minWidth: 150, filter: 'agSetColumnFilter', cellStyle: { textAlign: 'center' }, resizable: true, headerTooltip: 'Portfolio Balance' },
        { headerName: 'NRD', field: 'nrd', filter: 'agSetColumnFilter', minWidth: 130, resizable: true, headerTooltip: 'NRD' },
        { headerName: 'Advisor Name', field: 'advisorname', filter: 'agSetColumnFilter', minWidth: 110, resizable: true, headerTooltip: 'Advisor Name' },
        {
            headerName: 'Actions', field: 'status', minWidth: 150, filter: 'agSetColumnFilter', resizable: true, headerTooltip: 'Status',
            cellRenderer: (params) => {
                const showMandatory = params?.data?.kycstatus?.includes("NOT");
                const status = params.data.status;
                let label = "";
                if (status === USER_STATUSTYPE.ACTIVATE) {
                    label = "Deactivate";
                }
                else if (status === USER_STATUSTYPE.PROSTATE) {
                    label = "Activate"
                }
                else if (status === USER_STATUSTYPE.DEACTIVATE) {
                    label = "Activate"
                }
                return (
                    <div onClick={() => onClickUserStatusLink(params.data.id)}>
                        <label className={"cm_statusLink"}>  {label}</label>
                        {showMandatory && <label className={"cm_statusLabelMan"} title={t("KYCnotverified")}>*</label>}
                    </div>
                );
            }
        },
        {
            headerName: 'Edit',
            cellRenderer: (params) =>
                <img src={EditIcon} alt={"Edit"} title={"Edit"} onClick={() => onPressEditIcon(params)} className={"cm_editIcon"}></img>,
        },
    ]

    const hidePopOver = () => {
        if (editViewRef) {
            editViewRef.close();
        }
    }

    const onPressClose = () => {
        hidePopOver();
    }
    const onClickUnlockRiskAssessment = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 0) {
            showToast("Please select at least one row to unlock initiate risk.");
            return;
        }

        else if (selectedRows?.length > 0) {
            const updatePromises = [];
            const date = new Date();
            const updatedDate = date ? `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}` : '';
            // add promise for each item
            selectedRows.forEach((user) => {
                const data = {
                    "id": user.id,
                    "is_risk_profile_completed": false,
                    "risk_profile_updated_date": updatedDate
                }
                updatePromises.push(Bl.RiskProfile.createRiskProfileStatusInfo(data));
            });
            setState((prevState) => ({
                ...prevState,
                showProgress: true,
            }))
            Promise.allSettled(updatePromises)
                .then((result) => {
                    //Promise.allSettled returns all item status, if all success(fulfilled), do next action
                    //if any one get reject display the toast and stay in the same page
                    const rejectedApis = result.filter((e) => e.status === "rejected");
                    const resolvedApis = result.filter((e) => e.status === "fulfilled");
                    setState((prevState) => ({
                        ...prevState,
                        showProgress: false,
                    }))
                    //TO DO need to show rejected APIs in toast
                    if (rejectedApis?.length > 0) {
                        AlertNotify.error(`Unable to unlock the risk assessment `);
                    }

                    if (resolvedApis?.length > 0) {
                        getUserList()
                        AlertNotify.success("Unlocked the risk assessment for respective users")
                    }
                })
                .catch((error) => {
                    console.log("onClickRiskAssessment/Promise All settled error:", error);
                    showToast(t("someErrorHasOccured"));
                });
        }
    }
    const handleResetClick = () => {

    }

    const handleInitiateRisk = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 0) {
            showToast("Please select at least one row to initiate risk.");
            return;
        }

        else if (selectedRows?.length > 0) {
            console.log(selectedRows, 'selectedRows')
            const updatePromises = [];
            const lockedIds = [];
            // add promise for each item
            selectedRows.forEach((user) => {
                if (!user?.is_risk_profile_completed) {
                    const data = {
                        emailid: user.emailid,
                        url: RISK_PROFILE_URL
                    };
                    updatePromises.push(Bl.RiskProfile.userInitiateRisk(data));
                } else {
                    lockedIds.push(user.emailid)
                }
            });

            if (lockedIds?.length > 0) {
                let ids = '';
                lockedIds.map((id, index) => {
                    if (ids !== "") {
                        ids = `${ids}, ${id}`;
                    } else {
                        ids = id;
                    }
                })
                showToast(`Risk profile was locked for the following email ids:${ids}`)
            }
            setState((prevState) => ({
                ...prevState,
                showProgress: true,
            }))
            Promise.allSettled(updatePromises)
                .then((result) => {
                    //Promise.allSettled returns all item status, if all success(fulfilled), do next action
                    //if any one get reject display the toast and stay in the same page
                    const rejectedApis = result.filter((e) => e.status === "rejected");
                    const resolvedApis = result.filter((e) => e.status === "fulfilled");
                    setState((prevState) => ({
                        ...prevState,
                        showProgress: false,
                    }))
                    //TO DO need to show rejected APIs in toast
                    if (rejectedApis?.length > 0) {
                        AlertNotify.error(t("someErrorHasOccured"));
                    }

                    if (resolvedApis?.length) {
                        AlertNotify.success("Risk profile questionnaire link send to the clients's registered email")
                    }
                })
                .catch((error) => {
                    console.log("Promise All settled error:", error);
                    showToast(t("someErrorHasOccured"));
                });
        }
    }

    const handleReviewClick = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        if (selectedRows.length === 0) {
            showToast("Please select at least one row to initiate review");
            return;
        }

        else if (selectedRows?.length > 0) {
            const updatePromises = [];
            // add promise for each item
            selectedRows.forEach((user) => {
                const data = {
                    userId: user.id
                }
                updatePromises.push(Bl.CashLadder.initiateReview(data));
            });
            setState((prevState) => ({
                ...prevState,
                showProgress: true,
            }))
            Promise.allSettled(updatePromises)
                .then((result) => {
                    //Promise.allSettled returns all item status, if all success(fulfilled), do next action
                    //if any one get reject display the toast and stay in the same page
                    const rejectedApis = result.filter((e) => e.status === "rejected");
                    const resolvedApis = result.filter((e) => e.status === "fulfilled");

                    const rejectedUrls = rejectedApis.map(item => {
                        let url = new URL(item.reason);
                        let email = url.searchParams.get('email');

                        return email;
                    });

                    if (resolvedApis?.length > 0) {
                        getUserList()
                    }

                    setState((prevState) => ({
                        ...prevState,
                        showProgress: false,
                    }))

                    if (rejectedApis?.length > 0) {
                        AlertNotify.error(`Not able to initiate the review for the mail ids ${rejectedUrls.join()}`);
                    }

                    if (resolvedApis?.length > 0) {
                        AlertNotify.success(t("Review initiated for respective users"))
                    }

                })
                .catch((error) => {
                    console.log("handleReviewClick/Promise All settled error:", error);
                    showToast(t("someErrorHasOccured"));
                });
        }
    }

    const handleSendCustomerCred = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        if (selectedRows.length === 0) {
            showToast("Please select at least one row to send customer credential");
            return;
        }

        else if (selectedRows?.length > 0) {
            const updatePromises = [];
            // add promise for each item
            selectedRows.forEach((user) => {
                const data = {
                    emailId: user.emailid,
                    webUrl: RESET_PIN_URL,
                }
                updatePromises.push(Bl.Common.initiateCustomerCredentials(data));
            });
            setState((prevState) => ({
                ...prevState,
                showProgress: true,
            }))
            Promise.allSettled(updatePromises)
                .then((result) => {
                    //Promise.allSettled returns all item status, if all success(fulfilled), do next action
                    //if any one get reject display the toast and stay in the same page
                    const rejectedApis = result.filter((e) => e.status === "rejected");
                    const resolvedApis = result.filter((e) => e.status === "fulfilled");

                    const rejectedUrls = rejectedApis.map(item => {
                        let url = new URL(item.reason);
                        let email = url.searchParams.get('email');

                        return email;
                    });
                    setState((prevState) => ({
                        ...prevState,
                        showProgress: false,
                    }))

                    if (rejectedApis?.length > 0) {
                        AlertNotify.error(`Credential not sending for the mail ids ${rejectedUrls.join()}`);
                    }

                    if (resolvedApis?.length > 0) {
                        AlertNotify.success(t("Customer Credential sent for respective users"))
                    }

                })
                .catch((error) => {
                    console.log("handleSendCustomerCred/Promise All settled error:", error);
                    showToast(t("someErrorHasOccured"));
                });
        }
    }


    const handleGenerateIpsReport = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        if (selectedRows.length === 0) {
            showToast("Please select at least one row to send IPS report.");
            return;
        }

        else if (selectedRows?.length > 0) {
            const updatePromises = [];
            // add promise for each item
            selectedRows.forEach((user) => {
                const data = {
                    userId: user.id,
                    emailId: user.emailid,
                }
                updatePromises.push(Bl.CashLadder.generateIpsReport(data));
            });
            setState((prevState) => ({
                ...prevState,
                showProgress: true,
            }))
            Promise.allSettled(updatePromises)
                .then((result) => {
                    //Promise.allSettled returns all item status, if all success(fulfilled), do next action
                    //if any one get reject display the toast and stay in the same page
                    const rejectedApis = result.filter((e) => e.status === "rejected");
                    const resolvedApis = result.filter((e) => e.status === "fulfilled");

                    const rejectedUrls = rejectedApis.map(item => {
                        let url = new URL(item.reason);
                        let email = url.searchParams.get('email');

                        return email;
                    });
                    setState((prevState) => ({
                        ...prevState,
                        showProgress: false,
                    }))

                    if (rejectedApis?.length > 0) {
                        AlertNotify.error(`Cashladder acceptance is pending for ${rejectedUrls.join()}`);
                    }

                    if (resolvedApis?.length > 0) {
                        AlertNotify.success(t("IPS Report sent for respective users"))
                    }

                })
                .catch((error) => {
                    console.log("handleGenerateIpsReport/Promise All settled error:", error);
                    showToast(t("someErrorHasOccured"));
                });
        }
    }

    const handleGoalsReport = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        if (selectedRows.length === 0) {
            showToast("Please select at least one row to send Goals report.");
            return;
        }

        else if (selectedRows?.length > 0) {
            const updatePromises = [];
            // add promise for each item
            selectedRows.forEach((user) => {
                const data = {
                    userId: user.id,
                    emailId: user.emailid,
                }
                updatePromises.push(Bl.CashLadder.generateAdvisorReport(data));
            });
            setState((prevState) => ({
                ...prevState,
                showProgress: true,
            }))
            Promise.allSettled(updatePromises)
                .then((result) => {
                    //Promise.allSettled returns all item status, if all success(fulfilled), do next action
                    //if any one get reject display the toast and stay in the same page
                    const rejectedApis = result.filter((e) => e.status === "rejected");
                    const resolvedApis = result.filter((e) => e.status === "fulfilled");

                    const rejectedUrls = rejectedApis.map(item => {
                        let url = new URL(item.reason);
                        let email = url.searchParams.get('email');

                        return email;
                    });
                    setState((prevState) => ({
                        ...prevState,
                        showProgress: false,
                    }))

                    if (rejectedApis?.length > 0) {
                        AlertNotify.error(`Report not sending for the mail ids ${rejectedUrls.join()}`);
                    }

                    if (resolvedApis?.length > 0) {
                        AlertNotify.success(t("Advisor Report sent for respective users"))
                    }

                })
                .catch((error) => {
                    console.log("handleGoalssReport/Promise All settled error:", error);
                    showToast(t("someErrorHasOccured"));
                });
        }
    }

    const hideToast = () => {
        setState((prevState) => ({
            ...prevState,
            toastMessage: "",
            showToast: false,
        }));
    };

    const showToast = (toastMessage) => {
        setState((prevState) => ({
            ...prevState,
            toastMessage,
            showToast: true,
            showProgress: false,
        }));
        setTimeout(() => {
            hideToast();
        }, 3000);
    };

    const renderPopOverView = (index, onClickClose) => {
        return (
            <Popover className="cm_edit_view" onClose={hidePopOver}>
                <div className={"cm_actionClose"}>
                    <label className={"cm_actionHeading"}>{t("customermgmtActions")}</label>
                    <Close className={"cm_actionIconStyle"} onClick={onClickClose} />
                </div>
                <div className={"cm_actionLink"}>
                    <div className={"cm_resetpasslink"} onClick={handleInitiateRisk}>
                        <img src={InitiateRisk} alt={"Initiate risk profile"}></img>
                        <label>{t("customermgmtInitiateRiskAssessment")}</label>
                    </div>
                    <div className={"cm_unlockLink"} onClick={onClickUnlockRiskAssessment}>
                        <img src={UnlockRiskProfile} alt={"Unlock RiskProfile"}></img>
                        <label>{t('customermgmtUnlockRisk')}</label>
                    </div>
                    {/* <div className={"cm_resetpasslink"} onClick={handleResetClick}>
                        <img src={ResetPassword} alt={"reset password"}></img>
                        <label>{t("customermgmtResetPassword")}</label>
                    </div> */}
                    <div className={"cm_resetpasslink"} onClick={handleReviewClick}>
                        <img src={Review} alt={"review"}></img>
                        <label>{t("customermgmtReview")}</label>
                    </div>
                    <div className={"cm_resetpasslink"} onClick={handleSendCustomerCred}>
                        <img src={ResetPassword} alt={"Customer Credentials"}></img>
                        <label>{t("customermgmtsendcustomercredentials")}</label>
                    </div>
                    <div className={"cm_resetpasslink"} onClick={handleGenerateIpsReport}>
                        <img src={CustomerCredentials} alt={"Send IPS Report"}></img>
                        <label>{t("Send IPS Report")}</label>
                    </div>
                    <div className={"cm_resetpasslink"} onClick={handleGoalsReport}>
                        <img src={CustomerCredentials} alt={"Send Goals Report"}></img>
                        <label>{t("Send Goals Report")}</label>
                    </div>
                </div>
            </Popover>
        );
    }

    const renderActionIconView = (index) => {
        return (
            <div className={"cusmgmt_button_view"}>
                <Whisper
                    placement="bottomEnd"
                    speaker={renderPopOverView(index, onPressClose)}
                    trigger="click"
                    ref={(ref) => (editViewRef = ref)}
                >
                    <img
                        title="Actions"
                        src={ActionIcon}
                        alt={"More Icon"}
                        className="cdc_more"
                    />
                </Whisper>
            </div>
        );
    }

    const renderAddButtonView = () => {
        return (
            <div className='cm_addIconView' onClick={onPressAddIcon} >
                <label className="cm_addIcon" title="Add Customer Profile">Add</label>
            </div>
        );
    }

    const renderToggleView = () => {
        return (
            <div className='cm_toggleView'>
                <ToggleSwitch
                    checked={state.selectedUserType === USER_ROLE.CUSTOMER}
                    checkedChildren={t(USER_ROLE.CUSTOMER)}
                    unCheckedChildren={t(USER_ROLE.USER)}
                    onChangeToggle={handleToggleRoleChange}
                />
            </div>
        );
    }

    const renderSearchBar = () => {
        return (
            <div className="cm_searchView">
                <Input
                    prefix={<SearchOutlined />}
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                />
            </div>
        );
    };

    const renderHeaderView = () => {
        const showAddButton = isAdminRAdvisor();
        const showToggleView = isAdminOnly();
        return (
            <div className="cm_titleView">
                <div className="cm_lableView" >{t("customermgmtInformation")}</div>
                <div className='cm_leftContentItems'>
                    {showToggleView && renderToggleView()}
                    {renderSearchBar()}
                    {renderActionIconView()}
                    {showAddButton && renderAddButtonView()}
                </div>
            </div>
        );
    }

    const renderTableView = () => {
        return (
            <div className='cm_tableView'>
                <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={filterRowData()}
                        columnDefs={columnDefs}
                        onGridReady={params => {
                            params.api.sizeColumnsToFit();
                        }}
                        filterParams={{
                            applyButton: true,
                            clearButton: true,
                            suppressFilterButton: true,
                            suppressMiniFilter: true,

                        }}
                        onSelectionChanged={params => {
                            const selectedRows = params.api.getSelectedRows();
                        }}
                        rowSelection='multiple'
                    >
                    </AgGridReact>

                </div>
            </div>
        );
    }

    const renderConfirmationModalView = () => {
        const buttonNames = { no: "No", yes: "Yes" };
        const title = state.modalMessage;
        return (
            <RecordModal
                open={state.showConfirmationModal}
                onClose={onHideModal}
                onPressNo={handlePressNo}
                onPressYes={handlePressYes}
                title={title}
                buttonNames={buttonNames}
            />
        );
    };

    const renderRoleSelectorModal = () => {
        return (
            <RoleSelectorModal
                showModal={state.showRoleSelectorModal}
                hideModal={hideRoleSelectorModal}
                onSelectRole={onSelectRole}
            />
        );
    };

    const renderToastView = () => {
        return (
            <Toast showToast={state.showToast} toastMessage={state.toastMessage} />
        );
    };

    const renderProgressDialog = () => {
        return (
            <ProgressDialog
                showProgress={state.showProgress}
                progressMessage={state.progressMessage}
            />
        );
    };
    return (
        <div className="cm_container">
            {renderHeaderView()}
            {renderTableView()}
            {state.showConfirmationModal && renderConfirmationModalView()}
            {state.showRoleSelectorModal && renderRoleSelectorModal()}
            {renderToastView()}
            {renderProgressDialog()}
        </div>
    );
};

export default CustomerMgmt;


