import React, { Component } from "react";
import "./vacationexpensepage.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  CostPerYearView,
  FloatingLabelInput,
  Slider,
  TitleView,
  Toast,
  ProgressDialog,
  ToggleSwitch,
} from "../components";
import * as Bl from "../../businesslogic";
import { GOAL_CATEGORY, GOAL_FREQUENCY_TYPE, DEFAULT_OFFSET, GOAL_EXPENSE_TYPE } from "../constants";
import {
  calculate_age,
  calculate_year,
  getAdvisorId,
  getGoalsPathName,
  getUserDOB,
  getUserId,
} from "../common";
import { getCurrentYear } from "../utils/datetime";
import { getGoalConfigInfo, getCurrentAge } from "../utils/common";

const newGoal = {
  userid: '',
  goalcategory: GOAL_CATEGORY.VACATION,
  goalstartyear: 0,
  goalendyear: 0,
  goalamountpv: 0,
  goalfrequency: 1,
  advisorid: getAdvisorId(),
  goalfrequencytype: GOAL_FREQUENCY_TYPE.YEARLY,
  goalbucketcode: "",
  retirewanttype: "",
  monthlyexpenselist: [],
  goaldescription: "",
  goalinflationrate: 0,
  goalamountfv: 0,
  homeloanstatus: false,
  downpayment: 0,
  goaldetails: [],
  goalstartage: 0,
  goalendage: 0,
  userage: 0,
  expensetype: GOAL_EXPENSE_TYPE.RECURRING
};
class VacationExpensePage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const isEditView = params?.isEditView ? params.isEditView : false;
    const goalData = params?.goalData;
    this.state = {
      startValue: isEditView ? goalData.goalstartyear : getCurrentYear() + 1,
      endValue: isEditView ? goalData.goalendyear : getCurrentYear() + 2,
      startYear: getCurrentYear() + 1,
      endYear: getCurrentYear() + 2,
      currentAge: 0,
      frequencyValue: isEditView ? goalData.goalfrequency : 1,
      selectedSliderIndex: 0,
      type: isEditView ? goalData.expensetype : GOAL_EXPENSE_TYPE.RECURRING,
      sliderContent: [],
      description: isEditView ? goalData.goaldescription : "",
      cost: isEditView ? goalData.goalamountpv : "",
      inflationRate: isEditView ? goalData.goalinflationrate : 0,
      viewExpense: false,
      showButtons: false,
      isEditView: isEditView ? isEditView : false,
      newGoalData: isEditView ? new Bl.Goals(goalData) : new Bl.Goals({ ...newGoal, userid: getUserId() }),
    };
    this.costPerYearViewRef = React.createRef();
    this.inflationRate = 0;
    this.isRenderingAlready = false;
    this.dbValue = this.state.newGoalData;
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      !this.state.isEditView && this.getInflationRate();
      this.getCurrentAge();
    }
  }

  onSelectItem(path) {
    const localData = new Bl.Goals(this.state.newGoalData)
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeDescriptionValue(value) {
    const goals = this.state.newGoalData;
    goals.setDescription(value);
    this.setState({ description: value });
  }

  onChangeCost(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalAmountPv(value ? Number(value) : 0);
    this.setState({ cost: value, viewExpense: false });
  }

  onChangeInflationRate(value) {
    value = value.trim();
    const goals = this.state.newGoalData;
    goals.setGoalInflationRate(value ? Number(value) : 0);
    this.setState({ inflationRate: value, viewExpense: false });
  }
  isValueValid(value) {
    return value > 0;
  }
  isInflationValid(data) {
    let isValid = true;

    if (!data || data.toString().length <= 0) {
      isValid = false;
    }
    return isValid;
  }

  getInflationRate() {
    //TODO get inflation rate from API and use that
    this.setState({ showProgress: true, fetchingInflationRate: true }, () => {
      getGoalConfigInfo(GOAL_CATEGORY.VACATION)
        .then((configList) => {
          const configInfo = configList;
          const goalsObj = this.state.newGoalData;
          goalsObj.setGoalInflationRate(configInfo.inflationrate);
          this.dbValue.goalinflationrate = configInfo.inflationrate;
          this.inflationRate = configInfo.inflationrate;
          this.setState({
            inflationRate: configInfo.inflationrate,
            showProgress: false,
            fetchingInflationRate: false
          }, () => {
            this.getDBValue()
          });
        })
        .catch((error) => {
          console.log("VacationExpensePage.js/getInflationRate Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true, fetchingCurrentAge: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const goalsObj = this.state.newGoalData;
          goalsObj.setUserAge(currentAge);
          this.userage = currentAge;
          this.dbValue.userage = currentAge;
          this.setState({
            currentAge: currentAge,
            showProgress: false,
            fetchingCurrentAge: false
          }, () => {
            this.getDBValue();
          });
        })
        .catch((error) => {
          console.log("VacationExpensePage.js/getInflationRate Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  getDBValue() {
    if (this.state.fetchingInflationRate === false && this.state.fetchingCurrentAge === false) {
      const dbValue = this.state.newGoalData
        ? JSON.parse(JSON.stringify(this.state.newGoalData))
        : {};
      this.setState({ dbValue })
    }
  }

  isStartAndEndYearValid(goalType, startYear, endYear) {
    if (goalType === GOAL_EXPENSE_TYPE.RECURRING) {
      return startYear !== 0 && startYear < endYear;
    } else {
      return startYear !== 0;
    }
  }
  checkIsDataValid(data, skipDescriptionValidation = false) {
    let isValid = true;
    let message = "";
    const goalType = this.state.type;
    const startYear = data.goalstartyear ? data.goalstartyear : 0;
    const endYear = data.goalendyear ? data.goalendyear : 0;

    if (!skipDescriptionValidation && !data.goaldescription) {
      isValid = false;
      message = t("vacationToastDescription");
    } else if (!this.isStartAndEndYearValid(goalType, startYear, endYear)) {
      isValid = false;
      message = t("vacationToastYear");
    } else if (!this.isValueValid(data.goalamountpv)) {
      isValid = false;
      message = t("vacationToastCost");
    } else if (!this.isInflationValid(data.goalinflationrate)) {
      isValid = false;
      message = t("vacationToastInflationPercentage");
    }
    return { isValid, message };
  }

  checkIsEndYearValid(startYear, endYear) {
    return startYear < endYear;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }
  refreshData() {
    newGoal.goalinflationrate = this.inflationRate;
    newGoal.userage = this.userage;
    this.setState({
      description: "",
      cost: "",
      inflationRate: this.inflationRate,
      startValue: getCurrentYear() + 1,
      endValue: getCurrentYear() + 2,
      type: GOAL_EXPENSE_TYPE.RECURRING,
      viewExpense: false,
      showButtons: false,
      newGoalData: new Bl.Goals({ ...newGoal, userid: getUserId() }),
    });
  }

  navigateToNextScreen() {
    this.props.navigate("/goalscategorize", { state: { path: "Vacation" } });
  }
  createVacationGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
    }
    if (
      goalObj.expensetype === GOAL_EXPENSE_TYPE.RECURRING &&
      goalObj.goalendyear === 0
    ) {
      const endYear = getCurrentYear();
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .createGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("VacationExpensePage/createVacationGoal Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateVacationGoal(callbackfn) {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (
      goalObj.expensetype === GOAL_EXPENSE_TYPE.RECURRING &&
      goalObj.goalendyear === 0
    ) {
      const endYear = getCurrentYear();
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }
    // Removed front & back white spaces in Description
    if (goalObj.goaldescription) {
      const goaldescription = goalObj.goaldescription.trim();
      goalObj.setDescription(goaldescription);
    }
    console.log({ goalObj })
    const isDataValid = this.checkIsDataValid(goalObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true, viewExpense: false }, () => {
        goalObj
          .updateGoal()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              callbackfn();
            });
          })
          .catch((error) => {
            console.log("VacationExpensePage/updateVacationGoal Error:", error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  prepareData(data) {
    const sliderContent = [];
    data.map((item) =>
      sliderContent.push({
        year: item.cashflowyear,
        money: item.flowamount,
      })
    );
    return sliderContent;
  }
  onPressViewExpense() {
    const goalObj = new Bl.Goals(this.state.newGoalData);
    if (goalObj.goalstartyear === 0) {
      const currentYear = getCurrentYear();
      const startAge = calculate_age(this.state.startValue, currentYear, this.state.currentAge)
      goalObj.setStartYear(this.state.startValue);
      goalObj.setGoalStartAge(startAge)
    }
    if (
      goalObj.expensetype === GOAL_EXPENSE_TYPE.RECURRING &&
      goalObj.goalendyear === 0
    ) {
      const endYear = getCurrentYear();
      const endAge = calculate_age(this.state.endValue, endYear, this.state.currentAge)
      goalObj.setEndYear(this.state.endValue);
      goalObj.setGoalEndAge(endAge)
    }

    const isDataValid = this.checkIsDataValid(goalObj, true);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        goalObj
          .viewExpectedAmt()
          .then((data) => {
            const sliderContent = this.prepareData(data.result);
            this.setState({
              sliderContent,
              showProgress: false,
              viewExpense: true,
              showButtons: true
            }, () => {
              this.costPerYearViewRef.current.scrollIntoView({ behavior: "smooth" });
            });
          })
          .catch((error) => {
            console.log("VacationExpensePage/onPressViewExpense Error:", error);
            this.showToast(t('Some error has occurred'));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }
  onPressSaveButton() {
    const { showButtons } = this.state;
    if (!showButtons) {
      return;
    }
    if (!this.state.isEditView) {
      this.createVacationGoal(this.navigateToNextScreen.bind(this));
    }
    else {
      this.updateVacationGoal(this.navigateToNextScreen.bind(this));
    }
  }

  onPressSaveAndAdd() {
    const { showButtons } = this.state;
    if (!showButtons) {
      return;
    }
    this.createVacationGoal(this.refreshData.bind(this));
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const startAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    goals.setStartYear(newValue);
    goals.setGoalStartAge(startAge)
    this.setState({ startValue: newValue, viewExpense: false });
  }

  handleChangeEnd(event) {
    const newValue = event.target.value;
    const currentYear = getCurrentYear();
    const endAge = calculate_age(newValue, currentYear, this.state.currentAge)
    const goals = this.state.newGoalData;
    const isValid = this.checkIsEndYearValid(goals.goalstartyear, newValue);
    if (isValid) {
      goals.setEndYear(newValue);
      goals.setGoalEndAge(endAge);
      this.setState({ endValue: newValue, viewExpense: false });
    } else {
      this.showToast(t("vacationToastYear"));
    }
  }

  handleToggleSwitchChange(selectedItem, e, checked) {
    const goals = this.state.newGoalData;
    const frequencyValue =
      selectedItem === t("vacationToggle2")
        ? GOAL_EXPENSE_TYPE.RECURRING
        : GOAL_EXPENSE_TYPE.ONE_TIME;
    goals.setExpenseType(frequencyValue);
    const endValue =
      selectedItem === t("vacationToggle2")
        ? this.state.endValue
        : getCurrentYear() + 2;
    goals.setEndYear(0);
    this.setState({
      type: frequencyValue,
      endValue,
      viewExpense: false
    });
  }

  handleToggleSwitchVacation(selectedItem) {
    const goals = this.state.newGoalData;
    const frequencyValue = selectedItem === t("vacationToggle3") ? 1 : 2;
    goals.setGoalFrequency(frequencyValue);
    this.setState({ frequencyValue: frequencyValue, viewExpense: false });
  }

  handleSelectSlider(index) {
    this.setState({ selectedSliderIndex: index });
  }
  renderNavView() {
    const items = getGoalsPathName("Vacation");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        dbData={this.dbValue}
        localData={this.state.newGoalData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("vacationTitle");
    return <TitleView title={title} information={""} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="va_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderStartYearSlider() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.startValue, currentYear, this.state.currentAge);
    return (
      <div className="edu_start_year_row">
        <label className="edu_start_text">{t("vacationSliderStart")}</label>
        <div className="edu_slider_content">
          <Slider
            value={this.state.startValue}
            age={age}
            min={currentYear + 1}
            max={currentYear + 31}
            markStep={5}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderEndYearSlider() {
    const currentYear = getCurrentYear();
    const age = calculate_age(this.state.endValue, currentYear, this.state.currentAge);
    return (
      <div className="edu_start_year_row">
        <label className="edu_start_text">{t("vacationSliderEnd")}</label>
        <div className="edu_slider_content">
          <Slider
            value={this.state.endValue}
            age={age}
            min={currentYear + 2}
            max={currentYear + 32}
            markStep={5}
            handleChange={this.handleChangeEnd.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderYearView() {
    return (
      <div className="va_slider_view">
        {this.renderStartYearSlider()}
        {this.state.type === GOAL_EXPENSE_TYPE.RECURRING &&
          this.renderEndYearSlider()}
      </div>
    );
  }

  renderExpenseType() {
    return (
      <div className="va_description_view">
        {this.renderInputView(
          t("vacationInputDescription"),
          this.state.description,
          this.onChangeDescriptionValue.bind(this)
        )}
        <div className="va_expense">
          <label className="va_expense_text">{t("vacationToggle1Type")}</label>
          <div className="va_toggle">
            <ToggleSwitch
              checked={this.state.type === GOAL_EXPENSE_TYPE.ONE_TIME}
              checkedChildren={t("vacationToggle1")}
              unCheckedChildren={t("vacationToggle2")}
              onChangeToggle={this.handleToggleSwitchChange.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderMoneyInflationView() {
    return (
      <div className="va_money_inflation">
        <div className="va_frequency_row">
          <label className="va_expense_frequency_text">
            {t("vacationToggle2Frequency")}
          </label>
          <ToggleSwitch
            checked={this.state.frequencyValue === 1}
            checkedChildren={t("vacationToggle3")}
            unCheckedChildren={t("vacationToggle4")}
            onChangeToggle={this.handleToggleSwitchVacation.bind(this)}
          />
        </div>
        <div className="va_cost_inflation_input">
          {this.renderInputView(
            t("vacationInputCost"),
            this.state.cost,
            this.onChangeCost.bind(this),
            true
          )}
          {this.renderInputView(
            t("vacationInputInflationRate"),
            this.state.inflationRate,
            this.onChangeInflationRate.bind(this),
            false,
            true
          )}
        </div>
      </div>
    );
  }

  renderCostOfVacation() {
    return (
      <div className="va_cost_age_slider" ref={this.costPerYearViewRef}>
        <CostPerYearView
          sliderContent={this.state.sliderContent}
          sliderIndex={this.state.selectedSliderIndex}
          onSelectSlider={(index) => this.handleSelectSlider(index)}
          labelText="Likely Cost Of The Vacation Per Year"
        />
      </div>
    );
  }

  renderButtonsView() {
    const { showButtons } = this.state;
    return (
      <div className="va_bottomView">
        {!this.state.isEditView && <div className="va_buttonView">
          <div>
            <label
              onClick={this.onPressSaveAndAdd.bind(this)}
              className="va_link_text"
              disabled={!showButtons}
              style={{ opacity: showButtons ? 1 : 0.5 }}
            >
              {t("vacationButtonLink")}
            </label>
          </div>
        </div>}
        <div className="va_expense_button">
          <Button
            buttonName={t("vacationButton1")}
            emptyButton={true}
            onPressButton={this.onPressViewExpense.bind(this)}
          />
        </div>
        <div className="va_save">
          <Button
            buttonName={t("vacationButton2")}
            onPressButton={this.onPressSaveButton.bind(this)}
            disabled={!showButtons}
            style={{ opacity: showButtons ? 1 : 0.5 }}
          />
        </div>
      </div>
    );
  }

  renderContentView() {
    return (
      <div className="va_content_view">
        {this.renderTitleView()}
        {this.renderExpenseType()}
        <div className="va_seperatorStyle" />
        {this.renderYearView()}
        <div className="va_seperatorStyle" />
        {this.renderMoneyInflationView()}
        {this.state.viewExpense && this.renderCostOfVacation()}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  // renderDrawerContent() {
  //   const headerTitle = t("financialPlan");
  //   const step = 2;
  //   return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  // }

  render() {
    return (
      <div className="va_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
        {/* {this.renderDrawerContent()} */}
      </div>
    );
  }
}

export default VacationExpensePage;
