import React, { useEffect, useRef, useState } from "react";
import { t } from 'i18next';
import './portfolioruletable.css';
import { getSelectedUserData, resetSelectedUserDetail, resetUserCashLadderDetail } from "../common";
import { Button, ProgressDialog, Toast } from "../components";
import * as Bl from "../../businesslogic";
import RuleAllocationView from "./ruleallocationview";

const PortfolioRuleTable = () => {
  const isRenderedAlready = useRef(false);

  const [state, setState] = useState({
    showProgress: false,
    progressMessage: '',
    showToast: false,
    toastMessage: '',
    rules: [],
    selectedRule: '',
    assetValues: {},
    displayData: [],
    sebiCategories: [],
    disableSubmit: true
  })

  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      //When admin came out from the user screens,remove selectedUserDetail from localstorage
      if (getSelectedUserData()?.id) {
        resetSelectedUserDetail();
        resetUserCashLadderDetail();
      }

      getPortfolioRuleDetails()
      getAllSebiCategories();
    }
  }, []);

  const onSelectRule = (e) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      selectedRule: value
    }))
    getPortfolioAllocationValues(value);
  }

  const isDataValid = () => {
    const data = state.displayData;
    const assetValues = state.assetValues;

    const equityAssetTotal = assetValues.equity;
    const debtAssetTotal = assetValues.debt;
    const othersAssetTotal = assetValues.others;

    const equityData = data[0].equity;
    const debtData = data[1].debt;
    const othersData = data[2].others;

    const equityTotal = equityData?.reduce((total, item) => total + (item.revisedAllocation ? item.revisedAllocation : item.allocationpercentage), 0);
    const debtTotal = debtData?.reduce((total, item) => total + (item.revisedAllocation ? item.revisedAllocation : item.allocationpercentage), 0);
    const othersTotal = othersData?.reduce((total, item) => total + (item.revisedAllocation ? item.revisedAllocation : item.allocationpercentage), 0);

    const isEquityValid = equityAssetTotal !== 0 ? equityData?.length > 0 && equityTotal === 100 ? true : false : true;
    const isDebtValid = debtAssetTotal !== 0 ? debtData?.length > 0 && debtTotal === 100 ? true : false : true;
    const isOthersValid = othersAssetTotal !== 0 ? othersData?.length > 0 && othersTotal === 100 ? true : false : true;

    return isEquityValid && isDebtValid && isOthersValid;
  }


  const onPressSubmitButton = () => {
    const isValid = isDataValid();

    if (isValid) {
      const consolidatedArray = state.displayData.reduce((result, item) => {
        const keys = Object.keys(item);
        if (keys.length > 0) {
          const key = keys[0];
          result = result.concat(item[key]);
        }
        return result;
      }, []);

      const finalData = consolidatedArray.map(item => {
        const { netAllocation, revisedAllocation, assetclass, sebicategorydescription, fiveyearcategoryrate, lastupdate, id, ...rest } = item;
        rest.allocationpercentage = revisedAllocation !== '' ? Number(revisedAllocation) : Number(rest.allocationpercentage);
        return rest;
      });

      const data = {
        "data": finalData
      }

      console.log('finalData:', finalData)

      setState((prevState) => ({
        ...prevState,
        showProgress: true
      }))

      Bl.PortfolioAllocation.createPortfolioAllocationValues(data).then((result) => {
        getPortfolioAllocationValues(state.selectedRule, true);
      }).catch(error => {
        console.log('PortfolioAssetAllocation/onPressSubmitButton:', error.message)
        showToast(error.message)
      })

    } else {
      showToast('Please make sure all the categories have 100% of allocation')
    }
  }

  const onClickDelete = (item, index) => {
    const data = state.displayData;
    let currentData = [];
    switch (item.assetclass) {
      case "EQUTY":
        currentData = data[0].equity;
        break;
      case "DEBT ":
        currentData = data[1].debt;
        break;
      default:
        currentData = data[2].others;
        break;
    }

    const newData = currentData?.filter((el) => el.sebicategory !== item.sebicategory);
    if (item.assetclass === "EQUTY") {
      data[0].equity = newData;
    } else if (item.assetclass === "DEBT ") {
      data[1].debt = newData;
    } else if (item.assetclass !== "EQUTY" && item.assetclass !== "DEBT ") {
      data[2].others = newData;
    }

    setState((prevState) => ({
      ...prevState,
      displayData: data, disableSubmit: true
    }));
  }

  const onPressOk = (item, selectedItem) => {
    const data = state.displayData;
    let currentData = [];
    switch (item.assetclass) {
      case "EQUTY":
        currentData = data[0].equity;
        break;
      case "DEBT ":
        currentData = data[1].debt;
        break;
      default:
        currentData = data[2].others;
        break;
    }

    currentData.push(item);

    if (item.assetclass === "EQUTY") {
      data[0].equity = currentData;
    } else if (item.assetclass === "DEBT ") {
      data[1].debt = currentData;
    } else if (item.assetclass !== "EQUTY" && item.assetclass !== "DEBT ") {
      data[2].others = currentData;
    }

    setState((prevState) => ({
      ...prevState,
      displayData: data, disableSubmit: true
    }));
  }



  const prepareDataByGroups = (data, assetValues) => {
    const equityArray = data?.filter(item => item.assetclass === "EQUTY");
    const debtArray = data?.filter(item => item.assetclass === "DEBT ");
    const othersArray = data?.filter(item => item.assetclass !== "EQUTY" && item.assetclass !== "DEBT ");

    equityArray.map(item => {
      item.netAllocation = Number(item.allocationpercentage * ((assetValues.equity / 100).toFixed(2))).toFixed(2);
      item.revisedAllocation = '';
      return item;
    });

    debtArray.map(item => {
      item.netAllocation = Number(item.allocationpercentage * ((assetValues.debt / 100).toFixed(2))).toFixed(2);
      item.revisedAllocation = '';
      return item;
    });

    othersArray.map(item => {
      item.netAllocation = Number(item.allocationpercentage * ((assetValues.others / 100).toFixed(2))).toFixed(2);
      item.revisedAllocation = '';
      return item;
    });

    const newData = [
      {
        equity: equityArray
      },
      {
        debt: debtArray
      },
      {
        others: othersArray
      }
    ]
    console.log({ newData })
    return newData;
  }

  const getPortfolioRuleDetails = () => {
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }))
    Bl.PortfolioAllocation.getRuleDetails().then((result) => {
      const sortedData = result.sort((a, b) => a.ruleno?.localeCompare(b.ruleno));
      setState((prevState) => ({
        ...prevState,
        showProgress: false,
        rules: sortedData
      }))
    }).catch(error => {
      console.log('PortfolioRuleTable/getRuleDetails error:', error);
      showToast(error.message)
    })
  }

  const getAllSebiCategories = () => {
    Bl.AccountPortfolioRule.getAllSebiCategoryValues()
      .then(categoryList => {
        const sortedData = categoryList.sort((a, b) => a.mstarcategory?.localeCompare(b.mstarcategory));
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          sebiCategories: sortedData
        }))
      }).catch(error => {
        console.log('portfolioruletable/getAllSebiCategories:', error)
      })
  }

  const getPortfolioAllocationValues = (selectedRule, showSuccessMsg = false) => {
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }))

    const data = {
      ruleNo: selectedRule
    }

    Bl.PortfolioAllocation.getPortfolioAllocationValues(data).then(result => {
      console.log({ result })
      const assetAllocValues = result.assetAllocation;
      const assetValues = {
        equity: 0,
        debt: 0,
        others: 0
      };

      assetAllocValues.forEach(item => {
        if (item.assetclass === "EQUTY") {
          assetValues.equity = item.allocationpercentage;
        } else if (item.assetclass === "DEBT") {
          assetValues.debt = item.allocationpercentage;
        } else if (item.assetclass === "COMDT") {
          assetValues.others = item.allocationpercentage;
        }
      });

      const portfolioValues = prepareDataByGroups(result.Portfolioassetallocationmaster, assetValues);

      setState((prevState) => ({
        ...prevState,
        showProgress: false,
        assetValues: assetValues,
        displayData: portfolioValues
      }))
      if (showSuccessMsg) {
        showToast(" Portfolio Rule data updated")
      }
    }).catch(error => {
      console.log('PortfolioRuleTable/getPortfolioAllocationValues error:', error);
      showToast(error.message)
    })
  }

  const showToast = (toastMessage) => {
    console.log('showToast called')
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }



  const renderSelectionView = () => {
    return (
      <div className="prt_selectionView">
        <label className="prt_selectLabelStyle">{t('Portfolio Rule')}</label>
        <select
          className="prt_categorySelect"
          value={state?.selectedRule}
          onChange={onSelectRule}
        >
          <option value="" disabled selected hidden>{t('Select Portfolio Rule')}</option>
          {state.rules?.map((category, index) => (
            <option key={index} value={category.ruleno}>
              {category.ruleno}
            </option>
          ))}
        </select>
      </div>
    );
  }

  const renderTitleView = () => {
    return (
      <div className="prt_titleView">
        <div className="prt_titleStyle" >{t("portfoliorule")}</div>
        {renderSelectionView()}
      </div>
    )
  }

  const renderContentView = () => {
    return (
      <RuleAllocationView
        data={state.displayData}
        assetValues={state.assetValues}
        sebiCategories={state.sebiCategories}
        ruleNo={state.selectedRule}
        onClickDelete={onClickDelete}
        onPressOk={onPressOk}
      />
    );
  }

  const renderButtonsView = () => {
    return (
      <div className="prt_bottomView">
        <div className="prt_buttonView">
          <Button
            buttonName={t("Submit")}
            onPressButton={onPressSubmitButton}
          />
        </div>
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  return (
    <div className="prt_container">
      <div className="prt_content">
        {renderTitleView()}
        {renderContentView()}
      </div>
      {renderButtonsView()}
      {state.showToast && renderToastView()}
      {state.showProgress && renderProgressDialog()}
    </div>
  );
};


export default PortfolioRuleTable;










