import React, { Component } from "react";
import "./jewellerydetail.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Header,
  TitleView,
  SideModel,
  Toast,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getInvestmentPhysicalPath, getUserId } from "../../common";
import * as logger from "../../utils/logger/index";

class JewelleryDetail extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const fixedAssetsData = params?.fixedAssetsData
      ? params.fixedAssetsData
      : {};
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      fixedAssetsData: fixedAssetsData ? new Bl.FixedAsset(fixedAssetsData) : 0,
      isEditView,
    };
    this.dbData = fixedAssetsData
      ? JSON.parse(JSON.stringify(new Bl.FixedAsset(fixedAssetsData)))
      : {};
  }

  onSelectItem(path) {
    const localData = new Bl.FixedAsset(this.state.fixedAssetsData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeDescription(value) {
    const fixedAssets = this.state.fixedAssetsData;
    fixedAssets.setFixedAssetDescription(value);
    this.forceUpdate();
  }

  onChangeCurrentValue(value) {
    const fixedAssets = this.state.fixedAssetsData;
    fixedAssets.setCurrentValue(value ? Number(value) : 0);
    this.forceUpdate();
  }

  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!this.isDescValid(data.fixedassetdescription)) {
      isValid = false;
      message = t("jewelleryToastDesc");
    } else if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("jewelleryToastCurrentBalance");
    }
    return { isValid, message };
  }

  isDescValid(desc) {
    return desc && desc.length > 0;
  }

  isAmountValid(amount) {
    return amount && amount > 0;
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }
  async createUserFixedAssets(data) {
    const fixedAssetsObj = new Bl.FixedAsset(this.state.fixedAssetsData);

    const userId = await getUserId();
    fixedAssetsObj.setFixedAssetUuid(userId);

    const isDataValid = this.checkIsDataValid(fixedAssetsObj);
    console.log({ fixedAssetsObj })
    if (isDataValid.isValid) {
      logger.debug("object creation", fixedAssetsObj);
      this.setState({ showProgress: true }, () => {
        fixedAssetsObj
          .createFixedAssets()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/jewellerydashboard");
            });
          })
          .catch((error) => {
            logger.error(error);
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserFixedAssets(data) {
    const fixedAssetsObj = new Bl.FixedAsset(this.state.fixedAssetsData);
    const isDataValid = this.checkIsDataValid(fixedAssetsObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        fixedAssetsObj
          .updateFixedAssets()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/jewellerydashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressSaveButton() {
    if (this.state.isEditView) {
      this.updateUserFixedAssets();
    } else {
      this.createUserFixedAssets();
    }
  }



  renderNavView() {
    const items = getInvestmentPhysicalPath("Jewellery");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.fixedAssetsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsPA2");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = []
  ) {
    return (
      <div className="jwd_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
        />
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="jwd_bottomView">
        <div className="jwd_buttonView">
          <div className="jwd_save">
            <Button
              buttonName={t("jewellButton1")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderJewelleryDetails() {
    const fixedAssetsData = this.state.fixedAssetsData;
    const balance = fixedAssetsData?.currentvalue ? fixedAssetsData?.currentvalue : "";
    const description = fixedAssetsData?.fixedassetdescription;
    return (
      <div className="jwd_inputbox_view">
        {this.renderInputView(
          t("jewellInputDescription"),
          description,
          this.onChangeDescription.bind(this)
        )}
        {this.renderInputView(
          t("jewellInputValue"),
          balance,
          this.onChangeCurrentValue.bind(this),
          true
        )}
      </div>
    );
  }

  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    return (
      <div className="jwd_content_view">
        {this.renderTitleView()}
        {this.renderJewelleryDetails()}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="jwd_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default JewelleryDetail;
