import React from "react";
import './optimizedatatableview.css';
import CashLadderDetailsTable from "./cashladderdetailstable";
import { Modal } from "rsuite";
import Close from '../../../assets/svgs/ic_close.svg';
import { t } from "i18next";
import { Button } from "../../components";

const goalData = [
  {
    goaldesc: 'Custom goal',
    goalstatus: 'not met',
    goalvalue: '10000',
    optimizedvalue: '8000'
  },
  {
    goaldesc: 'Health care',
    goalstatus: 'not met',
    goalvalue: '15000',
    optimizedvalue: '12000'
  },
  {
    goaldesc: 'Health goal',
    goalstatus: 'Optimized',
    goalvalue: '10000',
    optimizedvalue: '8000'
  }
]
const OptimizeDataTableView = (props) => {

  const onClickClose = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  const onPressAccept = () => {
    if (props.onPressAccept) {
      props.onPressAccept()
    }
  }

  const onPressReject = () => {
    if (props.onPressReject) {
      props.onPressReject()
    }
  }

  const reportColumnData = [
    {
      header: "Goal Description",
      dataKey: "goaldesc",
      width: 350,
      resizable: true,
    },
    {
      header: "Goal Status (Optimized / Not met)",
      dataKey: "goalstatus",
      width: 250,
      resizable: true,
    },
    {
      header: "Original Goal Value",
      dataKey: "goalvalue",
      width: 200,
      resizable: true,
    },
    {
      header: "Optimized Goal Value",
      dataKey: "optimizedvalue",
      width: 200,
      resizable: true,
    }
  ];

  const renderTableTitle = () => {
    return (
      <div className="odv_titleView">
        <label className="odv_titleStyle">{t('Goal Optimization Report')}</label>
        <img className='em_closeIconStyle' src={Close} alt={'close'} onClick={onClickClose}></img>
      </div>
    );
  }

  const renderTableView = () => {
    return (
      <CashLadderDetailsTable
        data={goalData}
        tableHeight={400}
        columns={reportColumnData}
      />
    );
  };

  const renderButtonView = () => {
    return (
      <div className="odv_buttonsView">
        <div className="odv_buttonView">
          <Button
            buttonName={t('Accept Optimization')}
            emptyButton={true}
            onPressButton={onPressAccept}
          />
        </div>
        <div className="odv_buttonView">
          <Button
            buttonName={t('Reject Optimization')}
            onPressButton={onPressReject}
          />
        </div>
      </div>
    );
  }

  return (
    <Modal
      open={props.showModal}
      onClose={props.onClose}
      className={"odv_container"}
    >
      <div className="odv_content">
        {renderTableTitle()}
        <div className="odv_tableContent">
          {renderTableView()}
        </div>
        {renderButtonView()}
      </div>
    </Modal>
  );
}

export default OptimizeDataTableView;