import React from "react";
import BaseScrn from '../basescrn';
import { t } from "i18next";
import { Header, SignInForm } from "../components";

class RegisterScrn extends BaseScrn {
    constructor(props) {
        super(props);
        console.log('registerscrn - ', props)
        this.state = {

        }
    }

    renderHeaderView() {
        return <Header title={t("fliber")} />;
    }

    renderFormContentView() {
        return (
            <div className="bs_formContentView">
                <SignInForm
                    navigate={this.props.navigate}
                />
            </div>
        );
    }
}

export default RegisterScrn;