import React, { Component } from "react";
import "./ppfdetailpage.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  TitleView,
  SideModel,
  Toast,
  ProgressDialog,
  ToggleSwitch,
  Slider,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { calculate_age, getFinancialNonMarketPath, getUserDetails } from "../../common";
import * as logger from "../../utils/logger/index";
import { getCurrentYear } from "../../utils/datetime";

class PPFDetailPage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const investmentData = params?.investmentData ? params.investmentData : "";
    const isEditView = params?.isEditView ? params.isEditView : false;
    this.state = {
      investmentsData: investmentData ? new Bl.Investment(investmentData) : {},
      isEditView,
      lifeexpectancy: 0
    };
    this.isRenderingAlready = false;
    this.dbData = investmentData
      ? JSON.parse(JSON.stringify(new Bl.Investment(investmentData)))
      : {};
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getUserData();
    }
  }

  getUserData() {
    const userDetails = getUserDetails();
    this.setState({ lifeexpectancy: userDetails.lifeexpectancy })
  }

  onSelectItem(path) {
    const localData = new Bl.Investment(this.state.investmentsData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeBalanceValue(value) {
    const investment = this.state.investmentsData;
    investment.setCurrentValue(value ? Number(value) : 0);
    this.forceUpdate();
  }

  onChangeContributionValue(value) {
    const investment = this.state.investmentsData;
    investment.setAnnualContribution(value ? Number(value) : 0);
    this.forceUpdate();
  }

  handleToggleSwitchChange(selectedItem, isSelected) {
    const investment = this.state.investmentsData;
    investment.setIsIncludeFinancialPlan(isSelected);
    this.forceUpdate();
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const investment = this.state.investmentsData;
    const currentYear = getCurrentYear();
    const maturityAge = calculate_age(newValue, currentYear, investment.userage)
    investment.setMaturityYear(newValue ? Number(newValue) : newValue);
    investment.setMaturityAge(maturityAge);
    this.forceUpdate();
  }

  checkIsDataValid(data) {
    let isValid = true;
    let message = "";
    if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("ppfToastBalance");
    } else if (!this.isAnualContribution(data.annualcontribution)) {
      isValid = false;
      message = t("ppfToastAnnualContribution");
    }
    return { isValid, message };
  }

  isAmountValid(amount) {
    return amount && amount > 0;
  }

  isAnualContribution(annualcontribution) {
    return annualcontribution && annualcontribution > 0;
  }

  createUserInvestment() {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    const isDataValid = this.checkIsDataValid(investmentObj);
    console.log({ investmentObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .createInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/ppfdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserInvestment() {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    const isDataValid = this.checkIsDataValid(investmentObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .updateInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/ppfdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  preparePPFDetails() {
    const fields = {};
    const ppfToggleDetails = [];
    const ppfToggleDetail = {};
    let oneFieldAvail = false;
    Object.keys(fields).forEach((key) => {
      if (fields[key] !== "") {
        oneFieldAvail = true;
        ppfToggleDetail[key] = fields[key];
      }
    });
    if (oneFieldAvail) {
      ppfToggleDetails.push(ppfToggleDetail);
    }
    return { ppfToggleDetails };
  }

  onPressSaveButton() {
    if (this.state.isEditView) {
      this.updateUserInvestment();
    } else {
      this.createUserInvestment();
    }
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderToggleView(label, checked, onChange) {
    return (
      <div className="pdp_toggle_view">
        <label className="pdp_toggle_text">{label}</label>
        <ToggleSwitch
          checkedChildren={t("fdToggle1")}
          unCheckedChildren={t("fdToggle2")}
          onChangeToggle={this.handleToggleSwitchChange.bind(this)}
          defaultChecked={checked}
        />
      </div>
    );
  }

  renderNavView() {
    const items = getFinancialNonMarketPath("PPF");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        dbData={this.dbData}
        localData={this.state.investmentsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFNM5");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = true,
    showPercentage = false
  ) {
    return (
      <div className="pdp_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderPPFDetails() {
    const investmentData = this.state.investmentsData;
    const balance = investmentData?.currentvalue
      ? investmentData?.currentvalue
      : "";
    const contribution = investmentData?.annualcontribution
      ? investmentData?.annualcontribution
      : "";
    return (
      <div className="pdp_inputbox_view">
        {this.renderInputView(
          t("ppfInputBalance"),
          balance,
          this.onChangeBalanceValue.bind(this)
        )}
        {this.renderInputView(
          t("ppfInputContribution"),
          contribution,
          this.onChangeContributionValue.bind(this)
        )}
      </div>
    );
  }

  renderSliderView() {
    const investmentData = this.state.investmentsData;
    const currentYear = getCurrentYear();
    const maturityyear =
      investmentData && investmentData.maturityyear
        ? investmentData.maturityyear
        : getCurrentYear() + 1;
    const age = calculate_age(maturityyear, currentYear, investmentData.userage);
    const noOfYears = this.state.lifeexpectancy - investmentData.userage;
    const maxYear = noOfYears % 2 === 0 ? getCurrentYear() + noOfYears : getCurrentYear() + (noOfYears + 1);
    return (
      <div className="pdp_sliderNLabelView">
        <label className="pdp_labelStyle">{t('PPF contribution till age')}</label>
        <div className="pdp_sliderView">
          <Slider
            value={maturityyear}
            age={age}
            min={getCurrentYear() + 1}
            max={maxYear}
            markStep={10}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderButtonsView() {
    return (
      <div className="pdp_bottomView">
        <div className="pdp_buttonView">
          <div className="pdp_save">
            <Button
              buttonName={t("ppfButton1")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderContentView() {
    const investment = this.state.investmentsData;
    return (
      <div className="pdp_content_view">
        {this.renderTitleView()}
        <div className="pdp_detail_view">
          <label className="pdp_detail_text">{t("ppfSubTitleText")}</label>
          {this.renderPPFDetails()}
          {this.renderSliderView()}
          <div className="pdp_seperatorStyle" />
          <div className="pdp_retirement_toggle">
            {this.renderToggleView(t("fdTg1Text"), investment?.isincludefinancialplan)}
          </div>
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }

  render() {
    return (
      <div className="pdp_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default PPFDetailPage;
