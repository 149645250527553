import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import EditIcon from "../../assets/svgs/ic_edit.svg";
import TickIcon from "../../assets/svgs/ic_tick.svg";
import CloseIcon from "../../assets/svgs/ic_close.svg";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import AddRowModal from "../components/addrowmodal";
import { Input } from 'rsuite';
import { SearchOutlined } from '@material-ui/icons';
import { t } from 'i18next';
import "./assetallocationruletable.css"
import { getSelectedUserData, resetSelectedUserDetail, resetUserCashLadderDetail } from "../common";
import { Button, ProgressDialog, RecordModal, Toast } from "../components";
import * as Bl from "../../businesslogic";

const AssetAllocationRuleTable = (props) => {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const gridRef = useRef(null)
  const isRenderedAlready = useRef(false);

  const [state, setState] = useState({
    isEditableView: false,
    showModal: false,
    rowData: [],
    showNewRow: false,
    gridColumnApi: null,
    editedRowData: null,
    gridApi: null,
    showConfirmationModal: false,
    showProgress: false,
    progressMessage: '',
    showToast: false,
    toastMessage: '',
    modifiedData: []
  })

  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      //When admin came out from the user screens,remove selectedUserDetail from localstorage
      if (getSelectedUserData()?.id) {
        resetSelectedUserDetail();
        resetUserCashLadderDetail();
      }

      getAssetAllocationValues();
    }
  }, []);

  const columnDefs = [
    { headerName: 'Rule No', field: 'ruleno', editable: false },
    { headerName: 'Asset Category', field: 'assetclass', editable: false },
    { headerName: 'Bucket Type', field: 'buckettype', editable: false },
    { headerName: 'Allocation Percentage %', field: 'allocationpercentage', editable: true },
    {

      headerName: "Action",
      maxWidth: 100,
      cellRenderer: (params) => renderActionColumnView(params),
      editable: false,
      colId: "action"
    },
  ];

  const onRowEditingStarted = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  const onChangeSearchText = (value) => {
    state.gridApi.setQuickFilter(value)
  }

  const onRowEditingStopped = (params) => {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  const onGridReady = (params) => {
    setState((prevState) => ({
      ...prevState,
      gridApi: params.api,
      gridColumnApi: params.columnApi
    }))
    gridRef.current = params.api;
    gridRef.current.sizeColumnsToFit();
  }

  const onClickSaveButton = (params) => {
    params.api.stopEditing();
    const newData = params.node.data;
    const modifiedData = state.modifiedData;
    modifiedData.push(newData)
    setState((prevState) => ({ ...prevState, modifiedData, isEditableView: false }))
  }

  const onClickCloseIcon = (params) => {
    params.api.stopEditing(true);
    setState((prevState) => ({ ...prevState, isEditableView: false }))
  }

  const onClickEditIcon = (params) => {
    setSelectedRowData(params.node);
    setState((prevState) => ({ ...prevState, isEditableView: true }))
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: params.column.colId
    });
  }

  const onClickDeleteIcon = () => {
    setState((prevState) => ({
      ...prevState,
      showConfirmationModal: true
    }))
  }

  const isDataValid = () => {
    const originalData = state.rowData;
    const modifiedData = state.modifiedData;
    let isValid = true;
    modifiedData.forEach((data) => {
      if (data.ruleno) {
        const ruleSet = originalData.filter(item => item.ruleno === data.ruleno);
        const ruleSetTotal = ruleSet?.reduce((total, item) => total + Number(item.allocationpercentage), 0)
        if (ruleSetTotal !== 100) {
          isValid = false;
          return isValid;
        }
      }
    })

    return isValid
  }

  const onPressSubmitButton = () => {
    const isValid = isDataValid();
    if (isValid) {
      state.modifiedData.forEach((item, index) => {
        setState((prevState) => ({
          ...prevState,
          showProgress: true
        }));
        Bl.AssetAllocation.updateAssetAllocationValues(item).then(response => {
          console.log(`${item.ruleno}-${item.assetclass} updated`)
          if (index === state.modifiedData.length - 1) {
            setState((prevState) => ({ ...prevState, showProgress: false, modifiedData: [] }));
          }
        }).catch(error => {
          console.log('AssetallocationRuleTable/ updateAssetAllocationValues');
          showToast(error.messsage)
        })

      })
    } else {
      showToast("Please make sure all the set of Rule no have 100% of allocation")
    }
  }

  const onHideModal = () => {
    setState((prevState) => ({
      ...prevState,
      showConfirmationModal: false
    }))
  };

  const handlePressNo = () => {
    setState((prevState) => ({
      ...prevState,
      showConfirmationModal: false
    }))
  };

  const handlePressYes = () => {
    //TODO: need to implement remove logic here
    setState((prevState) => ({
      ...prevState,
      showConfirmationModal: false
    }))
  };


  const saveNewRow = (newValues) => {
    setState((prevState) => ({
      ...prevState,
      newRowData: { RuleNo: '', SEBICategory: '', AllocationPercentage: "" },
      showNewRow: false
    }));
  };

  const onNewRowDataChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      ...state.newRowData,
      [name]: value
    }))
  };

  const getAssetAllocationValues = () => {
    setState((prevState) => ({
      ...prevState,
      showProgress: true
    }))

    Bl.AssetAllocation.getAssetAllocationValues().then(result => {
      const data = result.sort((a, b) => a.ruleno.localeCompare(b.ruleno));
      setState((prevState) => ({
        ...prevState,
        rowData: data,
        showProgress: false
      }))
    }).catch((error) => {
      console.log('Asset Allocation rule table/ getAssetAllocationValues Error:', error)
      showToast(error.messsage)
    })
  }

  const showToast = (toastMessage) => {
    console.log('showToast called')
    setState((prevState) => ({
      ...prevState,
      showProgress: false,
      progressMessage: '',
      showToast: true,
      toastMessage
    }))

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showToast: false,
        toastMessage: ''
      }))
    }, 3000);
  }

  const renderActionColumnView = (params) => {
    if (state.isEditableView && params.node === selectedRowData) {
      // Render save button for selected row
      return (
        <div className="aar_editNdeleteView">
          <img
            src={TickIcon}
            alt={"Save"}
            title="Save"
            className={"aar_actionIconStyle"}
            onClick={() => onClickSaveButton(params)}
          />
          <img
            src={CloseIcon}
            alt={"Clear"}
            title="Clear"
            className={"aar_actionIconStyle"}
            onClick={() => onClickCloseIcon(params)}
          />
        </div>
      );
    } else {
      // Render edit icon for other rows
      return (
        <div className="aar_editNdeleteView">
          <img
            src={EditIcon}
            alt={"Edit"}
            title="Edit"
            className={"aar_actionIconStyle"}
            onClick={() => onClickEditIcon(params)}
          />
          {/* <img
            src={DeleteIcon}
            alt={"Delete"}
            title="Delete"
            className={"aar_actionIconStyle"}
            onClick={onClickDeleteIcon}
          /> */}
        </div>
      );
    }
  }

  const renderSearchBar = () => {
    return (
      <div className="aar_searchView">
        <Input
          prefix={<SearchOutlined />}
          placeholder="Search"
          onChange={onChangeSearchText}
        />
      </div>
    );
  };

  const renderTitleView = () => {
    return (
      <div className="aar_titleView">
        <div className="aar_lableView" >{t("assetallocationrule")}</div>
        {renderSearchBar()}
        {/* <div className='aar_addIconView' onClick={() => setState({ showModal: true })}>
          <label className="cm_addIcon" title="Add New Asset Rule">Add</label>
        </div> */}
      </div>
    )
  }

  const rebderAddModal = () => {
    return (
      <AddRowModal
        isOpen={state.showModal}
        onClose={() => setState({ showModal: false })}
        newRowData={state.newRowData}
        onNewRowDataChange={onNewRowDataChange}
        saveNewRow={saveNewRow}
      />
    )
  }

  const renderTabelView = () => {
    return (
      <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
        <AgGridReact
          rowData={state.rowData}
          columnDefs={columnDefs}
          editType="fullRow"
          suppressClickEdit={true}
          defaultColDef={{ editable: true }}
          onRowEditingStopped={onRowEditingStopped}
          onRowEditingStarted={onRowEditingStarted}
          onGridReady={onGridReady}
        />
      </div>
    )
  }

  const renderConfirmationModal = () => {
    const buttonNames = { no: t("no"), yes: t("yes") };
    const title = t("areYouSureYouWantToDeleteTheSelectedGoals");
    return (
      <RecordModal
        open={state.showConfirmationModal}
        onClose={onHideModal}
        onPressNo={handlePressNo}
        onPressYes={handlePressYes}
        title={title}
        buttonNames={buttonNames}
      />
    );
  }

  const renderButtonsView = () => {
    return (
      <div className="aar_bottomView">
        <div className="aar_buttonView">
          <Button
            disabled={state.disableSubmit}
            buttonName={t("Submit")}
            onPressButton={onPressSubmitButton}
          />
        </div>
      </div>
    );
  }

  const renderToastView = () => {
    return (
      <Toast
        showToast={state.showToast}
        toastMessage={state.toastMessage}
      />
    );
  }

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  }

  return (
    <div className="aar_container">
      {renderTitleView()}
      <div className="aar_content">
        {renderTabelView()}
      </div>
      {renderButtonsView()}
      {state.showToast && renderToastView()}
      {state.showProgress && renderProgressDialog()}
      {/* {rebderAddModal()}
      {state.showConfirmationModal && renderConfirmationModal()} */}
    </div>
  );
};


export default AssetAllocationRuleTable;










