import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class UserApi {

    static createUser(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/`, Methods.POST, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static updateUser(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/`, Methods.PATCH, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static deleteUser(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/${data.id}`, Methods.DELETE)
                .then((response) => {
                    resolve(response);
                }).catch((error) => {
                    reject(error);
                });
        })
    }

    static validateVerifyPAN(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/verify_pan_number?first_name=${data.firstname}&pan_number=${data.pannumber}`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    static updateUserStatus(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/status_info/?user_id=${data.userId}`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static getUserDetails() {
        return new Promise((resolve, reject) => {
            apiCall(`User/`, Methods.GET)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    static getUserDetailsById(id) {
        return new Promise((resolve, reject) => {
            apiCall(`User/${id}`, Methods.GET)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static userLogin(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/login`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static userForgetPin(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/forget_pin`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static verifyEmail(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/verify_email?id=${data.id}&email_otp=${data.email_otp}`, Methods.POST)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static changePassword(data) {
        return new Promise((resolve, reject) => {
            apiCall(`User/change_pin`, Methods.POST, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export default UserApi;
