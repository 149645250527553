import React from "react";
import GaugeNeedle from "./gaugeneedle";
import { ReactComponent as Gauge } from "../../assets/svgs/gauge/gaugeValue.svg";
import "./gaugedialler.css";

class GaugeDialler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={"gd_viewStyle"}>
        <svg
          width="211"
          height="166"
          viewBox="0 0 211 166"
          fill="none"
          className={"gd_diallerViewStyle"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.748 165.797C7.47425 153.864 3.67792 138.864 1.35998 123.985C-0.957959 109.105 -0.307659 93.8913 3.27076 79.282L16.9416 82.8919C12.4097 94.3655 8.88487 106.577 8.88487 123.985C8.88487 141.931 10.7797 156.118 16.748 165.797Z"
            fill="#F69698"
          />
          <path
            d="M5.06055 73.5397C9.39312 59.8361 16.4936 47.1636 25.9239 36.304C35.3542 25.4443 46.9144 16.628 59.8913 10.3987L67.2681 26.0803C57.3806 30.8266 44.5564 38.1917 37.3711 46.466C30.1858 54.7403 20.7409 67.3491 17.4398 77.7904L5.06055 73.5397Z"
            fill="#FDBE90"
          />
          <path
            d="M66 7.66364C79.3512 2.27926 93.6664 -0.318338 108.063 0.0311107C122.459 0.38056 136.63 3.66964 149.703 9.69544L140.925 27.572C130.965 22.9807 119.031 19.3856 108.063 19.1194C97.0936 18.8531 83.3317 18.9945 73.1589 23.0971L66 7.66364Z"
            fill="#FFDC84"
          />
          <path
            d="M156.023 12.8228C168.668 19.6979 179.767 29.0854 188.636 40.4063C197.505 51.7271 203.955 64.7412 207.589 78.6456L186.359 84.7515C183.59 74.1573 179.164 62.0529 172.407 53.4271C165.649 44.8014 156.629 34.7993 146.994 29.5609L156.023 12.8228Z"
            fill="#E9EB87"
          />
          <path
            d="M208.892 84.2542C211.757 98.3362 211.701 112.855 208.726 126.914C205.751 140.974 199.92 154.276 191.594 166L171.119 151.522C177.463 142.589 183.593 133.252 185.859 122.54C188.126 111.827 189.538 99.4587 187.354 88.7291L208.892 84.2542Z"
            fill="#72EEC0"
          />

          <GaugeNeedle
            size={200}
            currentValue={this.props.value}
            needleSharp={true}
            needleColor={this.props.needleColor}
            progressFontSize={12}
            needleBaseSize={9.5}
            needleBaseColor={this.props.needleBaseColor}
            needleWidth={8}
          />
        </svg>
        <div className="gd_valueViewStyle">
          <Gauge />
        </div>
      </div>
    );
  }
}

export default GaugeDialler;
