import BackendApi from "../backend/index";

class Common {
  static getCommonConfigInfo() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CommonApi.getCommonConfigInfo()
        .then((configList) => {
          resolve(configList);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getFutureValue(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CommonApi.getFutureValue(data)
        .then((configList) => {
          resolve(configList)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getFutureValueMarketLinked(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CommonApi.getFutureValueMarketLinked(data)
        .then((configList) => {
          resolve(configList)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getCurrentAge(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CommonApi.getCurrentAge(data)
        .then((currentAge) => {
          resolve(currentAge)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getStates() {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CommonApi.getStates()
        .then((configList) => {
          resolve(configList)
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  static updateConfigInfo(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CommonApi.updateConfigInfo(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static initiateCustomerCredentials(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.CommonApi.initiateCustomerCredentials(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default Common;
