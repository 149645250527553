import React from "react";
import "./annuitydashboard.css"
import { t } from "i18next";
import {
  Header,
  TitleView,
  Button,
  CardDetailContainer,
  BreadCrumb,
  Toast,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getIncomePathName, getUserId, } from "../../common";
import { INCOME_CATEGORY } from "../../constants";
import * as logger from "../../utils/logger/index";

class AnnuityDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incomeData: [],
    };
    this.isRenderingAlready = false;
  }
  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getUserIncomesByCategory();
    }
  }

  onSelectItem(path) {
    this.props.navigate(path);
  }

  onPressAddMore() {
    this.props.navigate("/annuityhomepage");
  }

  onPressFinishButton() {
    this.props.navigate("/financialdetails");
  }

  onClickEditIcon = (selectedItem) => {
    this.props.navigate("/annuitydetails", {
      state: { incomeData: selectedItem.item, isEditView: true },
    });
  };

  onPressNo = () => {
    //To Do implement the function
  };

  onPressYes = (selectedItem) => {
    this.deleteIncome(selectedItem.item);
  };
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  deleteIncome(item) {
    const incomeObj = new Bl.Income(item);
    this.setState({ showProgress: true }, () => {
      incomeObj
        .deleteIncome()
        .then((response) => {
          const incomeData = this.state.incomeData;
          const updatedIncomeData = incomeData.filter(
            (income) => income.id !== item.id
          );
          this.setState({
            incomeData: updatedIncomeData,
            showProgress: false,
          });
        })
        .catch((error) => {
          logger.error({ error })
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  getUserIncomesByCategory() {
    const data = {
      uuid: getUserId(),
      category: INCOME_CATEGORY.PENSION_ANNUITY,
    };

    this.setState({ showProgress: true }, () => {
      Bl.Income.getUserIncomesByCategory(data)
        .then((list) => {
          this.setState({ showProgress: false, incomeData: list });
        })
        .catch((error) => {
          logger.error({ error })
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  prepareCardData(list) {
    const displayData = [];
    list.forEach((item) => {
      const startYear = item?.incomestartyear ? item.incomestartyear : "";
      const endYear = item?.incomeendyear ? item.incomeendyear : "";
      const period =
        startYear && endYear ? `${startYear} - ${endYear}` : startYear;
      displayData.push({
        title: item.incomedescription,
        currencyLabel: item?.frequencytype ? `${t(item.frequencytype)} | Annuity Income` : '',
        money: item.incomeamount,
        period: period,
        item,
      });
    });

    return displayData;
  }



  renderNavView() {
    const items = getIncomePathName("Annuity");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}

        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("annuityTitle");
    return <TitleView title={title} information={""} />;
  }

  renderCardContainer() {
    const cardData = this.prepareCardData(this.state.incomeData);
    return (
      <div className="aes_card_view">
        {cardData.map((card, index) => (
          <CardDetailContainer
            key={index}
            title={card.title}
            currentValue={card.currencyLabel}
            money={card.money}
            period={card.period}
            extraContent={card.extraContent}
            onClickEdit={() => this.onClickEditIcon(card)}
            onPressNo={this.onPressNo.bind(this)}
            onPressYes={() => this.onPressYes(card)}
          />
        ))}
      </div>
    );
  }

  renderAddMoreButton() {
    return (
      <div className="aes_rebuttonView aes_button_view">
        <Button
          emptyButton={true}
          buttonName={t("annuityDBButton1")}
          onPressButton={this.onPressAddMore.bind(this)}
        />
      </div>
    );
  }
  renderButton() {
    return (
      <div id="aes_bottom_view">
        {this.renderAddMoreButton()}
        <div id="aes_button_view">
          <Button
            buttonName={t("annuityDBButton2")}
            onPressButton={this.onPressFinishButton.bind(this)}
          />
        </div>
      </div>
    );
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }

  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div id="aes_container">
        {this.renderNavView()}
        <div id="aes_inner_container">
          {this.renderTitleView()}
          {this.renderCardContainer()}
        </div>
        {this.renderButton()}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}
export default AnnuityDashboard;
