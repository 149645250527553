import { t } from "i18next";
import "./progressdialog.css";
import React from "react";
import { Loader } from "rsuite";

class ProgressDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return this.props.showProgress ? (
      <div>
        <Loader
          center
          content={this.props.progressMessage}
          className="pd_loaderStyle"
          size={"md"}
        />
      </div>
    ) : (
      <div />
    );
  }
}

export default ProgressDialog;

ProgressDialog.defaultProps = {
  progressMessage: t("loading"),
};
