import BackendApi from "../backend";

class Dashboard {
  static getAccountDetails(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.DashboardApi.getAccountDetails(data).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error);
      })
    })
  }

  static getPermanentCashladderValues(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.DashboardApi.getPermanentCashladderValues(data).then(result => {
        resolve(result);
      }).catch(error => {
        reject(error);
      })
    })
  }
}

export default Dashboard;