import React, { Component } from "react";
import "./otherfinancialdetail.css";
import { t } from "i18next";
import {
  BreadCrumb,
  Button,
  FloatingLabelInput,
  Slider,
  TitleView,
  ToggleSwitch,
  CostItemPerYear,
  RecordModal,
  SideModel,
  Toast,
  ProgressDialog,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getCurrentYear } from "../../utils/datetime";
import { getFinancialMarketPath, calculate_age, getUserDOB } from "../../common";
import * as logger from "../../utils/logger/index";
import { getCommonConfigInfo } from "../../utils/common";
import { DEFAULT_OFFSET } from "../../constants";
import { getCurrentAge } from "../../utils/common";
class OtherFinancialDetail extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const investmentData = params?.investmentData ? params.investmentData : "";
    const isEditView = params?.isEditView ? params.isEditView : false;

    this.state = {
      startValue: getCurrentYear() + 1,
      endValue: 2021,
      startAge: getCurrentYear() + 1,
      endAge: 65,
      description: "",
      currentValue: "",
      showModal: false,
      inputValue: "",
      editingItem: null,
      includeSellValue: false,
      costValue: "15,00,000",
      viewExpense: false,
      selectedOptions: [],
      investmentsData: investmentData ? new Bl.Investment(investmentData) : {},
      initialOtherMarkets: investmentData?.investmentdetails[0]?.isothermarkets
        ? investmentData?.investmentdetails[0]?.isothermarkets
        : true,
      otherMarkets: investmentData?.investmentdetails[0]?.isothermarkets
        ? investmentData?.investmentdetails[0]?.isothermarkets
        : true,
      initialSellProperty: investmentData?.investmentdetails[0]?.issellproperty
        ? investmentData?.investmentdetails[0]?.issellproperty
        : false,
      sellProperty: investmentData?.investmentdetails[0]?.issellproperty
        ? investmentData?.investmentdetails[0]?.issellproperty
        : false,
      initialFliberAdvise: investmentData?.investmentdetails[0]?.isfliberadvise
        ? investmentData?.investmentdetails[0]?.isfliberadvise
        : false,
      fliberAdvise: investmentData?.investmentdetails[0]?.isfliberadvise
        ? investmentData?.investmentdetails[0]?.isfliberadvise
        : false,
      initialEquityPercentage: investmentData?.investmentdetails[0]?.equity
        ? Number(investmentData?.investmentdetails[0]?.equity)
        : 0,
      equityPercentage: investmentData?.investmentdetails[0]?.equity
        ? Number(investmentData?.investmentdetails[0]?.equity)
        : 0,
      isEditView,
      currentAge: 0,
    };
    this.isRenderingAlready = false;
    this.costPerYearViewRef = React.createRef();
    this.dbData = investmentData
      ? JSON.parse(JSON.stringify(new Bl.Investment(investmentData)))
      : {};
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getConfigInfo();
      this.getCurrentAge();
    }
  }
  getConfigInfo() {
    this.setState({ showProgress: true }, () => {
      getCommonConfigInfo()
        .then((configList) => {
          const configInfo = configList[0];
          const investmentObj = this.state.investmentsData;
          investmentObj.setExpectedror(configInfo.generalinflation * 100);
          this.setState({
            showProgress: false,
          });
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const investmentObj = this.state.investmentsData;
          investmentObj.setUserAge(currentAge);
          this.setState({
            currentAge: currentAge,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("OtherMarketDetailsPage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  isStateValueChanged() {
    const {
      initialOtherMarkets,
      otherMarkets,
      initialSellProperty,
      sellProperty,
      initialFliberAdvise,
      fliberAdvise,
      initialEquityPercentage,
      equityPercentage,
    } = this.state;
    return (
      initialOtherMarkets !== otherMarkets ||
      initialSellProperty !== sellProperty ||
      initialFliberAdvise !== fliberAdvise ||
      initialEquityPercentage !== equityPercentage
    );
  }
  onSelectItem(path) {
    const localData = new Bl.Investment(this.state.investmentsData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  checkIsDataValid(data, isView = false) {
    let isValid = true;
    let message = "";
    if (!isView && !this.isDescValid(data.investmentdescription)) {
      isValid = false;
      message = t("omToastDesc");
    } else if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("omToastMaturityValue");
    }
    return { isValid, message };
  }
  isDescValid(desc) {
    return desc && desc.length > 0;
  }
  isAmountValid(amount) {
    return amount && amount > 0;
  }
  isMaturityYearValid(maturityyear) {
    return maturityyear !== 0;
  }
  prepareAssetData() {
    const fields = {
      issellproperty: this.state.sellProperty,
      isfliberadvise: this.state.fliberAdvise,
      isothermarkets: this.state.otherMarkets || true,
      equitypercentage: this.state.equityPercentage || 0,
    };
    const propertyDetails = [];
    const property = {};
    let oneFieldAvail = false;
    Object.keys(fields).forEach((key) => {
      if (fields[key] !== "") {
        oneFieldAvail = true;
        property[key] = fields[key];
      }
    });
    if (oneFieldAvail) {
      propertyDetails.push(property);
    }
    return { propertyDetails };
  }
  createUserInvestment(data) {
    const currentYear = getCurrentYear();
    const investmentData = this.state.investmentsData;
    const startValue = investmentData?.maturityyear
      ? investmentData.maturityyear
      : currentYear + 1;
    const maturityAge = calculate_age(startValue, currentYear, this.state.currentAge)
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    if (investmentObj.maturityyear === 0) {
      investmentObj.setMaturityYear(getCurrentYear() + 1);
      investmentObj.setMaturityAge(maturityAge)
    }
    investmentObj.setInvestmentDetails(data.propertyDetails);
    //initially asset inflation multiplied by 100 , so at time of store divide by 100
    investmentObj.expectedror /= 100;
    const isDataValid = this.checkIsDataValid(investmentObj);
    console.log({ investmentObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .createInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/otherfinancialdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserInvestment(data) {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    investmentObj.setInvestmentDetails(data.propertyDetails);
    //initially asset inflation multiplied by 100 , so at time of store divide by 100
    investmentObj.expectedror /= 100;
    const isDataValid = this.checkIsDataValid(investmentObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .updateInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/otherfinancialdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressSaveButton() {
    const data = this.prepareAssetData();
    if (this.state.isEditView) {
      this.updateUserInvestment(data);
    } else {
      this.createUserInvestment(data);
    }
  }
  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  onChangeDescription(value) {
    const investment = this.state.investmentsData;
    investment.setInvestmentDescription(value);
    this.forceUpdate();
  }

  onChangeCurrentValue(value) {
    const investment = this.state.investmentsData;
    investment.setCurrentValue(value ? Number(value) : 0);
    this.setState({ viewExpense: false })
    this.forceUpdate();
  }

  onPressViewExpense() {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    const investmentAsset = this.state.investmentsData;
    const period = investmentAsset?.maturityyear
      ? investmentAsset?.maturityyear - getCurrentYear()
      : 1;
    const isDataValid = this.checkIsDataValid(investmentObj, true);
    const data = {
      intial_investment: investmentAsset?.currentvalue,
      equity_percentage: this.state.equityPercentage,
      investment_period: period,
    };
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        Bl.Common.getFutureValueMarketLinked(data)
          .then((result) => {
            investmentAsset.setMaturityValue(result.futureValue);
            this.setState({
              viewExpense: true,
              showProgress: false,
            }, () => {
              this.costPerYearViewRef.current.scrollIntoView({ behavior: "smooth" });
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    const investment = this.state.investmentsData;
    const currentYear = getCurrentYear();
    const maturityAge = calculate_age(newValue, currentYear, this.state.currentAge)
    investment.setMaturityYear(newValue ? Number(newValue) : newValue);
    investment.setMaturityAge(maturityAge)
    this.forceUpdate();
  }

  handleEquityChange(event) {
    const newValue = event.target.value;
    this.setState({
      equityPercentage: newValue ? Number(newValue) : newValue,
      viewExpense: false,
    });
  }

  handleEdit = (item) => {
    this.setState({
      showModal: true,
      inputValue: item.cost,
      editingItem: item,
    });
  };

  handleInputChange = (value) => {
    this.setState({
      inputValue: value,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      inputValue: "",
      editingItem: null,
    });
  };

  handleSaveModal = () => {
    const { inputValue, editingItem } = this.state;
    // Update the cost value of the editingItem with the new input value
    editingItem.cost = inputValue;
    // Close the modal and update the state with the new cost value
    const investmentAssetsData = this.state.investmentsData;
    investmentAssetsData.setMaturityValue(Number(inputValue));
    this.setState({
      showModal: false,
      costValue: inputValue,
    });
  };

  toggleSellValue = (selectedItem, checked) => {
    this.setState({ sellProperty: checked, viewExpense: false }, () => {
      const data = this.prepareAssetData();
      const investment = this.state.investmentsData;
      investment.setInvestmentDetails(data.propertyDetails);
    });
  };

  toggleFliberAdvise = (selectedItem, checked) => {
    this.setState({ fliberAdvise: checked, viewExpense: false }, () => {
      const data = this.prepareAssetData();
      const investment = this.state.investmentsData;
      investment.setInvestmentDetails(data.propertyDetails);
    });
  };

  toggleFutureValue = (selectedItem, checked) => {
    const investment = this.state.investmentsData;
    investment.setIsIncludeFinancialPlan(checked);
    this.setState({ viewExpense: false });
  };

  renderNavView() {
    const items = getFinancialMarketPath("Others");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        isStateValueChanged={this.isStateValueChanged()}
        dbData={this.dbData}
        localData={this.state.investmentsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const information = " ";
    const title = t("others");
    return <TitleView title={title} information={information} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false,
    selectionView = false,
    selectedOptions = []
  ) {
    return (
      <div className="ofd_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
          selectionView={selectionView}
          selectionOptions={selectedOptions}
        />
      </div>
    );
  }

  renderToggleView(label, checked, onChange) {
    return (
      <div className="ofd_toggle_view">
        <label className="ofd_toggle_text">{label}</label>
        <ToggleSwitch
          checkedChildren={t("yes")}
          unCheckedChildren={t("no")}
          onChangeToggle={onChange}
          defaultChecked={checked}
          style={{ width: "60px" }}
        />
      </div>
    );
  }

  renderArtDetails() {
    const investmentData = this.state.investmentsData;
    const balance = investmentData?.currentvalue
      ? investmentData?.currentvalue
      : "";
    const description = investmentData?.investmentdescription;

    return (
      <div className="ofd_description_view">
        <div className="ofd_input_row">
          {this.renderInputView(
            t("description"),
            description,
            this.onChangeDescription.bind(this)
          )}
        </div>
        <div className="ofd_sec_row">
          {this.renderInputView(
            t("maturity/FaceValue"),
            balance,
            this.onChangeCurrentValue.bind(this),
            true
          )}
          {this.renderToggleView(
            t("doYouWantToSellTheAssetInTheFuture?"),
            this.state.sellProperty,
            this.toggleSellValue.bind(this)
          )}

        </div>
      </div>
    );
  }

  renderYearView() {
    const currentYear = getCurrentYear();
    const investmentData = this.state.investmentsData;
    const maturityyear =
      investmentData && investmentData.maturityyear
        ? investmentData.maturityyear
        : getCurrentYear() + 1;
    const age = calculate_age(maturityyear, currentYear, this.state.currentAge);
    return (
      <div className="ofd_slider_view">
        <div className="ofd_start_year_row">
          <label className="ofd_start_text">
            {t("targetYearOfLiquidation/Sell")}
          </label>
          <div className="ofd_slider_content">
            <Slider
              value={maturityyear}
              age={age}
              min={getCurrentYear() + 1}
              max={getCurrentYear() + 51}
              markStep={10}
              handleChange={this.handleChangeStart.bind(this)}
              showAge={true}
            />
          </div>
        </div>
      </div>
    );
  }

  renderEquityPercentage() {
    const equity = this.state.equityPercentage;
    const age = calculate_age(this.state.startAge);
    return (
      <div className="ofd_start_year_row">
        <label className="ofd_start_text">{t("omlSlider")}</label>
        <div className="ofd_slider_content">
          <Slider
            value={equity}
            age={age}
            min={0}
            max={100}
            markStep={20}
            handleChange={this.handleEquityChange.bind(this)}
            showAge={false}
            showPercentage={true}
          />
        </div>
      </div>
    );
  }

  renderCostItemPerYear() {
    const investmentData = this.state.investmentsData;
    const futureValue = investmentData?.maturityvalue;
    return (
      <div className="ofd_cost_item_view" ref={this.costPerYearViewRef}>
        <CostItemPerYear
          item={{
            label: t("expectedValueAtLiquidation(2050)"),
            cost: futureValue,
          }}
          showEditIcon={true}
          handleEdit={this.handleEdit.bind(this)}
        />
      </div>
    );
  }

  renderEditModal() {
    const { showModal, inputValue } = this.state;
    const title = t("expectedValueAtLiquidation(2050)");
    return (
      <RecordModal
        open={showModal}
        onClose={this.handleCloseModal.bind(this)}
        title={title}
        buttonNames={{ one: t("save&Changes") }}
        onPressYes={this.handleSaveModal.bind(this)}
        showInput={true}
        value={inputValue}
        label={t("value")}
        showRupeeSymbol={true}
        onInputChange={this.handleInputChange.bind(this)}
      />
    );
  }

  renderButtonsView() {
    return (
      <div className="ofd_bottomView">
        <div className="ofd_buttonView">
          <div className="ofd_sale_button">
            <Button
              buttonName={t("viewExpectedSaleValue")}
              emptyButton={true}
              disabled={!this.state.sellProperty}
              onPressButton={this.onPressViewExpense.bind(this)}
            />
          </div>

          <div className="ofd_save">
            <Button
              buttonName={t("save")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }
  renderContentView() {
    const { sellProperty, investmentsData } = this.state;
    return (
      <div className="ofd_content_view">
        {this.renderTitleView()}
        {this.renderArtDetails()}
        {sellProperty && (
          <>
            <div className="ofd_seperatorStyle" />
            <div className="ofd_yearNequityview">
              {this.renderEquityPercentage()}
              {this.renderYearView()}
            </div>
            {this.state.viewExpense && this.renderCostItemPerYear()}
            <div className="ofd_seperatorStyle" />
            <div className="ofd_retirement_toggle">
              {this.renderToggleView(
                t("omTg2Text"),
                investmentsData?.isincludefinancialplan,
                this.toggleFutureValue.bind(this)
              )}
              {this.renderToggleView(
                t("Fliber Advised"),
                this.state.fliberAdvise,
                this.toggleFliberAdvise.bind(this)
              )}
            </div>
            {this.renderEditModal()}
          </>
        )}
      </div>
    );
  }
  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="ofd_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default OtherFinancialDetail;
