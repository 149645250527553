import {
  BreadCrumb,
  Button,
  CashLadderEditModal,
  CashLadderFinancialView,
  GoalFulFillmentView,
  ProgressDialog,
  TitleView,
  Toast
} from "../components";
import { t } from "i18next";
import {
  prepareCashLadderValues,
  getCashLadderPathName,
  prepareGoalFullfilmentValues,
  getUserId,
  prepareIncomePayoutValues,
  getUserDOB,
  prepareGoalPayoutValues,
  getUserDetails,
  getUserCashLadderDetail,
  setUserCashLadderDetail,
  setSelectedUserDetail,
  isRestrictedCustomer
} from "../common";
import Edit from "../../assets/svgs/edit.svg";
import { useState, useRef, useEffect } from "react";
import "./cashladderpage.css";
import * as Bl from "../../businesslogic";
import * as logger from "../utils/logger/index";
import { Modal } from "rsuite";
import SuccessModalIcon from "../../assets/svgs/successmodal.svg"
import CashLadderViewDetails from "./viewdetails/cashladderviewdetails";
import { DEFAULT_OFFSET } from "../constants";
import { getCommonConfigInfo, getCurrentAge } from "../utils/common";
import OptimizeDataTableView from "./viewdetails/optimizedatatableview";

const CashLadderPage = (props) => {
  const params = props.location.state;
  const responseData = params?.cashLadderValues ? params.cashLadderValues : {};
  const isFromAssetAllocation = params?.isFromAssetAllocation ? params?.isFromAssetAllocation : false;
  const effectiveDataValue = params?.effectiveData
    ? params.effectiveData
    : {
      sbeffectiverate: 0,
      gbeffectiverate: 0
    }

  const [state, setState] = useState({
    lumpsum: 0,
    monthlySip: 0,
    showEditModal: false,
    showLumpsumView: true,
    showToast: false,
    toastMessage: "",
    showProgress: false,
    progressMessage: "",
    secureSip: '',
    secureLumpsum: '',
    growthSip: '',
    growthLumpsum: '',
    cashLadderValues: responseData ? prepareCashLadderValues(responseData) : [],
    showModal: false,
    showSuccessModal: false,
    goalAlloctaionValues: [],
    goalPayoutValues: [],
    goalData: [],
    goalDetails: [],
    incomePayoutValues: [],
    incomeData: [],
    incomeDetails: [],
    filterOption: {},
    currentAge: 0,
    sipTillAge: 0,
    sipGrowth: 0,
    lifeexpectancy: 0,
    finalizedCashLadderData: responseData,
    showOptimizeDataModal: false,
  });
  const [effectiveData, setEffectiveData] = useState(effectiveDataValue);
  const isRenderedAlready = useRef(false);
  const userId = getUserId();

  useEffect(() => {
    if (!isRenderedAlready.current) {
      isRenderedAlready.current = true;
      if (isFromAssetAllocation) {
        getCashLadderValues(effectiveData);
      } else {
        getAssetAllocationValues();
      }
      getLifeExpectancy();
      getUserConfig();
      getUserCurrentAge();
      getGoalAlloction();
      getIncomeAllocation();
    }
  }, []);


  const getCashladderValuesInLocalStorage = (effectiveData) => {
    let cashLadderDetails = getUserCashLadderDetail();
    if (cashLadderDetails !== null) {
      setState((prevState) => ({
        ...prevState,
        lumpsum: cashLadderDetails.lumpsum,
        monthlySip: cashLadderDetails.monthlySip,
        secureSip: cashLadderDetails.secureSip,
        secureLumpsum: cashLadderDetails.secureLumpsum,
        growthSip: cashLadderDetails.growthSip,
        growthLumpsum: cashLadderDetails.growthLumpsum,
        sipTillAge: cashLadderDetails.sipTillAge,
        sipGrowth: cashLadderDetails.sipGrowth,
        effectiveData
      }));

      setTimeout(() => {
        getCashLadderWithLumpsum(
          cashLadderDetails.lumpsum,
          cashLadderDetails.monthlySip,
          cashLadderDetails.secureSip,
          cashLadderDetails.secureLumpsum,
          cashLadderDetails.growthSip,
          cashLadderDetails.growthLumpsum,
          cashLadderDetails.sipTillAge,
          cashLadderDetails.sipGrowth,
          effectiveData)
      }, 2000);
    } else {
      getCashLadderWithLumpsum(
        state.lumpsum,
        state.monthlySip,
        state.secureSip,
        state.secureLumpsum,
        state.growthSip,
        state.growthLumpsum,
        state.sipTillAge,
        state.sipGrowth,
        effectiveData)
    }
  }

  const getAssetAllocationValues = () => {
    const data = {
      uuid: userId,
    };

    Bl.CashLadder.getAssetAllocationDetails(data)
      .then((response) => {
        if (response) {
          const data = {
            "sbequity": 0,
            "sbdebt": 0,
            "sbpthers": 0,
            "gbequity": 0,
            "gbdebt": 0,
            "gbpthers": 0
          }

          if (response.growth_bucket) {
            response.growth_bucket?.forEach((growth) => {
              if (growth.assetclass === "EQUTY") {
                data.gbequity = growth.allocationpercentage;
              } else if (growth.assetclass === "CMODT") {
                data.gbpthers = growth.allocationpercentage;
              } else if (growth.assetclass === "DEBT") {
                data.gbdebt = growth.allocationpercentage;
              }
            });
          }

          if (response.secure_bucket) {
            response.secure_bucket?.forEach((secure) => {
              if (secure.assetclass === "EQUTY") {
                data.sbequity = secure.allocationpercentage;
              } else if (secure.assetclass === "CMODT") {
                data.sbpthers = secure.allocationpercentage;
              } else if (secure.assetclass === "DEBT") {
                data.sbdebt = secure.allocationpercentage;
              }
            });
          }

          getEffectiveRate(data);

        }
      }).catch(error => {
        console.error(error)
      })
  }

  const getEffectiveRate = (data) => {
    Bl.CashLadder.createCalculateEffectiveRate(data)
      .then((response) => {
        if (response && response.data && response.data.length > 0) {
          const responseData = response.data[0]
          const effectiveDataVal = {
            sbeffectiverate: Number((responseData.secure_bucket / 100).toFixed(4)),
            gbeffectiverate: Number((responseData.growth_bucket / 100).toFixed(4)),
          }
          setEffectiveData(effectiveDataVal);
          getCashLadderValues(effectiveDataVal);
        }
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occurred while calculating the effective rate"));
      });
  }

  const getTempCashLadderValues = (effectiveData) => {
    const data = {
      uuid: userId,
      sbeffectiverate: effectiveData?.sbeffectiverate,
      gbeffectiverate: effectiveData?.gbeffectiverate,
    };

    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }))

    Bl.CashLadder.getCashLadder(data)
      .then((response) => {
        if (response) {
          const data = response;
          const cashLadderValues = prepareCashLadderValues(response);

          setState((prevState) => ({
            ...prevState,
            showProgress: false,
            cashLadderValues: cashLadderValues,
            finalizedCashLadderData: data
          }));
        }
      })
      .catch((error) => {
        logger.error({ error });
        this.showToast(t("someErrorHasOccured"));
      });
  }

  const getUserDetailsById = () => {
    Bl.User.getUserDetailsById(userId).then((userData) => {
      const userDetail = userData?.User;
      setSelectedUserDetail(userDetail)
    }).catch((error) => {
      console.log(error)
    })
  }


  const getLifeExpectancy = () => {
    const userDetail = getUserDetails();
    setState((prevState) => ({
      ...prevState,
      sipTillAge: Number(userDetail?.retirementage),
      lifeexpectancy: Number(userDetail?.lifeexpectancy),
    }));
  }

  const getCashLadderValues = (effectiveData) => {
    const userDetail = getUserDetails();
    if (userDetail?.iscashladderupdated) {
      getPermanentCashLadder();
    } else {
      if (isFromAssetAllocation) {
        getTempCashLadderValues(effectiveData);
      } else {
        getCashladderValuesInLocalStorage(effectiveData);
      }
    }
  }

  const getPermanentCashLadder = () => {
    const data = {
      uuid: userId
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    Bl.Dashboard.getPermanentCashladderValues(data)
      .then((response) => {
        if (response) {
          const data = response[0];
          const lumpsum = data && Object.keys(data)?.length > 0 ?
            data?.Begin_start_bal?.[0] : 0;
          const monthlySip = data && Object.keys(data)?.length > 0 ?
            data?.sb_sip_amount?.[0] + data?.gb_sip_amount?.[0] : 0;
          const cashLadderValues = prepareCashLadderValues(response[0]);

          setState((prevState) => ({
            ...prevState,
            showProgress: false,
            lumpsum,
            monthlySip,
            cashLadderValues: cashLadderValues,
            finalizedCashLadderData: data
          }));
        }
      })
      .catch((error) => {
        logger.error({ error });
        this.showToast(t("someErrorHasOccured"));
      });
  }

  const getUserConfig = () => {
    getCommonConfigInfo()
      .then((configList) => {
        const configData =
          configList && configList.length > 0 ? configList[0] : {};
        setState((prevState) => ({
          ...prevState,
          sipGrowth: (configData.sip_growth * 100).toFixed(2)
        }));
      })
      .catch((error) => {
        console.error("Error fetching config data:", error);
      });
  }

  const getUserCurrentAge = () => {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    getCurrentAge(data)
      .then((response) => {
        const currentAge = response.currentage;
        setState((prevState) => ({
          ...prevState,
          currentAge: currentAge,
          showProgress: false,
        }));
      })
      .catch((error) => {
        console.log("cashladderpage.js/getCurrentAge Error:", error);
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
        }));
      });
  }

  const getGoalAlloction = () => {
    const data = {
      uuid: userId
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    Bl.CashLadder.getCashLadderGoalAllocation(data)
      .then((response) => {
        const goalAlloctaionValues = prepareGoalFullfilmentValues(response)
        const { modifiedData, goalData, goalDetails } = prepareGoalPayoutValues(response);
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          goalData,
          goalDetails,
          goalPayoutValues: modifiedData,
          goalAlloctaionValues: goalAlloctaionValues
        }));
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occured"));
      });
  };

  const getIncomeAllocation = () => {
    const data = {
      uuid: userId
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    Bl.CashLadder.getCashLadderIncomeAllocation(data)
      .then((response) => {
        const { modifiedData, incomeData, incomeDetails } = prepareIncomePayoutValues(response)
        setState((prevState) => ({
          ...prevState,
          showProgress: false,
          incomeData,
          incomeDetails,
          incomePayoutValues: modifiedData
        }));
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occured"));
      });
  };

  const getSipOptimization = () => {
    const data = {
      uuid: userId,
      lumpsum: Number(state.lumpsum),
      sip: Number(state.monthlySip),
      ssip: Number(state.secureSip),
      slump: Number(state.secureLumpsum),
      sbeffectiverate: effectiveData?.sbeffectiverate,
      gbeffectiverate: effectiveData?.gbeffectiverate,
      sipTillAge: state.sipTillAge,
      sipGrowth: state.sipGrowth  //NOTE:Here the SipGrowth value passed as config sip_growth*100  - instructed by API team
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
      progressMessage: 'Please be patient, we are optimizing SIP,  it takes sometime'
    }));
    Bl.CashLadder.getCashLadderSipOptimization(data)
      .then((response) => {
        console.log({ response })
        if (response) {
          const monthlySip = response && Object.keys(response)?.length > 0 ?
            response.sb_sip_amount?.[0] + response.gb_sip_amount?.[0] : 0;
          const secureSip = response.sb_sip_amount[0];
          const growthSip = response.gb_sip_amount[0];
          const cashLadderValues = prepareCashLadderValues(response);

          setUserCashLadderDetail({
            lumpsum: state.lumpsum,
            monthlySip: monthlySip,
            secureSip: secureSip,
            secureLumpsum: state.secureLumpsum,
            growthSip: growthSip,
            growthLumpsum: state.growthLumpsum,
            sipTillAge: state.sipTillAge,
            sipGrowth: state.sipGrowth
          })

          setState((prevState) => ({
            ...prevState,
            showProgress: false,
            progressMessage: '',
            monthlySip,
            secureSip,
            growthSip,
            cashLadderValues: cashLadderValues,
            finalizedCashLadderData: response
          }));
        }
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occured"));
      });
  };

  const getGoalOptimization = () => {
    const data = {
      uuid: userId,
      lumpsum: Number(state.lumpsum),
      sip: Number(state.monthlySip),
      ssip: Number(state.secureSip),
      slump: Number(state.secureLumpsum),
      sbeffectiverate: effectiveData?.sbeffectiverate,
      gbeffectiverate: effectiveData?.gbeffectiverate,
      sipTillAge: state.sipTillAge,
      sipGrowth: state.sipGrowth  //NOTE:Here the SipGrowth value passed as config sip_growth*100  - instructed by API team
    };
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    Bl.CashLadder.getCashLadderGoalOptimization(data)
      .then((response) => {
        if (response) {
          const cashLadderValues = prepareCashLadderValues(response);

          setUserCashLadderDetail({
            lumpsum: state.lumpsum,
            monthlySip: state.monthlySip,
            secureSip: state.secureSip,
            secureLumpsum: state.secureLumpsum,
            growthSip: state.growthSip,
            growthLumpsum: state.growthLumpsum,
            sipTillAge: state.sipTillAge,
            sipGrowth: state.sipGrowth
          })

          setState((prevState) => ({
            ...prevState,
            showProgress: false,
            cashLadderValues: cashLadderValues,
            finalizedCashLadderData: response
          }));
        }
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occured"));
      });
  };
  const onClickBackIcon = (path) => {
    const userDetail = getUserDetails();
    if (userDetail?.isassetallocated) {
      props.navigate('/financial')
    } else {
      props.navigate('/assetallocdetails');
    }
  };

  const renderTabelView = () => {
    return (
      <CashLadderViewDetails
        cashLadderValues={state.cashLadderValues}
        incomeValues={state.incomePayoutValues}
        goalValues={state.goalPayoutValues}
        goalData={state.goalData}
        goalDetails={state.goalDetails}
        incomeData={state.incomeData}
        incomeDetails={state.incomeDetails}
      />
    )
  }

  const onClose = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: false
    }))
  }

  const onCloseOptimizeModal = () => {
    setState((prevState) => ({
      ...prevState,
      showOptimizeDataModal: false
    }))
  }

  const onPressAcceptOptimization = () => {
    setState((prevState) => ({
      ...prevState,
      showOptimizeDataModal: false
    }))
    getGoalOptimization();
  }

  const onPressRejectOptimization = () => {
    //Ignore the optimized value
    setState((prevState) => ({
      ...prevState,
      showOptimizeDataModal: false
    }))
  }

  const onSuccessModalClose = () => {
    setState((prevState) => ({
      ...prevState,
      showSuccessModal: false
    }))

    if (isRestrictedCustomer()) {
      props.navigate('/dashboard');
    } else {
      props.navigate('/financial')
    }
  }
  const onPressViewDetails = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: true
    }))
  }

  const onPressGoalDetails = () => {
    props.navigate('/goalscategorize')
  }

  const onPressFinancialDetails = () => {
    props.navigate('/financialdetails')
  }

  const renderTabelTitle = () => {
    return (
      <div className="view_modal_title">
        <div></div>
        <label className="view_modal_heading">Details</label>

        <span className="view_modal_close" onClick={onClose}>&times;</span>
      </div>
    )
  }
  const renderTabelModal = () => {
    return (
      <Modal
        open={state.showModal}
        onClose={onClose}
        className={"view_modal"}
      >
        <div className="view_modal-content">
          {renderTabelTitle()}
          {renderTabelView()}
        </div>
      </Modal>
    )
  };

  const renderSuccessMsg = () => {
    return (
      <div className="view_successModal_dispaly">
        <div className="view_successModal_close">
          <span onClick={onSuccessModalClose}>&times;</span>
        </div>
        <div className={"view_successModal_msg"}>
          <img src={SuccessModalIcon} alt="SelectedItem" className={"view_successlIcon"} />
          <label className="view_successModal_heading">{'Thank you for submitting the cash ladder details, Fliber team will taking you to the next step.'}</label>
        </div>
        <div className={"view_successModal_bottomView"}>
          <Button
            buttonName={"Okay"}
            onPressButton={onSuccessModalClose}
          />
        </div>
      </div>
    )
  }
  const renderSuccessModal = () => {
    return (
      <Modal
        open={state.showSuccessModal}
        onClose={onSuccessModalClose}
        size={'sm'}
        className={"view_successModal"}
      >
        <div className="view_successModal-content">
          {renderSuccessMsg()}
        </div>
      </Modal>
    )
  };

  const onPressOptimize = () => {
    setState((prevState) => ({
      ...prevState,
      showOptimizeDataModal: true
    }))
  };

  const onPressAccept = () => {
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));

    const data = {
      data: [
        state.finalizedCashLadderData
      ]
    }

    console.log('createCashLadder Data:', data);

    Bl.CashLadder.createCashLadder(userId, data).then((response) => {
      getUserDetailsById();
      setState((prevState) => ({
        ...prevState,
        showProgress: false,
        showSuccessModal: true
      }))
    }).catch((error) => {
      logger.error({ error });
      showToast(t("Unable to create the cash ladder values"));
    })

  };

  const onPressClose = () => {
    setState((prevState) => ({
      ...prevState,
      showEditModal: false,
    }));
  };

  const hideToast = () => {
    setState((prevState) => ({
      ...prevState,
      toastMessage: "",
      showToast: false,
    }));
  };

  const showToast = (toastMessage) => {
    setState((prevState) => ({
      ...prevState,
      toastMessage,
      showToast: true,
      showProgress: false,
    }));
    setTimeout(() => {
      hideToast();
    }, 3000);
  };

  const onPressEditLumpsum = () => {
    setState((prevState) => ({
      ...prevState,
      showEditModal: true,
      showLumpsumView: true,
    }));
  };

  const onPressEditSIP = () => {
    setState((prevState) => ({
      ...prevState,
      showEditModal: true,
      showLumpsumView: false,
    }));
  };

  const getCashLadderWithLumpsum = (lumpsum, monthlySip, secureSip, secureLumpsum, growthSip, growthLumpsum, sipTillAge, sipGrowth, effectiveData) => {
    const data = {
      uuid: userId,
      lumpsum: Number(lumpsum),
      sip: Number(monthlySip),
      ssip: Number(secureSip),
      slump: Number(secureLumpsum),
      sbeffectiverate: effectiveData?.sbeffectiverate,
      gbeffectiverate: effectiveData?.gbeffectiverate,
      sipTillAge: sipTillAge,
      sipGrowth: sipGrowth  //NOTE:Here the SipGrowth value passed as config sip_growth*100  - instructed by API team
    };
    console.log('data obj:', data)
    setState((prevState) => ({
      ...prevState,
      showProgress: true,
    }));
    Bl.CashLadder.getCashLadderWithLumsum(data)
      .then((response) => {
        if (response) {
          const cashLadderValues = prepareCashLadderValues(response)

          setState((prevState) => ({
            ...prevState,
            showProgress: false,
            cashLadderValues: cashLadderValues,
            finalizedCashLadderData: response
          }));
        }
      })
      .catch((error) => {
        logger.error({ error });
        showToast(t("Some error has occured"));
      });
  };

  const onPressGenerate = () => {
    getCashLadderWithLumpsum(
      state.lumpsum,
      state.monthlySip,
      state.secureSip,
      state.secureLumpsum,
      state.growthSip,
      state.growthLumpsum,
      state.sipTillAge,
      state.sipGrowth,
      effectiveData
    )
  };

  const onPressOptimizeSIP = () => {
    getSipOptimization();
  }

  const onPressSearchApply = (selectedOption, searchValue, searchMaxValue) => {
    if (searchMaxValue) {
      if (searchValue?.length > 0) {
        setState((prevState) => ({
          ...prevState,
          filterOption: {
            option: selectedOption,
            minValue: searchValue,
            maxValue: searchMaxValue
          }
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          filterOption: {}
        }));
      }
    }
    else {
      if (searchValue?.length > 0) {
        setState((prevState) => ({
          ...prevState,
          filterOption: {
            option: selectedOption,
            value: searchValue
          }
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          filterOption: {}
        }));
      }
    }

  }

  const prepareGoalAllocationData = () => {
    const data = state.goalAlloctaionValues
    const searchObj = state.filterOption


    if (!data || Object.keys(searchObj).length === 0) {
      return data;
    }
    else {
      const result = data.filter((obj) => {
        if (searchObj.option === 'goalCategory') {
          return obj.goalCategory.includes(searchObj.value);
        }
        else if (searchObj.option === 'age' || searchObj.option === 'amount') {
          let key = searchObj.option;
          let minValue = Number(searchObj.minValue);
          let maxValue = Number(searchObj.maxValue);
          return obj[key] >= minValue && obj[key] <= maxValue;
        }
      });
      return result;
    }
  }

  const onPressSaveButton = (total, secure, growth, sipTillAge, sipGrowth) => {

    setUserCashLadderDetail({
      lumpsum: state.showLumpsumView ? total : state.lumpsum,
      monthlySip: state.showLumpsumView ? state.monthlySip : total,
      secureSip: state.showLumpsumView ? state.secureSip : secure,
      secureLumpsum: state.showLumpsumView ? secure : state.secureLumpsum,
      growthSip: state.showLumpsumView ? state.growthSip : growth,
      growthLumpsum: state.showLumpsumView ? growth : state.growthLumpsum,
      sipTillAge: sipTillAge,
      sipGrowth: sipGrowth
    })

    setState((prevState) => ({
      ...prevState,
      lumpsum: prevState.showLumpsumView ? total : prevState.lumpsum,
      monthlySip: prevState.showLumpsumView ? prevState.monthlySip : total,
      secureSip: prevState.showLumpsumView ? prevState.secureSip : secure,
      secureLumpsum: prevState.showLumpsumView ? secure : prevState.secureLumpsum,
      growthSip: prevState.showLumpsumView ? prevState.growthSip : growth,
      growthLumpsum: prevState.showLumpsumView ? growth : prevState.growthLumpsum,
      showEditModal: false,
      sipTillAge: sipTillAge,
      sipGrowth: sipGrowth
    }));
  };

  const hasNegativeValues = (obj) => {
    const isNegativeValue = Object.values(obj)?.some(item => Number(item?.Begin_start_bal) < 0);
    return isNegativeValue;
  }

  const findNegativeValueObject = (obj) => {
    const negativeItem = Object.values(obj)?.find(item => Number(item?.Begin_start_bal) < 0);
    return negativeItem ? negativeItem.cashflowage : 0;
  }

  const renderNavView = () => {
    const items = getCashLadderPathName();
    const location = props.location;
    return (
      <BreadCrumb
        items={items}
        currentPath={location.pathname}
        onClickBackIcon={onClickBackIcon}
      />
    );
  };

  const renderTitleView = () => {
    const information = "";
    const title = t("CashLadder: Your Investment");
    return <TitleView title={title} information={information} />;
  };

  const renderAmountDisplayView = (label, amount, onPressEdit) => {
    return (
      <div className={"clp_amtDisplayView"}>
        <label className={"clp_amountLabelStyle"}>{label}</label>
        <div className={"clp_amtNeditView"}>
          <label className={"clp_amountStyle"}>{`₹  ${amount}`}</label>
          <img
            src={Edit}
            alt={"Edit"}
            className={"clp_editIconStyle"}
            onClick={onPressEdit}
          />
        </div>
      </div>
    );
  };

  const renderLumpsumNSipView = () => {
    return (
      <div className={"clp_detailsView"}>
        {renderAmountDisplayView(
          t("CashLadder: Lumpsum"),
          state.lumpsum,
          onPressEditLumpsum
        )}
        {renderAmountDisplayView(
          t("CashLadder: Monthly SIP"),
          state.monthlySip,
          onPressEditSIP
        )}
        {renderLinkView(t("CashLadder: Optimize SIP"), onPressOptimizeSIP)}
      </div>
    );
  };

  const renderTopButtonsView = () => {
    return (
      <div className={"clp_topButtonsView"}>
        {renderButtonView(t("CashLadder: Generate Cashladder"), onPressGenerate)}
        <div style={{ width: '160px' }} />
      </div>
    );
  };

  const renderFinancialNCashladderView = () => {
    const cashValue = state.cashLadderValues;
    const negativeYr = findNegativeValueObject(state.cashLadderValues);
    const displayNegativeYr = negativeYr > 0 ? negativeYr : state.lifeexpectancy;
    return (
      <div className={"clp_financialView"}>
        <CashLadderFinancialView
          cashLadderValues={cashValue}
          effectiveData={effectiveData}
          negativeYr={displayNegativeYr}
          showDisclaimer={true}
        />
        <div className="clp_disclaimerView">
          <div className="clp_effectiveView">
            <label className="clp_effectRateStyle">{`GBR : ${Number((effectiveData?.gbeffectiverate) * 100)?.toFixed(2)} %`}</label>
            <label className="clp_effectRateStyle">{`SBR : ${Number((effectiveData?.sbeffectiverate) * 100)?.toFixed(2)} %`}</label>
            <label className="clp_negativeStyle">{`Your Portfolio should last till your age of ${displayNegativeYr} years`}</label>
          </div>
          <label className="clp_disclaimerStyle">{"This is an illustrative scenario plan with respect to your financial goals and details that you have provided to us. This may vary from actuals and Fliber or Lookinglaz Technologies LLP does not guarantee any returns whatsoever. "}</label>
        </div>
      </div>
    );
  };

  const renderGoalFulFillmentView = () => {
    const preparedGoalData = prepareGoalAllocationData()
    return (
      <div className={"clp_goalView"}>
        <GoalFulFillmentView
          goalAllocationValues={preparedGoalData}
          onPressApply={onPressSearchApply}
        />
      </div>
    );
  };

  const renderContentView = () => {
    return (
      <div className={"clp_content"}>
        {renderTitleView()}
        {renderLumpsumNSipView()}
        {renderTopButtonsView()}
        {renderFinancialNCashladderView()}
        {renderGoalFulFillmentView()}
      </div>
    );
  };

  const renderLinkView = (linkName, onPressLink) => {
    return (
      <div className={"clp_linkView"} onClick={onPressLink}>
        <label className={"clp_linkStyle"}>{linkName}</label>
      </div>
    );
  };
  const renderToastView = () => {
    return (
      <Toast showToast={state.showToast} toastMessage={state.toastMessage} />
    );
  };

  const renderProgressDialog = () => {
    return (
      <ProgressDialog
        showProgress={state.showProgress}
        progressMessage={state.progressMessage}
      />
    );
  };
  const renderButtonView = (
    buttonName,
    onPressButton,
    isEmptyButton = false,
    isDisabled = false
  ) => {
    return (
      <div className={"clp_buttonView"}>
        <Button
          buttonName={buttonName}
          emptyButton={isEmptyButton}
          onPressButton={onPressButton}
          disabled={isDisabled}
        />
      </div>
    );
  };

  const renderBottomView = () => {
    //Begin start bal has negative value then enable optimize goal fulfillment, if it has only positive then disable the button 
    const disableOptimizeButton = !hasNegativeValues(state.cashLadderValues);
    const userDetail = getUserDetails();
    const cashladderAlreadyUpdated = !userDetail?.iscashladderupdated;
    return (
      <div className={"clp_bottomView"}>
        {cashladderAlreadyUpdated && renderLinkView(t("CashLadder: Goal details"), onPressGoalDetails)}
        {cashladderAlreadyUpdated && renderLinkView(t("CashLadder: Financial details"), onPressFinancialDetails)}
        {renderLinkView(t("CashLadder: View details"), onPressViewDetails)}
        {renderButtonView(
          t("CashLadder: Optimize Goal FulFillment"),
          onPressOptimize,
          false,
          true
        )}
        {renderButtonView(t("CashLadder: Accept & Proceed"), onPressAccept, false, !cashladderAlreadyUpdated)}
      </div>
    );
  };

  const renderEditModalView = () => {
    return (
      <CashLadderEditModal
        open={state.showEditModal}
        isLumpsumView={state.showLumpsumView}
        onClickClose={onPressClose}
        onPressSaveButton={onPressSaveButton}
        lumpsum={state.lumpsum}
        secureLumpsum={state.secureLumpsum}
        growthLumpsum={state.growthLumpsum}
        monthlySip={state.monthlySip}
        secureSip={state.secureSip}
        growthSip={state.growthSip}
        currentAge={state.currentAge}
        sipTillAge={state.sipTillAge}
        sipGrowth={state.sipGrowth}
        lifeexpectancy={state.lifeexpectancy}
      />
    );
  };

  const renderOptimizeDataModal = () => {
    return (
      <OptimizeDataTableView
        showModal={state.showOptimizeDataModal}
        onClose={onCloseOptimizeModal}
        onPressAccept={onPressAcceptOptimization}
        onPressReject={onPressRejectOptimization}
      />
    );
  }

  return (
    <div className={"clp_container"}>
      {renderNavView()}
      {renderContentView()}
      {renderBottomView()}
      {state.showEditModal && renderEditModalView()}
      {renderToastView()}
      {renderProgressDialog()}
      {state.showModal && renderTabelModal()}
      {state.showSuccessModal && renderSuccessModal()}
      {state.showOptimizeDataModal && renderOptimizeDataModal()}
    </div>
  );
};

export default CashLadderPage;
