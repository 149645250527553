import React from "react";
import "./index.css";
import Logo from "../../assets/svgs/logo.svg";
import Expand from "../../assets/svgs/expand.svg";
import Collapsee from "../../assets/svgs/collapse.svg";
import { t } from "i18next";
import { getMenuList } from "../../rbac";
import { AlertNotify } from "../components";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    const menulist = getMenuList();
    this.state = {
      showIconMenu: false,
      selectedItem: menulist[0],
      isChecked: false,
      selectedSubMenu: null,
      selectedMenuIndex: null,
      isExpanded: false,
    };
  }

  setCollapse() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  onselectMenu(selectedMenu, index) {
    if (selectedMenu.subMenus?.length > 0) {
      this.setState((prevState) => ({
        selectedMenuIndex: prevState.selectedMenuIndex === index ? null : index,
        isChecked: true,
      }));
    } else {
      this.props.navigate(selectedMenu.url);
    }
  }

  onChangeMenu() {
    this.setState({ showIconMenu: !this.state.showIconMenu });
  }

  onSelectSubMenu(subMenu, index) {
    this.setState({ selectedSubMenu: subMenu });
    if (subMenu.showToast) {
      AlertNotify.info(subMenu.toastMsg)
    } else {
      this.props.navigate(subMenu.url);
    }
  }

  renderHeaderView() {
    return (
      <div className="m_headerView">
        <img src={Logo} alt={Logo} className="m_logoStyle" />
        {!this.state.showIconMenu ? (
          <label className="m_titleStyle">{t("title")}</label>
        ) : (
          <div />
        )}
      </div>
    );
  }

  renderSubMenuItemsView(subMenus) {
    return subMenus.map((subMenu, index) => (
      <div
        key={index}
        className="m_subMenuItemView"
        onClick={() => {
          this.onSelectSubMenu(subMenu, index);
        }}
      >
        <label className="m_subMenuStyle">{subMenu.name}</label>
      </div>
    ));
  }

  renderMenuItemsView() {
    const menuItems = getMenuList();
    return menuItems.map((menu, index) => {
      if (menu.show) {
        return (
          <div key={index} className="m_menuListView">
            <div
              className="m_menuItemView"
              onClick={() => {
                this.onselectMenu(menu, index);
              }}
            >
              <img
                src={menu.icon}
                alt={menu.icon}
                className="m_menuIconStyle"
              />
              {!this.state.showIconMenu ? (
                <label
                  className="m_menuStyle"
                  onClick={() => {
                    this.setCollapse();
                  }}
                >
                  {menu.name}
                </label>
              ) : (
                <div />
              )}
              {!this.state.showIconMenu && menu.subMenus?.length > 0 && (
                <img
                  src={this.state.isExpanded ? Expand : Collapsee}
                  alt={this.state.isExpanded ? Expand : Collapsee}
                  className="m_expandIconStyle"
                  onClick={() => {
                    this.setCollapse();
                  }}
                />
              )}
            </div>
            {this.state.selectedMenuIndex === index && (
              <div>
                {!this.state.showIconMenu &&
                  menu.subMenus?.length > 0 &&
                  this.renderSubMenuItemsView(menu.subMenus)}
              </div>
            )}
          </div>
        );
      } else {
        return <></>;
      }
    });
  }

  render() {
    return (
      <div
        className="m_container"
        style={{
          width: this.state.showIconMenu ? 68 : 256,
        }}
      >
        {this.renderHeaderView()}
        {this.renderMenuItemsView()}
      </div>
    );
  }
}

export default Menu;
