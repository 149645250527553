import { apiCall } from "./apiservice";
import Methods from "./methods.json";

class LiabilityApi {
  static createLiability(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserLiabilities/`, Methods.POST, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static updateLiability(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserLiabilities/`, Methods.PATCH, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static deleteLiability(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserLiabilities/${data.id}`, Methods.DELETE, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserLiabilityByUserId(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserLiabilities/${data.uuid}`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getUserLiabilityByCategory(data) {
    return new Promise((resolve, reject) => {
      apiCall(
        `UserLiabilities/by/category?userid=${data.uuid}&liabilitiescategory=${data.category}`,
        Methods.GET
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getAllUserLiabilities(data) {
    return new Promise((resolve, reject) => {
      apiCall(`UserLiabilities/`, Methods.GET)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default LiabilityApi;
