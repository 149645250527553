import React from "react";
import { t } from "i18next";
import './index.css';
import { Button } from "../../components";
import BgImage_1 from '../../../assets/svgs/websvgs/bgImage1.svg';
import BgImage_2 from '../../../assets/svgs/websvgs/bgImage2.svg';
import BgImage_3 from '../../../assets/svgs/websvgs/bgImage3.svg';
import BgImage_4 from '../../../assets/svgs/websvgs/bgImage4.svg';
import { Carousel } from "rsuite";

const Banners = (props) => {

  const onPressGetStarted = () => {
    if (props.onPressGetStarted) {
      props.onPressGetStarted()
    }
  }

  const renderButtonView = (buttonName, onPressButton) => {
    return (
      <Button
        buttonName={buttonName}
        onPressButton={onPressButton}
      />
    );
  }

  const renderBannersView = (bgImage, label, heading, subLabel) => {
    return (
      <div
        className="ba_bannersView"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="ba_bannerContentView">
          <label className={"ba_labelStyle_1"}>{label}</label>
          <label className={"ba_labelStyle_2"}>{heading}</label>
          <label className={"ba_labelStyle_3"}>{subLabel}</label>
          <div className="ba_startButtonView">
            {renderButtonView(t('Get Started'), onPressGetStarted)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="ba_container">
      <Carousel
        shape={"dot"}
        autoplay={true}
        autoplayInterval={3000}
        className="ba_content"
      >
        {renderBannersView(
          BgImage_1,
          t("The Token of Trust"),
          t("A Friendly Investment Advisor You can rely on"),
          t("Curated by Industry Experts with 50+ Years of Cumulative Experience")
        )}
        {renderBannersView(
          BgImage_2,
          "",
          t("Kick Start your financial journey with us"),
          t("With Personalized Financial Planning Backed by Zero Commission Diversified Products")
        )}
        {renderBannersView(
          BgImage_3,
          "",
          t("Customer First – High Touch Model"),
          t("Have access to your financial advisor anytime, anywhere and live a stress-free financial life")
        )}
        {renderBannersView(
          BgImage_4,
          "",
          t("Does Your Current Portfolio Align with Your Future Planning?"),
          t("Plan Your Financial Freedom with Our Scenario Planning Proprietary Tool- Cash Ladder, and Arrive at the Most Optimum Financial Plan for You")
        )}
      </Carousel>
    </div>
  );
}

export default Banners;
