import BackendApi from "../backend/index";

class ScoreCalculation {

  static calculateInsuranceScore(params) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.ScoreCalculationApi.calculateInsuranceScore(params)
        .then((data) => {
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  }

  static calculateRRScore(params) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.ScoreCalculationApi.calculateRRScore(params)
        .then((data) => {
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  }

  static calculateSustainAbilityScore(params) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.ScoreCalculationApi.calculateSustainAbilityScore(params)
        .then((data) => {
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  }
}

export default ScoreCalculation;