import BackendApi from "../backend/index";

class Support {

  static createSupportQuery(data) {
    return new Promise((resolve, reject) => {
      BackendApi.ServerApi.SupportApi.createSupportQuery(data).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    })
  }
    static getMonthEndingComplaints(){
    return new Promise((resolve,reject)=>{
      BackendApi.ServerApi.SupportApi.getMonthEndingComplaints().then(response =>{   
        resolve(response);
      }).catch(error=>{
        reject(error);
      })
    })
   }
   static getMonthlyDisposalTrend(){
    return new Promise((resolve,reject)=>{
      BackendApi.ServerApi.SupportApi.getMonthlyDisposalTrend().then(response =>{        
        resolve(response);
      }).catch(error=>{
        reject(error);
      })
    })
   }

   static getYearlyDisposalTrend(){
     return new Promise((resolve,reject)=>{
      BackendApi.ServerApi.SupportApi.getYearlyDisposalTrend().then(response =>{
        resolve(response);
      }).catch(error=>{
        reject(error);
      })
    })
   }
}

export default Support;