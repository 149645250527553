import React, { Component } from "react";
import "./npsdetailpage.css";
import { t } from "i18next";
import NPSSelectedToggle from "../../../assets/svgs/selected_toggle.svg";
import NPSUnSelectedToggle from "../../../assets/svgs/unselected_toggle.svg";
import {
  BreadCrumb,
  Button,
  CostItemPerYear,
  FloatingLabelInput,
  Slider,
  TitleView,
  ToggleSingleSelection,
  SideModel,
  Toast,
  ProgressDialog,
  ToggleSwitch,
} from "../../components";
import * as Bl from "../../../businesslogic";
import { getCurrentYear } from "../../utils/datetime";
import { calculate_age, calculate_year, getUserDOB } from "../../common";
import { getFinancialMarketPath } from "../../common";
import * as logger from "../../utils/logger/index";
import { getCommonConfigInfo } from "../../utils/common";
import { DEFAULT_OFFSET } from "../../constants";
import { getCurrentAge } from "../../utils/common";
class NPSDetailPage extends Component {
  constructor(props) {
    super(props);
    const params = props.location.state;
    const investmentData = params?.investmentData ? params.investmentData : {};
    const isEditView = params?.isEditView ? params.isEditView : false;
    const investdetails = investmentData?.investmentdetails[0];
    const accountType = investdetails?.accounttype
      ? investdetails?.accounttype
      : "Tier 1";

    this.state = {
      startValue: getCurrentYear() + 1,
      startAge: getCurrentYear() + 1,
      selectedIndex: accountType === "Tier 1" ? 0 : 1,
      showCostItem: false,
      isViewSaleValueClicked: false,
      viewExpense: false,
      investmentsData: investmentData ? new Bl.Investment(investmentData) : {},
      initialAccountType: accountType,
      accountType,
      initialFundManager: investdetails?.fundmanager
        ? investdetails?.fundmanager
        : "",
      fundManager: investdetails?.fundmanager
        ? investdetails?.fundmanager
        : "",
      initialWithdrawal: investdetails?.lumpsumwithdrawl
        ? investdetails?.lumpsumwithdrawl
        : 0,
      withdrawal: investdetails?.lumpsumwithdrawl
        ? investdetails?.lumpsumwithdrawl
        : 0,
      initialAnnuityRate: investdetails?.annuityrate
        ? investdetails?.annuityrate
        : 0,
      annuityRate: investdetails?.annuityrate
        ? investdetails?.annuityrate
        : 0,
      initialEquityPercentage: investdetails?.equity
        ? Number(investdetails?.equity)
        : 0,
      equityPercentage: investdetails?.equity
        ? Number(investdetails?.equity)
        : 0,
      isEditView,
      currentAge: 0,
    };
    this.isRenderingAlready = false;
    this.costPerYearViewRef = React.createRef();
    this.dbData = investmentData
      ? JSON.parse(JSON.stringify(new Bl.Investment(investmentData)))
      : {};
  }

  componentDidMount() {
    if (!this.isRenderingAlready) {
      this.isRenderingAlready = true;
      this.getConfigInfo();
      this.getCurrentAge();

    }
  }
  getCurrentAge() {
    const data = { dob: getUserDOB(), offset: DEFAULT_OFFSET }
    this.setState({ showProgress: true }, () => {
      getCurrentAge(data)
        .then((response) => {
          const currentAge = response.currentage;
          const investmentObj = this.state.investmentsData;
          investmentObj.setUserAge(currentAge);
          this.setState({
            currentAge: currentAge,
            showProgress: false,
          });
        })
        .catch((error) => {
          console.log("NPSDetailsPage.js/getCurrentAge Error:", error);
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }
  getConfigInfo() {
    this.setState({ showProgress: true }, () => {
      getCommonConfigInfo()
        .then((configList) => {
          const configInfo = configList[0];
          this.setState({
            initialWithdrawal: configInfo.npslumwithdrawal,
            withdrawal: configInfo.npslumwithdrawal,
            initialAnnuityRate: configInfo.npsannuityrate * 100,
            annuityRate: configInfo.npsannuityrate * 100,
            showProgress: false,
          });
        })
        .catch((error) => {
          logger.error({ error });
          this.showToast(t("someErrorHasOccured"));
        });
    });
  }

  isStateValueChanged() {
    const {
      initialAccountType,
      accountType,
      initialFundManager,
      fundManager,
      initialWithdrawal,
      withdrawal,
      initialAnnuityRate,
      annuityRate,
      initialEquityPercentage,
      equityPercentage,
    } = this.state;
    return (
      initialAccountType !== accountType ||
      initialFundManager !== fundManager ||
      initialAnnuityRate !== Number(annuityRate) ||
      initialWithdrawal !== Number(withdrawal) ||
      initialEquityPercentage !== equityPercentage
    );
  }

  onSelectItem(path) {
    const localData = new Bl.Investment(this.state.investmentsData);
    localData.revertAllChanges();
    this.props.navigate(path);
  }

  onChangeFund(value) {
    this.setState({ fundManager: value });
  }

  onChangeBalance(value) {
    const investment = this.state.investmentsData;
    investment.setCurrentValue(value ? Number(value) : 0);
    this.setState({ viewExpense: false });
  }

  onChangeContribution(value) {
    const investment = this.state.investmentsData;
    investment.setAnnualContribution(value ? Number(value) : 0);
    this.setState({ viewExpense: false });
  }

  onChangeGrowthRate(value) {
    const investment = this.state.investmentsData;
    investment.setContributionGrowthRate(value ? Number(value) : 0);
    this.setState({ viewExpense: false });
  }

  onChangeWithdrawal(value) {
    this.setState({ withdrawal: value, viewExpense: false });
  }

  onChangeAnnuityRate(value) {
    this.setState({ annuityRate: value, viewExpense: false });
  }

  handleToggleSwitchChange(selectedItem, isSelected) {
    const investment = this.state.investmentsData;
    investment.setIsIncludeFinancialPlan(isSelected);
    this.setState({ viewExpense: false });
  }

  checkIsDataValid(data, isView = false) {
    let isValid = true;
    let message = "";
    if (
      (isView =
        false && !this.isDescValid(this.state.fundManager))
    ) {
      isValid = false;
      message = t("npsToastFundManager");
    } else if (!this.isAmountValid(data.currentvalue)) {
      isValid = false;
      message = t("npsToastCurrentValue");
    } else if (!this.isAmountValid(data.annualcontribution)) {
      isValid = false;
      message = t("npsToastAnnualContribution");
    } else if (!this.isMaturityYearValid(data.maturityyear)) {
      isValid = false;
      message = t("npsToastMaturityYear");
    } else if (
      this.state.selectedIndex === 0 &&
      !this.isAmountValid(Number(this.state.withdrawal))
    ) {
      isValid = false;
      message = t("npsToastLumpsumWithdrawl");
    } else if (
      this.state.selectedIndex === 0 &&
      !this.isAmountValid(Number(this.state.annuityRate))
    ) {
      isValid = false;
      message = t("npsToastAnnuityRater");
    }
    return { isValid, message };
  }

  isDescValid(desc) {
    return desc && desc.length > 0;
  }
  isAmountValid(amount) {
    return amount && amount > 0;
  }
  isMaturityYearValid(maturityyear) {
    return maturityyear !== 0;
  }

  toggleType = (selectedItem, checked) => {
    this.setState({ accounttype: checked });
  };

  prepareNPSData() {
    const npsDetails = [{
      accounttype: this.state.accountType,
      fundmanager: this.state.fundManager || "",
      lumpsumwithdrawl: this.state.selectedIndex === 0 ? this.state.withdrawal || 0 : 0,
      annuityrate:
        this.state.selectedIndex === 0 ? (this.state.annuityRate / 100) || 0 : 0,
      equity: this.state.equityPercentage || 0, // equity for npscalulator ( view expense )
      equitypercentage: this.state.equityPercentage || 0, // equitypercentage for create & edit
    }];
    return { npsDetails };
  }

  createUserInvestment(data) {
    const currentYear = getCurrentYear();
    const investmentData = this.state.investmentsData;
    const startValue = investmentData?.maturityyear
      ? investmentData.maturityyear
      : currentYear + 1;
    const maturityAge = calculate_age(startValue, currentYear, this.state.currentAge)
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    if (investmentObj.maturityyear === 0) {
      investmentObj.setMaturityYear(getCurrentYear() + 1);
    }
    investmentObj.setMaturityAge(maturityAge)
    investmentObj.setInvestmentDetails(data.npsDetails);
    const isDataValid = this.checkIsDataValid(investmentObj);
    console.log({ investmentObj })
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .createInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/npsdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  updateUserInvestment(data) {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    investmentObj.setInvestmentDetails(data.npsDetails);
    const isDataValid = this.checkIsDataValid(investmentObj);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .updateInvestment()
          .then(() => {
            this.setState({ showProgress: false }, () => {
              this.props.navigate("/npsdashboard");
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressViewExpense() {
    const investmentObj = new Bl.Investment(this.state.investmentsData);
    const isDataValid = this.checkIsDataValid(investmentObj, true);
    const data = this.prepareNPSData();
    investmentObj.setInvestmentDetails(data.npsDetails);
    if (isDataValid.isValid) {
      this.setState({ showProgress: true }, () => {
        investmentObj
          .createNPSCalculator()
          .then((response) => {
            const {
              Total_corpus_accumaled,
              amount_withdrawal,
              anunity_purchase,
              monthly_pension,
              total_amount_invested,
            } = response;
            this.setState({
              viewExpense: true,
              isViewSaleValueClicked: true,
              showProgress: false,
              showCostItem: true,
              npsData: {
                Total_corpus_accumaled,
                amount_withdrawal,
                anunity_purchase,
                monthly_pension,
                total_amount_invested,
              },
            }, () => {
              this.costPerYearViewRef.current.scrollIntoView({ behavior: "smooth" });
            });
          })
          .catch((error) => {
            logger.error({ error });
            this.showToast(t("someErrorHasOccured"));
          });
      });
    } else {
      const toastMessage = isDataValid.message;
      this.showToast(toastMessage);
    }
  }

  onPressSaveButton() {
    const data = this.prepareNPSData();
    if (this.state.isEditView) {
      this.updateUserInvestment(data);
    } else {
      this.createUserInvestment(data);
    }
  }

  handleChangeStart(event) {
    const newValue = event.target.value;
    this.setState({
      equityPercentage: newValue ? Number(newValue) : newValue,
      viewExpense: false,
    });
  }
  handleChangeStartMaturity(event) {
    const newValue = event.target.value;
    const investment = this.state.investmentsData;
    const currentYear = getCurrentYear();
    const maturityAge = calculate_age(newValue, currentYear, this.state.currentAge)
    investment.setMaturityYear(newValue ? Number(newValue) : newValue);
    investment.setMaturityAge(maturityAge)
    this.forceUpdate();
  }

  onSelectRadioButton(index) {
    const selectedItem = this.getTypes(index);
    this.setState({ accountType: selectedItem, selectedIndex: index });
  }

  getTypes(index) {
    const types = ["Tier 1", "Tier 2"];
    return types[index];
  }

  hideToast() {
    this.setState({ toastMessage: "", showToast: false });
  }

  showToast(toastMessage) {
    this.setState(
      { toastMessage, showToast: true, showProgress: false },
      () => {
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      }
    );
  }

  renderNavView() {
    const items = getFinancialMarketPath("NPS");
    const location = this.props.location;
    return (
      <BreadCrumb
        items={items}
        isLastPath={true}
        currentPath={location.pathname}
        isStateValueChanged={this.isStateValueChanged()}
        dbData={this.dbData}
        localData={this.state.investmentsData}
        onClickBackIcon={this.onSelectItem.bind(this)}
        onClickItem={this.onSelectItem.bind(this)}
      />
    );
  }

  renderTitleView() {
    const title = t("networthAssetsFM2");
    return <TitleView title={title} />;
  }

  renderInputView(
    label,
    value,
    onChange,
    showRupeeSymbol = false,
    showPercentage = false
  ) {
    return (
      <div className="ndp_input">
        <FloatingLabelInput
          label={label}
          value={value}
          showRupeeSymbol={showRupeeSymbol}
          showPercentage={showPercentage}
          onChangeValue={onChange}
        />
      </div>
    );
  }

  renderToggleView(label, checked, onChange) {
    return (
      <div className="pdp_toggle_view">
        <label className="pdp_toggle_text">{label}</label>
        <ToggleSwitch
          checkedChildren={t("fdToggle1")}
          unCheckedChildren={t("fdToggle2")}
          onChangeToggle={this.handleToggleSwitchChange.bind(this)}
          defaultChecked={checked}
        />
      </div>
    );
  }

  renderRadioContent(index) {
    const type = this.getTypes(index);
    return (
      <div className="ndp_toggle_input">
        <img src={NPSUnSelectedToggle} alt="UnSelectToggle" />
        <label className="ndp_tier_text">{type}</label>
      </div>
    );
  }

  renderSelectedRadioContent(index) {
    const type = this.getTypes(index);
    return (
      <div className="ndp_toggle_input">
        <img src={NPSSelectedToggle} alt="SelectedToggle" />
        <label className="ndp_tier_text">{type}</label>
      </div>
    );
  }

  renderRadioToggles() {
    return (
      <div className="ndp_toggle_container">
        <ToggleSingleSelection
          noOfRows={1}
          noOfCols={2}
          onSelectItem={this.onSelectRadioButton.bind(this)}
          contentCb={this.renderRadioContent.bind(this)}
          selectedContentCb={this.renderSelectedRadioContent.bind(this)}
          selectedIndex={this.state.selectedIndex}
        />
      </div>
    );
  }

  renderLumpsumAnnuityView() {
    return (
      <div className="ndp_input_lumb_row">
        {this.renderInputView(
          t("npsInputWithdrawal"),
          this.state.withdrawal,
          this.onChangeWithdrawal.bind(this),
          false,
          true
        )}
        {this.renderInputView(
          t("npsInputAnnuityRate"),
          this.state.annuityRate,
          this.onChangeAnnuityRate.bind(this),
          false,
          true
        )}
      </div>
    );
  }

  renderNPSDetails() {
    const investmentData = this.state.investmentsData;
    const balance = investmentData?.currentvalue
      ? investmentData?.currentvalue
      : "";
    const contribution = investmentData?.annualcontribution
      ? investmentData?.annualcontribution
      : "";
    const contributionGrowthRate = investmentData?.contributiongrowthrate;
    const growthRate = contributionGrowthRate || contributionGrowthRate === 0 ? contributionGrowthRate : "";

    return (
      <div className="ndp_description_view">
        <div className="ndp_input_row">
          {this.renderInputView(
            t("npsInputFundManager"),
            this.state.fundManager,
            this.onChangeFund.bind(this)
          )}
          {this.renderInputView(
            t("npsinputCurrentBalance"),
            balance,
            this.onChangeBalance.bind(this),
            true
          )}
        </div>
        <div className="ndp_input_sec_row">
          {this.renderInputView(
            t("npsInputYearlyContribution"),
            contribution,
            this.onChangeContribution.bind(this),
            true
          )}
          {this.renderInputView(
            t("npsInputAnnualGrowthRate"),
            growthRate,
            this.onChangeGrowthRate.bind(this),
            false,
            true
          )}
        </div>
      </div>
    );
  }

  renderCostItemPerYear() {
    const npsData = this.state.npsData;
    return (
      <div className="ndp_cost_item_view" ref={this.costPerYearViewRef}>
        <CostItemPerYear
          item={{
            label: t("npsExpectedCorpusYear"),
            cost: npsData?.Total_corpus_accumaled,
          }}
        />
        <CostItemPerYear
          item={{
            label: t("npsTaxFreeWithdrawalYear"),
            cost: npsData?.amount_withdrawal,
          }}
        />
        <CostItemPerYear
          item={{
            label: t("npsMonthlyPensionYear"),
            cost: npsData?.monthly_pension,
          }}
        />
      </div>
    );
  }
  renderEquityPercentage() {
    const equity = this.state.equityPercentage;
    const age = calculate_age(this.state.startAge);
    return (
      <div className="ndp_start_year_row">
        <label className="ndp_start_text">{t("npsSlider")}</label>
        <div className="ndp_slider_content">
          <Slider
            value={equity}
            age={age}
            min={0}
            max={100}
            markStep={20}
            handleChange={this.handleChangeStart.bind(this)}
            showAge={false}
            showPercentage={true}
          />
        </div>
      </div>
    );
  }
  renderExpectedReturn() {
    const investmentData = this.state.investmentsData;
    const currentYear = getCurrentYear();
    const maturityyear =
      investmentData && investmentData.maturityyear
        ? investmentData.maturityyear
        : getCurrentYear() + 1;
    const age = calculate_age(maturityyear, currentYear, this.state.currentAge);
    return (
      <div className="ndp_start_year_row">
        <label className="ndp_start_text">{t("npsSliderLiquida")}</label>
        <div className="ndp_slider_content">
          <Slider
            value={maturityyear}
            age={age}
            min={calculate_year(60)}
            max={calculate_year(80)}
            markStep={5}
            handleChange={this.handleChangeStartMaturity.bind(this)}
            showAge={true}
          />
        </div>
      </div>
    );
  }

  renderButtonsView() {
    const { selectedIndex } = this.state;
    return (
      <div className="ndp_bottomView">
        <div className="ndp_buttonView">
          <div className="ndp_material_button">
            {selectedIndex !== 1 && (
              <Button
                buttonName={t("npsButton1")}
                emptyButton={true}
                disabled={!this.state.accountType}
                onPressButton={this.onPressViewExpense.bind(this)}
              />
            )}
          </div>
          <div className="ndp_save">
            <Button
              buttonName={t("npsButton2")}
              onPressButton={this.onPressSaveButton.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderContentView() {
    const { selectedIndex, investmentsData } = this.state;
    return (
      <div className="ndp_content_view">
        {this.renderTitleView()}
        <div className="ndp_detail_view">
          <label className="ndp_detail_text">{t("npsSubTitle")}</label>
          {this.renderRadioToggles()}
          {this.renderNPSDetails()}
          <div className="ndp_seperatorStyle" />
          <div className="ndp_sliderStyle">
            {this.renderEquityPercentage()}
            {this.renderExpectedReturn()}
          </div>
          <React.Fragment>
            <div className="ndp_seperatorStyle" />
            {selectedIndex !== 1 && this.renderLumpsumAnnuityView()}
          </React.Fragment>
          {this.state.showCostItem && selectedIndex !== 1 && (
            <React.Fragment>
              {this.state.viewExpense && this.renderCostItemPerYear()}
            </React.Fragment>
          )}
        </div>
        <div className="ndp_seperatorStyle" />
        <div className="pdp_retirement_toggle">
          {this.renderToggleView(t("fdTg1Text"), investmentsData?.isincludefinancialplan)}
        </div>
      </div>
    );
  }
  renderDrawerContent() {
    const headerTitle = t("financialPlan");
    const step = 1;
    return <SideModel step={step} headerTitle={headerTitle}></SideModel>;
  }

  renderToastView() {
    return (
      <Toast
        showToast={this.state.showToast}
        toastMessage={this.state.toastMessage}
      />
    );
  }
  renderProgressDialog() {
    return (
      <ProgressDialog
        showProgress={this.state.showProgress}
        progressMessage={this.state.progressMessage}
      />
    );
  }
  render() {
    return (
      <div className="ndp_container">
        {this.renderNavView()}
        {this.renderContentView()}
        {this.renderButtonsView()}
        {/* {this.renderDrawerContent()} */}
        {this.renderToastView()}
        {this.renderProgressDialog()}
      </div>
    );
  }
}

export default NPSDetailPage;
